import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DateTimePickerComponent } from '@shared/ui-components/date-time-picker/date-time-picker.component';
import { TimepickerModule } from '@shared/ui-components/timepicker/timepicker.module';
import { DatepickerModule } from '@shared/ui-components/datepicker/datepicker.module';

@NgModule({
	imports: [
		MatFormFieldModule,
		MatDatepickerModule,
		MatIconModule,
		TimepickerModule,
		FormsModule,
		ReactiveFormsModule,
		DatepickerModule,
	],
	declarations: [DateTimePickerComponent],
	exports: [DateTimePickerComponent],
})
export class DateTimePickerModule {}
