import {ITemplate} from '@shared/models/common-types';
import {ComponentType} from '@angular/cdk/overlay';
import {ConnectionLossModalComponent} from '@shared/modals/connection-loss/connection-loss-modal.component';
import {MapModalComponent} from '@shared/modals/map/map-modal.component';
import {MnemoMapModalComponent} from '@shared/modals/mnemo-map/mnemo-map-modal.component';
import {ScheduleInfoModalComponent} from '@shared/modals/schedule-info/schedule-info-modal.component';
import {CreatingNewPassComponent} from '@shared/modals/creating-new-pass/creating-new-pass.component';
import {IdlesCommentsComponent} from '@shared/modals/idles-comments/idles-comments.component';
import {IdleEditingComponent} from '@shared/modals/idle-editing/idle-editing.component';
import {QrCodeGeneratorComponent} from '@shared/modals/qr-code-generator/qr-code-generator.component';
import {TrendsTemplateModalComponent} from '@shared/modals/trends-template/trends-template-modal.component';
import {UnitInfoModalComponent} from '@shared/modals/unit-info-modal/unit-info-modal.component';
import {ReportReviewerModalComponent} from '@shared/modals/report-reviewer-modal/report-reviewer-modal.component';
import {QrCodeScannerComponent} from '@shared/modals/qr-code-scanner/qr-code-scanner.component';
import {UnitsSelectorModalComponent} from '@shared/modals/units-selector/units-selector-modal.component';
import {UnitSubscriptionModalComponent} from '@shared/modals/unit-subscription/unit-subscription-modal.component';
import {QrGeneratorModalComponent} from '@shared/modals/qr-generator-modal/qr-generator-modal.component';
import {CompareProgramsModalComponent} from '@shared/modals/compare-programs-modal/compare-programs-modal.component';
import {WarehouseModalComponent} from '@shared/modals/warehouse-modal/warehouse-modal.component';
import {IdleEditorModalComponent} from '@shared/modals/idle-editor-modal/idle-editor-modal.component';
import {ConfirmModalComponent} from '@shared/modals/confirm-modal/confirm-modal.component';
import {VideoModalComponent} from '@shared/modals/video-modal/video-modal.component';
import {NeedResetPasswordModalComponent} from '@shared/modals/need-reset-password-modal/need-reset-password-modal.component';
import {ListOfNewChangesModalComponent} from '@shared/modals/list-of-new-changes/list-of-new-changes-modal.component';
import {IdleCreatorModalComponent} from '@shared/modals/idle-creator-modal/idle-creator-modal.component';
import {WorkflowGraphViewModalComponent} from '@shared/modals/workflow-graph-view-modal/workflow-graph-view-modal.component';
import {EnlargedImageModalComponent} from '@shared/modals/enlarged-image-modal/enlarged-image-modal.component';
import {DialogConfirmationModalComponent} from '@shared/modals/dialog-confirmation-modal/dialog-confirmation-modal.component';

export type TypeModals =
	'Map' |
	'MnemoMap' |
	'ScheduleInfo' |
	'ConnectionLoss' |
	'CreatingNewPass' |
	'IdlesComments' |
	'IdleEditing' |
	'QRCodeGenerator' |
	'TrendsTemplateModal' |
	'UnitInfoModal' |
	'ReportReviewerModal' |
	'QRCodeScanner' |
	'UnitsSelector' |
	'UnitSubscriptionModal' |
	'QRGeneratorModal' |
	'ComparePrograms' |
	'WarehouseModal' |
	'IdleEditor' |
	'ConfirmModal' |
	'VideoModal' |
	'NeedResetPasswordModal' |
	'ListOfNewChangesModal' |
	'IdleCreatorModal' |
	'UnitSubscription' |
	'WorkflowGraphViewModal' |
	'EnlargedImageModal' |
	'DialogConfirmationModal';


export const MODALS: ITemplate<TypeModals, ComponentType<any>> = {
	Map: MapModalComponent,
	MnemoMap: MnemoMapModalComponent,
	ConnectionLoss: ConnectionLossModalComponent,
	ScheduleInfo: ScheduleInfoModalComponent,
	CreatingNewPass: CreatingNewPassComponent,
	IdlesComments: IdlesCommentsComponent,
	IdleEditing: IdleEditingComponent,
	QRCodeGenerator: QrCodeGeneratorComponent,
	TrendsTemplateModal: TrendsTemplateModalComponent,
	UnitInfoModal: UnitInfoModalComponent,
	ReportReviewerModal: ReportReviewerModalComponent,
	QRCodeScanner: QrCodeScannerComponent,
	UnitsSelector: UnitsSelectorModalComponent,
	UnitSubscriptionModal: UnitSubscriptionModalComponent,
	QRGeneratorModal: QrGeneratorModalComponent,
	ComparePrograms: CompareProgramsModalComponent,
	WarehouseModal: WarehouseModalComponent,
	IdleEditor: IdleEditorModalComponent,
	ConfirmModal: ConfirmModalComponent,
	VideoModal: VideoModalComponent,
	NeedResetPasswordModal: NeedResetPasswordModalComponent,
	ListOfNewChangesModal: ListOfNewChangesModalComponent,
	IdleCreatorModal: IdleCreatorModalComponent,
	UnitSubscription: UnitSubscriptionModalComponent,
	WorkflowGraphViewModal: WorkflowGraphViewModalComponent,
	EnlargedImageModal: EnlargedImageModalComponent,
	DialogConfirmationModal: DialogConfirmationModalComponent,
};
