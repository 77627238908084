import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { IdleEditorComponent } from '@shared/components/idle-editor/idle-editor.component';
import { ButtonComponent } from '@shared/ui-components/button/button.component';


@NgModule({
	declarations: [IdleEditorComponent],
	imports: [
		CommonModule,
		FormsModule,
		BsDatepickerModule,
		ButtonComponent,
	],
	exports: [IdleEditorComponent],
})
export class IdleEditorModule {
}
