<div class="modal">
	<div class="modal__header">
		<div class="title">{{ data.title }}</div>

		<app-button
			class="g-button-secondary-empty btn-close"
			svgIcon="close"
			(click)="dialogRef.close()"
		></app-button>
	</div>

	<div class="modal__content g-flex-center-w-h">
		@if (data.paths?.length) {
			<app-report-reviewer
				class="g-width-100"
				[jasperReport]="data.paths"
			></app-report-reviewer>
		} @else {
			<app-empty-data class="empty-data"></app-empty-data>
		}
	</div>
</div>
