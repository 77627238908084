import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StaticBarComponent} from '@shared/components/charts/modules/static-bar/static-bar.component';

@NgModule({
	declarations: [StaticBarComponent],
	imports: [
		CommonModule
	],
	exports: [StaticBarComponent]
})
export class StaticBarModule {
}
