import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { QrGeneratorComponent } from '@shared/components/qr-generator/qr-generator.component';
import { ButtonComponent } from '@shared/ui-components/button/button.component';
import { CheckboxComponent } from '@shared/ui-components/checkbox/checkbox.component';
import { MatInputModule } from '@angular/material/input';


@NgModule({
	declarations: [QrGeneratorComponent],
	imports: [
		FormsModule,
		ButtonComponent,
		CheckboxComponent,
		MatInputModule,
	],
	exports: [QrGeneratorComponent],
})
export class QrGeneratorModule {
}
