<mat-form-field
	class="example-full-width"
	appearance="outline"
	[class]="classForm"
>
	<mat-label *ngIf="!!label">{{ label }}</mat-label>

	<input
		type="text"
		matInput
		[formControl]="formControl"
		[matAutocomplete]="auto"
	>

	<mat-autocomplete
		#auto="matAutocomplete"
		[displayWith]="displayWith"
		[autoActiveFirstOption]="false"
	>
		<mat-option
			*ngFor="let option of (filteredOptions | async)"
			[value]="option"
			(click)="selectValue(option)"
		>
			{{ displayWith(option) }}
		</mat-option>

		<mat-option *ngIf="!(hasOptions$ | async)" class="option-no-content">
			Ничего не найдено
		</mat-option>
	</mat-autocomplete>

	<div matSuffix class="flex-row align-items-center justify-content-center">
		<mat-spinner *ngIf="isLoading$ | async" matSuffix [strokeWidth]="2" [diameter]="24">
		</mat-spinner>

		<app-button
			appStopPropagation
			class="g-button-secondary-empty font-size-12"
			svgIcon="close"
			(click)="clear()"
		>
		</app-button>
	</div>
</mat-form-field>
