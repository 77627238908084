<div class="modal">
	<div class="modal__header">
		<h1 class="title">
			{{ data.title }}
		</h1>

		<app-button
			svgIcon="close"
			class="g-button-secondary-empty btn-close"
			(click)="closeModal()"
		></app-button>
	</div>

	<div
		id="content"
		name="content"
		class="modal__content"
	>
		<div id="print-qrcode-gen-1" class="code">
			<div *ngFor="let title of data.codeTitles" class="code__title">
				{{ title }}
			</div>

			<div class="code__imgs">
				<img *ngIf="!!data.qrCode" id="data-matrix-img">

				<hr *ngIf="!!data.qrCode && !!data.barcode">

				<img *ngIf="!!data.barcode" class="barcode128" id="barcode128">
			</div>
		</div>
	</div>

	<div class="modal__basement">
		<app-button
			class="g-button-secondary-square left"
			(click)="closeModal()"
		>
			Закрыть
		</app-button>

		<app-button
			*ngIf="!isTerminal"
			class="g-button-primary-square"
			svgIcon="print-outline"
			(click)="printQrCode('content')"
		>
			Печать
		</app-button>
	</div>
</div>
