<ng-container>
	<div class="programs_diff">
		<td-ngx-text-diff
			[left]="firstTextSlice"
			[right]="secondTextSlice"
			[diffContent]="changeContent$"
		></td-ngx-text-diff>
	</div>
	<div class="row">
		<div class="text-left col-sm-1">
			<ng-container *ngIf="firstProgramArray">
				<span class="font-size-14">Всего строк:</span> {{ firstProgramArray.length }}
			</ng-container>
		</div>
		<div class="g-col-lg-2">
			<ng-container *ngIf="totalPages > 1">
				<div class="flex-center">
					<app-pagination
						rangePosition="none"
						[pageSize]="1"
						[totalItem]="totalPages"
						(change)="pageChanged($event)"
					></app-pagination>
				</div>
				<div class="g-text-center">
					{{ currentPage * pageSize }} - {{ (currentPage + 1) * pageSize }}
				</div>
			</ng-container>
		</div>
		<div class="text-right col-sm-1">
			<ng-container *ngIf="secondProgramArray">
				<span class="font-size-14">Всего строк:</span> {{ secondProgramArray.length }}
			</ng-container>
		</div>
	</div>
</ng-container>
