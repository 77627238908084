import {Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges, ViewChild,} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Router} from '@angular/router';
import {cloneDeep} from 'lodash';
import {StatisticUnitsTableComponent} from '@shared/components/statistic-units-container/components/statistic-units-table/statistic-units-table.component';
import {IPartialInterval} from '@shared/ui-components/n-inverval/models/interval.types';
import {IUnitFull} from '../../../modules/monitoring/modules/unit/models/unit';
import {UnitHelpersService} from '../../../modules/monitoring/modules/unit/services/unit-helpers.service';
import {MODALS} from '@shared/modals/modals';
import {IIdleEditorModalData} from '@shared/modals/idle-editor-modal/models/idle-editor-modal.types';
import {ModalsService} from '@shared/services/modals.service';

@Component({
	selector: 'app-statistic-units-container',
	templateUrl: './statistic-units-container.component.html',
	styleUrls: ['./statistic-units-container.component.scss'],
})
export class StatisticUnitsContainerComponent implements OnChanges {
	@ViewChild('idleEditor', {static: false}) idleEditor;
	@ViewChild(StatisticUnitsTableComponent)
	statisticUnitsTableComponent: StatisticUnitsTableComponent;

	@Input() interval: IPartialInterval;
	@Input() selectedUnits: IUnitFull[];
	@Input() dynamicRepairData;
	@Input() dynamicData;
	@Input() idlesData;
	@Input() deviceType?: 'mobile' | 'computer' = null;
	@Input() isPrintMode = false;

	@Input() staticData;
	@Input() isIdleInChart;

	@Input() coefficientsDataStatistic;

	@Input() showUnitsNames = true;
	@Output() onClickUnit = new EventEmitter();
	@Output() built = new EventEmitter();

	@Input() isUseList = false;

	dynamicCharts = new Map();
	public idleId: number;

	constructor(
		@Inject(DOCUMENT) private _document: Document,
		private _helpers: UnitHelpersService,
		private _router: Router,
		private _dialog: ModalsService,
	) {
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (this.coefficientsDataStatistic) {
			const coefficientsList = Object.keys(
				this.coefficientsDataStatistic[this.selectedUnits[0].id]
			);

			// Добавление в обект юнита коэффицентов,
			// для последующей более удобной сортровки
			this.selectedUnits.forEach(unit => {
				coefficientsList.forEach(coef => {
					unit[coef.toString()] =
						this.coefficientsDataStatistic[unit.id][
							coef.toString()
							].value;
				});
			});
		}
	}

	public changeUnits(unit): void {
		this.selectedUnits = cloneDeep(unit);
	}

	// По клику - перенаправление на заявку
	public redirect(event?): void {
		this.idleId = event.dataPoint.idleId;

		if (this.idleId) {
			const data: IIdleEditorModalData = {
				idleId: this.idleId,
			};

			this._dialog.open(MODALS.IdleEditor, {data});
		} else if (!this.idleId) {
			const ugm = event.dataPoint.name.split(' ')[0];
			const values = Object.values(this.dynamicRepairData);

			let taskId;
			let address;

			for (const device of values) {
				const deviceTasks = Object.values(device);

				for (const issue of deviceTasks) {
					if (issue.key === ugm) {
						taskId = issue.taskId;
						address = issue.address;

						break;
					}
				}
			}

			if (address) {
				window.open(address, '_blank');
			} else if (taskId) {
				const url = this._router.serializeUrl(
					this._router.createUrlTree(['/mes/task/' + taskId], {
						queryParams: {},
					})
				);

				window.open(url, '_blank');
			}
		}
	}

	// Составление списка с динамическими диаграммами
	onChart(chart, id?: number) {
		this.dynamicCharts.set(id, chart);
	}

	// Синхронизация приближения динамических диаграмм
	onChartSync(e) {
		const charts = Array.from(this.dynamicCharts.values());

		for (let i = 0; i < charts.length; i++) {
			const chart = charts[i];

			if (!chart.options.axisX) {
				chart.options.axisX = {};
			}

			if (!chart.options.axisY) {
				chart.options.axisY = {};
			}

			if (e.trigger === 'reset') {
				chart.options.axisX.viewportMinimum =
					chart.options.axisX.viewportMaximum = null;
				chart.options.axisY.viewportMinimum =
					chart.options.axisY.viewportMaximum = null;

				chart.render();
			} else if (chart !== e.chart) {
				chart.options.axisX.viewportMinimum =
					e.axisX[0].viewportMinimum;
				chart.options.axisX.viewportMaximum =
					e.axisX[0].viewportMaximum;

				chart.options.axisY.viewportMinimum =
					e.axisY[0].viewportMinimum;
				chart.options.axisY.viewportMaximum =
					e.axisY[0].viewportMaximum;

				chart.render();
			}
		}
	}
}
