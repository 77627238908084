import {NotifierService} from 'angular-notifier';
import {EventEmitter} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

import {IDataServiceInterface} from '../models/data-service-interface';
import {SdwebRepository} from '@shared/repositories/sdweb.repository';
import {IDto} from '@store/data/dto-interface';
import {EntityConstantEnums} from '@shared/constants/entity-constant.enums';
import {MesCounterparty} from '@store/data/plan/mes-counterparty';
import {getErrorMessage} from '../../../utils';
import {NgxUiLoaderService} from 'ngx-ui-loader';

@UntilDestroy()
export class DtoService {
	private readonly updateTimeOut: number = 30;

	public toDeleteNomList: number[] = [];
	public lastUpdate: Date = new Date(0);
	// Подписка на событие при отловки изменения
	public refresh: EventEmitter<any> = new EventEmitter();
	private loaderTaskMap: Map<string, number> = new Map<string, number>();

	constructor(
		private service: IDataServiceInterface,
		public entityConstant: EntityConstantEnums,
		private notifierService: NotifierService,
		private loaderService: NgxUiLoaderService,
		private mesApi: SdwebRepository,
		private parentIds?: number[],
		private fildName?: string,
	) {
	}

	private mapCreator(list: any[], map: Map<any, any>): Map<number, any> {
		map.clear();
		// const resultMap = new Map<number, any>();
		list.forEach(value => map.set(value.id, value));

		return map;
	}

	public asyncCheck() {
		const lastUpdate: Date = this.lastUpdate;

		lastUpdate.setTime(lastUpdate.getTime() + this.updateTimeOut * 1000);

		if (
			(lastUpdate.getTime() < (new Date()).getTime()) ||
			!this.service.itemList
		) {
			this.asyncUpdate();
		}
	}

	public asyncUpdate() {
		this.lastUpdate = new Date();
		const randomuid = Math.floor(Math.random() * 10000);
		this.loaderService.start(randomuid.toString());
		this.loaderTaskMap.set(this.entityConstant, randomuid);

		this.mesApi.getDTO<any>(this.parentIds, this.entityConstant, this.fildName)
			.pipe(untilDestroyed(this))
			.subscribe(res => {
				this.service.itemList.length = 0;

				res.forEach(value => {
					this.service.itemList.push(value);
				});

				this.mapCreator(res, this.service.itemMap);
				this.loaderService.stop(this.loaderTaskMap.get(this.entityConstant).toString());
				this.loaderTaskMap.clear();
				this.refresh.emit(true);
			});
	}

	public save(data: IDto[]) {
		this.mesApi.saveDTO(data, this.entityConstant)
			.pipe(untilDestroyed(this))
			.subscribe((response: IDto[]) => {
				if (!response) {
					this.notifierService.notify('error', 'Ошибка при сохранении');

					return;
				}

				this.notifierService.notify('success', 'Сохранено');
				this.service.activeItem = response[0];
				this.refresh.emit(true);

				this.asyncUpdate();
			}, error => {
				this.notifierService.notify('error', getErrorMessage(error, 'Ошибка при сохранении.'));
			});
	}

	public delete(ids: number[]) {
		this.mesApi.deleteDTO(ids, this.entityConstant)
			.pipe(untilDestroyed(this))
			.subscribe(value => {
				this.service.activeItem = new MesCounterparty();
				this.notifierService.notify('success', 'Удалено');

				this.asyncUpdate();

				this.refresh.emit(true);
			}, error => this.notifierService.notify(
				'error',
				getErrorMessage(error, 'Ошибка при удалении. Проверьте связанные данные')
			));
	}

	public markToDelete(dto: IDto) {
		let deleteCounter = 0;
		let sameFildCounter: number;

		const toDelete = this.service.itemList.find((value: IDto) => {
			sameFildCounter = 0;

			if (value.id === dto.id) {
				this.service.tableDataConf.forEach(tabConf => {
					if (value[tabConf.name] === dto[tabConf.name]) {
						sameFildCounter++;
					}
				});

				if (this.service.tableDataConf.length === sameFildCounter) {
					return value;
				}
			}

			deleteCounter++;
		});

		// tslint:disable-next-line:triple-equals
		if (toDelete && toDelete.id != null && toDelete.id != 0) {
			this.toDeleteNomList.push(toDelete.id);
		}

		this.service.itemList.splice(deleteCounter, 1);
	}

	public saveAllChanges() {
		if (this.toDeleteNomList.length > 0) {
			this.mesApi.deleteDTO(this.toDeleteNomList, this.entityConstant)
				.pipe(untilDestroyed(this))
				.subscribe(value => {
					this.toDeleteNomList = [];
					this.notifierService.notify('success', 'Удалено');
					this.save(this.service.itemList);
				}, error => this.notifierService.notify(
					'error',
					getErrorMessage(error, 'Ошибка при удалении'),
				));
		} else {
			this.save(this.service.itemList);
		}
	}


}
