<div class="g-line-container">
	@for (path of downloadFileList; track path) {
		<div
			class="image"
			(click)="openLink(path)"
		>
			@switch (getExtension(path)) {
				@case ('pdf') {
					<div class="image__icon image__icon_pdf"></div>
				}
				@case ('xlsx') {
					<div class="image__icon image__icon_excel"></div>
				}
				@default {
					<span class="image__text">{{ getExtension(path) }}</span>
				}
			}
		</div>
	}
</div>

<app-carousel
	[data]="htmlFileList"
	[isWidthMax]="true"
>
	<ng-container *item="let path">
		@if (path) {
			<iframe
				id="myIframe"
				class="iframe"
				[src]="path"
				allowfullscreen
			></iframe>
		}
	</ng-container>
</app-carousel>

