<div class="g-width-100">
	<div>
		<mat-form-field appearance="outline" class="g-width-100">
			<mat-label>Тип простоя</mat-label>
			<mat-select
				[value]="noIdleType.id"
				[(ngModel)]="newIdle.comments[0].idle_type.id"
			>
				<mat-option
					*ngFor="let type of idleTypes"
					[disabled]="type.disabled"
					[value]="type.id"
				>
					<span class="text-option">{{ type.name }}</span>
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>

	Начало простоя
	<app-date-time-picker [(date)]="newIdle.start_idle"></app-date-time-picker>

	Конец простоя
	<app-date-time-picker [(date)]="newIdle.end_idle"></app-date-time-picker>

	Коментарий
	<textarea
		matInput
		[(ngModel)]="newIdle.comments[0].comment"
		placeholder="Введите комментарий..."
	></textarea>


	<hr>

	<app-button
		class="g-button-success-square full-btn"
		[disabled]="isDisabledButton()"
		(click)="onIdleCreate.emit(newIdle)"
	>
		Добавить
	</app-button>
</div>
