import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {NgxSelectModule} from 'ngx-select-ex';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';

import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {StopPropagationModule} from '@shared/directives/stop-propagation/stop-propagation.module';
import {MatIconModule} from '@angular/material/icon';
import {PhoneMaskDirective} from '@shared/directives/phone-mask/phone-mask.directive';
import {ButtonComponent} from '@shared/ui-components/button/button.component';
import {DtoItemSelectComponent} from '@shared/components/dto-viewer/modules/dto-item/components/dto-item-select/dto-item-select.component';
import {DtoItemComponent} from '@shared/components/dto-viewer/modules/dto-item/dto-item.component';
import {NgxBootstrapModule} from '@shared/ngx-bootstrap.module';
import {SharedModule} from '@shared/shared.module';
import {IconModule} from '@shared/ui-components/icon/icon.module';
import {CheckboxComponent} from '@shared/ui-components/checkbox/checkbox.component';

@NgModule({
	declarations: [DtoItemComponent, DtoItemSelectComponent],
	imports: [
		CommonModule,
		FormsModule,
		NgxBootstrapModule,
		NgxSelectModule,
		SharedModule,
		IconModule,
		MatFormFieldModule,
		MatInputModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatAutocompleteModule,
		MatButtonModule,
		MatTooltipModule,
		MatProgressSpinnerModule,
		StopPropagationModule,
		MatIconModule,
		PhoneMaskDirective,
		ButtonComponent,
		CheckboxComponent,
	],
	exports: [DtoItemComponent, DtoItemSelectComponent],
})
export class DtoItemModule {
}
