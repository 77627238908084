<div
	id="{{idCarousel}}"
	class="carousel g-unselectable"
>
	<div class="carousel__content">
		<ul class="carousel__list">
			<li
				class="carousel__element"
				[class.g-width-100]="isWidthMax"
				[class.g-pointer]="isClickable"
				*ngFor="let value of data; let index = index"
				(click)="moveByClick(index)"
			>
				<ng-container
					*ngTemplateOutlet="
						item.templateRef;
						context: { $implicit: value }
					"
				></ng-container>

				<div
					*ngIf="index === indexCurrentElement && !!selectedItem?.templateRef && !isLongClick"
					class="carousel__element_selected"
				>
					<ng-container
						*ngTemplateOutlet="
							selectedItem.templateRef;
							context: { $implicit: value }
						"
					></ng-container>
				</div>
			</li>
		</ul>
	</div>

	<div
		class="carousel__button carousel__button_side_left"
		(click)="clickArrow('left')"
		[ngClass]="visibilityLeftArrow ? 'carousel__button_visible' : 'carousel__button_invisible'"
	>
		<div #leftButtonTemplate>
			<ng-content
				select="[leftButton]"
				class="g-button-icon"
			></ng-content>
		</div>

		<button
			*ngIf="!leftButton?.nativeElement?.children?.length"
			class="g-button-icon"
		>
			<mat-icon svgIcon="arrow-left"></mat-icon>
		</button>
	</div>

	<div
		class="carousel__button carousel__button_side_right"
		(click)="clickArrow('right')"
		[ngClass]="visibilityRightArrow ? 'carousel__button_visible' : 'carousel__button_invisible'"
	>
		<div #rightButtonTemplate>
			<ng-content
				select="[rightButton]"
				class="g-button-icon"
			></ng-content>
		</div>

		<button
			*ngIf="!rightButton?.nativeElement?.children?.length"
			class="g-button-icon"
		>
			<mat-icon svgIcon="arrow-right"></mat-icon>
		</button>
	</div>
	<div class="carousel__dots"></div>
</div>
