import { Injectable } from '@angular/core';
import { UnitsTemplatesRepository } from '@shared/repositories/units-templates.repository';
import { BehaviorSubject, combineLatest, firstValueFrom, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { NotifierService } from 'angular-notifier';
import { getErrorMessage } from '../../../../../utils';
import { TemplateFilterService } from '@shared/components/template-unit-filter/services/template-filter.service';
import {
	ICreateUnitTemplateUserList,
	IUnitTemplate,
	IUpdateUnitsTemplate,
} from '@shared/components/template-unit-filter/components/units-templates/models/units-templates.type';
import { FilterService } from '@shared/components/filter/services/filter.service';
import { IUser } from '@store/types';

@Injectable({providedIn: 'root'})
export class UnitsTemplatesService {
	public isVisibleButtonAdd$: BehaviorSubject<boolean>;
	public isVisibleButtonSave$: BehaviorSubject<boolean>;
	public isVisibleButtonShare$: BehaviorSubject<boolean>;
	public isVisibleButtonDelete$: BehaviorSubject<boolean>;

	public isLoadingCreate$: BehaviorSubject<boolean>;
	public isLoadingUpdate$: BehaviorSubject<boolean>;
	public isLoadingDelete$: BehaviorSubject<boolean>;

	public titleOfUnitsTemplate$: BehaviorSubject<string>;

	constructor(
		private _repository: UnitsTemplatesRepository,
		private _filter: FilterService,
		private _templateFilter: TemplateFilterService,
		private _notifierService: NotifierService,
	) {
		this.initSubjects();
		this.initTitleOfUnitsTemplate();
		this.initVisibilityOfButtons();
	}

	private initSubjects(): void {
		this.isVisibleButtonAdd$ = new BehaviorSubject<boolean>(false);
		this.isVisibleButtonSave$ = new BehaviorSubject<boolean>(false);
		this.isVisibleButtonShare$ = new BehaviorSubject<boolean>(false);
		this.isVisibleButtonDelete$ = new BehaviorSubject<boolean>(false);

		this.isLoadingCreate$ = new BehaviorSubject(false);
		this.isLoadingUpdate$ = new BehaviorSubject(false);
		this.isLoadingDelete$ = new BehaviorSubject(false);
		this.titleOfUnitsTemplate$ = new BehaviorSubject('');
	}

	private initTitleOfUnitsTemplate(): void {
		const selectUnitSub$ = this._filter.getSelectedUnitsListener();
		const selectedTemplate$ = this._templateFilter.getSelectedTemplateListener();

		combineLatest([selectedTemplate$, selectUnitSub$])
			.pipe(map((res) =>
				(res[0]?.name || 'Новая группа') +
				` (выбрано станков ${res[1].length || 0})`
			)).subscribe(this.titleOfUnitsTemplate$);
	}

	private initVisibilityOfButtons(): void {
		const selectUnitSub$ = this._filter.getSelectedUnitsListener();
		const selectedTemplate$ = this._templateFilter.getSelectedTemplateListener();

		selectedTemplate$
			.pipe(map((group) => !!group))
			.subscribe((value) => {
				this.isVisibleButtonDelete$.next(value);
				this.isVisibleButtonShare$.next(value);
			});

		this.isVisibleButtonAdd$.next(true);

		merge(selectedTemplate$, selectUnitSub$)
			.pipe(map(
				() => this.isHaveTemplateChanges() &&
					!!this._templateFilter.getSelectedTemplate()
			))
			.subscribe(value => this.isVisibleButtonSave$.next(value));
	}

	public isDefaultForUser(template: IUnitTemplate, user: IUser): boolean {
		return !!template.unitTemplateUserList?.find(
			({userId}) => userId === user.id
		)?.isDefault;
	}

	private isHaveTemplateChanges(): boolean {
		const groupUnits = this._templateFilter.getUnitsBySelectedTemplate();
		const selectedUnits = this._filter.getSelectedUnits();

		if (!groupUnits || !selectedUnits) {
			return false;
		}

		return !_.isEqual(groupUnits, selectedUnits);
	}

	public isCanEdit(group?: IUnitTemplate): boolean {
		if (!group) {
			return false;
		}

		const currentUser: { userId: number } | null
			= JSON.parse(localStorage.getItem('currentUser'));

		if (!currentUser?.userId) {
			return false;
		}

		return !!(group.unitTemplateUserList?.find(
			({userId}) => userId === currentUser.userId
		))?.isEdit;
	}

	public async createTemplate(
		name: string,
		users: ICreateUnitTemplateUserList[]
	): Promise<IUnitTemplate> {
		this.isLoadingCreate$.next(true);

		const selectedUnits = this._filter.getSelectedUnits();
		const unitTemplateOrderList = selectedUnits.map(({id}, index) => ({
			unitId: id,
			order: index,
		}));

		let createdTemplate: IUnitTemplate;

		try {
			createdTemplate = await firstValueFrom(this._repository.createUnitsTemplate({
				name,
				unitTemplateOrderList,
				unitTemplateUserList: users,
			}));

			this._templateFilter.setAllTemplates([
				...this._templateFilter.getAllTemplates(),
				createdTemplate
			]);

			this._templateFilter.setSelectedTemplate(createdTemplate);

			this._notifierService.notify('success', 'Группа создана');
		} catch (error) {
			this._notifierService.notify('error', getErrorMessage(error));
		}

		this.isLoadingCreate$.next(false);

		return createdTemplate;
	}

	public async updateSelectedTemplate(
		updatedTemplate: Omit<IUpdateUnitsTemplate, 'id'>
	): Promise<IUnitTemplate> {
		const selectedTemplate = this._templateFilter.getSelectedTemplate();

		if (!selectedTemplate) {
			return null;
		}

		return this.update(updatedTemplate, selectedTemplate);
	}

	public getUsersIdsOfSelectedTemplate(): number[] {
		return this._templateFilter.getSelectedTemplate().unitTemplateUserList
			?.map(({userId}) => userId);
	}

	public getEditorsIdsOfSelectedTemplate(): number[] {
		return this._templateFilter.getSelectedTemplate().unitTemplateUserList
			?.filter(({isEdit}) => isEdit)
			.map(({userId}) => userId);
	}

	public async update(
		payloadOfUpdatedTemplate: Omit<IUpdateUnitsTemplate, 'id'>,
		oldTemplate: IUnitTemplate
	): Promise<IUnitTemplate> {
		this.isLoadingUpdate$.next(true);

		let updatedTemplate: IUnitTemplate;

		try {
			const payload = {
				id: oldTemplate.id,
				...payloadOfUpdatedTemplate
			};

			const template$ = this._repository.updateUnitsTemplate(payload);

			updatedTemplate = await firstValueFrom(template$);

			if (updatedTemplate.id === this._templateFilter.getSelectedTemplate()?.id) {
				this._templateFilter.setSelectedTemplate(updatedTemplate);
			}

			const groups = this._templateFilter.getAllTemplates()
				.filter(({id}) => id !== updatedTemplate.id);

			this._templateFilter.setAllTemplates([...groups, updatedTemplate]);

			this._notifierService.notify('success', 'Группа обновлена');
		} catch (error) {
			this._notifierService.notify('error', getErrorMessage(error));
		}

		this.isLoadingUpdate$.next(false);

		return updatedTemplate;
	}

	public async deleteSelectedTemplate(): Promise<void> {
		const selectedTemplate = this._templateFilter.getSelectedTemplate();

		if (!selectedTemplate) {
			return;
		}

		await this.deleteTemplateById(selectedTemplate.id);
	}

	public async selectAsDefault(template: IUnitTemplate): Promise<void> {
		const currentUser: { userId } = JSON.parse(localStorage.getItem('currentUser'));

		const isDefault = template.unitTemplateUserList?.find(
			({userId}) => currentUser.userId === userId
		)?.isDefault;

		this._templateFilter.setDefaultTemplate(!isDefault ? template : null);

		const request$ = this._repository.selectAsDefault(template.id, !isDefault);

		await firstValueFrom(request$);

		const templates = await firstValueFrom(this._repository.getUnitsTemplates());

		this._templateFilter.setAllTemplates(templates);
	}

	public async deleteTemplateById(id: number): Promise<void> {
		this.isLoadingDelete$.next(true);

		try {
			await firstValueFrom(this._repository.deleteUnitsTemplate(id));

			if (id === this._templateFilter.getSelectedTemplate()?.id) {
				this._templateFilter.setSelectedTemplate(null);
			}

			const templates = this._templateFilter.getAllTemplates();
			const filteredTemplates = templates.filter(
				({id: groupId}) => id !== groupId
			);

			this._templateFilter.setAllTemplates(filteredTemplates);

			this._notifierService.notify('success', 'Группа удалена');
		} catch (error) {
			this._notifierService.notify('error', getErrorMessage(error));
		}

		this.isLoadingDelete$.next(false);
	}
}
