import { Injectable } from '@angular/core';
import { BrigadesStore, IBrigadesState } from '@store/brigades-store/brigades.store';
import { Query } from '@datorama/akita';
import { IBrigade } from '@shared/repositories/sdweb.repository';

@Injectable({ providedIn: 'root' })
export class BrigadesSelector extends Query<IBrigadesState> {
	constructor(
		protected store: BrigadesStore,
	) {
		super(store);
	}

	getBrigades(): IBrigade[] {
		return this.store.getValue().brigades;
	}
}
