import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IUser } from '@store/types';
import { ButtonComponent } from '@shared/ui-components/button/button.component';
import { ExpandModule } from '@shared/ui-components/expand/expand.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { ScrollModule } from '@shared/ui-components/scroll/scroll.module';
import { SearchModule } from '@shared/ui-components/search/search.module';
import { TreeModule } from '@shared/ui-components/tree/tree.module';
import { BehaviorSubject, firstValueFrom, merge } from 'rxjs';
import { IBrigade, SdwebRepository } from '@shared/repositories/sdweb.repository';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfigService } from '@shared/services/config.service';
import { NgIf } from '@angular/common';
import { SelectorLettersModule } from '@shared/pipes/selector-letters/selector-letters.module';
import { CheckboxComponent } from '@shared/ui-components/checkbox/checkbox.component';
import { StopPropagationModule } from '@shared/directives/stop-propagation/stop-propagation.module';
import { TooltipModule } from '@shared/directives/tooltip/tooltip.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ITree } from '@shared/models/tree';
import { SelectorLettersComponent } from '@shared/ui-components/selector-letters/selector-letters.component';
import { UsersSelector } from '@store/user-store/users.selector';
import { BrigadesSelector } from '@store/brigades-store/brigades.selector';

@UntilDestroy()
@Component({
	selector: 'app-share-selector',
	templateUrl: 'share-selector.component.html',
	styleUrls: ['share-selector.component.scss'],
	standalone: true,
	imports: [
		ButtonComponent,
		ExpandModule,
		MatCheckboxModule,
		MatIconModule,
		ScrollModule,
		SearchModule,
		TreeModule,
		NgIf,
		SelectorLettersModule,
		CheckboxComponent,
		StopPropagationModule,
		TooltipModule,
		MatTooltipModule,
		SelectorLettersComponent
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareSelectorComponent implements OnInit {
	@Input() defaultUsers?: IUser[];
	@Input() defaultUsersIds?: number[];
	@Input() defaultEditors?: IUser[];
	@Input() defaultEditorsIds?: number[];

	@Input() linkForCopy?: string;
	@Input() useEditing = true;
	@Input() isLoading = true;

	@Output() share = new EventEmitter<{
		users: IUser[],
		editors?: IUser[],
		editorsIds?: Record<string, boolean>
	}>();

	public filters: {
		brigades$: BehaviorSubject<(ITree & IBrigade)[]>,
		searchLine$: BehaviorSubject<string>,
	};

	private readonly _allUsers: IUser[];

	public filteredUsers$: BehaviorSubject<IUser[]>;
	public useSortBySelecting$: BehaviorSubject<boolean>;

	public selectUserIds$: BehaviorSubject<Record<string, boolean>>;
	public isSelectedAll$: BehaviorSubject<boolean>;

	public editorsIds$: BehaviorSubject<Record<string, boolean>>;
	public isAllUsersEditors$: BehaviorSubject<boolean>;

	constructor(
		public brigadesSelector: BrigadesSelector,
		private _usersSelector: UsersSelector,
		private _mesService: SdwebRepository,
		private _globalConfig: ConfigService,
	) {
		this.filters = {
			brigades$: new BehaviorSubject<(ITree & IBrigade)[]>([]),
			searchLine$: new BehaviorSubject('')
		};

		this._allUsers = [...this._usersSelector.getAllUsers()];
		this.filteredUsers$ = new BehaviorSubject<IUser[]>(this._allUsers);

		this.useSortBySelecting$ = new BehaviorSubject(false);

		this.isSelectedAll$ = new BehaviorSubject(false);
		this.selectUserIds$ = new BehaviorSubject<Record<number, boolean>>({});

		this.isAllUsersEditors$ = new BehaviorSubject(false);
		this.editorsIds$ = new BehaviorSubject<Record<number, boolean>>({});
	}

	public ngOnInit(): void {
		this.initFiltering();
		this.initDefaultUsers();
	}

	private initFiltering(): void {
		merge(...Object.values(this.filters), this.useSortBySelecting$)
			.pipe(untilDestroyed(this))
			.subscribe(async () => {
				const idsBrigades = this.getIdSelectedBrigades();
				const searchLine = this.filters.searchLine$.getValue()?.toLowerCase();

				let filteringUsers = this._allUsers;

				if (idsBrigades?.length) {
					const request$ = this._mesService.getUserIdsByBrigades(idsBrigades);
					const userIds = await firstValueFrom(request$);

					filteringUsers = userIds.map(id => this._usersSelector.getUserById(id));
				}

				if (searchLine) {
					filteringUsers = filteringUsers.filter(
						user => user.fullName
							.toLowerCase()
							.includes(searchLine)
					);
				}

				if (this.useSortBySelecting$.getValue()) {
					filteringUsers = [...filteringUsers].sort(
						(a, b) =>
							this.selectUserIds$.getValue()[b.id] &&
							!this.selectUserIds$.getValue()[a.id]
								? 1
								: -1
					);
				}

				this.filteredUsers$.next(filteringUsers);

				this.checkIsSelectedAllUser();
				this.checkIsAllUsersEditors();
			});
	}

	private initDefaultUsers() {
		if (this.defaultUsers) {
			this.selectUserIds$.next(
				this.defaultUsers.reduce((a, c) => (a[c.id] = true, a), {})
			);
		} else if (this.defaultUsersIds) {
			this.selectUserIds$.next(
				this.defaultUsersIds.reduce((a, c) => (a[c] = true, a), {})
			);
		}

		if (this.defaultEditors) {
			this.editorsIds$.next(
				this.defaultEditors.reduce((a, c) => (a[c.id] = true, a), {})
			);
		} else if (this.defaultEditorsIds) {
			this.editorsIds$.next(
				this.defaultEditorsIds.reduce((a, c) => (a[c] = true, a), {})
			);
		}

		this.checkIsAllUsersEditors();
		this.checkIsAllUsersEditors();
	}

	private checkIsSelectedAllUser() {
		const amountOfUser = this.filteredUsers$.getValue().length;

		this.isSelectedAll$.next(this.amountOfSelectUser === amountOfUser);
	}

	private checkIsAllUsersEditors() {
		const isSelectAll =
			this.amountOfEditors === this.amountOfSelectUser &&
			this.amountOfEditors !== 0;

		this.isAllUsersEditors$.next(isSelectAll);
	}

	public selectAll(): void {
		if (this.isSelectedAll$.getValue()) {
			this.selectUserIds$.next({});
			this.isSelectedAll$.next(false);
		} else {
			const idsSelectedUser = this.filteredUsers$
				.getValue()
				.reduce((a, c) => (a[c.id] = true, a), {});

			this.selectUserIds$.next(idsSelectedUser);
			this.isSelectedAll$.next(true);
		}

		if (
			this.useEditing &&
			!this.isSelectedAll$.getValue()
		) {
			this.isAllUsersEditors$.next(false);
			this.editorsIds$.next({});
		}
	}

	public selectOne(user: IUser): void {
		const selectUserIds = this.selectUserIds$.getValue();

		selectUserIds[user.id] = !selectUserIds[user.id];

		this.selectUserIds$.next(selectUserIds);

		this.checkIsSelectedAllUser();

		if (this.useEditing) {
			if (
				this.editorsIds$.getValue()[user.id] &&
				!selectUserIds[user.id]
			) {
				this.makeUserAsEditor(user);
			} else {
				this.checkIsAllUsersEditors();
			}
		}
	}

	public makeAllUsersAsEditors(): void {
		if (this.isAllUsersEditors$.getValue()) {
			this.editorsIds$.next({});
			this.isAllUsersEditors$.next(false);
		} else {
			const editorsIds = Object.entries(this.selectUserIds$.getValue())
				.reduce((a, c) => {
					if (c[1]) {
						a[c[0]] = true;
					}
					return a;
				}, {});

			this.editorsIds$.next(editorsIds);

			this.checkIsAllUsersEditors();
		}
	}

	public makeUserAsEditor(user: IUser) {
		const editorsIds = this.editorsIds$.getValue();

		editorsIds[user.id] = !editorsIds[user.id];

		this.editorsIds$.next(editorsIds);

		this.checkIsAllUsersEditors();

		if (editorsIds[user.id] && !this.selectUserIds$.getValue()[user.id]) {
			this.selectOne(user);
		}
	}

	get amountOfSelectUser() {
		return Object.values(this.selectUserIds$.getValue()).filter(i => i).length;
	}

	get amountOfEditors() {
		return Object.values(this.editorsIds$.getValue()).filter(i => i).length;
	}

	get selectUsers(): IUser[] {
		return Object.entries(this.selectUserIds$.getValue()).reduce((a, c) => {
			if (c[1]) {
				a.push(this._usersSelector.getUserById(Number(c[0])));
			}

			return a;
		}, []);
	}

	get editors(): IUser[] {
		return Object.entries(this.editorsIds$.getValue()).reduce((a, c) => {
			if (c[1]) {
				a.push(this._usersSelector.getUserById(Number(c[0])));
			}

			return a;
		}, []);
	}

	public getIdSelectedBrigades(): number[] {
		return this.filters.brigades$
			.getValue()
			.filter(i => i.isChecked)
			.map(i => i.id);
	}

	public getUrlImage(imageUrl: string) {
		return this._globalConfig.getConfig([
			'javaApiUrl',
		]).javaApiUrl + imageUrl;
	}

	public copy(): void {

	}

	public send(): void {
		this.share.next({
			users: this.selectUsers,
			editors: this.useEditing ? this.editors : null,
			editorsIds: this.useEditing ? this.editorsIds$.getValue() : null,
		});
	}
}
