import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {UnitsTemplatesService} from '@shared/components/template-unit-filter/components/units-templates/services/units-templates.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-template-unit-filter',
	templateUrl: 'template-unit-filter.component.html',
	styleUrls: ['template-unit-filter.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateUnitFilterComponent implements OnInit {
	@Input() isOpen: boolean = true;
	@Input() isAbsolute: boolean = false;
	@Input() isAutoClose: boolean = false;
	@Input() useButton: boolean = true;

	constructor(
		public unitsGroupService: UnitsTemplatesService,
		private _cdr: ChangeDetectorRef,
	) {
	}

	ngOnInit(): void {
		this.unitsGroupService.titleOfUnitsTemplate$
			.pipe(untilDestroyed(this))
			.subscribe(() => {
				this._cdr.markForCheck();
			});
	}
}
