<div class="modal">
	<div class="modal__header">
		<div class="title">
			{{ view.toString() }}
		</div>

		<app-button
			class="g-button-secondary-empty btn-close"
			svgIcon="close"
			(click)="closeModal()"
		></app-button>
	</div>

	<div
		id="content"
		name="content"
		class="modal__content"
	>
		<!--		@switch (view) {-->
		<!--			@case (viewEnum.qrScaner) {-->
		<!--				<app-qr-scanner (onResult)='onCodeResult($event)'></app-qr-scanner>-->
		<!--			}-->
		<!--			@case (viewEnum.tool) {-->
		<div class="full-height">
			<app-tool-item></app-tool-item>
		</div>
		<!--			}-->
		<!--		}-->
	</div>
</div>
