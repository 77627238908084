<app-table
	*ngIf="selectedUnits?.length"
	#tableRef
	[data]="selectedUnits"
	[useVirtualScroll]="!isPrintMode"
>
	<ng-container *ngIf="showUnitsNames">
		<ng-container *viewItem>
			<app-table-header
				#header
				[sortByField]="['unitType']"
				[searchByField]="[['unitType', 'name'], ['inventoryNumber']]"
				[sizeColumn]="{minSize: '0px', maxSize: '200px'}"
			>
				Название
			</app-table-header>
			<app-table-item *lineValue="let element">
				<span
					class="link"
					[appLink]="'/monitoring/unit/' + element.id"

				>
					<app-selector-letters
						class="cell-unit__text"
						[value]="element.unitType.name"
						[searchString]="header.searchInput$.getValue()"
					></app-selector-letters>
					/
					<app-selector-letters
						*ngIf="element.inventoryNumber"
						class="cell-unit__text"
						[value]="element.inventoryNumber"
						[searchString]="header.searchInput$.getValue()"
					></app-selector-letters>
				  </span>
			</app-table-item>
			<ng-container *ngIf="dynamicData">
				<app-table-item *headerItem></app-table-item>
			</ng-container>
		</ng-container>
		<ng-container *viewItem>
			<app-table-header
				[sortByField]="['indexNumber']"
				[sizeColumn]="{minSize: '0px', maxSize: '100px'}"
				matTooltip="Порядковый номер"
			>
				№
			</app-table-header>
			<ng-container *ngIf="dynamicData">
				<app-table-item *headerItem></app-table-item>
			</ng-container>
			<app-table-item *lineValue="let element">
				{{ element.indexNumber }}
			</app-table-item>
		</ng-container>
		<ng-container *viewItem>
			<app-table-header
				#header
				[sortByField]="['location', 'name']"
				[searchByField]="['location', 'name']"
				[sizeColumn]="{minSize: '0px', maxSize: '100px'}"
				matTooltip="Расположение оборудования"
			>
				Расп.
			</app-table-header>
			<ng-container *ngIf="dynamicData">
				<app-table-item *headerItem></app-table-item>
			</ng-container>
			<app-table-item *lineValue="let element">
				<app-selector-letters
					class="cell-unit__text"
					[value]="element.location.name"
					[searchString]="header.searchInput$.getValue()"
				></app-selector-letters>
			</app-table-item>
		</ng-container>
	</ng-container>

	<ng-container *ngIf="dynamicData">
		<ng-container *viewItem>
			<app-table-header
				matTooltip="Динамическая загрузка"
			>
				Динамическая загрузка
			</app-table-header>
			<app-table-item *headerItem>
				<app-dynamic-bar
					[lineId]="'line2'"
					[startTime]="interval.start"
					[endTime]="interval.end"
					(onChart)='onChart.emit([$event,  "line2"])'
					(onChartSync)='onChartSync.emit([$event,  "unit.id"])'
					(completedBuild)="completeBuildCanvas()"
				></app-dynamic-bar>
			</app-table-item>
			<app-table-item *lineValue="let element">
				<app-dynamic-bar
					[id]="element.id"
					[dynamicStat]="dynamicData ? dynamicData[element.id] : []"
					[dynamicRepairData]="dynamicRepairData ? dynamicRepairData[element.id] : []"
					[idlesData]="idlesData ? idlesData[element.id] : []"
					[startTime]="interval.start"
					[endTime]="interval.end"
					(onChart)='onChart.emit([$event,  element.id])'
					(onChartSync)='onChartSync.emit([$event,  "unit.id"])'
					(redirectEmitter)="redirect.emit($event)"
					(completedBuild)="completeBuildCanvas()"
				></app-dynamic-bar>
			</app-table-item>
		</ng-container>
	</ng-container>

	<ng-container *ngIf="staticData">
		<ng-container *viewItem>
			<app-table-header
				matTooltip="Статическая загрузка"
			>
				Статическая загрузка
			</app-table-header>
			<ng-container *ngIf="dynamicData">
				<app-table-item *headerItem></app-table-item>
			</ng-container>
			<app-table-item *lineValue="let element">
				<app-static-bar
					[id]="element.id"
					[staticStat]="staticData? staticData.value[element.id] : []"
					[idlesData]="idlesData? idlesData[element.id] : null"
					[isIdleInChart]="isIdleInChart"
					[startTime]="interval.start"
					[endTime]="interval.end"
					(completedBuild)="completeBuildCanvas()"
				></app-static-bar>
			</app-table-item>
		</ng-container>
	</ng-container>

	<ng-container *ngIf="coefficientsDataStatistic && LOAD != null">
		<ng-container *viewItem>
			<app-table-header
				matTooltip="{{LOAD.status.description}}"
				[sortByField]="['LOAD']"
			>
				{{ LOAD.status.title }}, {{ LOAD.status.measure }}
			</app-table-header>
			<ng-container *ngIf="dynamicData">
				<app-table-item *headerItem></app-table-item>
			</ng-container>
			<app-table-item *lineValue="let element">
				<div
					class="cell"
					[ngClass]="{
						'coef-red': element.LOAD >= 0 && element.LOAD < 40,
						'coef-yellow': element.LOAD >= 40 && element.LOAD < 60,
						'coef-green': element.LOAD >= 60}"
				>
					{{ element.LOAD }}
				</div>
			</app-table-item>
		</ng-container>
	</ng-container>

	<ng-container *ngIf="coefficientsDataStatistic && OEE != null">
		<ng-container *viewItem>
			<app-table-header
				matTooltip="{{OEE.status.description}}"
				[sortByField]="['OEE']"
			>
				{{ OEE.status.title }}, {{ OEE.status.measure }}
			</app-table-header>
			<ng-container *ngIf="dynamicData">
				<app-table-item *headerItem></app-table-item>
			</ng-container>
			<app-table-item *lineValue="let element">
				<div
					class="cell"
					[ngClass]="{
					'coef-red': element.OEE >= 0 && element.OEE < 40,
					'coef-yellow': element.OEE >= 40 && element.OEE < 60,
					'coef-green': element.OEE >= 60}
				">
					{{ element.OEE }}
				</div>
			</app-table-item>
		</ng-container>
	</ng-container>

	<ng-container *ngIf="coefficientsDataStatistic && AQ != null">
		<ng-container *viewItem>
			<app-table-header
				matTooltip="{{AQ.status.description}}"
				[sortByField]="['AQ']"
			>
				{{ AQ.status.title }} , {{ AQ.status.measure }}
			</app-table-header>
			<ng-container *ngIf="dynamicData">
				<app-table-item *headerItem></app-table-item>
			</ng-container>
			<app-table-item *lineValue="let element">
				<div
					class="cell"
					[ngClass]="{
					'coef-red': element.AQ >= 0.0 && element.AQ < 0.6,
					'coef-yellow': element.AQ >= 0.6 && element.af < 0.89,
					'coef-green': element.AQ >= 0.89}
				">
					{{ element.AQ }}
				</div>
			</app-table-item>
		</ng-container>
	</ng-container>

	<ng-container *ngIf="coefficientsDataStatistic && MTTR != null">
		<ng-container *viewItem>
			<app-table-header
				matTooltip="{{MTTR.status.description}}"
				[sortByField]="['MTTR']"
			>
				{{ MTTR.status.title }}, {{ MTTR.status.measure }}
			</app-table-header>
			<ng-container *ngIf="dynamicData">
				<app-table-item *headerItem></app-table-item>
			</ng-container>
			<app-table-item *lineValue="let element">
				{{ element.MTTR }}
			</app-table-item>
		</ng-container>
	</ng-container>

	<ng-container *ngIf="coefficientsDataStatistic && MTBF != null">
		<ng-container *viewItem>
			<app-table-header
				matTooltip="{{MTBF.status.description}}"
				[sortByField]="['MTBF']"
			>
				{{ MTBF.status.title }}, {{ MTBF.status.measure }}
			</app-table-header>
			<ng-container *ngIf="dynamicData">
				<app-table-item *headerItem></app-table-item>
			</ng-container>
			<app-table-item *lineValue="let element">
				{{ element.MTBF }}
			</app-table-item>
		</ng-container>
	</ng-container>

	<ng-container *ngIf="coefficientsDataStatistic && MTTA != null">
		<ng-container *viewItem>
			<app-table-header
				matTooltip="{{MTTA.status.description}}"
				[sortByField]="['MTTA']"
			>
				{{ MTTA.status.title }}, {{ MTTA.status.measure }}
			</app-table-header>
			<ng-container *ngIf="dynamicData">
				<app-table-item *headerItem></app-table-item>
			</ng-container>
			<app-table-item *lineValue="let element">
				{{ element.MTTA }}
			</app-table-item>
		</ng-container>
	</ng-container>

	<ng-container *ngIf="coefficientsDataStatistic && MDT != null">
		<ng-container *viewItem>
			<app-table-header
				matTooltip="{{MDT.status.description}}"
				[sortByField]="['MDT']"
			>
				{{ MDT.status.title }}, {{ MDT.status.measure }}
			</app-table-header>
			<ng-container *ngIf="dynamicData">
				<app-table-item *headerItem></app-table-item>
			</ng-container>
			<app-table-item *lineValue="let element">
				{{ element.MDT }}
			</app-table-item>
		</ng-container>
	</ng-container>

	<ng-container *ngIf="coefficientsDataStatistic && MUT != null">
		<ng-container *viewItem>
			<app-table-header
				matTooltip="{{MUT.status.description}}"
				[sortByField]="['MUT']"
			>
				{{ MUT.status.title }}, {{ MUT.status.measure }}
			</app-table-header>
			<ng-container *ngIf="dynamicData">
				<app-table-item *headerItem></app-table-item>
			</ng-container>
			<app-table-item *lineValue="let element">
				{{ element.MUT }}
			</app-table-item>
		</ng-container>
	</ng-container>

	<ng-container *ngIf="coefficientsDataStatistic && TASKSCOUNT != null">
		<ng-container *viewItem>
			<app-table-header
				matTooltip="{{TASKSCOUNT.status.description}}"
				[sortByField]="['TASKSCOUNT']"
			>
				{{ TASKSCOUNT.status.title }}, {{ TASKSCOUNT.status.measure }}
			</app-table-header>
			<ng-container *ngIf="dynamicData">
				<app-table-item *headerItem></app-table-item>
			</ng-container>
			<app-table-item *lineValue="let element">
				{{ element.TASKSCOUNT }}
			</app-table-item>
		</ng-container>
	</ng-container>
</app-table>
