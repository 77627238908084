import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {IdlesRepository, IIdleType} from '@shared/repositories/idles.repository';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ICreateIdle} from '@shared/models/idle';
import {Idle} from '@shared/components/idle-creator/models/idle-creator.classes';

@UntilDestroy()
@Component({
	selector: 'app-idle-creator',
	templateUrl: './idle-creator.component.html',
	styleUrls: ['./idle-creator.component.scss']
})
export class IdleCreatorComponent implements OnInit {
	@Output() onIdleCreate = new EventEmitter<ICreateIdle>();

	idleTypes;
	// todo : убрать все данные в эти два класса
	newIdle = new Idle();

	noIdleType = {
		id: 0,
		color: '#555',
		name: 'Не указан тип',
		disabled: true
	};

	constructor(private _idlesService: IdlesRepository) {
	}

	ngOnInit() {
		this._idlesService.getIdlesTypes()
			.pipe(untilDestroyed(this))
			.subscribe(idleTypes => {

				this.idleTypes = this.filterUnitClass(idleTypes);
				this.idleTypes[Object.keys(this.idleTypes).length] = this.noIdleType;
			});
	}

	// Отфильтрует типы простоев по юнит классам
	filterUnitClass(idleTypes: IIdleType[]): IIdleType[] {
		return idleTypes.filter(i => i.unitClassIdList || (i.unitClassIdList));
	}

	isDisabledButton() {
		return !this.newIdle.end_idle || !this.newIdle.start_idle ||
			!this.newIdle.comments[0].idle_type.id ||
			new Date(this.newIdle.end_idle) < new Date(this.newIdle.start_idle);
	}
}
