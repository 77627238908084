import {Injectable} from '@angular/core';
import {IDashboardColumn, ISimpleTask} from '../models/dashboard.types';
import {Observable} from 'rxjs';
import {BaseRepository} from '@shared/repositories/base-repository';

@Injectable({providedIn: 'root'})
export class DashboardRepository extends BaseRepository {
	public getColumnsByDashboardId(
		dashboardId: number
	): Observable<IDashboardColumn[]> {
		const url = `${this._config.javaApiUrl}/sdweb/dashboard/info/get-row-by-dashboard/${dashboardId}`;

		return this._http.get<IDashboardColumn[]>(url);
	}

	public getTasksByDashboardId(
		dashboardId: number
	): Observable<ISimpleTask[]> {
		const url = `${this._config.javaApiUrl}/sdweb/dashboard/info/get-task-by-dashboard/${dashboardId}`;

		return this._http.get<ISimpleTask[]>(url);
	}

	public moveTasksToColumn(
		data: { dashboardId: number, columnId: number, taskIds: number[] }
	): Observable<boolean> {
		const {dashboardId, columnId, taskIds} = data;
		const url = `${this._config.javaApiUrl}/sdweb/dashboard/change-task-status/${dashboardId}/${columnId}`;

		return this._http.post<boolean>(url, {value: taskIds});
	}

	public getEntrainUrlsByTerminalIps(terminalIps: string[]) {
		const url = `${this._config.javaApiUrl}/sdweb/dashboard/info/get-functional-by-mac`;

		return this._http.post<string[]>(url, {value: terminalIps});
	}
}
