import {Component, Input} from '@angular/core';
import {Edge, Node} from '@swimlane/ngx-graph';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-graph-view',
  templateUrl: './graph-view.component.html',
  styleUrls: ['./graph-view.component.scss']
})
export class GraphViewComponent {

	@Input() nodes: Node[];
	@Input() edges: Edge[];
	@Input() layoutSettings: any;
	@Input() panningEnabled: boolean;
	@Input() zoomEnabled: boolean;
	@Input() panOnZoom: boolean;
	@Input() curve: any;
	@Input() layout;
	@Input() autoCenter: boolean;
	@Input() update: Subject<boolean>;
	@Input() currentNode: Node = {id: 'none'} as Node;
	stroke = '#f9ff8a';
}
