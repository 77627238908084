<div
	role="group"
	[formGroup]="parts"
	(focusin)="onFocusIn($event)"
	(focusout)="onFocusOut($event)"
>
	<input
		formControlName="hour"
		size="2"
		maxLength="2"
		aria-label="Area code"
		[class.disabled]="disabled"
		(input)="_handleInput(parts.controls.hour)"
		#area
	>
	<span
		[class.active]="!!this.parts.get('hour').value || !!this.parts.get('minute').value"
	>
		:
	</span>
	<input
		formControlName="minute"
		maxLength="2"
		size="2"
		aria-label="Exchange code"
		[class.disabled]="disabled"
		(input)="_handleInput(parts.controls.minute)"
		(keyup.backspace)="autoFocusPrev(parts.controls.minute, exchange)"
		#exchange
	>
</div>
