<div class="row">
	<div class="label g-col-sm-3">
		<span>Дата техпроцесса:</span>
	</div>
	<div class="g-col-md-3">
		<input
			[bsValue]="currentDate"
			type="text"
			class="form-control form-control-sm"
			bsDatepicker
			[bsConfig]="datePickerConfig"
			[isDisabled]="!!currentProgram"
			[ngClass]="{'disabled': !!currentProgram}"
			(bsValueChange)="changeDate($event)"
		/>
	</div>
</div>

<div class="row">
	<div class="label g-col-sm-3">
		<span>Оборудование:</span>
	</div>
	<div class="select g-col-md-3">
		<ngx-select
			[items]="units"
			class="g-relative"
			optionValueField="id"
			optionTextField="fullName"
			[(ngModel)]="currentUnitId"
			size="small"
			noResultsFound="Нет совпадений"
			[disabled]="!!currentProgram"
			(select)="setCurrentUnit($event)">
		</ngx-select>
	</div>
</div>

<div class="row">
	<div class="label g-col-sm-3">
		<span>Техпроцесс:</span>
	</div>
	<div
		*ngIf="(techprocesses$ | async) as techprocesses"
		class="select g-col-md-3"
	>
		<ngx-select
			*ngIf="techprocesses.length > 0"
			class="g-relative"
			[items]="techprocesses"
			optionValueField="guid"
			optionTextField="fullName"
			[(ngModel)]="currentTechprocessGuid"
			size="small"
			noResultsFound="Нет техпроцессов"
			(select)="setTechprocess($event)"
		>
		</ngx-select>
		<input
			*ngIf="techprocesses.length === 0"
			readonly
			value="Нет техпроцессов"
			class="form-control form-control-sm disabled"
		/>
	</div>
</div>

<div class="row">
	<div class="label g-col-sm-3">
		<span>Программа:</span>
	</div>
	<div
		*ngIf="(programs$ | async) as programs"
		class="select g-col-md-3"
	>
		<ngx-select
			*ngIf="programs.length > 0"
			[items]="programs"
			optionValueField="id"
			optionTextField="fullName"
			[(ngModel)]="currentProgramId"
			class="g-relative"
			size="small"
			noResultsFound="Нет программ"
			(select)="setProgram($event)"
		>
		</ngx-select>
		<input
			*ngIf="programs.length === 0"
			readonly
			value="Нет программ"
			class="form-control form-control-sm disabled"
		/>
	</div>
</div>
