import {Component, Inject, Injector, OnInit, QueryList, ViewChildren} from '@angular/core';
import {TrendsTemplateModalService} from './services/trends-template-modal.service';
import {TrendsTemplateModalEditService} from './services/trends-template-modal-edit.service';
import {TrendsTemplateModalCreateService} from './services/trends-template-modal-create.service';
import {IUnitDeviceParameter} from '@shared/services/unit.service';
import {getCustomScaleAppearanceAnimation} from '@shared/animations/common.animations';
import {NotifierService} from 'angular-notifier';
import {CdkDragDrop, CdkDragSortEvent, CdkDropList, moveItemInArray} from '@angular/cdk/drag-drop';
import {first, map} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {GroupTrendsComponent} from './component/group-trends/group-trends.component';
import {nextTick} from '@shared/utils';
import {ITrendsTemplateModalResponse, TrendsTemplateModalComponentData} from '@shared/modals/trends-template/models/trends-template-modal.types';
import {
	ITrendsTemplate
} from '../../../modules/monitoring/modules/unit/components/unit-tabs-area/components/trends/components/trends-template/models/trends-template.models';
import {DIALOG_DATA, ModalRef} from '@shared/services/modals.service';

@Component({
	selector: 'app-trends-template-modal',
	templateUrl: 'trends-template-modal.component.html',
	styleUrls: ['trends-template-modal.component.scss'],
	animations: [getCustomScaleAppearanceAnimation(0.1, 0.9)]
})
export class TrendsTemplateModalComponent implements OnInit {
	@ViewChildren(GroupTrendsComponent) groups: QueryList<GroupTrendsComponent>;

	public cdkDropLists: CdkDropList[];
	public service: TrendsTemplateModalService;
	public selectedGroup: number;
	public isDropped: boolean;

	private _unitId: number;
	private _userId: number;
	private _templateRes: ITrendsTemplateModalResponse;

	constructor(
		@Inject(DIALOG_DATA) public data: TrendsTemplateModalComponentData,
		private _dialogRef: ModalRef<TrendsTemplateModalComponent>,
		private _injector: Injector,
		private _notifierService: NotifierService,
	) {
		this.cdkDropLists = [];
		this.selectedGroup = 0;
		this.isDropped = false;
		this._templateRes = null;

		this.service = this.data.mode === 'edit' ?
			this._injector.get(TrendsTemplateModalEditService) :
			this._injector.get(TrendsTemplateModalCreateService);
	}

	public ngOnInit(): void {
		setTimeout(() => {
			this.groups.forEach((item) => {
				this.cdkDropLists.push(item.group);
			});
		}, 0);

		this.service.init(this.data.allCharts, this.data.template);
		this._unitId = this.data.unitId;
		this._userId = this.data.userId;

		this._dialogRef.beforeClosed()
			.subscribe((res) => {
				this.closeModal();
			});
	}

	public save(apply: boolean): void {
		if (this._checkValid()) {
			return;
		}

		this.service.save(this._unitId, this._userId)
			.pipe(
				first(),
				map((res) => {
					if (!res) {
						throw throwError(res);
					}
					return res;
				}),
			).subscribe({
			next: (res: ITrendsTemplate) => {
				const msg = this.data.mode === 'create'
					? 'Шаблон параметров успешно создан.' : 'Шаблон параметров успешно изменен.';
				this._notifierService.notify('success', msg);

				this._templateRes = {
					template: res,
					apply: apply,
				};
				if (apply) {
					this.closeModal();
				}
			},
			error: () => {
				const msg = this.data.mode === 'create'
					? 'Не удалось создать шаблон параметров.' : 'Не удалось изменить шаблон параметров.';
				this._notifierService.notify('error', msg);
			},
		});
	}

	public choiceChart(chart: IUnitDeviceParameter): void {
		if (this.service.selectedChartsMap.has(chart.deviceParameterId)) {
			this.deleteChart(chart);
		} else {
			this.service.selectedChartsMap.set(chart.deviceParameterId, chart);
			const charts = this.service.selectedCharts[this.selectedGroup];
			charts.push(chart);
			this.service.selectedCharts[this.selectedGroup] = charts;
		}
		this._updateGroups();
	}

	public deleteChart(chart: IUnitDeviceParameter, indexGroup?: number): void {
		this.service.selectedChartsMap.delete(chart.deviceParameterId);


		if (typeof indexGroup === 'number') {
			const charts = this.service.selectedCharts[indexGroup]
				.filter((item) => item.deviceParameterId !== chart.deviceParameterId);

			this.service.selectedCharts[indexGroup] = charts;
		} else {
			let iDelChart;

			const iDelGroup = this.service.selectedCharts.findIndex(charts => {
				iDelChart = charts.findIndex((item) => item.deviceParameterId === chart.deviceParameterId);
				return iDelChart !== -1;
			});
			const tmp = this.service.selectedCharts[iDelGroup];
			tmp.splice(iDelChart, 1);

			this.service.selectedCharts[iDelGroup] = tmp;
		}
		this._updateGroups();
	}

	public deleteGroup(charts: IUnitDeviceParameter[], index: number): void {
		charts.forEach((item) => {
			this.service.selectedChartsMap.delete(item.deviceParameterId);
		});

		if (this.service.selectedCharts.length > 1) {
			this.service.selectedCharts.splice(index, 1);
		} else {
			this.service.selectedCharts[index] = [];
		}
		this._updateGroups();
	}

	public closeModal(): void {
		this._dialogRef.close(this._templateRes);
	}

	public addNewGroup(): void {
		this.selectedGroup = this.service.selectedCharts.length;
		this.service.selectedCharts.push([]);
	}

	public selectActiveGroup(index: number): void {
		this.selectedGroup = index;
	}

	async drop(event: CdkDragDrop<string[]>): Promise<void> {
		moveItemInArray(this.service.selectedCharts, event.previousIndex, event.currentIndex);

		if (event.previousIndex === this.selectedGroup) {
			this.selectedGroup = event.currentIndex;
		}
		await nextTick();
		this.isDropped = false;
	}

	public dropSorted(event: CdkDragSortEvent): void {
		this.isDropped = true;
	}

	private _updateGroups(): void {
		this.groups.forEach((group) => {
			group.updateView();
		});
	}

	private _checkValid(): boolean {
		this.service.formControl.markAllAsTouched();

		if (this.service.selectedChartsMap.size === 0) {
			this._notifierService.notify('warning', 'Нет выбранных параметров.');
		}

		return this.service.formControl.invalid || this.service.selectedChartsMap.size === 0;
	}
}
