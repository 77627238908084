import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {IdlesRepository, IPostIdleRedact} from '@shared/repositories/idles.repository';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {NotifierService} from 'angular-notifier';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Idle} from '@shared/components/idle-editor/models/idle-editor.classes';

@UntilDestroy()
@Component({
	selector: 'app-idle-editor',
	templateUrl: './idle-editor.component.html',
	styleUrls: ['./idle-editor.component.scss']
})
export class IdleEditorComponent implements OnChanges {
	@Input() idleId: number;
	@Output() onIdleChange = new EventEmitter<IPostIdleRedact>();

	unitClassId: number;

	idleTypes;
	bsConfig: Partial<BsDatepickerConfig>;

	// todo : убрать все данные в эти два класса
	baseIdle = new Idle();
	secondIdle = new Idle();

	baseIdleType;
	secondIdleType;
	start;
	end;
	newStart;
	newEnd;

	sliderInterval;

	saveBtnAvailable = false;
	editIdle = false;

	noIdleType = {
		id: 0,
		color: '#555',
		name: 'Не указан тип',
		disabled: true
	};


	constructor(
		private idlesService: IdlesRepository,
		private notifier: NotifierService
	) {
		this.bsConfig = Object.assign(
			{},
			{
				dateInputFormat: 'DD.MM.YYYY HH:mm',
				containerClass: 'theme-dark',
				selectFromOtherMonth: true
			}
		);
	}

	ngOnChanges() {
		this.idlesService.getIdlesTypes()
			.pipe(untilDestroyed(this))
			.subscribe(idleTypes => {

				if (this.idleId) {
					this.idlesService.getIdleById(this.idleId)
						.pipe(untilDestroyed(this))
						.subscribe(idleResponse => {
							this.unitClassId = idleResponse.unitClassId;

							this.idleTypes = this.filterUnitClass(idleTypes);
							this.idleTypes[Object.keys(this.idleTypes).length] = this.noIdleType;

							const endTime = idleResponse.idles[0].end_idle
								? new Date(idleResponse.idles[0].end_idle)
								: new Date();

							this.start = new Date(idleResponse.idles[0].start_idle);

							this.end = endTime;
							this.newStart = endTime;
							this.newEnd = endTime;

							this.sliderInterval = this.newEnd.getTime();

							if (!idleResponse.idles[0].comments[0]) {
								this.baseIdleType = {...this.noIdleType};
							} else {
								this.baseIdleType = idleResponse.idles[0].comments[0].idle_type;
							}

							this.secondIdleType = {...this.noIdleType};
						});
				}
			});
	}

	// Отфильтрует типы простоев по юнит классам
	filterUnitClass(idleTypes: any[]): any[] {
		const res = [];

		idleTypes.forEach(value => {
			if (
				!value.unitClassIdList ||
				(
					value.unitClassIdList && value.unitClassIdList.find(
						classId => classId === this.unitClassId
					)
				)
			) {
				res.push(value);
			}
		});

		return res;
	}

	changeDate(date: Date) {
		if (!date) {
			return;
		}

		if (date.getTime() < this.start.getTime()) {
			date = this.start;
		} else if (date.getTime() > this.newEnd.getTime()) {
			date = this.newEnd;
		}

		this.end = date;
		this.newStart = date;

		this.sliderInterval = this.newStart.getTime();

		this.checkIntervalPeriud();
	}

	changeIdleType(idle, event) {
		idle.id = event.target.value;

		const idleType = this.idleTypes.find(type => type.id === +event.target.value);

		idle.color = idleType.color;

		this.saveBtnAvailable = true;
	}

	newSliderInterval() {
		this.end = new Date(this.sliderInterval);
		this.newStart = new Date(this.sliderInterval);

		this.checkIntervalPeriud();
	}

	private checkIntervalPeriud() {
		if (this.editIdle) {
			if (this.end.getTime() === this.newEnd.getTime()) {
				this.saveBtnAvailable = false;
			} else {
				this.saveBtnAvailable = true;
			}
		}
	}

	editIdleClick() {
		this.editIdle = !this.editIdle;
		this.saveBtnAvailable = false;
	}

	save() {
		this.baseIdle.idleId = this.idleId;
		this.baseIdle.start = this.start;
		this.baseIdle.end = this.end;
		this.baseIdle.idleTypeId = this.baseIdleType.id;

		this.secondIdle.start = this.newStart;
		this.secondIdle.end = this.newEnd;
		this.secondIdle.idleTypeId = this.secondIdleType.id;

		const payLoad = {
			'baseIdle': this.baseIdle,
			'secondIdle': this.secondIdle
		};

		this.idlesService.redactIdle(payLoad)
			.pipe(untilDestroyed(this))
			.subscribe(idleResponse => {
					if (idleResponse) {
						this.notifier.notify('success', 'Успешно сохранено');
						this.onIdleChange.emit(idleResponse);


						if (idleResponse.secondIdle.idleId && idleResponse.secondIdle.idleId > 0) {
							this.idleId = idleResponse.secondIdle.idleId;
							this.saveBtnAvailable = false;
							this.editIdle = false;
							this.noIdleType = {
								id: 0,
								color: '#555',
								name: 'Не указан тип',
								disabled: true
							};
							this.ngOnChanges();
						}
					} else {
						this.notifier.notify('warning', 'Ошибка сохранения - проверте вводимые данные');
					}
				},
				err => {
					this.notifier.notify('warning', 'Ошибка сохранения - проверте вводимые данные');
				});
	}
}
