import {Injectable, InjectionToken, Injector, TemplateRef, ViewContainerRef} from '@angular/core';
import {ComponentType, Overlay, OverlayRef} from '@angular/cdk/overlay';
import {firstValueFrom, fromEvent, Observable, Subject} from 'rxjs';
import {ComponentPortal, TemplatePortal} from '@angular/cdk/portal';
import {filter, first} from 'rxjs/operators';
import {IModalDialogRef, IModalService} from '@shared/interfaces/modal';
import randomId from '@shared/utils/random-id';

export const DIALOG_DATA = new InjectionToken<any>('DIALOG_DATA');

export class ModalRef<T> implements IModalDialogRef {
	private _afterClosedSubject: Subject<any>;
	private _beforeClosedSubject: Subject<any>;
	private _dataForOutsideClosing: any;

	constructor(
		private _overlayRef: OverlayRef
	) {
		this._afterClosedSubject = new Subject();
		this._beforeClosedSubject = new Subject();
		this.initExitFromModal();
	}

	private initExitFromModal(): void {
		this._overlayRef
			.outsidePointerEvents()
			.pipe(
				filter((value) =>
					value.type === 'click' &&
					this._overlayRef.backdropElement === value.target
				),
				first()
			).subscribe(() => this.close(this._dataForOutsideClosing));
	}

	private async animateDisappearing(): Promise<void> {
		const {overlayElement} = this._overlayRef;

		overlayElement.classList.add('overlay-panel_disappear');

		document
			.querySelector('.overlay-backdrop').classList
			.add('overlay-backdrop_disappear');

		await firstValueFrom(fromEvent(overlayElement, 'animationend'));
	}

	public setDataForOutsideClosing<T>(value: T): void {
		this._dataForOutsideClosing = value;
	}

	public async close(result?: any): Promise<void> {
		await this.animateDisappearing();

		this._beforeClosedSubject.next(result);
		this._beforeClosedSubject.complete();

		this._overlayRef.dispose();

		this._afterClosedSubject.next(result);
		this._afterClosedSubject.complete();
	}

	public afterClosed<T>(): Observable<T> {
		return this._afterClosedSubject.asObservable();
	}

	public beforeClosed<T>(): Observable<T> {
		return this._beforeClosedSubject.asObservable();
	}
}

@Injectable({
	providedIn: 'root'
})
export class ModalsService implements IModalService {
	private readonly _mapComponent: Map<string, IModalDialogRef>;
	private _vcr: ViewContainerRef;

	constructor(
		private _injector: Injector,
		private _overlay: Overlay,
	) {
		this._mapComponent = new Map<string, IModalDialogRef>();
	}

	public setViewContainerRef(vcr: ViewContainerRef): void {
		this._vcr = vcr;
	}

	public open<T>(
		view: ComponentType<any> | TemplateRef<any>,
		data?: { data?: T },
	): IModalDialogRef {
		const identifier = this.getIdentifier(view);
		const isTemplate = view instanceof TemplateRef;

		if (this._mapComponent.has(identifier)) {
			return this._mapComponent.get(identifier);
		}

		const positionStrategy = this._overlay
			.position()
			.global()
			.centerHorizontally()
			.centerVertically();

		const overlayRef = this._overlay.create({
			positionStrategy,
			hasBackdrop: true,
			backdropClass: 'overlay-backdrop',
			panelClass: 'overlay-panel'
		});

		const modalRef = new ModalRef(overlayRef);
		const injector = Injector.create({
			parent: this._injector,
			providers: [
				{provide: ModalRef, useValue: modalRef},
				{provide: DIALOG_DATA, useValue: data?.data}
			]
		});

		overlayRef.attach(
			isTemplate
				? new TemplatePortal(view, this._vcr, injector)
				: new ComponentPortal(view, null, injector)
		);

		modalRef
			.afterClosed()
			.pipe(first())
			.subscribe(() => this._mapComponent.delete(identifier));

		this._mapComponent.set(identifier, modalRef);

		return modalRef;
	}

	private getIdentifier(view: ComponentType<any> | TemplateRef<any>): string {
		if (view instanceof TemplateRef) {
			return randomId('template');
		} else {
			return view.name;
		}
	}

	public close(view: ComponentType<any>): void {
		const identifier = this.getIdentifier(view);

		this._mapComponent.get(identifier)?.close();
	}

	public closeAll(): void {
		for (const [_, view] of this._mapComponent) {
			view?.close();
		}
	}
}
