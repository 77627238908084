import {Component, EventEmitter, Input, Output} from '@angular/core';
import {StopPropagationModule} from '@shared/directives/stop-propagation/stop-propagation.module';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IconModule} from '@shared/ui-components/icon/icon.module';
import {MatIconModule} from '@angular/material/icon';

@Component({
	selector: 'app-checkbox',
	styleUrls: ['./checkbox.component.scss'],
	templateUrl: './checkbox.component.html',
	standalone: true,
	imports: [
		StopPropagationModule,
		CommonModule,
		FormsModule,
		IconModule,
		MatIconModule,
		ReactiveFormsModule,
	],
})
export class CheckboxComponent {
	@Input() id = '';
	@Input() labelPosition: 'before' | 'after' = 'after';
	@Input() checked: boolean;
	@Input() disabled: boolean;
	@Input() fill: boolean;
	@Input() isOffChangesByClickTitle = false;
	@Input() isStopPropagationByClickOnCheck = false;

	@Output() change: EventEmitter<void> = new EventEmitter<void>();
	@Output() checkedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	public emit(): void {
		if (this.disabled) {
			return;
		}

		this.checkedChange.emit(!this.checked);
		this.change.emit();
	}
}
