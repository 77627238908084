import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {CookieService} from 'ngx-cookie-service';

import {EWebsocketEvents, WebsocketService} from './data.service';
import {IUnitClass} from '@store/types';
import {IUnitService} from '@shared/interfaces/unit';
import {IDevice} from '../../modules/monitoring/modules/unit/models/device';
import {IUnitType} from '../../modules/admin/models/admin-unit.type';

declare var SVG: any;

export class UnitDTO implements IUnitService {
	constructor(id) {
		this.id = id;
		this.unitId = id;
	}

	public id = null;
	public unitId = null;
	public commonData = [];
	public techData = [];
	public alarm = false;
	public hasJiraIssue = false;
	public isOverMaxIdleTime: boolean;
	public backupRef = '';
	public devices = [];
	public docRef = '';

	public hasRepairTaskWithIdle = false;
	public inventoryNumber = NaN;
	public location = {};
	public parentLocationId = NaN;
	public sdRepairTasks = [];

	public sdTaskKey = '';
	public taskMapList = [];
	public sdTaskServiceName = '';
	public sdTaskName = '';
	public sdTaskResponsibleFIO = '';
	public sdTaskResponsibleImg = '';

	public status = '';
	public unitClass: {
		id: number;
		isPpr: boolean;
		isCounterUsing: boolean;
		isUnitUsing: boolean;
		name: string;
		shortName: string
	};
	public unitType: {
		description: string;
		hasMnemo: boolean;
		id: number;
		image: string;
		mnemoId: number;
		name: string
	};
	public isUnitInFavorite = false;
	public setValue = (key: string, value: any) => {
		this[key] = value;
	};
}

export interface IUnitDeviceParameter {
	name: string;
	shortName: string;
	measure: string | null;
	dataType: string;
	deviceParameterId: number;
	actualValue: boolean | null;
}

export interface IUnitCommonDeviceParameter {
	deviceParameterId: number;
	dataType: string;
	name: string;
	actualValue: boolean;
	shortName: string;
}

export interface IUnitWebsocket {
	alarm: boolean;
	backupRef: string;
	commonData: IUnitCommonDeviceParameter[];
	devices: IDevice[];
	docRef: string;
	factoryNumber: string;
	hasJiraIssue: boolean;
	inventoryNumber: string;
	isUnitInFavorite: boolean;
	location: {
		id: number;
		name: string;
		description: string;
	};
	parentLocationId: number;
	repairTasks: {
		id: number;
		url: string;
		key: string;
	}[];
	status: string;
	techData: IUnitDeviceParameter[];
	unitClass: Omit<IUnitClass, 'isChecked'>;
	unitType: IUnitType;

	sdTaskKey?: string;
	sdTaskServiceName?: string;
	sdTaskName?: string;
	sdTaskResponsibleFIO?: string;
	sdTaskResponsibleImg?: string;
}

@Injectable({
	providedIn: 'root'
})
export class UnitService {
	public updateUnit: BehaviorSubject<boolean>;
	public unit: IUnitService;
	public commonParams = {
		mode: new BehaviorSubject(null),
		programname: new BehaviorSubject(null),
		connection: new BehaviorSubject(false),
		run: new BehaviorSubject(false),
		alarm: new BehaviorSubject(false),
		sdrepairtask: new BehaviorSubject(false),
	};

	constructor(
		private _dataService: WebsocketService,
		private _cookieService: CookieService
	) {
		this._dataService.messages[EWebsocketEvents.ON_UNIT_DATA].subscribe(data => {
			if (this.unit && this.unit.techData) {
				data.result.forEach((p) => {
					const td = this.unit.techData.find(
						d => d.deviceParameterId === p.deviceParameterId
					);

					if (td) {
						this.setColor(td.deviceParameterId);

						td.actualValue = p.value;

						if (td.shortName) {
							this.commonParams[td.shortName.toLowerCase()]?.next(p.value);
						}
					}
				});
			}
		});

		this.updateUnit = new BehaviorSubject<boolean>(false);
	}

	public findDeviceByOptionName(name: string): any {
		return this.unit.devices.find(
			({deviceOptions}) => deviceOptions.find(
				({deviceOptionsTypes: {optionName}}) => optionName === name
			)
		);
	}

	private setColor(id): void {
		const av = document.getElementById('actualValue_' + id);

		if (av) {
			if (av.classList.contains('data_item_value_fff')) {
				clearTimeout(av['timeout']);
			}

			av.classList.remove('data_item_value_fff');
			av.classList.add('data_item_value_fff');

			av['timeout'] = setTimeout((d) => {
				d.classList.remove('data_item_value_fff');
			}, 2500, av);
		}
	}

	public getUnit(id): Subject<IUnitWebsocket> {
		const loader$ = new Subject<IUnitWebsocket>();

		this._cookieService.set('unitId', id.toString(), null, '/');

		this._dataService.send('announce/unSubscribeOnUnitData', {id: id});
		this._dataService.send('announce/subscribeOnUnitData',
			{
				id: id,
				userId: JSON.parse(localStorage.getItem('currentUser')).userId
			})
			.then((unit: IUnitWebsocket) => {
				// @ts-ignore
				this.unit = unit;
				loader$.next(unit);
				this.unit.id = id;

				this.unit.techData.sort(
					(a, b) => (a.name.indexOf('Программа. Номер') > -1) ? -1 : 1
				);

				this.unit.commonData = unit['commonData'];
				this.unit.techData = unit['techData'];

				this.unit.commonData.forEach(item => {
					const techDataItem = this.unit.techData.find(
						td => td.deviceParameterId === item.deviceParameterId
					);

					techDataItem.shortName = item.shortName;
				});

				this.unit.sdRepairTasks = unit.repairTasks;

				this.unit.sdTaskKey = unit.sdTaskKey
					? unit.sdTaskKey
					: null;
				this.unit.sdTaskResponsibleFIO = unit.sdTaskResponsibleFIO
					? unit.sdTaskResponsibleFIO
					: null;
				this.unit.sdTaskServiceName = unit.sdTaskServiceName
					? unit.sdTaskServiceName
					: null;
				this.unit.sdTaskName = unit.sdTaskName
					? unit.sdTaskName
					: null;

				Object.keys(this.commonParams).forEach(key => {
					const commonParam = this.unit.techData.find(techDataItem => {
						return techDataItem.shortName &&
							techDataItem.shortName.toLowerCase() === key;
					});

					this.commonParams[key].next(commonParam ? commonParam.actualValue : []);
				});

				this.updateUnit.next(!this.updateUnit.value);
			});

		return loader$;
	}
}
