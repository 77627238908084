<app-tree-node
	[node]="node"
	[disabled]="node.accessAllow === false"
	[useCheckbox]="useCheckbox"
>
	<app-selector-letters
		[value]="node[displayField]"
		[searchString]="searchString"
	></app-selector-letters>
</app-tree-node>

<div
	*ngIf="node.isOpen && node.children?.length"
	@untwistingAnimation
	class="nodes-wrapper"
>
	<ng-container
		*ngFor="let child of node.children; let i = index"
	>
		<app-tree-nodes
			*ngIf="child.isVisible"
			[node]="node.children[i]"
			[useCheckbox]="useCheckbox"
			[displayField]="displayField"
			[searchString]="searchString"
		></app-tree-nodes>
	</ng-container>
</div>
