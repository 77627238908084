import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { IUnitDeviceParameter } from '@shared/services/unit.service';
import { CdkDragDrop, CdkDropList, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { getCustomScaleAppearanceAnimation } from '@shared/animations/common.animations';

let key = 'group' + Math.floor(Math.random() * 100);

@Component({
	selector: 'app-group-trends',
	templateUrl: 'group-trends.component.html',
	styleUrls: ['group-trends.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [getCustomScaleAppearanceAnimation(0.1)]
})
export class GroupTrendsComponent {
	@ViewChild('list') group: CdkDropList;

	@Input() charts: IUnitDeviceParameter[] = [];
	@Input() index: number;
	@Input() active: boolean = false;
	@Input() cdkDropGroups: CdkDropList[] = [];


	@Output() activeChange: EventEmitter<void> = new EventEmitter();
	@Output() deleteChart: EventEmitter<IUnitDeviceParameter> = new EventEmitter();
	@Output() deleteGroup: EventEmitter<void> = new EventEmitter();

	constructor(private _cdr: ChangeDetectorRef) {
	}

	@HostListener('click', ['$event']) onClick() {
		this.activeChange.emit();
	}

	public trackByFn(index: number, item: IUnitDeviceParameter): number {
		return 1;
	}

	public drop(event: CdkDragDrop<string[]>): void {
		if (event.previousContainer === event.container) {
			moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
		} else {
			transferArrayItem(
				event.previousContainer.data,
				event.container.data,
				event.previousIndex,
				event.currentIndex,
			);
		}
	}

	// Используется в родительском компоненте
	public updateView(): void {
		this._cdr.markForCheck();
	}
}
