import { Injectable } from '@angular/core';
import { ParameterFilterService } from '@shared/components/filters/services/parameter-filter.service';
import { IUnitFull } from '../../../../../../modules/monitoring/modules/unit/models/unit';
import { UnitsSelector } from '@store/units-store/units.selector';

@Injectable({
	providedIn: 'root',
})
export class LocationFilterService extends ParameterFilterService {
	private _locationsIds: number[] = [];

	constructor(
		private _unitsSelector: UnitsSelector,
	) {
		super();
	}

	set(parameters: number[]): void {
		this._selectedUnit = new Map();
		this._selectedUnit.clear();

		this._locationsIds = parameters;

		parameters.forEach((locationId) => {
			const units: IUnitFull[] = this._unitsSelector.getUnitsByLocation(locationId);

			if (!!units?.length) {
				units.forEach((unit: IUnitFull) => {
					this._selectedUnit.set(unit.id, unit);
				});
			}
		});

		this._selectedUnitChange.next();
	}

	getLocationsIds(): number[] {
		return this._locationsIds;
	}

	override clear() {
		super.clear();
		this._locationsIds = [];
	}
}
