import {ChangeDetectorRef, Component, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import {untwistingAnimation} from '@shared/animations/common.animations';

@Component({
	selector: 'app-expand',
	templateUrl: './expand.component.html',
	styleUrls: ['./expand.component.scss'],
	animations: [untwistingAnimation]
})
export class ExpandComponent {
	@Input() isOpen = false;
	@Input() isAbsolute = false;
	@Input() isAutoClose = false;
	@Input() template?: TemplateRef<any>;

	@Output() isOpenChange = new EventEmitter<void>();

	constructor(
		private _cdr: ChangeDetectorRef
	) {
	}

	public closeExpand(): void {
		if (this.isOpen) {
			this.isOpen = false;
			this._cdr.markForCheck();
		}
	}
}
