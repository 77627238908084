import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
	selector: 'app-trends-select-item',
	templateUrl: 'trends-select-item.component.html',
	styleUrls: ['trends-select-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrendsSelectItemComponent {
	@Input({required: true}) mode: 'add' | 'delete';
	@Input() isChecked: boolean;

	@Output() click: EventEmitter<void> = new EventEmitter<void>();
	@Output() delete: EventEmitter<void> = new EventEmitter<void>();
}
