import {AfterViewInit, Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {IPartialInterval} from '@shared/ui-components/n-inverval/models/interval.types';
import {DIALOG_DATA, ModalRef} from '@shared/services/modals.service';

@UntilDestroy()
@Component({
	selector: 'app-idles-comments',
	templateUrl: 'idles-comments.component.html',
	styleUrls: [
		'idles-comments.component.scss',
	],
})
export class IdlesCommentsComponent implements AfterViewInit {
	@ViewChild('textArea') textArea: ElementRef;

	private _hasComment: boolean;

	public interval: IPartialInterval;

	constructor(
		private _dialogRef: ModalRef<IdlesCommentsComponent>,
		@Inject(DIALOG_DATA) public dataComment,
	) {
		this._hasComment = false;
	}

	public ngAfterViewInit(): void {
		const textarea = document.querySelector('textarea');

		if (textarea) {
			textarea.focus();
		}
	}

	public setNewCommentText(): void {
		this.dataComment.textChanged =
			this.dataComment.newComment.comment.localeCompare(
				this.dataComment.idleCommentText,
			) !== 0;

		this._hasComment = true;

		this.closeModal();
	}

	public closeModal(): void {
		this._dialogRef.close(this._hasComment);
	}
}
