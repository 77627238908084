import { Injectable } from '@angular/core';
import { UnitsMetadataReducer } from '@store/units-metadata-store/units-metadata.reducer';
import { Observable, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { UnitApiRepository } from '@shared/repositories/unit-api.repository';
import { NotifierService } from 'angular-notifier';

@Injectable({providedIn: 'root'})
export class UnitFavoriteExecutor {
	constructor(
		private _reducer: UnitsMetadataReducer,
		private _unitApiRepository: UnitApiRepository,
		private _notifierService: NotifierService,
	) {
	}

	public addOrRemoveFromFavourites(unitId: number): Observable<{ [key: string]: number }> {
		const res$: Subject<{ [key: string]: number }> = new Subject()
		this._unitApiRepository.addOrDeleteUnitToUserFavorites(unitId)
			.pipe(first()).subscribe(res => {
				if (res['delete']) {
					this._reducer.deleteValueInSet('favoriteUnitIds', unitId);

					this._notifierService.notify(
						'warning',
						'Оборудование удалено из избранного',
					);
				} else if (res['add']) {
					this._reducer.addValueInSet('favoriteUnitIds', unitId);

					this._notifierService.notify(
						'success',
						'Оборудование добавлено в избранное',
					);
				} else {
					this._notifierService.notify(
						'error',
						'Не удалось добавить оборудование в избранное. Обратитесь в службу поддержки.',
					);
				}
				res$.next(res);
			},
			error => {
				this._notifierService.notify(
					'error',
					'Не удалось добавить оборудование в избранное. Обратитесь в службу поддержки.',
				);
				res$.next(error)
			});

		return res$.asObservable();
	}
}
