import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ButtonComponent} from '@shared/ui-components/button/button.component';
import {CdkDrag, CdkDropList} from '@angular/cdk/drag-drop';
import {FormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {filter, first} from 'rxjs/operators';
import {IUnitWebsocket, UnitService} from '@shared/services/unit.service';
import {NavigationEnd, Router} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {NgxUiLoaderModule, NgxUiLoaderService} from 'ngx-ui-loader';
import {WebsocketService} from '@shared/services/data.service';
import {UnitInformationModule} from '../../../modules/monitoring/modules/unit/components/unit-tabs-area/modules/unit-information/unit-information.module';
import {IUnitInfoModalComponentData} from '@shared/modals/unit-info-modal/models/unit-info-modal.types';
import {DIALOG_DATA, ModalRef} from '@shared/services/modals.service';

@UntilDestroy()
@Component({
	templateUrl: 'unit-info-modal.component.html',
	styleUrls: ['unit-info-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		CommonModule,
		UnitInformationModule,
		ButtonComponent,
		CdkDrag,
		CdkDropList,
		FormsModule,
		MatCheckboxModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		NgxUiLoaderModule,
	],
})
export class UnitInfoModalComponent implements OnInit, OnDestroy {
	constructor(
		@Inject(DIALOG_DATA) public data: IUnitInfoModalComponentData,
		private _dialogRef: ModalRef<UnitInfoModalComponent>,
		private _unitService: UnitService,
		private _loaderService: NgxUiLoaderService,
		private _router: Router,
		private _cdr: ChangeDetectorRef,
		private _websocketService: WebsocketService
	) {
	}

	public ngOnInit(): void {
		this._loaderService.startLoader('loaderUnitInfoModal');
		this._unitService.getUnit(this.data.unitId)
			.pipe(first())
			.subscribe((unit: IUnitWebsocket) => {
				this._loaderService.stopLoader('loaderUnitInfoModal');
				this._cdr.markForCheck();
			});
		this.initListeners();
	}

	private initListeners(): void {
		this._router.events
			.pipe(
				filter(event => event instanceof NavigationEnd),
				untilDestroyed(this)
			).subscribe(() => {
			this.closeModal();
		});
	}

	public closeModal(): void {
		this._dialogRef.close();
	}

	public ngOnDestroy(): void {
		this._websocketService.send(
			'announce/unSubscribeOnUnitData',
			{id: this.data.unitId}
		);
		this._websocketService.send(
			'announce/unSubscribeOnLocationData',
			{ids: []}
		);
	}
}
