<div class="modal g-overflow-visible">
	<div class="modal__header">
		<div class="title">{{ data.title }}</div>

		<app-button
			svgIcon="close"
			class="g-button-secondary-empty btn-close"
			(click)="closeModal()"
		></app-button>
	</div>

	@if (data.description) {
		<div class="modal__content">
			{{ data.description }}
		</div>
	}

	<div class="modal__basement g-gap">
		<app-button
			class="g-button-success-square"
			(click)="closeModal(true)"
		>Да
		</app-button>
		<app-button
			class="g-button-primary-square"
			(click)="closeModal(false)"
		>Нет
		</app-button>
	</div>
</div>
