import {
	Directive,
	ElementRef,
	HostListener, Injector,
	Input, OnChanges,
	OnDestroy, OnInit,
	TemplateRef, ViewContainerRef,
} from '@angular/core';
import {ComponentTooltipService} from '@shared/directives/tooltip/tooltip-component.service';
import {TooltipService} from '@shared/directives/tooltip/tooltip.service';
import {TemplateTooltipService} from '@shared/directives/tooltip/tooltip-template.service';

@Directive({
	selector: '[appTooltip]'
})
export class TooltipDirective implements OnDestroy, OnInit {
	@Input() appTooltip = '';
	@Input() tooltipTemplate: TemplateRef<Object> = null;
	@Input() tooltipTemplateData: any;
	@Input() tooltipCondition = true;

	private _tooltipService: TooltipService<Object>;

	constructor(
		private _elementRef: ElementRef,
		private _containerRef: ViewContainerRef,
		private _injector: Injector,
	) {
	}

	public ngOnInit(): void {
		const service = this.tooltipTemplate
			? TemplateTooltipService
			: ComponentTooltipService;

		this._tooltipService = this._injector.get(service);
	}

	@HostListener('mouseenter')
	public onMouseEnter(): void {
		if (this._tooltipService.isHave() || !this.tooltipCondition) {
			return;
		}

		const tooltipData = {
			container: this._elementRef.nativeElement,
			data: this.tooltipTemplate
				? this._containerRef.createEmbeddedView(
					this.tooltipTemplate,
					{data: this.tooltipTemplateData}
				)
				: {text: this.appTooltip}
		};

		this._tooltipService.create(tooltipData);
	}

	public ngOnDestroy(): void {
		this._tooltipService.close();
	}
}
