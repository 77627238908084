import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BsLocaleService} from 'ngx-bootstrap/datepicker';
import {NotifierService} from 'angular-notifier';
import {Router} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {IDataServiceInterface} from '@shared/components/dto-viewer/models/data-service-interface';
import {DataConfigType} from '@shared/components/dto-viewer/models/data-config';


@UntilDestroy()
@Component({
	selector: 'app-dto-item',
	templateUrl: './dto-item.component.html',
	styleUrls: ['./dto-item.component.scss']
})
export class DtoItemComponent implements OnInit {
	@Input() dataService: IDataServiceInterface;
	@Input() showSaveButton = true;

	@Output() change: EventEmitter<any> = new EventEmitter<any>();
	@Output() deleted = new EventEmitter<void>();
	@Output() saved = new EventEmitter<void>();

	public loading: boolean;
	public dataConfigType: typeof DataConfigType;

	public displayWithSingleNomenclature = (option) => {
		return !option
			? ''
			: (
			!option?.partnumber
				? ''
				: (option.partnumber + ' - ')
		) + option?.name;
	};

	constructor(
		private _localeService: BsLocaleService,
		private _notifierService: NotifierService,
		private _router: Router,
	) {
		this._localeService.use('ru');

		this.loading = false;
		this.dataConfigType = DataConfigType;
	}

	public ngOnInit(): void {
		if (this.dataService.dtoTransfer) {
			this.dataService.dtoTransfer.refresh
				.pipe(untilDestroyed(this))
				.subscribe(value => {
					this.loading = false;
				});
		}
	}

	public onSubmit(): void {
		this.loading = true;

		this.dataService.saveActiveItem(this.dataService.activeItem);

		this.saved.emit();
	}

	public onDelete(): void {
		this.dataService.deleteActiveItem([this.dataService.activeItem.id]);

		this.deleted.emit();
	}

	public copyInputInBuffer(inputElement): void {
		inputElement.select();

		document.execCommand('copy');

		inputElement.setSelectionRange(0, 0);

		this._notifierService.notify('success', 'Ссылка скопирована - воспользуйтесь Interner  Explorer или Проводником');
	}

	public goToFtp(ftpLink: string): void {
		const ftpClean = ftpLink.split('=')[1];

		this._router.navigate(['/docs'], { /// docs/ftp
			queryParams: {
				path: ftpClean
			}
		}).then(value => setTimeout(() => {
			// this.navigationMenuService.setViewType('ftp-doc');
		}, 1000));
	}

	getDate(val): any {
		return new Date(val);
	}

	public changeSelectField(name: string, event: any): void {
		this.dataService.activeItem[name] = event !== null ? event.id : null;
		this.change.emit({
			fieldId: Number(name.substring(4, name.length)),
			value: event !== null ? event.name : null,
		});
	}

	public changeString(name: string, event: any, input: HTMLInputElement): void {
		const selectionStart = input.selectionStart;
		if (!!this.dataService.itemValidator[name]?.regExp) {
			event = this.useRegExp(this.dataService.itemValidator[name].regExp, event);
		}
		if (!!this.dataService.itemValidator[name]?.maskFunc) {
			event = this.dataService.itemValidator[name]?.maskFunc(event);
		}

		input.value = event;
		input.selectionStart = selectionStart;
		input.selectionEnd = selectionStart;
		this.dataService.activeItem[name] = event;

		this.change.emit({
			fieldId: Number(name.substring(4, name.length)),
			value: event
		});
	}

	public changeMask(event: string, name: string): void {
		if (event.length === 11) {
			this.dataService.activeItem[name] = event;
		} else {
			this.dataService.activeItem[name] = null;
		}
	}

	private useRegExp(regExp: RegExp, value: string): string {
		return value.replace(regExp, '');
	}
}
