import {NgModule} from '@angular/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';

import {TimepickerComponent} from '@shared/ui-components/timepicker/timepicker.component';
import {StopPropagationModule} from '@shared/directives/stop-propagation/stop-propagation.module';
import {ColumnValuesComponent} from '@shared/ui-components/timepicker/components/column-values/column-values.component';
import {TimeInputComponent} from '@shared/ui-components/timepicker/components/time-input/time-input.component';

@NgModule({
	declarations: [
		TimepickerComponent,
		ColumnValuesComponent,
		TimeInputComponent,
	],
	imports: [
		MatFormFieldModule,
		MatInputModule,
		MatIconModule,
		MatButtonModule,
		MatMenuModule,
		CommonModule,
		StopPropagationModule,
		ReactiveFormsModule,
	],
	exports: [TimepickerComponent],
})
export class TimepickerModule {
}
