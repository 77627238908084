import {Injectable} from '@angular/core';
import {Query} from '@datorama/akita';

import {MesStore} from './mes.store';
import {IState, IStateStatus} from './mes-store.models';

@Injectable({
	providedIn: 'root',
})
export class MesReducer extends Query<any> {
	constructor(protected mesStore: MesStore) {
		super(mesStore);
	}

	public rewriteStore<T = any>(nameState: string, value: T): void {
		this.store.update((state: Cache) => {
			return {
				...state,
				[nameState]: value
			};
		});
	}

	public setStatus(nameState: string, status: IStateStatus): void {
		const newState: IState = this.mesStore.getValue()[nameState];
		newState.status = status;

		this.store.update((state: Cache) => ({
				[nameState]: {...newState}
			})
		);
	}

	public setValue<T>(nameState: string, value: T, status: IStateStatus = 'success'): void {
		const newState: IState = this.mesStore.getValue()[nameState];
		newState.status = status;
		newState.value = value;

		return this.store.update((state: Cache) => {
			return {
				[nameState]: {...newState}
			};
		});
	}

	public setValuesMap<T, K>(nameState: string, values: T[], key: string, status: IStateStatus = 'success'): void {
		this.setValue<Map<K, T[]>>(
			nameState,
			this.castValuesToMap(new Map(), values, key),
			status
		);
	}

	public addValueMap<T, K>(nameState: string, value: T, key: string, status: IStateStatus = 'success'): void {
		const map = this.mesStore.getValue()[nameState].value;

		this.setValue<Map<K, T[]>>(
			nameState,
			this.castValueToMap(!!map ? map : new Map(), value, key),
			status
		);
	}

	public castValuesToMap<T = any, K = any>(map: Map<K, T[]>, values: T[], key: string): Map<K, T[]> {
		values.forEach((value) => this.castValueToMap(map, value, key));

		return map;
	}

	public castValueToMap<T = any, K = any>(map: Map<K, T[]>, value: T, key: string): Map<K, T[]> {
		if (!map.has(value[key])) {
			map.set(value[key], [value]);
		} else {
			map.get(value[key]).push(value);
		}

		return map;
	}
}
