import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { ApiRepository, ISettings } from '@shared/repositories/api.repository';
import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

export interface ISettingsState {
	settings: ISettings<string>[];
}

const DefaultSettingsState: ISettingsState = {
	settings: [],
};

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'settingsStore'})
export class SettingsStore extends Store<ISettingsState> {
	private _settingTypes = {
		'auth.local': 'boolean',
	};

	constructor(
		private _apiRepository: ApiRepository,
	) {
		super(DefaultSettingsState);
	}

	init(): Observable<ISettingsState> {
		const settings$: Observable<ISettings<'auth.local'>[]> = this._apiRepository.getSettings(
			Object.keys(this._settingTypes)
		);

		return combineLatest({
			settings: settings$
		})
			.pipe(
				take(1),
				map((res) => {
					const data = {
						settings: this.fetchSettings(res.settings)
					};

					this.update(data);

					return data;
				})
			);
	}


	private fetchSettings(settings: ISettings<'auth.local'>[]): ISettings<'auth.local'>[] {
		return settings.map(item => {
			const type = this._settingTypes[item.key];
			if (type === 'boolean') {
				item.value = item.value === 'true';
			}
			if (type === 'date') {
				item.value = new Date(item.value);
			}
			if (type === 'object') {
				item.value = JSON.parse(item.value);
			}

			return item;
		});
	}
}
