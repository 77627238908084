import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {IColumnValues} from '@shared/ui-components/timepicker/components/column-values/models/column-values.types';

@Component({
	selector: 'app-column-values',
	templateUrl: 'column-values.component.html',
	styleUrls: ['column-values.component.scss']
})
export class ColumnValuesComponent implements OnInit {
	counterWheel = 0;

	@Input()
	columnElements: string[];

	@Input()
	activeElement = '0';

	@Output()
	changeCurrentValue = new EventEmitter<string>();

	columnValues: IColumnValues[] = [];
	elementViews: string[] = [];

	@HostListener('wheel', ['$event'])
	onWheel($event: WheelEvent): void {
		if ($event.deltaY > 0 && this.elementViews[2] !== ' - ') {
			this.counterWheel--;

			this.scroll(1);
			this.changeCurrentValue.emit(this.elementViews[3]);
		} else if ($event.deltaY < 0 && this.elementViews[4] !== ' - ') {
			this.counterWheel++;

			this.scroll(-1);
			this.changeCurrentValue.emit(this.elementViews[3]);
		}
	}

	ngOnInit(): void {
		if (this.activeElement.length === 1) {
			this.activeElement = '0' + this.activeElement;
		}

		let moveIndex = this.columnElements.indexOf(this.activeElement);

		moveIndex = moveIndex === -1 ? 0 : moveIndex;

		this.columnElements.forEach((element, index) => {
			this.columnValues.push({
				value: element,
				index: index + 3 - moveIndex
			});
		});

		this.scroll(0);
	}

	setActive(indexActiveElement: number) {
		if (this.elementViews[indexActiveElement] === ' - ') {
			return;
		}

		this.columnValues.forEach(element => {
			element.index += 3 - indexActiveElement;
		});

		this.scroll(0);
		this.changeCurrentValue.emit(this.elementViews[3]);
	}

	scroll(num: number) {
		this.columnValues.forEach((element, index) => {
			element.index += num;
		});

		if (this.columnValues[0].index > 0) {
			let iValues = 0;
			for (let index = 0; index < 8; index++) {
				if (
					index >= this.columnValues[iValues].index &&
					this.columnValues[index].index >= 0
				) {
					this.columnElements[
						this.columnValues[iValues].index
						] = this.columnValues[iValues].value;

					iValues++;
				} else {
					this.columnElements[index] = ' - ';
				}
			}
		} else {
			let remainder = 0;

			for (let index = 0; index < this.columnValues.length; index++) {
				if (this.columnValues[index].index >= 0) {
					remainder++;

					this.columnElements[
						this.columnValues[index].index
						] = this.columnValues[index].value;
				}
			}

			for (; remainder < this.columnElements.length; remainder++) {
				this.columnElements[remainder] = ' - ';
			}
		}

		this.elementViews = this.columnElements.slice(0, 7);
	}
}
