
export function maskForPhone(value: string, selectionStart: number, isDeleting: boolean = false, withoutCode: boolean = false): {
	value: string,
	shift: number
} {
	if (!value) {
		return {
			value: '',
			shift: 0
		};
	}
	let i = 0;
	let res: string = '';
	const shiftSelection: {value: number} = { value: 0 };
	if (withoutCode) {
		if (value.length >= 11) {
			while (
				value[i] === '+' ||
				value[i] === ' ' ||
				value[i] === '7' ||
				value[i] === '8')
			{
				++i;
			}
		}
	} else if (value[i] === '+') {
		++i;
		res += '+';
		shiftSelection.value++

		if (!!value[i]) {
			if (value[i] === '7') {
				++i;
			}
			res += '7';
			shiftSelection.value++;
		}

		if (!!value[i]) {
			if (value[i] === ' ') {
				++i;
			}
			res += ' ';
			shiftSelection.value++
		}
	} else if (value.length > 0 && (value[0] === '8' || value[0] === '7')) {
		res += '+7 (';
		shiftSelection.value += 4;
		++i;
	}
	else if (value.length > 0 && isInteger(value[0])) {
		shiftSelection.value += 3;

		res += '+7 ';
	} else if (value.length > 0) {
		return {
			value: '+7 ',
			shift: 3,
		}
	}

	let number = getScopeNumberOfNumberPhone(value, i, shiftSelection, isDeleting, selectionStart);

	return {
		value: res + number.value + getTheRestOfThePhoneNumber(value, number.i, shiftSelection, isDeleting, selectionStart).value,
		shift: shiftSelection.value,
	}
}

export function getTheRestOfThePhoneNumber(value: string, i: number, shift: {value: number}, isDeleting: boolean, selectionStart: number): { i: number, value: string } {
	let res = '';

	let number = getNumberOfNumberPhone(value, i, 3);
	i = number.i;
	res += number.value;

	if (i !== value.length) {
		res += '-'

		number = getNumberOfNumberPhone(value, i, 2);
		res += number.value;
		i = number.i;

		if (!isDeleting &&
			(number.value.length === 1 || (value[selectionStart] === '-' && selectionStart + 2 === number.i))
		) {
			shift.value++;
		}
	} else {
		return {
			i: i,
			value: res
		}
	}

	if (i !== value.length) {
		res += '-';
		number = getNumberOfNumberPhone(value, i, 2);
		res += number.value;
		if (!isDeleting &&
			(number.value.length === 1 || (value[selectionStart] === '-' && selectionStart + 2 === number.i))
		) {
			shift.value++;
		}
	}

	return {
		i: i,
		value: res
	}
}

export function getScopeNumberOfNumberPhone(value: string, i: number, shift: {value: number}, isDeleting: boolean, selectionStart: number): { i: number, value: string } {
	let res = '';
	let count = 4;

	if (!!value[i]) {
		if (value[i] === '(') {
			res += '(';
		} else if (isInteger(value[i])) {
			res += '(' + value[i];
			shift.value++;
			--count;
		}
		++i;
	}

	for (; i < value.length && --count !== 0; i++) {
		if (isInteger(value[i])) {
			res += value[i];
		} else {
			++count;
		}
	}

	if (isDeleting) {
		if (value[i] === ')' && value.length - 1 === i) {
			++i;
		} else if (value[i] === ')' && value.length - 1 !== i) {
			res += ') ';
			if (selectionStart - 1 === i) {
				shift.value -= 1;
			}
		} else if (i > selectionStart) {
			res += ') ';
		}
	} else if (count === 0) {
		if (!!value[i] && isInteger(value[i])) {
			res += ') ';

			if (value[selectionStart] === ')' || value[selectionStart] === undefined) {
				shift.value += 2;
			}
		}else if (value[i] === ')') {
			res += ')';
			++i;
			if (value[i] === ' ') {
				++i;
				res += ' '
			} else {
				shift.value += 1;
				res += ' ';
			}
		}
	}

	return {
		i: i,
		value: res,
	}
}

export function getNumberOfNumberPhone(s: string, i: number, count: number): { i: number, value: string } {
	let res = '';

	for (; i < s.length && --count !== -1; i++) {
		if (isInteger(s[i])) {
			res += s[i];
		} else {
			++count;
		}
	}
	return {
		i: i,
		value: res
	}
}

export function isInteger(value: string): boolean {
	return /^[0-9]+$/.test(value)
}

export function isValidInputPhoneNumber(value: string): boolean {
	return /^[0-9]+$/.test(value)
}
