<div class="modal">
	<div class="modal__header">
		<div class="title">Мнемосхема</div>

		<app-button
			svgIcon="close"
			class="g-button-secondary-empty btn-close"
			(click)="dialogRef.close()"
		></app-button>
	</div>

	<div class="modal__content">
		<app-mnemo
			class="mnemo"
			[unit]="data.unit"
			[onlineParams]="unitHelpers.getFormattedParams(data.unit.techData)"
		></app-mnemo>
	</div>
</div>
