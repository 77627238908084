import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { IPermissions } from '@store/types';
import { IAlarms } from '@shared/models/alarms';
import { AccessStore, IAccessState } from '@store/accesses-store/access.store';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { EFunctionalConstantEnums } from '@shared/constants/e-functional-constant.enums';
import { IAlarmLevel } from '@shared/repositories/api.repository';

@Injectable({ providedIn: 'root' })
export class AccessSelector extends Query<IAccessState> {
	constructor(
		protected store: AccessStore,
	) {
		super(store);
	}

	public getPermissions(): IPermissions {
		return this.store.getValue().permissions;
	}

	public getAlarmLevels(): IAlarmLevel[] {
		return this.store.getValue().alarms.levels;
	}

	public getFilledPermissions$(): Observable<IPermissions> {
		return this.select('permissions').pipe(filter((ev) => !!ev));
	}

	public isAnyFunctionalAvailable(
		neededFunctionals: EFunctionalConstantEnums[],
		functionals?: EFunctionalConstantEnums[]
	): boolean {
		const roles = functionals || this.getPermissions()?.functional;

		if (!roles) {
			return false;
		}

		const noFunc = neededFunctionals.filter(
			// @ts-ignore
			func => roles.includes(func.toString())
		);

		return noFunc.length > 0;


	}

	public haveUnitAccess(unitId: number): boolean {
		const units = this.getValue().permissions.units;

		return units && units.includes(unitId);
	}
}
