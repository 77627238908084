import {NgModule} from '@angular/core';
import {ExpandComponent} from '@shared/ui-components/expand/expand.component';
import {ExpandRowModule} from '@shared/directives/expand-row-directive/expand-row.module';
import {ClickOnOtherElementsModule} from '@shared/directives/click-on-other-elements/click-on-other-elements.module';
import {NgTemplateOutlet} from '@angular/common';

@NgModule({
	declarations: [ExpandComponent],
	imports: [
		ExpandRowModule,
		ClickOnOtherElementsModule,
		NgTemplateOutlet
	],
	exports: [ExpandComponent]
})
export class ExpandModule {
}
