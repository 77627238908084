<div class="modal">
	<div class="modal__header">
		<div class="title">
			{{ data.title }}
		</div>

		<app-button
			svgIcon="close"
			class="btn-close g-button-secondary-empty"
			(click)="closeModal()"
		></app-button>
	</div>

	<div class="modal__content g-scroll">
		<video
			width="100%"
			autoplay
			controls
		>
			<source [src]="data.path"/>
			Ваш браузер не поддерживает воспроизведение видео.
		</video>
	</div>
</div>
