import {Injectable} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';

import {iconNames} from '../../../assets/icons/icon-names';


@Injectable()
export class UseCustomIconService {
	constructor(
		private _matIconRegistry: MatIconRegistry,
		private _domSanitizer: DomSanitizer
	) {
		iconNames.forEach(({name, path}) => {
			const url = this._domSanitizer.bypassSecurityTrustResourceUrl(path);

			this._matIconRegistry.addSvgIcon(name, url);
		});
	}
}
