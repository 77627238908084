<label
	appStopPropagation
	[isStopPropagation]="disabled || isStopPropagationByClickOnCheck"
	class="checkbox-container"
	(click)="emit()"
	[class.disable]="disabled"
	[class.checked]="checked"
	[class.fill]="fill"
>

	<input
		type="checkbox"
		[ngModel]="checked"
		[disabled]="true"
	>

	<span class="square"></span>

	<svg class="checkmark" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
		<path fill="none" d="M1.73,12.91 8.1,19.28 22.79,4.59"></path>
	</svg>
</label>

<span
	appStopPropagation
	(click)="!isOffChangesByClickTitle && emit()"
	[isStopPropagation]="disabled"
	class="content-checkbox g-unselectable"
>
	<ng-content></ng-content>
</span>
