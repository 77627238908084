import {Injectable} from '@angular/core';
import {forkJoin} from 'rxjs';
import moment from 'moment';

import {ProgramCompareStore} from './program-compare.store';
import {sortByFullName} from '@shared/utils/sorting';
import {ITechProcessData, UnitApiRepository} from '@shared/repositories/unit-api.repository';
import {IProgram} from '../../../models/interfaces';

@Injectable({providedIn: 'root'})
export class ProgramCompareService {
	constructor(
		private _programStore: ProgramCompareStore,
		private _apiService: UnitApiRepository,
	) {
	}

	public setUnit(unitId, unitStoreName): void {
		this._programStore.update({[unitStoreName]: unitId});
	}

	public fetchTechprocesses(unitId, date, unitStoreName): void {
		const param = {
			unitIds: [unitId],
			startDate: moment.utc(moment(date).startOf('day')).utc().format(),
			endDate: moment.utc(moment(date)).endOf('day').utc().format(),
		};

		forkJoin(
			this._apiService.getArchiveTechprocesses(param),
			this._apiService.getCurrentTechProcess(unitId),
		).subscribe((response: ITechProcessData[][]) => {
			const result = this.setFullName([...response[0], ...response[1]]);
			this._programStore.update({[unitStoreName + 'Techprocesses']: result});
		});
	}

	public fetchPrograms(guid, unitStoreName): void {
		this._apiService.getProgramsByTechProcessGuid(
			guid,
		).subscribe((response: IProgram[]) => {
			const result = this.setFullName(response);
			this._programStore.update({[unitStoreName + 'Programs']: result});
		});
	}

	public setProgram(programId, unitStoreName): void {
		this._programStore.update({[unitStoreName + 'SelectedProgram']: programId});
	}

	public resetState(unitStoreName): void {
		const initialState = {
			[unitStoreName]: null,
			[unitStoreName + 'Techprocesses']: null,
			[unitStoreName + 'Programs']: null,
			[unitStoreName + 'SelectedProgram']: null,
		};

		this._programStore.update(state => ({...state, ...initialState}));
	}

	public resetTechprocesses(unitStoreName): void {
		this._programStore.update({[unitStoreName + 'Techprocesses']: null});
		this.resetPrograms(unitStoreName);
	}

	public resetPrograms(unitStoreName): void {
		this._programStore.update({[unitStoreName + 'Programs']: null});
		this._programStore.update({[unitStoreName + 'SelectedProgram']: null});
	}

	private setFullName(array) {
		return array.map(item => {
			item.fullName = moment(item.start).format('HH:mm:ss') + ' | ' + item.name;
			return item;
		}).sort(sortByFullName);
	}

	public setPanelVisibility(showPanel): void {
		this._programStore.update({showProgramPanel: showPanel});
	}
}
