import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IPartialInterval} from '@shared/ui-components/n-inverval/models/interval.types';
import {IUnit} from '@shared/models/unit';

@Component({
	selector: 'app-statistic-units-list',
	templateUrl: './statistic-units-list.component.html',
	styleUrls: ['./statistic-units-list.component.scss'],
})
export class StatisticUnitsListComponent {
	@Input() showUnitsNames;
	@Input() selectedUnits;
	@Input() dynamicData;
	@Input() staticData;
	@Input() coefficientsDataStatistic;
	@Input() interval: IPartialInterval;
	@Input() dynamicRepairData;
	@Input() idlesData;
	@Input() isIdleInChart;

	@Output() changeUnits = new EventEmitter<IUnit>();
	@Output() onChart = new EventEmitter<[any, number]>();
	@Output() onChartSync = new EventEmitter<any>();
	@Output() unitClick = new EventEmitter<void>();
	@Output() redirect = new EventEmitter<any>();

	isEmpty(str: string) {
		return str !== '' && str !== null && str !== undefined;
	}
}
