import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { IAlarms } from '@shared/models/alarms';
import { IPermissions } from '@store/types';
import { combineLatest, forkJoin, lastValueFrom, Observable } from 'rxjs';
import { ApiRepository } from '@shared/repositories/api.repository';
import { UnitsTemplatesRepository } from '@shared/repositories/units-templates.repository';
import { map, tap } from 'rxjs/operators';

export interface IAccessState {
	alarms: IAlarms;
	permissions: IPermissions;
}

const DefaultAccessState: IAccessState = {
	alarms: { levels: [] },
	permissions: null,
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'accessStore' })
export class AccessStore extends Store<IAccessState> {
	constructor(
		private _apiRepository: ApiRepository,
	) {
		super(DefaultAccessState);
	}

	init(): Observable<IAccessState> {
		const alarmsLevels$ = this._apiRepository.getAlarmLevels();
		const permission$ = this._apiRepository.getPermission();

		return forkJoin({
			alarmsLevels: alarmsLevels$,
			permission: permission$,
		})
			.pipe(
				map((res) => {
					const data =  {
						alarms: {
							levels: res.alarmsLevels,
						},
						permissions: res.permission,
					}

					this.update(data);

					return data;
				})
			)
	}
}
