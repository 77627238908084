import {Injectable} from '@angular/core';
import {TrendsTemplateModalService} from './trends-template-modal.service';
import {Observable} from 'rxjs';
import {IUnitDeviceParameter} from '@shared/services/unit.service';
import {FormControl, Validators} from '@angular/forms';
import {TrendsTemplateRepository} from '@shared/repositories/trends-template.repository';
import {
	ITrendsTemplate,
	ITrendsTemplateCreate
} from '../../../../modules/monitoring/modules/unit/components/unit-tabs-area/components/trends/components/trends-template/models/trends-template.models';

@Injectable({providedIn: 'root'})
export class TrendsTemplateModalCreateService extends TrendsTemplateModalService {
	title: string = 'Создание';
	btnSaveTitle: string = 'Создать';
	btnSaveAndApply: string = 'Создать и применить';
	formControl: FormControl;
	isDefault: boolean;

	charts: IUnitDeviceParameter[];
	selectedCharts: IUnitDeviceParameter[][];
	selectedChartsMap: Map<number, IUnitDeviceParameter>;

	constructor(private _trendsTemplateService: TrendsTemplateRepository) {
		super();
	}

	init(charts: IUnitDeviceParameter[], template: ITrendsTemplate): void {
		this.charts = charts;
		this.selectedCharts = [[]];
		this.selectedChartsMap = new Map();
		this.isDefault = false;
		this.formControl = new FormControl('', [Validators.required]);
	}

	save(unitId: number, userId: number): Observable<ITrendsTemplate> {
		const data: ITrendsTemplateCreate = this.preparationData(unitId, userId);

		return this._trendsTemplateService.createTemplate(data);
	}
}
