import {Component, Inject, OnInit} from '@angular/core';
import {IUnitWebsocket, UnitService} from '@shared/services/unit.service';
import {filter, first} from 'rxjs/operators';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {NavigationEnd, Router} from '@angular/router';
import {IMapModalData} from '@shared/modals/map/models/map-modal.types';
import {DIALOG_DATA, ModalRef} from '@shared/services/modals.service';

@UntilDestroy()
@Component({
	selector: 'app-map-modal',
	templateUrl: 'map-modal.component.html',
	styleUrls: ['map-modal.component.scss']
})
export class MapModalComponent implements OnInit {
	constructor(
		@Inject(DIALOG_DATA) public data: IMapModalData,
		private _dialogRef: ModalRef<MapModalComponent>,
		private _unitService: UnitService,
		private _loaderService: NgxUiLoaderService,
		private _router: Router,
	) {
	}

	public ngOnInit(): void {
		if (!this.data.parentLocationId) {
			this._loaderService.startLoader('loaderMapModal');
			this._unitService.getUnit(this.data.activeUnitId)
				.pipe(first())
				.subscribe((unit: IUnitWebsocket) => {
					this.data.parentLocationId = unit.parentLocationId;
					this._loaderService.stopLoader('loaderMapModal');

					this.data.parentLocationId;
				});
		}
		this.initListeners();
	}

	private initListeners(): void {
		this._router.events
			.pipe(
				filter(event => event instanceof NavigationEnd),
				untilDestroyed(this)
			).subscribe(() => {
			this.closeModal();
		});
	}

	public closeModal(): void {
		this._dialogRef.close();
	}
}
