import {NgModule} from '@angular/core';
import {TooltipComponent} from '@shared/directives/tooltip/component/tooltip.component';
import {TooltipDirective} from '@shared/directives/tooltip/tooltip.directive';


@NgModule({
	imports: [],
	declarations: [TooltipComponent, TooltipDirective],
	exports: [TooltipComponent, TooltipDirective],
})
export class TooltipModule {}
