<div class="modal">
	<div class="modal__header">
		<div class="title">Данные с оборудования</div>

		<app-button
			svgIcon="close"
			class="btn-close g-button-secondary-empty"
			(click)="closeModal()"
		></app-button>
	</div>

	<div
		class="modal__content g-al-items-center"
	>
		<app-unit-information></app-unit-information>

		<ngx-ui-loader loaderId="loaderUnitInfoModal"></ngx-ui-loader>
	</div>

	<div class="modal__basement">
		<app-button
			class="g-button-secondary-square ml-8"
			(click)="closeModal()"
		>
			Закрыть
		</app-button>
	</div>
</div>
