import {Injectable} from '@angular/core';
import {BaseRepository} from './base-repository';
import {Observable} from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class InfoRepository extends BaseRepository {
	public saveOrderNumber(unitId: number, orderNumber: string): Observable<Object> {
		const url = `${this._config.javaApiUrl}/core/unit/${unitId}/index/${orderNumber.length === 0 ? 'null' : orderNumber}`;
		return this._http.put(url, null);
	}
}
