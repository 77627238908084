import {UntilDestroy} from '@ngneat/until-destroy';
import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {UnitsSelectorComponent} from '@shared/components/units-selector/units-selector.component';
import {MatIconModule} from '@angular/material/icon';
import {UnitsSelectorModule} from '@shared/components/units-selector/units-selector.module';
import {IUnitsSelectorModalComponentData} from '@shared/modals/units-selector/models/units-selector-modal.types';
import {DIALOG_DATA, ModalRef} from '@shared/services/modals.service';
import {ButtonComponent} from '@shared/ui-components/button/button.component';

@UntilDestroy()
@Component({
	templateUrl: 'units-selector-modal.component.html',
	styleUrls: ['units-selector-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		MatIconModule,
		UnitsSelectorModule,
		ButtonComponent
	]
})
export class UnitsSelectorModalComponent {
	constructor(
		@Inject(DIALOG_DATA) public data: IUnitsSelectorModalComponentData,
		public dialogRef: ModalRef<UnitsSelectorComponent>,
	) {
	}
}
