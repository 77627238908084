import {Injectable} from '@angular/core';
import {sleep} from '@shared/utils';

@Injectable({
	providedIn: 'root'
})
export class UnitHelpersService {

	public getFormattedParams(rawParams: any): any {
		return rawParams
			.map(param => ({
				parameter: param.name,
				shortName: param.shortName,
				value: param.actualValue,
				id: param.deviceParameterId,
				showIcon: typeof this.getValue(param.actualValue) !== 'string' ||
					this.isNullParam(param.actualValue),
				unitMeasure: null// param.unitMeasure
			}))
			.filter(item => item.parameter !== 'Неизвестный параметр')
			.sort(this.sortByParamName);
	}

	private getValue(data: any): any {
		if (!data || !data[0]) {
			return '---';
		}
		if (data[0].doubleValue !== null) {
			return Math.ceil(data[0].doubleValue * 1000) / 1000;
		}
		if (data[0].stringValue !== null) {
			return data[0].stringValue;
		}
		if (data[0].booleanValue !== null) {
			return data[0].booleanValue;
		}

		return '---';
	}

	private isNullParam(param: any): boolean {
		if (!param) {
			return null;
		}

		return param[0] && param[0].doubleValue === null &&
			param[0].stringValue === null &&
			param[0].booleanValue === null;
	}

	private sortByParamName(a, b): 1 | -1 | 0 {
		if (a.parameter > b.parameter) {
			return 1;
		}
		if (a.parameter < b.parameter) {
			return -1;
		}

		return 0;
	}

	// printTable<>(nameHeaders: string, content)

	public async printCharts(divName): Promise<void> {
		const styles = `
			<style>
			  body{ color: #000; margin: 0;}
			  text { fill: #000;  }
			  line { stroke: grey; }
			  ngx-ui-loader, .tooltip { display: none; }
			  mat-icon { display: none; }
			  button { display: none; }
			  input { display: none; }
			  .left, .wrap{
				display: inline-block;
				min-height: 450px;
				overflow: hidden;
			  }
			  .left{ width: 65px; }
			  .row{
				display: flex;
				flex-wrap: nowrap;
			  }
			  .d-none{ display: block; }
			  .interval_wrapper { text-align: left; font-size: 22px; padding-bottom: 20px;}
			</style>
		`;

		const contents = this.getHtmlContents(divName, styles);
		const frame = document.createElement('iframe');

		frame.name = 'frame';
		frame.style.position = 'absolute';
		frame.style.top = '-1000000px';

		document.body.appendChild(frame);

		const frameDoc = frame.contentWindow;

		frameDoc.document.open();
		frameDoc.document.write('<html><head><title>OWL Scada|Печать</title>');
		frameDoc.document.write('</head><body>');
		frameDoc.document.write(contents);
		frameDoc.document.write('</body></html>');
		frameDoc.document.close();

		await sleep(500);

		window.frames['frame'].focus();
		window.frames['frame'].print();

		document.body.removeChild(frame);
	}

	// Дополнительный метод для печать canvas
	private getHtmlContents(divName, styles): string {
		const printContents = document.createElement('div');

		printContents.innerHTML = document.getElementById(divName).innerHTML + styles;

		const innards = printContents.getElementsByTagName('input');

		for (let i = 0; i < innards.length; i++) {
			innards[i].defaultValue = innards[i].value;
		}

		const canvas = Array.from(document.getElementsByTagName('canvas'));
		const canvasClone = Array.from(printContents.getElementsByTagName('canvas'));

		for (let i = 0; i < canvas.length; i++) {
			const e = canvas[i];
			const eClone = canvasClone[i];
			const img = document.createElement('img');

			img.className = e.className;
			img.style.cssText = e.getAttribute('style');
			img.src = e.toDataURL();

			if (eClone) {
				eClone.parentNode.replaceChild(img, eClone);
			}
		}

		return printContents.innerHTML;
	}
}
