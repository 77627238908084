import {ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit} from '@angular/core';
import {Printer} from '@shared/utils';
import {IQRCode} from '@shared/modals/qr-code-generator/models/qr-code-generator.types';
import {DIALOG_DATA, ModalRef} from '@shared/services/modals.service';

declare var DATAMatrix: any;

@Component({
	selector: 'app-qr-code-generator',
	templateUrl: 'qr-code-generator.component.html',
	styleUrls: ['qr-code-generator.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QrCodeGeneratorComponent implements OnInit {
	private _JsBarcode: any;
	private _barHeight = 80;
	private _barWidth = 2;

	public isTerminal: boolean = false;

	constructor(
		@Inject(DIALOG_DATA) public data: IQRCode,
		private _dialogRef: ModalRef<QrCodeGeneratorComponent>,
		private _elementRef: ElementRef,
	) {
	}

	public ngOnInit(): void {
		this.isTerminal = !!localStorage.getItem('TerminalIpList');

		this._JsBarcode = require('jsbarcode');

		setTimeout(() => {
			if (this.data.barcode) {
				this.buildBarCode();
			}
			if (this.data.qrCode) {
				this.buildDataMatrix();
			}
		});
	}

	public closeModal(res: boolean = false): void {
		this._dialogRef.close();
	}

	private buildBarCode(): void {
		if (this._barWidth >= 15 || this._barWidth <= 0) {
			this._barWidth = 1;
		}

		const width = (this._barWidth - 1) * 112 + 168;

		if (width >= this._elementRef.nativeElement.offsetWidth) {
			--this._barWidth;
			return;
		}

		this._JsBarcode('#barcode128', this.data.barcode, {
			width: this._barWidth,
			height: this._barHeight,
			fontSize: 0,
			margin: 25,
		});
	}

	private buildDataMatrix(): void {
		const svgNode = DATAMatrix({
			msg: this.data.qrCode,
			pal: ['#000000', '#ffffff']
		});

		const clonedSvgElement = svgNode.cloneNode(true);
		const outerHTML = clonedSvgElement.outerHTML;
		const blob = new Blob([outerHTML], {type: 'image/svg+xml;charset=utf-8'});
		const blobURL = URL.createObjectURL(blob);

		document.getElementById('data-matrix-img').setAttribute('src', blobURL);
	}

	public async printQrCode(divClass: string): Promise<void> {
		Printer.printElement(divClass).then();
	}
}
