import {NgModule} from '@angular/core';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {CommonModule} from '@angular/common';
import {MatDatepickerModule} from '@angular/material/datepicker';

import {DatepickerComponent} from '@shared/ui-components/datepicker/datepicker.component';
import {MatNativeDateModule} from '@angular/material/core';

@NgModule({
	declarations: [DatepickerComponent],
	imports: [
		MatInputModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatIconModule,
		CommonModule
	],
	exports: [DatepickerComponent],
})
export class DatepickerModule {
}
