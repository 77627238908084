export const iconNames: { name: string; path: string }[] = [
	{ name: 'empty', path: '../../assets/icons/empty.svg' },
	{ name: 'sort', path: '../../assets/icons/sort.svg' },
	{ name: 'cancel-future', path: '../../assets/icons/cancel-future.svg' },
	{ name: 'warning', path: '../../assets/icons/warning.svg' },
	{ name: 'kk-logo', path: '../../assets/icons/kk-logo.svg' },
	{ name: 'imz-logo', path: '../../assets/icons/imz-logo.svg' },
	{ name: 'barcode-scanner', path: '../../assets/icons/barcode-scanner.svg' },
	{ name: 'undo', path: '../../assets/icons/undo.svg' },
	{ name: 'pdf', path: '../../assets/icons/pdf-icon.png' },
	{ name: 'excel', path: '../../assets/icons/excel-icon.png' },
	{ name: 'triangle', path: '../../assets/icons/triangle.svg' },
	{ name: 'not-found', path: '../../assets/icons/not-found.svg' },
	{ name: 'chart', path: '../../assets/icons/chart.svg' },
	{ name: 'view-list', path: '../../assets/icons/view-list.svg' },
	{ name: 'list-task', path: '../../assets/icons/list-task.svg' },
	{ name: 'like', path: '../../assets/icons/like.svg' },
	{ name: 'tasks', path: '../../assets/icons/tasks.svg' },
	{ name: 'task', path: '../../assets/icons/task.svg' },
	{ name: 'statistics', path: '../../assets/icons/statistics.svg' },
	{ name: 'fullscreen-exit', path: '../../assets/icons/fullscreen-exit.svg' },
	{ name: 'fullscreen', path: '../../assets/icons/fullscreen.svg' },
	{ name: 'move', path: '../../assets/icons/ic-move.svg' },
	{ name: 'filter', path: '../../assets/icons/ic-filter.svg' },
	{ name: 'search', path: '../../assets/icons/ic-search.svg' },
	{
		name: 'advanced-search',
		path: '../../assets/icons/ic-advanced-search.svg',
	},
	{ name: 'close', path: '../../assets/icons/ic-close.svg' },
	{ name: 'menu', path: '../../assets/icons/ic-menu.svg' },
	{ name: 'add', path: '../../assets/icons/ic-add.svg' },
	{ name: 'delete', path: '../../assets/icons/ic-delete.svg' },
	{ name: 'chevron-right', path: '../../assets/icons/ic-chevron-right.svg' },
	{ name: 'edit', path: '../../assets/icons/ic-edit.svg' },
	{
		name: 'question-answer',
		path: '../../assets/icons/ic-question-answer.svg',
	},
	{ name: 'done', path: '../../assets/icons/ic-done.svg' },
	{ name: 'check-circle', path: '../../assets/icons/check-circle.svg' },
	{
		name: 'delete-forever',
		path: '../../assets/icons/ic-delete-forever.svg',
	},
	{ name: 'arrow-back2', path: '../../assets/icons/ic-arrow-back.svg' },
	{ name: 'highlight-off', path: '../../assets/icons/ic-highlight-off.svg' },
	{ name: 'date-range', path: '../../assets/icons/ic-date-range.svg' },
	{
		name: 'add-circle-outline',
		path: '../../assets/icons/ic-add-circle-outline.svg',
	},
	{ name: 'save-alt', path: '../../assets/icons/ic-save-alt.svg' },
	{
		name: 'not-interested',
		path: '../../assets/icons/ic-not-interested.svg',
	},
	{
		name: 'settings-overscan',
		path: '../../assets/icons/ic-settings-overscan.svg',
	},
	{ name: 'zoom-out-map', path: '../../assets/icons/ic-zoom-out-map.svg' },
	{ name: 'access-time', path: '../../assets/icons/ic-access-time.svg' },
	{ name: 'edit-location', path: '../../assets/icons/ic-edit-location.svg' },
	{ name: 'history', path: '../../assets/icons/ic-history.svg' },
	{ name: 'attachment', path: '../../assets/icons/ic-attachment.svg' },
	{ name: 'file-jpeg', path: '../../assets/icons/ic-file-jpeg.svg' },
	{ name: 'file-png', path: '../../assets/icons/ic-file-png.svg' },
	{ name: 'file-docx', path: '../../assets/icons/ic-file-docx.svg' },
	{ name: 'file', path: '../../assets/icons/ic-file.svg' },
	{ name: 'visibility', path: '../../assets/icons/ic-visibility.svg' },
	{
		name: 'visibility-off',
		path: '../../assets/icons/ic-visibility-off.svg',
	},
	{ name: 'owl-logo', path: '../../assets/img/owl-logo.svg' },
	{ name: 'sun', path: '../../assets/icons/ic-sun.svg' },
	{ name: 'moon', path: '../../assets/icons/ic-moon.svg' },
	{ name: 'key', path: '../../assets/icons/ic-key.svg' },
	{ name: 'help', path: '../../assets/icons/ic-help.svg' },
	{ name: 'analytics', path: '../../assets/icons/graph-up.svg' },
	{ name: 'lock', path: '../../assets/icons/lock.svg' },
	{ name: 'unlock', path: '../../assets/icons/unlock.svg' },
	{ name: 'eye', path: '../../assets/icons/eye.svg' },
	{ name: 'eye-slash', path: '../../assets/icons/eye-slash.svg' },
	{ name: 'planning', path: '../../assets/icons/calendar-week.svg' },
	{
		name: 'nomenclature',
		path: '../../assets/icons/layout-text-sidebar-reverse.svg',
	},
	{ name: 'cloud-download', path: '../../assets/icons/cloud-download.svg' },
	{ name: 'play', path: '../../assets/icons/play.svg' },
	{ name: 'pause', path: '../../assets/icons/pause-fill.svg' },
	{ name: 'options', path: '../../assets/icons/gear.svg' },
	{ name: 'settings', path: '../../assets/icons/gear.svg' },
	{ name: 'checkmark', path: '../../assets/icons/check-lg.svg' },
	{ name: 'checkmark-done', path: '../../assets/icons/check-square.svg' },
	{ name: 'build', path: '../../assets/icons/building.svg' },
	{ name: 'list-outline', path: '../../assets/icons/list.svg' },
	{ name: 'menu', path: '../../assets/icons/list.svg' },
	{ name: 'version', path: '../../assets/icons/version.svg' },
	{
		name: 'checkmark-outline',
		path: '../../assets/icons/file-earmark-plus.svg',
	},
	{ name: 'create', path: '../../assets/icons/file-earmark-plus.svg' },
	{ name: 'trash-outline', path: '../../assets/icons/trash.svg' },
	{ name: 'trash', path: '../../assets/icons/trash.svg' },
	{ name: 'arrow-down', path: '../../assets/icons/arrow-down.svg' },
	{ name: 'arrow-up', path: '../../assets/icons/arrow-up.svg' },
	{ name: 'star-outline', path: '../../assets/icons/star.svg' },
	{ name: 'star', path: '../../assets/icons/star-fill.svg' },
	{ name: 'business', path: '../../assets/icons/building.svg' },
	{
		name: 'desktop-outline',
		path: '../../assets/icons/pc-display-horizontal.svg',
	},
	{
		name: 'document-outline',
		path: '../../assets/icons/document-outline.svg',
	},
	{ name: 'stats-chart', path: '../../assets/icons/bar-chart-line.svg' },
	{
		name: 'bar-chart-outline',
		path: '../../assets/icons/bar-chart-line.svg',
	},
	{ name: 'construct', path: '../../assets/icons/tools.svg' },
	{ name: 'time', path: '../../assets/icons/clock.svg' },
	{ name: 'speedometer-outline', path: '../../assets/icons/clock.svg' },
	{ name: 'flash', path: '../../assets/icons/lightning-fill.svg' },
	{ name: 'water', path: '../../assets/icons/droplet.svg' },
	{ name: 'flame', path: '../../assets/icons/water.svg' },
	{ name: 'share-social', path: '../../assets/icons/share.svg' },
	{ name: 'call', path: '../../assets/icons/telephone.svg' },
	{ name: 'information-circle', path: '../../assets/icons/info-circle.svg' },
	{ name: 'scan', path: '../../assets/icons/qr-code-scan.svg' },
	{ name: 'person', path: '../../assets/icons/person.svg' },
	{ name: 'notifications', path: '../../assets/icons/bell.svg' },
	{ name: 'lock-closed', path: '../../assets/icons/gear.svg' },
	{
		name: 'log-out-outline',
		path: '../../assets/icons/box-arrow-in-left.svg',
	},
	{ name: 'cog', path: '../../assets/icons/gear-wide-connected.svg' },
	{ name: 'map', path: '../../assets/icons/map.svg' },
	{ name: 'location', path: '../../assets/icons/map.svg' },
	{ name: 'qr-code', path: '../../assets/icons/qr-code.svg' },
	{ name: 'arrow-forward', path: '../../assets/icons/chevron-right.svg' },
	{ name: 'caret-forward', path: '../../assets/icons/chevron-right.svg' },
	{ name: 'chevron-forward', path: '../../assets/icons/chevron-right.svg' },
	{
		name: 'chevron-forward-outline',
		path: '../../assets/icons/chevron-right.svg',
	},
	{ name: 'caret-down', path: '../../assets/icons/chevron-down.svg' },
	{ name: 'caret-down-outline', path: '../../assets/icons/chevron-down.svg' },
	{
		name: 'chevron-down-circle-outline',
		path: '../../assets/icons/chevron-down.svg',
	},
	{ name: 'caret-up-outline', path: '../../assets/icons/chevron-up.svg' },
	{
		name: 'chevron-up-circle-outline',
		path: '../../assets/icons/chevron-up.svg',
	},
	{ name: 'caret-up', path: '../../assets/icons/chevron-up.svg' },
	{
		name: 'folder-open-outline',
		path: '../../assets/icons/folder2-open.svg',
	},
	{ name: 'code-working', path: '../../assets/icons/scissors.svg' },
	{ name: 'folder', path: '../../assets/icons/folder.svg' },
	{ name: 'arrow-back', path: '../../assets/icons/arrow-90deg-left.svg' },
	{ name: 'home', path: '../../assets/icons/home.svg' },
	{ name: 'arrow-redo', path: '../../assets/icons/arrow-up.svg' },
	{ name: 'arrow-left', path: '../../assets/icons/arrow-left.svg' },
	{ name: 'arrow-right', path: '../../assets/icons/arrow-right.svg' },
	{ name: 'documents-outline', path: '../../assets/icons/files.svg' },
	{ name: 'copy', path: '../../assets/icons/files.svg' },
	{ name: 'link-outline', path: '../../assets/icons/file-earmark-plus.svg' },
	{ name: 'add-outline', path: '../../assets/icons/file-earmark-plus.svg' },
	{ name: 'close-outline', path: '../../assets/icons/x-circle.svg' },
	{ name: 'expand', path: '../../assets/icons/arrows-angle-expand.svg' },
	{ name: 'refresh', path: '../../assets/icons/arrow-clockwise.svg' },
	{ name: 'refresh-outline', path: '../../assets/icons/arrow-clockwise.svg' },
	{ name: 'checkbox-outline', path: '../../assets/icons/check-square.svg' },
	{ name: 'square-outline', path: '../../assets/icons/square.svg' },
	{ name: 'shuffle-outline', path: '../../assets/icons/shuffle.svg' },
	{ name: 'git-compare', path: '../../assets/icons/shuffle.svg' },
	{ name: 'link', path: '../../assets/icons/link.svg' },
	{ name: 'add', path: '../../assets/icons/plus-lg.svg' },
	{ name: 'remove', path: '../../assets/icons/dash-lg.svg' },
	{ name: 'tv-outline', path: '../../assets/icons/tv.svg' },
	{ name: 'pie-chart', path: '../../assets/icons/pie-chart.svg' },
	{ name: 'search', path: '../../assets/icons/search.svg' },
	{ name: 'pulse-outline', path: '../../assets/icons/activity.svg' },
	{ name: 'podium-outline', path: '../../assets/icons/bar-chart-steps.svg' },
	{ name: 'calculator-outline', path: '../../assets/icons/calculator.svg' },
	{ name: 'keypad-outline', path: '../../assets/icons/percent.svg' },
	{ name: 'id-card', path: '../../assets/icons/person-badge.svg' },
	{ name: 'print-outline', path: '../../assets/icons/printer.svg' },
	{ name: 'save', path: '../../assets/icons/hdd.svg' },
	{ name: 'save-classic', path: '../../assets/icons/save-classic.svg' },
	{ name: 'image', path: '../../assets/icons/card-image.svg' },
	{ name: 'file-tray-stacked', path: '../../assets/icons/inboxes-fill.svg' },
	{ name: 'videocam', path: '../../assets/icons/camera-video.svg' },
	{ name: 'cone-striped', path: '../../assets/icons/cone-striped.svg' },
	{ name: 'bi-pencil', path: '../../assets/icons/pencil.svg' },
	{ name: 'filter', path: '../../assets/icons/funnel.svg' },
	{ name: 'bi-key', path: '../../assets/icons/key.svg' },
	{ name: 'alphabeticalAZ', path: '../../assets/icons/alphabeticalAZ.svg' },
	{ name: 'alphabeticalZA', path: '../../assets/icons/alphabeticalZA.svg' },
	{ name: 'play-arrow', path: '../../assets/icons/play-arrow.svg' },
	{ name: 'important', path: '../../assets/icons/important.svg' },
];
