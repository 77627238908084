<div class="modal">
	<div class="modal__header">
		<div class="title">Положение на карте</div>

		<app-button
			class="g-button-secondary-empty btn-close"
			svgIcon="close"
			(click)="closeModal()"
		></app-button>
	</div>

	<div class="modal__content">
		<app-map
			*ngIf="!!data.parentLocationId"
			class="g-full-width g-full-height"
			[locationId]="data.parentLocationId"
			[activeUnitId]="data.activeUnitId"
			[mapType]="data.mapType"
			(hideSidebarEvent)="closeModal()"
		>
		</app-map>

		<ngx-ui-loader [loaderId]="'loaderMapModal'"></ngx-ui-loader>
	</div>
</div>

