import {Injectable} from '@angular/core';
import {fromEvent, merge, Observable} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

export enum ECommonEvents {
	USER_ACTIVITY,
}

@Injectable({ providedIn: 'root' })
export class CommonEventsService {
	private readonly _events: Record<ECommonEvents, Observable<any>>;

	constructor() {
		this._events = {
			[ECommonEvents.USER_ACTIVITY]: getUserActivity(),
		};
	}

	public get<T>(key: ECommonEvents): Observable<T> {
		return this._events[key];
	}

	public getUserInactivity(ms: number): Observable<Event> {
		return this._events[ECommonEvents.USER_ACTIVITY].pipe(
			debounceTime(ms),
		);
	}
}

function getUserActivity(): Observable<Event> {
	return merge(
		fromEvent(document, 'click'),
		fromEvent(document, 'mousemove'),
		fromEvent(document, 'keydown')
	);
}
