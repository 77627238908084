import { NgModule } from '@angular/core';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { CommonModule } from '@angular/common';
import { NgxSelectModule } from 'ngx-select-ex';
import { FormsModule } from '@angular/forms';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';

import { ToolItemComponent } from '@shared/components/tool-item/tool-item.component';
import { QrGeneratorModule } from '@shared/components/qr-generator/qr-generator.module';
import { ButtonComponent } from '@shared/ui-components/button/button.component';
import { StatisticUnitsContainerModule } from '@shared/components/statistic-units-container/statistic-units-container.module';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
	declarations: [ToolItemComponent],
	imports: [
		TabsModule,
		CommonModule,
		NgxSelectModule,
		FormsModule,
		ProgressbarModule,
		StatisticUnitsContainerModule,
		QrGeneratorModule,
		ButtonComponent,
		MatTabsModule,
	],
	exports: [ToolItemComponent],
})
export class ToolItemModule {
}
