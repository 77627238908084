<div class="modal">
	<div class="modal__header">
		<div class="title">{{ !!data.title ? data.title : 'Подтвердите действие' }}</div>
		<app-button
			svgIcon="close"
			class="g-button-secondary-empty btn-close"
			(click)="cancel()"
		></app-button>
	</div>
	<div class="modal__content">
		<div [innerHTML]="data.content"></div>
	</div>
	<div class="modal__basement">
		<app-button
			class="g-button-secondary-square"
			(click)="cancel()"
		>
			{{ !!data.btnCansel ? data.btnCansel : 'Нет' }}
		</app-button>
		<app-button
			class="g-button-primary-square ml-8"
			(click)="confirm()"
		>
			{{ !!data.btnOk ? data.btnOk : 'Да' }}
		</app-button>
	</div>
</div>
