import {IUnitGroup} from '@store/types';
import {Injectable} from '@angular/core';
import {ParameterFilterService} from '@shared/components/filters/services/parameter-filter.service';
import {IUnitFull} from '../../../../../../modules/monitoring/modules/unit/models/unit';
import { UnitsSelector } from '@store/units-store/units.selector';

@Injectable({
	providedIn: 'root'
})
export class GroupUnitFilterService extends ParameterFilterService {
	constructor(
		private _unitsSelector: UnitsSelector,
	) {
		super();
	}

	set(parameter: IUnitGroup[]): void {
		const selectedGroups: Map<number, void> = new Map();
		this._selectedUnit.clear();

		parameter.forEach((group) => {
			if (group.isChecked) {
				selectedGroups.set(group.id);
			}
		});

		if (selectedGroups.size === 0) {
			this._selectedUnit = new Map(this._unitsSelector.getUnitsMap());
		} else {
			this._unitsSelector.getUnitsMap().forEach((unit: IUnitFull) => {
				if (selectedGroups.has(unit.unitType.unitGroupId)) {
					this._selectedUnit.set(unit.id, unit);
				}
			});
		}

		this._selectedUnitChange.next();
	}
}
