<div class="modal">
	<div class="modal__header">
		<div class="title">
			Создание временного пароля
		</div>

		<app-button
			svgIcon="close"
			class="g-button-secondary-empty btn-close"
			(click)="closeModal()"
		></app-button>
	</div>

	<form class="modal__content g-scroll"
		  [formGroup]="formGroup"
	>
		<mat-form-field appearance="outline">
			<mat-label>Почта</mat-label>
			<input
				matInput
				formControlName="email"
				type="text"
				placeholder="i.i.ivanov@kalashnikovconcern.ru"
			>
		</mat-form-field>

		<mat-form-field appearance="outline">
			<mat-label>Мессенджер</mat-label>
			<input
				matInput
				formControlName="messengerName"
				type="text"
			>
		</mat-form-field>

		<mat-form-field appearance="outline" class="g-full-width">
			<mat-label>Временный пароль</mat-label>
			<input
				matInput
				type="text"
				formControlName="password"
			>
		</mat-form-field>
	</form>

	<div class="modal__basement">
		<app-button
			class="g-button-primary-square"
			[matTooltip]="'Отправить временный пароль'"
			[disabled]="!formGroup.dirty || formGroup.invalid"
			(click)="sendTempPassword()"
		>
			Отправить
		</app-button>
	</div>
</div>
