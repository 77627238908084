import {Component, Inject} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {IdleEditorModule} from '@shared/components/idle-editor/idle-editor.module';
import {IPostIdleRedact} from '@shared/repositories/idles.repository';
import {IIdleEditorModalData} from '@shared/modals/idle-editor-modal/models/idle-editor-modal.types';
import {DIALOG_DATA, ModalRef} from '@shared/services/modals.service';
import {ButtonComponent} from '@shared/ui-components/button/button.component';

@Component({
	selector: 'app-idle-editor-modal',
	templateUrl: './idle-editor-modal.component.html',
	styleUrls: ['./idle-editor-modal.component.scss'],
	standalone: true,
	imports: [
		MatIconModule,
		IdleEditorModule,
		ButtonComponent
	]
})
export class IdleEditorModalComponent {
	constructor(
		@Inject(DIALOG_DATA) public data: IIdleEditorModalData,
		private _dialogRef: ModalRef<IdleEditorModalComponent>,
	) {
	}

	public closeModal(): void {
		this._dialogRef.close();
	}

	public onIdleChange(value: IPostIdleRedact): void {
		if (!!this.data.onIdleChange) {
			this.data.onIdleChange(value);
		}
	}
}
