import { BehaviorSubject, Subject } from 'rxjs';
import { TreeUtils } from '../../../utils';
import { ITree } from '@shared/models/tree';

export class TreeNode implements ITree {
	public id: number;
	public name: string;
	public original: ITree;
	public children?: TreeNode[];

	public click$: Subject<ITree>;

	public isVisible?: boolean;
	public isOpen?: boolean;
	public isFound?: boolean;
	public isSomeChildrenSelected?: boolean;
	public isAllChildrenSelected?: boolean;
	public accessAllow?: boolean;

	public checked$?: BehaviorSubject<boolean>;

	constructor(originalNode: ITree) {
		this.initSubjects();
		this.initProperties(originalNode);
	}

	private initProperties(originalNode: ITree): void {
		this.original = originalNode;

		for (const key in originalNode) {
			this[key] = originalNode[key];
		}

		this.isVisible = true;
		this.isOpen = false;
		this.isFound = false;
		this.isSomeChildrenSelected = false;
		this.isAllChildrenSelected = false;
	}

	private initSubjects(): void {
		this.click$ = new Subject();
		this.checked$ = new BehaviorSubject<boolean>(false);
	}

	set checked(value: boolean) {
		if (!this.original) {
			return;
		}

		if (this.checked$.getValue() === value) {
			return;
		}

		this.original['checked'] = value;
		this.original['isChecked'] = value;

		this.checked$.next(value);
	}

	get checked(): boolean {
		return this.checked$.getValue();
	}

	get isChecked(): boolean {
		return this.checked$.getValue();
	}

	set isChecked(value: boolean) {
		this.checked = value;
	}

	public open(): void {
		this.isOpen = !this.isOpen;
	}

	public select(): void {
		this.checked = !this.checked;

		TreeUtils.eachDown(
			this,
			(t) => t.checked = this.checked,
		);

		this.checkCorrect();

		this.click$.next(this.original);
	}

	public openAllVisibleChildren(): void {
		if (this.isOpen) {
			TreeUtils.eachDown(this, (tree) => tree.isOpen = false);
		} else {
			TreeUtils.eachDown(this, (tree) => {
				if (tree.isAllChildrenSelected) {
					return;
				}

				tree.isOpen = tree.isSomeChildrenSelected;
			});
		}
	}

	public checkSelectedChildren(): void {
		TreeUtils.eachFromChildren(
			this,
			node => node.isSomeChildrenSelected = (node.children as TreeNode[])?.some(
				c => c.checked || c.isSomeChildrenSelected,
			),
		);
	}

	public applyFilter({ line }: { line: string }): void {
		line = line.toLowerCase();

		TreeUtils.eachFromChildren(this, (tree) => {
			if (!line?.length) {
				tree.isFound = false;
				tree.isVisible = true;
				tree.isOpen = false;

				return;
			}

			tree.isFound = tree.name.toLowerCase().includes(line);

			if (tree.isFound && tree.children?.length) {
				TreeUtils.eachDown(tree, (child) => child.isVisible = true);
			}

			// @ts-ignore
			tree.isVisible = tree.children?.some(s => s.isVisible) || tree.isFound;

			if (tree.isVisible && tree.children?.length) {
				tree.isOpen = true;
			}
		});
	}

	public applyIds(ids: number[]): boolean {
		TreeUtils.eachDown(
			this,
			(tree) => {
				tree.checked = ids.includes(tree.original.id);
			},
		);

		TreeUtils.eachFromChildren(
			this,
			tree => {
				if (tree.children?.length) {
					tree.checked = tree.children?.every(c => c.checked);
				}
			},
		);

		return this.checkCorrect();
	}

	public applyToAll(value: boolean): void {
		TreeUtils.eachDown(
			this,
			tree => tree.checked = value,
		);

		this.checkCorrect();
	}

	public sort<T extends ITree>(
		condition?: (a: T, b: T) => 1 | -1 | 0,
	): void {
		const func = condition ?? ((a, b) => a.name > b.name ? 1 : -1);

		// @ts-ignore
		TreeUtils.sort<T>(this, func);
	}

	public checkCorrect(): boolean {
		let isSelectedAll = true;

		TreeUtils.eachFromChildren(this, node => {
			if (node.children?.length) {
				node.checked = node.children?.every(c => c.checked);
				node.isSomeChildrenSelected = (node.children as TreeNode[])?.some(
					c => c.checked || c.isSomeChildrenSelected,
				);
				node.isAllChildrenSelected = (node.children as TreeNode[])?.filter(
					c => c.checked,
				).length === (node.children as TreeNode[]).length;
			}
			if (!node.checked) {
				isSelectedAll = false;
			}
		});

		return isSelectedAll;
	}

	public getSelected(): { selectedIds: number[], selectedNodes: ITree[] } {
		const selectedIds = [];
		const selectedNodes = [];

		TreeUtils.eachFromRoot(
			this,
			tree => {
				if (tree.checked || tree.isChecked) {
					selectedIds.push(tree.original.id);
					selectedNodes.push(tree.original);
				}
			},
		);

		return { selectedIds, selectedNodes };
	}
}
