import {NgModule} from '@angular/core';
import {TemplateUnitFilterComponent} from '@shared/components/template-unit-filter/template-unit-filter.component';
import {CommonModule} from '@angular/common';
import {ExpandModule} from '@shared/ui-components/expand/expand.module';
import {UnitsTemplatesComponent} from '@shared/components/template-unit-filter/components/units-templates/units-templates.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {ButtonComponent} from '@shared/ui-components/button/button.component';
import {MatInputModule} from '@angular/material/input';
import {MatTooltipModule} from '@angular/material/tooltip';
import {StopPropagationModule} from '@shared/directives/stop-propagation/stop-propagation.module';
import {PopoverModule} from '@shared/directives/popover/popover.module';
import {CheckboxComponent} from '@shared/ui-components/checkbox/checkbox.component';
import {MatRadioModule} from '@angular/material/radio';
import {SelectorLettersModule} from '@shared/pipes/selector-letters/selector-letters.module';
import {ScrollModule} from '@shared/ui-components/scroll/scroll.module';
import {SearchModule} from '@shared/ui-components/search/search.module';
import {TooltipModule} from '@shared/directives/tooltip/tooltip.module';
import {FormsModule} from '@angular/forms';
import {SelectorLettersComponent} from '@shared/ui-components/selector-letters/selector-letters.component';

@NgModule({
	declarations: [TemplateUnitFilterComponent, UnitsTemplatesComponent],
	exports: [TemplateUnitFilterComponent],
	providers: [],
	imports: [
		CommonModule,
		ExpandModule,
		MatFormFieldModule,
		ButtonComponent,
		MatInputModule,
		MatTooltipModule,
		StopPropagationModule,
		PopoverModule,
		CheckboxComponent,
		MatRadioModule,
		SelectorLettersModule,
		ScrollModule,
		SearchModule,
		TooltipModule,
		FormsModule,
		SelectorLettersComponent,
	],
})
export class TemplateUnitFilterModule {
}
