import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotifierModule} from 'angular-notifier';
import {EnlargedImageModalComponent} from '@shared/modals/enlarged-image-modal/enlarged-image-modal.component';
import {ButtonComponent} from '@shared/ui-components/button/button.component';

@NgModule({
	declarations: [EnlargedImageModalComponent],
	imports: [CommonModule, NotifierModule, ButtonComponent],
	exports: [EnlargedImageModalComponent],
})
export class EnlargedImageModalModule {
}
