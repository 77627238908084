import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { ArrayUtils } from '../../../utils';
import { ClassUnitFilterService } from '@shared/components/filters/components/class-unit-filter/services/class-unit-filter.service';
import { LocationFilterService } from '@shared/components/filters/components/location-filter/services/location-filter.service';
import { GroupUnitFilterService } from '@shared/components/filters/components/group-unit-filter/services/group-unit-filter.service';
import { debounceTime } from 'rxjs/operators';
import { IUnitFull } from '../../../../modules/monitoring/modules/unit/models/unit';

@Injectable({
	providedIn: 'root'
})
export class ParametersFilterService {
	private _activeMode: boolean = false;

	private _filteredUnitsByParameters$: BehaviorSubject<IUnitFull[]>;

	private _changeParametersFilter$: Subject<void>;

	constructor(
		private _classFilter: ClassUnitFilterService,
		private _groupFilter: GroupUnitFilterService,
		private _locationFilter: LocationFilterService,
	) {
		this.init();
	}

	init(): void {
		const data = this._useFilterByParameters();
		this._filteredUnitsByParameters$ = new BehaviorSubject<IUnitFull[]>(data);
		this._changeParametersFilter$ = new Subject<void>();

		this._initListeners();
	}

	public clear(): void {
		this._classFilter.clear();
		this._groupFilter.clear();
		this._locationFilter.clear();
	}

	private _initListeners(): void {
		const classFilter$ = this._classFilter.changeUnitsListeners();
		const groupFilter$ = this._groupFilter.changeUnitsListeners();
		const locationFilter$ = this._locationFilter.changeUnitsListeners();

		combineLatest([
			classFilter$,
			groupFilter$,
			locationFilter$,
		])
			.pipe(debounceTime(0))
			.subscribe(() => {
				this.changeFilterParameters();
			});
	}

	getFilteredUnits(): IUnitFull[] {
		return this._filteredUnitsByParameters$.getValue();
	}

	getFilteredUnitsListener(): Observable<IUnitFull[]> {
		return this._filteredUnitsByParameters$.asObservable();
	}

	changeFilterParameters(): void {
		const data = this._useFilterByParameters();
		this._filteredUnitsByParameters$.next(data);

		this._changeParametersFilter$.next();
	}

	setActiveMode(activeMode: boolean): void {
		this._activeMode = activeMode;
	}

	getActiveMode(): boolean {
		return this._activeMode;
	}

	private _useFilterByParameters(): IUnitFull[] {
		const data: IUnitFull[] = [];

		const isActive = this._activeMode ? (unit: IUnitFull) => unit.isActive : (unit: IUnitFull) => true;
		let units: Map<number, IUnitFull>;

		if (this._classFilter.getSelectedUnitMap().size < this._locationFilter.getSelectedUnitMap().size
			&& this._classFilter.getSelectedUnitMap().size < this._groupFilter.getSelectedUnitMap().size
		) {
			units = this._classFilter.getSelectedUnitMap();
		} else if (this._locationFilter.getSelectedUnitMap().size < this._classFilter.getSelectedUnitMap().size
			&& this._locationFilter.getSelectedUnitMap().size < this._groupFilter.getSelectedUnitMap().size
		) {
			units = this._locationFilter.getSelectedUnitMap();
		} else {
			units = this._groupFilter.getSelectedUnitMap();
		}

		units.forEach((unit: IUnitFull) => {
			if (
				isActive(unit) &&
				this._classFilter.isHave(unit.id) &&
				this._groupFilter.isHave(unit.id) &&
				this._locationFilter.isHave(unit.id)
			) {
				data.push(unit);
			}
		});

		return ArrayUtils.unitSortByLocation(data);
	}
}
