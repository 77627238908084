export enum EFunctionalConstantEnums {
	show_monitoring = 'show-monitoring',
	core = 'core',
	askue = 'askue',
	ftp = 'ftp',
	jira = 'jira',
	video = 'video',
	details = 'details',
	report = 'report',
	reliability = 'reliability',
	admin = 'admin',
	videomonitoring = 'videomonitoring',
	manager = 'manager',
	idles = 'idles',
	tool = 'tool',
	sd = 'sd',
	sdweb = 'sdweb',
	nomenclature = 'nomenclature',
	plan = 'plan',

	map = 'map',
	user_menu = 'user_menu',

	kim_800 = 'kim_800',

	bfg_dip = 'bfg_dip',
	bfg_dvst = 'bfg_dvst',

	mes_task = 'mes-task',
	mes_task_redact = 'mes-task-redact',
	mes_repair_task = 'mes-repair-task',
	mes_repair_task_redact = 'mes-repair-task-redact',
	mes_materials = 'mes-materials',
	mes_clients = 'mes-clients',
	mes_techprocess = 'mes-techprocess',
	mes_tool = 'mes-tool',
	mes_tool_type = 'mes-tool-type',
	mes_tool_type_char = 'mes-tool-type-char',
	mes_planning = 'mes-planning'
}
