<div class="modal g-overflow-visible">
	<div class="modal__header">
		<div class="title">Редактирование простоя</div>

		<app-button
			svgIcon="close"
			class="btn-close g-button-secondary-empty"
			(click)="closeModal()"
		></app-button>
	</div>

	<div
		class="modal__content g-overflow-visible"
		style="align-items: center"
	>
		<app-idle-editor
			[idleId]="data.idleId"
			(onIdleChange)="onIdleChange($event)"
		></app-idle-editor>
	</div>
</div>
