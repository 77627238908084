import {Component} from '@angular/core';
import {ButtonComponent} from '@shared/ui-components/button/button.component';
import {MatIconModule} from '@angular/material/icon';
import {NgForOf, NgIf} from '@angular/common';
import {QrScannerModule} from '@shared/components/qr-scanner/qr-scanner.module';
import {ToolItemModule} from '@shared/components/tool-item/tool-item.module';
import {Router} from '@angular/router';
import {NotifierService} from 'angular-notifier';
import {UnitService} from '@shared/services/unit.service';
import {MesStoreService} from '@store/mes-store/mes-store.service';
import {EView} from '@shared/modals/qr-code-scanner/models/qr-code-scanner.enums';
import {ModalRef} from '@shared/services/modals.service';

@Component({
	selector: 'app-qr-code-scanner',
	templateUrl: 'qr-code-scanner.component.html',
	styleUrls: ['qr-code-scanner.component.scss'],
	standalone: true,
	imports: [
		ButtonComponent,
		MatIconModule,
		NgForOf,
		NgIf,
		QrScannerModule,
		ToolItemModule
	]
})
export class QrCodeScannerComponent {
	public viewEnum = EView;
	public view: EView = EView.qrScaner;

	constructor(
		private _dialogRef: ModalRef<QrCodeScannerComponent>,
		private _router: Router,
		private _notifierService: NotifierService,
		private _unitService: UnitService,
		private _mesStoreService: MesStoreService,
	) {
	}

	public closeModal(): void {
		this._dialogRef.close();
	}

	public onCodeResult(str: string): void {
		const strS = str.toString();

		try {
			if (strS.includes('/')) {
				window.open(str, 'blank');
			} else if (strS.includes('unit')) {
				this._router.navigate(['/monitoring/unit/' + strS.replace('unit', '')]);
			} else if (strS.includes('repair')) {
				this._router.navigate(['/mes/task/' + strS.replace('repair', '')]);
			} else if (strS.includes('mes')) {
				this.redirectToMES(str.replace('mes', ''));
			} else if (parseInt(strS)) {
				this._router.navigate(['/tool/' + str]);
			} else {
				this._notifierService.notify('warning', 'QR распознан. Но действие не назначено. Обратитесь к разработчику.' + str);
				return;
			}

			this._dialogRef.close();
		} catch (m) {
			this._notifierService.notify('error', 'Ошибка при распозновании QR');
		}
	}

	private redirectToMES(id): void {
		this._unitService.getUnit(id);

		this._mesStoreService.setActiveUnit(this._unitService.unit);
		this._router.navigate(['/mes']);
	}
}
