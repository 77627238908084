<div class="modal">
	<div class="modal__header">
		<div class="title">Граф задачи</div>
		<app-button
			svgIcon="close"
			class="btn-close g-button-secondary-empty"
			(click)="closeDialog()"
		></app-button>
	</div>

	<div class="modal__content">
		<app-graph-view
			*ngIf="nodes && edges && update$ | async"
			[edges]="edges"
			[nodes]="nodes"
			[update]="update$"
			[layoutSettings]="layoutSettings"
			[zoomEnabled]="zoomEnabled"
			[panningEnabled]="panningEnabled"
			[autoCenter]="autoCenter"
			[currentNode]="currentNode"
		>
		</app-graph-view>
	</div>
</div>
