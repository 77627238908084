import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

export type ThemeType = 'dark-theme' | 'light-theme';

@Injectable({
	providedIn: 'root'
})
export class SwitchThemeService {
	private _theme$: BehaviorSubject<ThemeType>;

	constructor() {
		const theme = localStorage.getItem('theme');
		this._theme$ = new BehaviorSubject<ThemeType>(<ThemeType>theme);
	}

	public initTheme(): void {
		const theme = localStorage.getItem('theme');

		if (!theme || theme === 'light-theme') {
			this.setLightTheme();
		} else {
			this.setDarkTheme();
		}
	}

	public switchTheme(): void {
		if (!this.isLightTheme()) {
			this.setLightTheme();
		} else {
			this.setDarkTheme();
		}
	}

	private setLightTheme(): void {
		window.document.body.classList.remove('dark-theme');
		window.document.body.classList.add('light-theme');
		localStorage.setItem('theme', 'light-theme');
		this._theme$.next('light-theme');
	}

	private setDarkTheme(): void {
		window.document.body.classList.remove('light-theme');
		window.document.body.classList.add('dark-theme');
		localStorage.setItem('theme', 'dark-theme');
		this._theme$.next('dark-theme');
	}

	public isLightTheme(): boolean {
		return this._theme$.value === 'light-theme';
	}

	public isLightThemeListener(): Observable<boolean> {
		return this._theme$.pipe(map((theme) => theme === 'light-theme'));
	}
}
