import {Component, Input} from '@angular/core';
import {getCustomAppearanceAnimation} from '@shared/animations/common.animations';

@Component({
	selector: 'app-tooltip',
	templateUrl: './tooltip.component.html',
	styleUrls: ['./tooltip.component.scss'],
	animations: [
		getCustomAppearanceAnimation(0.15),
	]
})
export class TooltipComponent {
	@Input() text = '';
	@Input() left = 0;
	@Input() top = 0;
}
