import {Component, Inject} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {MatIconModule} from '@angular/material/icon';
import {ReportReviewerComponent} from '@shared/components/report-reviewer/report-reviewer.component';
import {IReportReviewerModalComponentData} from '@shared/modals/report-reviewer-modal/models/report-reviewer-modal.types';
import {ButtonComponent} from '@shared/ui-components/button/button.component';
import {DIALOG_DATA, ModalRef} from '@shared/services/modals.service';
import {EmptyDataComponent} from '@shared/ui-components/empty-data/empty-data.component';

@UntilDestroy()
@Component({
	selector: 'app-report-reviewer-modal',
	templateUrl: 'report-reviewer-modal.component.html',
	styleUrls: [
		'report-reviewer-modal.component.scss',
	],
	imports: [
		MatIconModule,
		ReportReviewerComponent,
		ButtonComponent,
		EmptyDataComponent
	],
	standalone: true
})
export class ReportReviewerModalComponent {
	constructor(
		@Inject(DIALOG_DATA) public data: IReportReviewerModalComponentData,
		public dialogRef: ModalRef<ReportReviewerModalComponent>,
	) {
	}
}
