import {NgModule} from '@angular/core';
import {CarouselComponent} from './carousel.component';
import {ItemDirective, SelectedItemDirective} from './directives/carousel.directive';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
	declarations: [
		ItemDirective,
		SelectedItemDirective,
		CarouselComponent
	],
	exports: [
		ItemDirective,
		SelectedItemDirective,
		CarouselComponent
	],
	imports: [
		CommonModule,
		MatIconModule
	],
})
export class CarouselModule {
}
