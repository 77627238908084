<mat-form-field appearance="outline">
	<mat-label *ngIf="!!labelForm">{{ labelForm }}</mat-label>

	<div class="input-control">
		<app-time-input
			[hour]="selectedHour"
			[minute]="selectedMinutes"
			(hourChange)="changeHour($event)"
			(minuteChange)="changeMinute($event)"
			[disabled]="timeForm.disabled"
		></app-time-input>

		<button
			mat-icon-button
			[matMenuTriggerFor]="menu"
			(click)="isOpenMenu = true"
			[disabled]="timeForm.disabled"
		>
			<mat-icon
				matSuffix
				svgIcon="access-time"
			></mat-icon>
		</button>
	</div>
</mat-form-field>

<mat-menu
	#menu="matMenu"
	(close)="closeMenu()"
>
	<div
		*ngIf="isOpenMenu"
		@appearanceAnimation
		class="columns-time"
	>
		<app-column-values
			appStopPropagation
			[columnElements]="cloneDeep(hour)"
			[activeElement]="selectedHour"
			(changeCurrentValue)="changeHour($event)"
		></app-column-values>

		<span>:</span>

		<app-column-values
			appStopPropagation
			[columnElements]="cloneDeep(minute)"
			[activeElement]="selectedMinutes"
			(changeCurrentValue)="changeMinute($event)"
		></app-column-values>
	</div>
</mat-menu>
