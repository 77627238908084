<div class="modal g-overflow-visible">
	<div class="modal__header">
		<div class="title">Список изменений (версия от {{ getDate(data.releaseNotes?.date) }})</div>

		<app-button
			svgIcon="close"
			class="g-button-secondary-empty btn-close"
			(click)="closeModal()"
		></app-button>
	</div>

	<div
		class="modal__content g-overflow-visible"
		style="align-items: center"
	>
		<div class="logo">
			<img src="assets/img/owl-logo-transparent.png" alt="">
		</div>
		<div
			*ngFor="let item of data.releaseNotes?.releaseContent; let i = index"
			class="release-notes"
		>
			<div
				*ngIf="item.img"
				matTooltip="Увеличить"
				class="img-container"
				(click)="imgIncrease = item.img"
			>
				<img [src]="item.img">
			</div>

			<div class="title-release">{{ item.firstSentence }}</div>

			<span class="item-release">
				{{ item.description }}
			</span>
		</div>
		<p></p>
		<div class="about">
			Для того, чтобы больше узнать про систему перейдите на вкладку <a style="color: #4f8bee; cursor: pointer"
																			  (click)="goToPage('/about')">"О системе"</a>
		</div>
	</div>
</div>

<app-enlarged-image
	*ngIf="imgIncrease !== null"
	[urlImg]="imgIncrease"
	(close)="imgIncrease = null"
></app-enlarged-image>
