import {
	Directive,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
} from '@angular/core';
import {fromEvent, Subscription} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {skip} from 'rxjs/operators';

@UntilDestroy()
@Directive({
	selector: '[appClickOnOtherElements]',
})
export class ClickOnOtherElementsDirective implements OnChanges {
	@Input() useClickOnOtherElements = true;
	@Input() useLeaveOnOtherElements = false;

	@Output() clickOnOtherElements = new EventEmitter();

	private _subscription: Subscription;

	constructor(private _el: ElementRef) {
	}

	@HostListener('click', ['$event'])
	@HostListener('focus', ['$event'])
	public onClick(event: any): void {
		if (!this.useClickOnOtherElements) {
			return;
		}

		event.stopPropagation();
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (this.useLeaveOnOtherElements) {
			fromEvent(this._el.nativeElement, 'mouseleave')
				.pipe(untilDestroyed(this))
				.subscribe(() => this.clickOnOtherElements.emit());
		}

		if (!this.useClickOnOtherElements) {
			this._subscription?.unsubscribe();
			this._subscription = null;
		} else if (!this._subscription) {
			this._subscription = fromEvent(document, 'click')
				.pipe(untilDestroyed(this), skip(1))
				.subscribe(() => this.clickOnOtherElements.emit());
		}
	}
}
