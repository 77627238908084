import {Component, Input} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
	selector: 'app-datepicker',
	templateUrl: 'datepicker.component.html',
	styleUrls: ['datepicker.component.scss'],
})
export class DatepickerComponent {
	@Input()
	labelForm = 'Дата';

	@Input()
	dateForm: FormControl;

	public changeInput({value}: any): void {
		const updatedDate = new Date(value);

		this.dateForm.patchValue(updatedDate);
	}
}
