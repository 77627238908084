export const sortByName = (a, b): number => {
	if (a.name > b.name) { return 1; }
	if (a.name < b.name) { return -1; }
	return 0;
};

export const sortBySurname = (a, b): number => {
	if (a.surname > b.surname) { return 1; }
	if (a.surname < b.surname) { return -1; }
	return 0;
};

export const sortByLowercaseName = (a, b): number => {
	if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
	if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
	return 0;
};

export const sortById = (a, b): number => {
	if (a.id > b.id) { return 1; }
	if (a.id < b.id) { return -1; }
	return 0;
};


export const sortByFullName = (a, b): number => {
	if (a.fullName > b.fullName) { return 1; }
	if (a.fullName < b.fullName) { return -1; }
	return 0;
};
