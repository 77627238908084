<div class="statistic__wrapper">
	<div
		*ngIf="selectedUnits.length > 0"
		class="statistic"
	>
		<div
			*ngIf="interval"
			class="interval_wrapper"
			style="display: none;"
		>
			{{ interval.start | date:'dd.MM.yy H:mm' }} - {{ interval.end | date:'dd.MM.yy H:mm' }}
		</div>
		<app-statistic-units-table
			*appAdaptive="{min: 768, and: deviceType === null ? null : deviceType === 'computer'}"
			[style.height]="'100%'"
			[showUnitsNames]="showUnitsNames"
			[selectedUnits]="selectedUnits"
			[dynamicData]="dynamicData"
			[staticData]="staticData"
			[coefficientsDataStatistic]="coefficientsDataStatistic"
			[interval]="interval"
			[dynamicRepairData]="dynamicRepairData"
			[idlesData]="idlesData"
			[isIdleInChart]="isIdleInChart"
			[isPrintMode]="isPrintMode"
			(changeUnits)="changeUnits($event)"
			(onChart)="onChart($event[0], $event[1])"
			(onChartSync)="onChartSync($event)"
			(unitClick)="onClickUnit.emit()"
			(redirect)="redirect($event)"
			(built)="built.emit()"
		></app-statistic-units-table>

		<app-statistic-units-list
			*appAdaptive="{max: 768, and: deviceType === null ? null : deviceType === 'mobile'}"
			[showUnitsNames]="showUnitsNames"
			[selectedUnits]="selectedUnits"
			[dynamicData]="dynamicData"
			[staticData]="staticData"
			[coefficientsDataStatistic]="coefficientsDataStatistic"
			[interval]="interval"
			[dynamicRepairData]="dynamicRepairData"
			[idlesData]="idlesData"
			[isIdleInChart]="isIdleInChart"
			(changeUnits)="changeUnits($event)"
			(onChart)="onChart($event[0], $event[1])"
			(onChartSync)="onChartSync($event)"
			(unitClick)="onClickUnit.emit()"
			(redirect)="redirect($event)"
		></app-statistic-units-list>
	</div>
</div>

<div *ngIf="selectedUnits.length === 0">
	<p style="font-size: 14px; font-weight: 400">
		Не выбранно ни одной единицы оборуддования
	</p>
</div>
