import {Injectable} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';

import {MesStore} from './mes.store';
import {SdwebRepository} from '@shared/repositories/sdweb.repository';
import {first} from 'rxjs/operators';
import {DEFAULT_STATE} from './mes-store.models';
import {MesReducer} from './mes-reducer';
import {ISDTaskFilter} from '../../modules/sd/models/sd-task.interface';
import {ISimpleTask} from '../../modules/dashboard/models/dashboard.types';


@UntilDestroy()
@Injectable({providedIn: 'root'})
export class MesStoreService {
	constructor(
		private _store: MesStore,
		private _mesApiService: SdwebRepository,
		private _mesReducer: MesReducer
	) {
	}

	public resetStore(): void {
		this._store.update({
			userRole: null,
			rolesList: [],
			selectedUnit: null,
			selectedUser: null,
			taskStatuses: [],
			tasks: DEFAULT_STATE,
		});
	}

	public setActiveUnit(unit: any): void  {
		this._store.update({selectedUnit: unit});
	}

	public resetActiveUnit(): void  {
		this._store.update({selectedUnit: null});
	}

	public setActiveUser(user): void  {
		this._store.update({selectedUser: user});
	}

	public setTask(task: ISimpleTask): void {
		this._mesReducer.addValueMap('tasks', task, 'unitId');
	}

	public getSimpleTasks(data: ISDTaskFilter, key: string): void {
		this._mesReducer.setStatus('tasks', 'loading');


		this._mesApiService.getTasks(data)
			.pipe(first())
			.subscribe((res: ISimpleTask[]) => {
				this._mesReducer.setValuesMap<ISimpleTask, number>('tasks', res, key);
			});
	}

	public getSimpleTaskByUnitIds(unitIds: number[], nomenclatureId: number[]): void {
		const data = this.getDefaultSimpleTask();
		data.unitId = unitIds;
		data.nomenclatureId = nomenclatureId;

		this.getSimpleTasks(data, 'unitId');
	}

	public getSimpleTaskByLocationIds(locationIds: number[], nomenclatureId: number[]): void {
		const data = this.getDefaultSimpleTask();
		data.locationId = locationIds;
		data.nomenclatureId = nomenclatureId;

		this.getSimpleTasks(data, 'locationId');
	}

	public getDefaultSimpleTask(): ISDTaskFilter {
		return {
			locationId: [],
			nomenclatureId: [],
			unitId: [],
			userId: [],
			userTaskRole: null,
			serviceId: [],
			intervalDate: {
				start: new Date(),
				end: new Date(Date.now()),
			}
		};
	}
}
