import {Component, Inject, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Edge, Node} from '@swimlane/ngx-graph';
import {DIALOG_DATA, ModalRef} from '@shared/services/modals.service';
import {IWorkflowData} from '@shared/modals/workflow-graph-view-modal/models/workflow-graph-view.types';


@Component({
	selector: 'app-workflow-graph-view',
	templateUrl: './workflow-graph-view-modal.component.html',
	styleUrls: ['./workflow-graph-view-modal.component.scss']
})
export class WorkflowGraphViewModalComponent implements OnInit {
	currentNode: Node;
	nodes: Node[] = [];
	edges: Edge[] = [];
	update$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	layoutSettings = {
		orientation: 'TB',
		rankPadding: 80,
	};
	panningEnabled = true;
	zoomEnabled = true;
	layout = 'dagre';
	autoCenter = true;

	constructor(
		@Inject(DIALOG_DATA) public data: IWorkflowData,
		private dialogRef: ModalRef<WorkflowGraphViewModalComponent>,
	) {
	}

	ngOnInit(): void {
		this.nodes = [];
		this.edges = [];

		this.currentNode = {
			id: this.data.currentStatus.id.toString(),
			data: {
				customColor: this.data.currentStatus.color
			},
			label: this.data.currentStatus.name
		} as Node;

		for (const status of this.data.statuses) {
			this.nodes.push({
				id: status.id.toString(),
				data: {
					customColor: status.color
				},
				label: status.name
			} as Node);
		}

		for (const workflowTransition of this.data.transitions) {
			this.edges.push({
				id: 'id' + workflowTransition.id.toString(),
				source: workflowTransition.fromStatus.id.toString(),
				target: workflowTransition.toStatus.id.toString(),
				label: workflowTransition.name
			} as Edge);
		}

		this.updateGraph();
	}

	updateGraph(): void {
		setTimeout(() => this.update$.next(true), 300);
	}

	closeDialog(): void {
		this.dialogRef.close();
	}
}
