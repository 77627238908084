<div class="modal">
	<div class="modal__header">
		<div class="title">Изображение</div>
		<app-button
			svgIcon="close"
			class="g-button-secondary-empty btn-close"
			(click)="onClose()"
		></app-button>
	</div>
	<div class="modal__content">
		<div class="popup">
			<img
				[src]="urlImg"
				(error)="setDefaultImg()"
			>
		</div>

		<span
			*ngIf="isNotImg"
			class="no-content"
		>Не удалось открыть изображение!</span>
	</div>
</div>
