import {Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, Output, SimpleChanges,} from '@angular/core';
import {SwitchThemeService} from '@shared/services/switch-theme.service';
import {IPoint} from '@shared/components/charts/modules/dynamic-bar/models/dynamic-bar.types';
import {of, Subject} from 'rxjs';
import {delay, skip, takeUntil} from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

declare var CanvasJS: any;

@UntilDestroy()
@Component({
	selector: 'app-dynamic-bar',
	templateUrl: './dynamic-bar.component.html',
	styleUrls: ['./dynamic-bar.component.scss'],
})
export class DynamicBarComponent implements OnChanges, OnDestroy {
	@Input() id;
	@Input() lineId = null;
	@Input() dynamicStat;
	@Input() idlesData;
	@Input() dynamicRepairData;
	@Input() isDynamicWithIdles?: boolean;
	@Input() startTime;
	@Input() endTime;

	@Output() onChart = new EventEmitter<any>();
	@Output() onChartSync = new EventEmitter<any>();
	@Output() redirectEmitter = new EventEmitter<any>();
	@Output() completedBuild = new EventEmitter<void>();

	public chartName: string;
	public sizeHeight: number;
	public isBuilding: boolean;

	private _chart;
	private _isLightTheme: boolean;

	private _cancelBuilding$: Subject<void>;

	constructor(private _switchThemeService: SwitchThemeService) {
		this.sizeHeight = this.getSizeHeight();
		this.isBuilding = true;
		this._cancelBuilding$ = new Subject<void>();
	}

	@HostListener('window:resize', ['$event'])
	private checkAccess(): void {
		this.render();
	}

	public ngOnChanges(changes: SimpleChanges): void {
		this.isBuilding = true;
		this._cancelBuilding$.next();

		this.sizeHeight = this.id ? this.sizeHeight : 40;

		if (this.idlesData) {
			this.idlesData = Object.values(this.idlesData);
		}

		if (this.id) {
			this.chartName = 'dynamicGraphContainer' + this.id;
		} else if (this.lineId) {
			this.chartName = 'dynamicGraphContainer' + this.lineId;
		}

		if (this.id && this.lineId) {
			this.sizeHeight += 15;
		}

		of(null)
			.pipe(
				delay(500),
				untilDestroyed(this),
				takeUntil(this._cancelBuilding$),
			).subscribe(() => this.buildDynamic());


		this._switchThemeService
			.isLightThemeListener()
			.pipe(
				untilDestroyed(this),
				skip(1),
			)
			.subscribe(i => {
				this._isLightTheme = i;
				this.buildDynamic();
			});
	}

	public ngOnDestroy(): void {
		this._chart?.destroy();
		this._chart = undefined;
	}

	private getHtmlTemplateForInfo(points: IPoint[]): string {
		let template = '<div style="color:#d7d6d6">';

		points.forEach(p => {
			template +=
				'<span style="color:#fed42a; text-shadow: 1px 1px 2px black, 0 0 1em #ffffff">' +
				'<b style="color:' +
				p.color +
				'">' +
				p.name +
				'</b> ' +
				CanvasJS.formatDate(p.y[0], 'DD.MM HH:mm') +
				' - ' +
				CanvasJS.formatDate(p.y[1], 'DD.MM HH:mm') +
				'<div>' +
				this.msToTime(p.y[1] - p.y[0]) +
				'</div>' +
				'</span>';
		});

		template += '</div>';

		return template;
	}

	private msToTime(duration): string {
		const seconds = parseInt('' + ((duration / 1000) % 60));
		const minutes = parseInt('' + ((duration / (1000 * 60)) % 60));
		const hours = parseInt('' + ((duration / (1000 * 60 * 60)) % 24));
		const day = parseInt('' + duration / (1000 * 60 * 60 * 24));

		return (
			day + 'д. ' + hours + ' ч. ' + minutes + 'мин. ' + seconds + 'сек.'
		);
	}

	private render(): void {
		if (!document.querySelector(`#${this.chartName}`) || !this._chart) {
			return;
		}

		this._chart.render();
	}

	private buildDynamic(): void {
		if (!document.querySelector(`#${this.chartName}`)) {
			return;
		}

		if (this._chart) {
			this._chart?.destroy();
		}

		const data = this.dynamicDataPrepare();

		const tempChartSync = this.onChartSync;

		this._chart = new CanvasJS.Chart(this.chartName, {
			zoomEnabled: true,
			zoomType: 'y',
			backgroundColor: 'rgba(0, 0, 0, 0)',
			toolTip: {
				cornerRadius: 5,
				backgroundColor: 'rgba(76,76,76,0.9)',
				fontColor: '#FFFFFF',
				borderThickness: 3,
				contentFormatter: e => {
					const selectedPoint = e.entries[0].dataPoint;
					const allPoints =
						e.entries[0]?.dataSeries?.dataPoints || [];

					let points = [selectedPoint];

					if (selectedPoint.crosshair) {
						points = points.concat(
							selectedPoint.crosshair.map(name =>
								allPoints.find(p => p.name === name)
							)
						);
					}

					return this.getHtmlTemplateForInfo(points);
				},
			},
			axisX: {
				lineThickness: 0,
				tickLength: 0,
				tickThickness: 0,
				labelFontSize: 0,
			},
			axisY: {
				lineThickness: !this.lineId == null ? 0 : 1,
				tickLength: !this.lineId ? 0 : 5,
				tickThickness: !this.lineId ? 0 : 3,
				labelFontSize: !this.lineId ? 0 : this.getLabelFontSize(),
				labelFontColor: this._isLightTheme ? '#181c20' : '#fdfdfe',
				labelAngle: 0,
				labelFormatter: function (e) {
					return CanvasJS.formatDate(
						new Date(e.value),
						'DD.MM HH:mm'
					);
				},
				minimum: new Date(this.startTime),
				maximum: new Date(this.endTime),
			},
			dataPointWidth: setDataPointWidth(this.id),
			data: data,
			rangeChanged: syncHandler,
		});

		this.render();
		this.onChart.emit(this._chart);

		function setDataPointWidth(id) {
			if (window.screen.width <= 3000) {
				return 10;
			}

			return id ? 22 : 32;
		}

		function syncHandler(e) {
			tempChartSync.emit(e);
		}

		setTimeout(() => {
			this.isBuilding = false;
			this.completedBuild.emit();
		}, 200);
	}

	private dynamicDataPrepare(): any {
		const dynDataPoints: IPoint[] = [];
		let idlesDataPoints: IPoint[] = [];
		let repairDataPoints: IPoint[] = [];

		const idlesDataPointsCur: IPoint[] = this.idlesDataPrepare();

		const repairDataPointsCur: IPoint[] = this.repairDataPrepare();

		if (this.id && this.dynamicStat) {
			this.dynamicStat.forEach(dynamicElement => {
				const startDynamicElemet = new Date(
					dynamicElement.interval.start
				).getTime();
				const endDynamicElemet = new Date(
					dynamicElement.interval.end
				).getTime();

				const data: IPoint = {
					x: 0,
					y: [startDynamicElemet, endDynamicElemet],
					name: dynamicElement.status.title,
					label: '',
					color: dynamicElement.status.color,
				};
				dynDataPoints.push(data);

				const idlePoint = this.getDataPoint(data, idlesDataPointsCur);
				if (idlePoint) {
					idlesDataPoints.push(idlePoint);
				}
				const repairPoint = this.getDataPoint(data, repairDataPointsCur);
				if (repairPoint) {
					repairDataPoints.push(repairPoint);
				}
			});
		}

		idlesDataPoints = this.addDataPoint(idlesDataPoints, idlesDataPointsCur);
		repairDataPoints = this.addDataPoint(repairDataPoints, repairDataPointsCur);


		for (const point of repairDataPoints) {
			for (const point2 of repairDataPoints) {
				if (point.y[1] <= point2.y[0]) {
					continue;
				}
				if (point2.y[1] <= point.y[0]) {
					continue;
				}
				if (point2.name === point.name) {
					continue;
				}

				if (!point.crosshair) {
					point.crosshair = [];
				}

				point.crosshair.push(point2.name);
			}
		}

		const redirectEmitterRef = this.redirectEmitter;

		function redirect(event) {
			redirectEmitterRef.emit(event);
		}

		return [
			{
				type: 'rangeBar',
				dataPoints: idlesDataPoints,
				click: redirect,
				indexLabelFontSize: this.getFontSize(),
			},
			{
				type: 'rangeBar',
				dataPoints: dynDataPoints,
				indexLabelFontSize: this.getFontSize(),
			},
			{
				type: 'rangeBar',
				dataPoints: repairDataPoints,
				click: redirect,
				indexLabelFontSize: this.getFontSize(),
			},
		];
	}

	private addDataPoint(dynDataPoint: IPoint[], addDataPoints: IPoint[]): IPoint[] {
		for (const addDataPoint of addDataPoints) {
			dynDataPoint.push(addDataPoint);
		}
		return dynDataPoint;
	}

	private getDataPoint(dataPoint: IPoint, addDataPoints: IPoint[]): IPoint {
		const dynDataPoint: IPoint = {
			x: dataPoint.x,
			y: [dataPoint.y[0], dataPoint.y[1]],
			name: dataPoint.name,
			label: dataPoint.label,
			color: dataPoint.color,
		};

		let resDynDataPoint: IPoint = null;

		let newStart = dynDataPoint.y[0];
		let newEnd = dynDataPoint.y[1];

		const startDyn = dynDataPoint.y[0];
		const endDyn = dynDataPoint.y[1];
		// суть в том , что добавочное поле обладает приоритетом, динамические отрезки могут только подстраиваться
		for (const addDataPoint of addDataPoints) {
			const startAdd = addDataPoint.y[0];
			const endAdd = addDataPoint.y[1];

			// проверка начала динамики
			if (startDyn >= startAdd && startDyn <= endAdd) {
				newStart = endAdd;
			}

			// проверка конца динамики
			if (endDyn >= startAdd && endDyn <= endAdd) {
				newEnd = startAdd;
			}
		}

		if (newStart <= newEnd) {
			resDynDataPoint = dynDataPoint;
			resDynDataPoint.y[0] = newStart;
			resDynDataPoint.y[1] = newEnd;
		}
		return resDynDataPoint;
	}

	private repairDataPrepare(): any {
		const dataPoints = [];

		if (this.id && this.dynamicRepairData) {
			this.dynamicRepairData.forEach(element => {
				dataPoints.push({
					x: 0,
					y: [
						new Date(element.start).getTime(),
						new Date(element.end).getTime(),
					],
					label: '',
					name: element.key + ' ' + element.breakType,
					color: element.color,
				});
			});
		}

		return dataPoints;
	}

	private idlesDataPrepare(): any {
		const dataPoints = [];

		if (this.id && this.idlesData) {
			this.idlesData.forEach(element => {
				dataPoints.push({
					fillOpacity: 0.3,
					x: 0,
					y: [
						new Date(element.interval.start).getTime(),
						new Date(element.interval.end).getTime(),
					],
					label: '',
					name: element.title,
					color: element.color,
					idleId: element.id,
				});
			});
		}

		return dataPoints;
	}

	private getSizeHeight(): number {
		return window.screen.width > 3000 ? 110 : 45;
	}

	private getFontSize(): number {
		return window.screen.width > 3000 ? 32 : 12;
	}

	private getLabelFontSize(): number {
		return window.screen.width > 3000 ? 20 : 10;
	}
}
