@if (!filteredTree?.isEmpty$.getValue()) {
	<div class="search-wrapper">
		<app-search
			[isShowIcon]="false"
			[placeholder]="'Фильтр'"
			(search)="searchString$.next($event || '')"
		></app-search>
	</div>
}

@if (useCheckbox) {
	<app-checkbox
		class="g-margin-left"
		[checked]="filteredTree?.isSelectedAll$.getValue()"
		(click)="filteredTree.applyToAll(!filteredTree?.isSelectedAll$.getValue())"
	>Выбрать все
	</app-checkbox>
}

@if (filteredTree?.isArray$.getValue()) {
	@for (node of getRootNodesAsArray(filteredTree?.rootNodes); track node.id) {
		@if (node.isVisible) {
			<app-tree-nodes
				[useCheckbox]="useCheckbox"
				[node]="filteredTree.rootNodes[$index]"
				[displayField]="displayField"
				[searchString]="searchString$.getValue()"
			></app-tree-nodes>
		}
	}
} @else if (filteredTree?.rootNodes?.isVisible) {
	<app-tree-nodes
		[useCheckbox]="useCheckbox"
		[node]="filteredTree?.rootNodes"
		[displayField]="displayField"
		[searchString]="searchString$.getValue()"
	></app-tree-nodes>
}
