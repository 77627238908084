<div class="modal">
	<div class="modal__header">
		<h4 class="title">Редактирование простоя</h4>

		<app-button
			class="g-button-secondary-empty btn-close"
			svgIcon="close"
			(click)="closeModal()"
		>
		</app-button>
	</div>

	<div class="modal__content">
		<div
			class="container"
			*ngIf="start && end && newStart && newEnd"
		>
			<div class="center g-col-md-3">
				<div class="select_container">
					<mat-form-field appearance="outline" class="full-width">
						<input
							type="text"
							matInput
							[formControl]="myControl"
							[matAutocomplete]="auto"
							[placeholder]="baseIdleType.name"
						>
						<mat-autocomplete
							#auto="matAutocomplete"
						>
							<mat-option
								*ngFor="let type of filteredOptions | async"
								[value]="type.name"
								[disabled]="type.disabled"
								(onSelectionChange)="changeIdleType($event)"
							>
								<span class="d-flex align-items-center">
									{{ type.name }}
									<div
										class="ml-3"
										[ngStyle]="{'background-color': type.color, 'width': '20px', 'height': '20px', 'border-radius': '4px'}"
									></div>
								</span>
							</mat-option>
						</mat-autocomplete>
					</mat-form-field>
				</div>

				Начало простоя
				<input
					bsDatepicker
					[(ngModel)]="start"
					type="text"
					class="form-control"
					[bsConfig]="{ dateInputFormat: 'DD.MM.YYYY HH:mm', containerClass: 'theme-dark', selectFromOtherMonth: false }"
					disabled
					readonly
				/>

				Конец простоя
				<input
					bsDatepicker
					[(ngModel)]="end"
					[(bsValue)]="end"
					[bsConfig]="{ dateInputFormat: 'DD.MM.YYYY HH:mm', containerClass: 'theme-dark', selectFromOtherMonth: false }"
					type="text"
					class="form-control"
					(change)="changeDate(end)"
					(bsValueChange)='changeDate(end)'
					[minDate]="start"
					[maxDate]="newEnd"
				/>

				<hr>

				<div *ngIf="editIdle">
					<div class="select_container">
						<!--						<mat-form-field appearance="outline" class="full-width">-->
						<!--							<input-->
						<!--								type="text"-->
						<!--								matInput-->
						<!--								[formControl]="myControl2"-->
						<!--								[matAutocomplete]="auto"-->
						<!--								[placeholder]="baseIdleType.name"-->
						<!--							>-->
						<!--							<mat-autocomplete-->
						<!--								#auto="matAutocomplete"-->
						<!--							>-->
						<!--								<mat-option-->
						<!--									*ngFor="let type of secondFilteredOptions | async"-->
						<!--									[value]="type.name"-->
						<!--									[disabled]="type.disabled"-->
						<!--									(onSelectionChange)="changeSecondIdleType($event)"-->
						<!--								>-->
						<!--								<span class="d-flex align-items-center">-->
						<!--									{{ type.name }}-->
						<!--									<div-->
						<!--										class="ml-3"-->
						<!--										[ngStyle]="{'background-color': type.color, 'width': '20px', 'height': '20px', 'border-radius': '4px'}"-->
						<!--									></div>-->
						<!--								</span>-->
						<!--								</mat-option>-->
						<!--							</mat-autocomplete>-->
						<!--						</mat-form-field>-->
						<select
							#selectSecondType
							[value]="secondIdleType.id"
							[ngStyle]="{'background-color': secondIdleType.color}"
							(change)="changeSecondIdleType(secondIdleType, $event)"
							[(ngModel)]="secondIdleType.id" class="form-control"
						>
							<option
								*ngFor="let type of idleTypes"
								[disabled]='type.disabled'
								[value]="type.id"
								style="background-color: #353c42;"
							>
								<span>{{ type.name }}</span>
							</option>
						</select>
					</div>

					Начало нового простоя
					<input
						bsDatepicker
						[(ngModel)]="newStart"
						[(bsValue)]="newStart"
						[bsConfig]="{ dateInputFormat: 'DD.MM.YYYY HH:mm', containerClass: 'theme-dark', selectFromOtherMonth: false }"
						type="text"
						class="form-control "
						[bsConfig]="bsConfig"
						(change)="changeDate(newStart)"
						(bsValueChange)='changeDate(newStart)'
						[minDate]="start"
						[maxDate]="newEnd"
					/>
					Конец нового простоя
					<input
						bsDatepicker
						[(ngModel)]="newEnd"
						type="text"
						class="form-control "
						[bsConfig]="bsConfig"
						disabled
						readonly
					/>

					<hr>
					<div class="slidecontainer">
						<input
							type="range"
							min={{start.getTime()}}
							max={{newEnd.getTime()}}
							step="30000"
							[(ngModel)]="sliderInterval"
							(change)="newSliderInterval()"
							class="slider"
						>
					</div>
					<hr>

				</div>

				<div>
					<div *ngIf="!editIdle">
						<app-button
							class="g-button-warning-square full-btn"
							(click)="editIdleClick()"
						>
							Разделить простой
						</app-button>
					</div>

					<app-button
						class="g-button-success-square full-btn mt-8"
						[disabled]="!saveBtnAvailable"
						(click)="save()"
					>Сохранить
					</app-button>
				</div>
			</div>
		</div>
	</div>
</div>
