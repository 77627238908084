import {Component, HostListener, OnInit, ViewContainerRef} from '@angular/core';
import {ActivationEnd, NavigationEnd, Router} from '@angular/router';
import {filter, first, mergeMap, take} from 'rxjs/operators';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {NotifierService} from 'angular-notifier';
import {CookieService} from 'ngx-cookie-service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

import {SidebarService} from './layout/sidebar/services/sidebar.service';
import {RouterStateService} from '@shared/services/router-state.service';
import {MetaTagsService} from '@shared/services/meta-tags.service';
import {ConfigService, IConfigEnterprise} from '@shared/services/config.service';
import {IReleaseNotes} from '@shared/models/release-notes';
import {UseCustomIconService} from '@shared/services/use-custom-icon.service';
import {Platform} from '@angular/cdk/platform';
import {initSwipeSideMenu, sleep} from './shared/utils';
import {SwitchThemeService} from '@shared/services/switch-theme.service';
import {CommonEventsService} from '@shared/services/common-events.service';
import {TemplateFilterService} from '@shared/components/template-unit-filter/services/template-filter.service';
import {IUnitParameters} from '@shared/models/unit/unit-parameters';
import {ApiRepository, IFrontDescription} from '@shared/repositories/api.repository';
import {DashboardRepository} from './modules/dashboard/services/dashboard.repository';
import {AuthService} from './modules/auth/services/auth.service';
import {SwUpdate} from '@angular/service-worker';
import {fromEvent, interval} from 'rxjs';
import {MODALS} from '@shared/modals/modals';
import {INeedResetPasswordModalData} from '@shared/modals/need-reset-password-modal/models/need-reset-password-modal.types';
import {IListOfNewChangesModalData} from '@shared/modals/list-of-new-changes/models/list-of-new-changes.types';
import {ModalsService} from '@shared/services/modals.service';
import {StoreService} from '@store/store.service';

export const URL_START_PAGE = 'service-desk/service';

@UntilDestroy()
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	public isSidebarShown: boolean;
	public params: IUnitParameters[];
	public hideSidebar: boolean;
	public isStoreLoaded: boolean;
	public isHeaderHidden: boolean;
	public loadingError: boolean;
	public token: string;
	public currentEnterprise: IConfigEnterprise;
	public releaseNotes: IReleaseNotes;
	public resetPassUrl?: string;
	public url: 'login' | 'dashboard' | 'other';


	constructor(
		public platform: Platform,
		private _sidebarService: SidebarService,
		private _router: Router,
		private _routerService: RouterStateService,
		private _meta: MetaTagsService,
		private _storeService: StoreService,
		private _loaderService: NgxUiLoaderService,
		private _notifierService: NotifierService,
		private _config: ConfigService,
		private _authService: AuthService,
		private _cookieService: CookieService,
		private _apiService: ApiRepository,
		private _: UseCustomIconService,
		private _switchThemeService: SwitchThemeService,
		private _dashboardRepository: DashboardRepository,
		private _commonEvents: CommonEventsService,
		private _templateFilter: TemplateFilterService,
		private _swUpdate: SwUpdate,
		private _dialog: ModalsService,
		private _vcr: ViewContainerRef,
	) {
		this.isStoreLoaded = false;
		this.isHeaderHidden = false;
		this.loadingError = false;

		this._meta.updateTitle();

		if (this._swUpdate.isEnabled) {
			interval(3600000)
				.subscribe(() => this._swUpdate.checkForUpdate()
					.then(() => console.log('Checking for Updates')));

			this._swUpdate.versionUpdates.pipe(untilDestroyed(this)).subscribe((event) => {
				if (event.type === 'VERSION_READY') {
					this._swUpdate.activateUpdate().then(() => {
						window.location.reload();
					});
				}
			});
		}

		this._dialog.setViewContainerRef(this._vcr);
	}

	public ngOnInit(): void {
		this.checkTheme();
		this.deviceTypeDefinition();

		this._router.events
			.pipe(
				filter(route => route instanceof NavigationEnd),
				untilDestroyed(this),
			).subscribe((route: NavigationEnd) => {
			if (this._router.url.includes('login')) {
				this.url = 'login';
				this.token = this._cookieService.get('accessToken');
			} else if (this._router.url.includes('dashboard')) {
				this.url = 'dashboard';
			} else {
				this.url = 'other';
			}

			if (route.url.length === 1) {
				this._router.navigateByUrl('monitoring');
			}
		});

		this._router.events
			.pipe(
				filter(
					event => event instanceof ActivationEnd || event instanceof NavigationEnd,
				),
				untilDestroyed(this),
			)
			.subscribe((event: ActivationEnd | NavigationEnd): void => {
				if (event instanceof ActivationEnd) {
					this.hideSidebar = this.isSidebarHidden(event);
					this._routerService.setCurrentRoute(event.snapshot);
					this.isHeaderHidden = event.snapshot.routeConfig.path === 'login';
				}
			});


		this._sidebarService.isSidebarShown
			.pipe(untilDestroyed(this))
			.subscribe((response: boolean): void => {
				this.isSidebarShown = response;
			});

		this._authService.getLoginStatus()
			.pipe(
				filter(event => event),
				untilDestroyed(this),
			).subscribe(() => {
			this.startApp();
		});

		this.initAutoEntrainInDashboard();
		this.notifyOn();

		initSwipeSideMenu().subscribe(
			(isOpened) => this.isSidebarShown = isOpened,
		);
	}

	private initAutoEntrainInDashboard(): void {
		let terminalIps: string[] = JSON.parse(
			localStorage.getItem('TerminalIpList'),
		);

		if (!terminalIps?.length) {
			terminalIps = JSON.parse(
				localStorage.getItem('TerminalList'),
			);
		}

		if (terminalIps?.length) {
			this._commonEvents.getUserInactivity(30000).pipe(
				untilDestroyed(this),
				filter(() => this._router.url.indexOf('login') >= 0),
				mergeMap(() => this._dashboardRepository.getEntrainUrlsByTerminalIps(
					terminalIps,
				)),
				filter(urls => !!urls.length),
			).subscribe((urls) => this._router.navigateByUrl(urls[0]));
		}
	}

	private startApp(): void {
		this.token = this._cookieService.get('accessToken');

		if (this.token) {
			this._apiService.getLastReleaseNotes()
				.pipe(filter(releaseNotes => !!releaseNotes), take(1))
				.subscribe(res => {
					const currentUpdateDate = this._cookieService.get('releaseNotesDate');

					this.releaseNotes = res;
					if (this.releaseNotes && this.releaseNotes.releaseContent) {
						this.releaseNotes.releaseContent
							.sort((a, b) => a.order - b.order);

						if (currentUpdateDate) {
							if (new Date(currentUpdateDate) < this.releaseNotes.date) {
								this.updateReleaseNotesCookieDate();
							}
						} else {
							this.updateReleaseNotesCookieDate();
						}

						this.releaseNotes.releaseContent.forEach((content) => {
							let pointIndex = content.description.indexOf('.');

							pointIndex = pointIndex !== -1
								? pointIndex + 1
								: content.description.length;

							content.firstSentence = content.description.substring(0, pointIndex);
							content.description = content.description.substring(
								pointIndex, content.description.length,
							);
						});
					}
				});

			this._apiService.getFrontDescription()
				.pipe(first())
				.subscribe((res: IFrontDescription) => {
					this._config.setConfig(res);
					this.currentEnterprise = this._config.getRootEnterprise();
					this.loadStore();
					this.checkNeedResetPassword();
				}, error => {
					this.token = null;

					console.log('Ошибка получения настроек');
					this._authService.logout();
				});
		}
	}

	private checkNeedResetPassword(): void {
		const currentUser = localStorage.getItem('currentUser');

		if (!currentUser) {
			return;
		}

		const restTimePassword = JSON.parse(currentUser)['restTimePassword'];
		const config = this._config.getConfig(['resetPassTime', 'resetPassURL']);
		const resetPassTime = config?.resetPassTime;

		this.resetPassUrl = config?.resetPassURL;

		if (this._router.url.includes('/login')) {
			const subscribe = this._router.events.subscribe((event) => {
				if (event instanceof NavigationEnd) {
					this.checkNeedResetPassword();

					subscribe.unsubscribe();
				}
			});

			return;
		}

		if (!resetPassTime || !restTimePassword || !this.resetPassUrl) {
			return;
		}

		const msecInDay = 86400000;
		const numberOfDayElapsed = Number(restTimePassword) / (msecInDay);
		const isNeedResetPassword = numberOfDayElapsed >= Number(resetPassTime);

		if (isNeedResetPassword) {
			const data: INeedResetPasswordModalData = {
				path: this.resetPassUrl,
			};

			this._dialog.open(MODALS.NeedResetPasswordModal, {data});
		}
	}

	private notifyOn(): void {
		fromEvent(document, 'DOMContentLoaded')
			.pipe(untilDestroyed(this))
			.subscribe(() => {
				if (!Notification) {
					alert('Desktop notifications not available in your browser. Try Chromium.');

					return;
				}

				if (Notification.permission !== 'granted') {
					Notification.requestPermission();
				}
			});
	}

	private async loadStore(): Promise<void> {
		const rootLocation = this._config.getConfig(['rootLocationId']).rootLocationId;

		await sleep(500);

		if (rootLocation) {
			this.isStoreLoaded = false;

			this._loaderService.start();
			this._storeService.initStore();

			const store$ = this._storeService.isStoreLoaded.asObservable()
				.pipe(
					filter(event => !!event),
					take(1)
				)
				.subscribe(isStoreLoaded => {
					if (isStoreLoaded) {
						this._loaderService.stop();
						this.isStoreLoaded = true;
						this._templateFilter.init();
					}
				}, error => {
					this._loaderService.stop();
					this._notifierService.notify('error', 'Ошибка при получении данных, попробуйте авторизоваться заново.');

					this.loadingError = true;
					this.token = null;
					this._authService.logout();
					store$.unsubscribe();
				});
		} else {
			this.isStoreLoaded = true;
			this.token = null;
		}
	}

	private isSidebarHidden(routerEvent): boolean {
		return routerEvent.snapshot._routerState &&
			(
				routerEvent.snapshot._routerState.url === '/' ||
				routerEvent.snapshot._routerState.url === '/login'
			);
	}

	@HostListener('document:keydown', ['$event'])
	@HostListener('document:click', ['$event'])
	public documentClick(event): void {
		this._authService.resetTimer();
	}

	private updateReleaseNotesCookieDate(): void {
		this._cookieService.set(
			'releaseNotesDate',
			new Date(this.releaseNotes.date).toString(),
		);

		const data: IListOfNewChangesModalData = {
			releaseNotes: this.releaseNotes,
		};

		this._dialog.open(MODALS.ListOfNewChangesModal, {data});
	}

	private deviceTypeDefinition(): void {
		this.setDeviceType();

		fromEvent(window, 'resize')
			.pipe(untilDestroyed(this))
			.subscribe((value) => {
				(value.target as Window).document.body.classList.remove('web', 'tablet', 'mobile', 'television', 'rotated-mobile');
				this.setDeviceType();
			});
	}

	private setDeviceType(): void {
		const widthSize = (window as Window).innerWidth;

		if (widthSize >= 3000) {
			(window as Window).document.body.classList.add('television');
		} else if (widthSize >= 1024) {
			(window as Window).document.body.classList.add('web');
		} else if (widthSize >= 768) {
			(window as Window).document.body.classList.add('tablet');
		} else if (widthSize >= 426) {
			(window as Window).document.body.classList.add('rotated-mobile');
			(window as Window).document.body.classList.add('mobile');
		} else {
			(window as Window).document.body.classList.add('mobile');
		}
	}

	private checkTheme(): void {
		this._switchThemeService.initTheme();
	}

	// Вынимаем из скрытого поля json строку со всеми
	// установленными ip адрессами устройства которое
	// запустило фронт (устанавливает терминал)
	public setSystemIpList(): void {
		const elem = document.getElementById('TerminalIpList');
		localStorage.setItem('TerminalIpList', elem['value']);
	}
}
