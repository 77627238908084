import {Component, Input, OnInit} from '@angular/core';

import {IUnit} from '@shared/models/unit';
import {IProgram} from '../../../../models/interfaces';
import {ProgramCompareQuery} from '../../store/program-compare.query';
import {ProgramCompareService} from '../../store/program-compare.service';
import {UnitsSelector} from '@store/units-store/units.selector';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {SharedModule} from '@shared/shared.module';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';

@UntilDestroy()
@Component({
	selector: 'app-techprocess-selector',
	templateUrl: './techprocess-selector.component.html',
	styleUrls: ['./techprocess-selector.component.scss'],
	standalone: true,
	imports: [
		SharedModule,
		NgClass,
		AsyncPipe,
		NgIf,
	],
})
export class TechprocessSelectorComponent implements OnInit {
	@Input() units: Array<IUnit>;
	@Input() unitId?: number;
	@Input() currentProgram?: IProgram;
	@Input() unitStoreName: string;
	@Input() currentStartTime;
	datePickerConfig;
	currentDate;
	currentUnitId;
	currentTechprocessGuid;
	currentProgramId;
	techprocesses$;
	programs$;

	constructor(
		private _programQuery: ProgramCompareQuery,
		private _programService: ProgramCompareService,
		private _unitsSelector: UnitsSelector,
	) {
		this.datePickerConfig = Object.assign(
			{},
			{
				dateInputFormat: 'DD.MM.YYYY',
				containerClass: 'theme-dark',
				selectFromOtherMonth: true,
			}
		);
	}

	public ngOnInit(): void {
		this._programService.resetState(this.unitStoreName);
		this.currentDate =
			this.currentProgram && this.currentProgram.start
				? new Date(this.currentProgram.start)
				: new Date(this.currentStartTime);

		this.setCurrentUnit(
			this.unitId && this._unitsSelector.getUnitById(this.unitId)
				? this.unitId
				: null
		);
		this.subscribeToTechprocesses();
		this.subscribeToPrograms();
	}

	private subscribeToTechprocesses(): void {
		this.techprocesses$ = this._programQuery.getTechprocessesSubscriber(
			this.unitStoreName
		);

		this.techprocesses$
			.pipe(untilDestroyed(this))
			.subscribe(techprocesses => {
				if (!this.currentProgram || !techprocesses) {
					return;
				}

				const currentTechprocess = techprocesses.find(
					process => process.guid === this.currentProgram.techProcessGuid
				);

				if (currentTechprocess) {
					this.currentTechprocessGuid = currentTechprocess.guid;

					this._programService.fetchPrograms(
						currentTechprocess.guid,
						this.unitStoreName
					);
				}
			});
	}

	private subscribeToPrograms(): void {
		this.currentProgramId = this.currentProgram
			? this.currentProgram.id
			: null;
		this.programs$ = this._programQuery.getProgramsSubscriber(
			this.unitStoreName
		);

		this.setProgram(this.currentProgramId);
	}

	public setCurrentUnit(unitId: number): void {
		if (!unitId) {
			return;
		}

		this._programService.setUnit(unitId, this.unitStoreName);
		this.currentUnitId = unitId;

		this._programService.resetTechprocesses(this.unitStoreName);
		this._programService.fetchTechprocesses(
			unitId,
			this.currentDate,
			this.unitStoreName
		);
	}

	public setTechprocess(techprocessGuid: string): void {
		if (!techprocessGuid) {
			return;
		}

		this._programService.resetPrograms(this.unitStoreName);
		this.resetCurrentTechprocess();

		this.currentTechprocessGuid = techprocessGuid;
		this._programService.fetchPrograms(techprocessGuid, this.unitStoreName);
	}

	public setProgram(programId): void {
		const programs = this._programQuery.getPrograms(this.unitStoreName);
		const selectedProgram = programs
			? programs.find(item => item.id === +programId)
			: this.currentProgram;

		if (!selectedProgram) {
			return;
		}

		this._programService.setProgram(
			selectedProgram.textId,
			this.unitStoreName
		);
	}

	public changeDate(newDate): void {
		if (
			this.currentDate &&
			this.currentDate.getTime() === newDate.getTime()
		) {
			return;
		}

		this.currentDate = newDate;
		this._programService.resetTechprocesses(this.unitStoreName);

		this.resetCurrentTechprocess();
		this.setCurrentUnit(
			this.currentUnitId ? this.currentUnitId : this.unitId
		);
	}

	private resetCurrentTechprocess(): void {
		this.currentTechprocessGuid = null;
		this.currentProgramId = null;
	}
}
