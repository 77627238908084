import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {NotifierService} from 'angular-notifier';

import {UnitService} from '@shared/services/unit.service';
import {UnitsSelector} from '@store/units-store/units.selector';
import {first, take} from 'rxjs/operators';
import {UnitsExecutor} from '@store/units-store/units.executor';

@Component({
	selector: 'app-unit-info',
	templateUrl: './unit-info.component.html',
	styleUrls: ['./unit-info.component.scss'],
})
export class UnitInfoComponent implements OnInit {
	@ViewChild('content') contentText!: ElementRef;
	@ViewChild('internetSettings') internetSettingsText!: ElementRef;

	@Input() showNetwork?: boolean;


	public isOrderNumberChanged = false;
	public orderNumber: string = null;
	public orderNumberTMP: string = null;

	public isEdit = false;

	constructor(
		public unitService: UnitService,
		private _notifierService: NotifierService,
		private _unitsSelector: UnitsSelector,
		private _unitsExecutor: UnitsExecutor,
	) {
	}

	ngOnInit() {
		this.orderNumber = this._unitsSelector.getUnitById(this.unitService.unit.id).indexNumber;
		this.orderNumberTMP = this._unitsSelector.getUnitById(this.unitService.unit.id).indexNumber;
	}

	public changeDetected(): void {
		this.isOrderNumberChanged = true;
	}

	saveOrderNumber() {
		const unit = this._unitsSelector.getUnitById(this.unitService.unit.id);
		this._unitsExecutor.saveOrderNumber(unit, this.orderNumberTMP)
			.pipe(take(1))
			.subscribe({
				next: () => {
					this.orderNumber = this.orderNumberTMP;
					this.isEdit = false;
				},
			});
	}

	canselChangeOrderNumber(): void {
		this.orderNumberTMP = this.orderNumber;
		this.isEdit = false;
	}

	copyText(): void {
		const textToCopy = this.contentText.nativeElement.innerText;
		navigator.clipboard.writeText(textToCopy)
			.then(() => {
				this._notifierService.notify('success', 'Скопировано!');
			})
			.catch(() => {
				this._notifierService.notify('failure', 'Ошибка копирования!');
			});
	}

	copyInternetSettings(): void {
		// const textToCopy = document.getElementById(id.toString()).innerText;
		const textToCopy = this.internetSettingsText.nativeElement.innerText;
		navigator.clipboard.writeText(textToCopy)
			.then(() => {
				this._notifierService.notify('success', 'Скопировано!');
			})
			.catch(() => {
				this._notifierService.notify('failure', 'Ошибка копирования!');
			});
	}

	protected readonly first = first;
}
