import {Component, Inject, OnInit} from '@angular/core';
import {IUser} from '@store/types';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NotifierService} from 'angular-notifier';
import {ApiRepository, IEditUser} from '@shared/repositories/api.repository';
import {first} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {MailValidator} from '@shared/utils/form-control-validators';
import {ICreatingNewPassForm} from '@shared/modals/creating-new-pass/models/creating-new-pass.types';
import {DIALOG_DATA, ModalRef} from '@shared/services/modals.service';

@Component({
	selector: 'app-creating-new-pass',
	templateUrl: 'creating-new-pass.component.html',
	styleUrls: ['creating-new-pass.component.scss']
})
export class CreatingNewPassComponent implements OnInit {
	public formGroup: FormGroup<ICreatingNewPassForm>;

	constructor(
		@Inject(DIALOG_DATA) public data: IUser | null,
		private _dialogRef: ModalRef<CreatingNewPassComponent>,
		private _notifierService: NotifierService,
		private _apiService: ApiRepository,
	) {
	}

	public ngOnInit(): void {
		this.createForm();

		if (!!this.data) {
			this.setForm(this.data);
		}
	}

	private setForm(data: IUser): void {
		this.formGroup.setValue({
			email: data.email,
			messengerName: data.messengerName,
			password: data.password
		});
	}

	private createForm(): void {
		this.formGroup = new FormGroup<ICreatingNewPassForm>({
			email: new FormControl<string>('', [MailValidator(['@'])]),
			messengerName: new FormControl<string>(''),
			password: new FormControl<string>('', [Validators.required]),
		});
	}

	public sendTempPassword(): void {
		const value = this.formGroup.value;

		if (!value.email && !value.messengerName) {
			this._notifierService.notify('warning', 'Заполните одно из полей: Почта или Мессенджер');
			return;
		}

		const data: IEditUser = {
			login: this.data.login,
			name: this.data.name,
			password: value.password,
			email: value.email,
			messengerName: value.messengerName,
			patronymic: this.data.patronymic,
			surname: this.data.surname,
			tabNumber: this.data.tabNumber + '',
			isNew: true,
		};

		this._apiService.editUser(data)
			.subscribe({
				next: (res) => {
					this.successMessage('Пароль успешно установлен!');
					this.sendTempPassMessage();
				},
				error: () => {
					this.failureMessage('Не удалось установить пароль!');
				}
			});
	}

	private sendTempPassMessage(): void {
		this.sendTempMessToBack().pipe(first())
			.subscribe({
				next: () => {
					this._notifierService.notify('success', 'Пароль отправлен пользователю!');
					this.closeModal(true);
				},
				error: () => {
					this.failureMessage('Не удалось отправить пароль!');
				}
			});
	}

	private sendTempMessToBack(): Observable<void> {
		return this._apiService
			.sendTemporaryPassword(this.data.id, this.formGroup.get('password').value);
	}

	public closeModal(update: boolean = false): void {
		this._dialogRef.close(update);
	}

	private successMessage(mess: string): void {
		this._notifierService.notify('success', mess);
	}

	private failureMessage(mess: string): void {
		this._notifierService.notify('warning', mess);
	}
}
