import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';
import { UUID } from 'angular2-uuid';
import { CookieService } from 'ngx-cookie-service';

import { EFunctionalConstantEnums } from '@shared/constants/e-functional-constant.enums';
import { ConfigService, IConfig } from '@shared/services/config.service';
import { TemplateFilterService } from '@shared/components/template-unit-filter/services/template-filter.service';
import { FilterService } from '@shared/components/filter/services/filter.service';
import { SidebarService } from '../../../layout/sidebar/services/sidebar.service';
import { URL_START_PAGE } from '../../../app.component';
import { IAuth } from '../models/auth.types';
import { IntervalService } from '@shared/ui-components/n-inverval/services/interval.service';
import { AccessSelector } from '@store/accesses-store/access.selector';


@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private config: Pick<IConfig, 'javaApiUrl'>;
	private logoutTimer$: Subscription;

	public isLoggedIn: BehaviorSubject<boolean>;
	public startLogOutTimer = 120;
	public logOutTimer: number;

	constructor(
		private globalConfig: ConfigService,
		private http: HttpClient,
		private router: Router,
		private cookieService: CookieService,
		private sidebarService: SidebarService,
		private templateService: TemplateFilterService,
		private intervalService: IntervalService,
		private filterService: FilterService,
		private accessSelector: AccessSelector,
	) {
		this.config = this.globalConfig.getConfig(['javaApiUrl']);
		this.isLoggedIn = new BehaviorSubject(false);

		this.check();

		if (this.isLoggedIn && !!this.cookieService.get('isTimeSession')) {
			this.logOutTimer = this.startLogOutTimer;
			this.startLogoutTimer();
		}

		if (localStorage.getItem('smartCardLogin') === 'loginBySmartCard') {
			this.sidebarService.typeSidebar.next('terminal');
		}
	}

	getLoginStatus() {
		return this.isLoggedIn;
	}

	public getClientId(): string {
		let clientId = localStorage.getItem('clientId');

		if (clientId == null) {
			clientId = UUID.UUID();
			localStorage.setItem('clientId', clientId);
		}

		return clientId;
	}

	public login(
		username: string,
		password: string,
		clientId: string,
		newPassword: string = null,
	): Observable<IAuth> {
		return this.http.post<IAuth>(
			this.config.javaApiUrl + '/auth/login',
			{
				login: username,
				password: password,
				clientId: clientId,
				newPassword: newPassword,
			},
		);
	}

	public getFrontDescription(): Observable<IConfig> {
		return this.http.get<IConfig>(this.config.javaApiUrl + '/auth/get-front-description');
	}

	public loginBySmartCard(
		smartCardId: string,
		clientId: string,
	): Observable<IAuth> {
		return this.http.post<IAuth>(
			this.config.javaApiUrl + '/auth/login/smartcard',
			{
				smartCardId: smartCardId,
				clientId: clientId,
			},
		);
	}

	public setIsTimeSession() {
		this.cookieService.set('isTimeSession', 'true', null, '/');
	}

	public startLogoutTimer() {
		this.logOutTimer = this.startLogOutTimer;
		this.logoutTimer$ = timer(1000, 1000)
			.subscribe(() => {
				if (this.logOutTimer > 0) {
					this.logOutTimer--;
				} else {
					this.logout();
				}
			});
	}

	public resetTimer() {
		this.logOutTimer = this.startLogOutTimer;
	}

	public logout() {
		if (!this.isLoggedIn.value) {
			return;
		}

		if (this.logoutTimer$) {
			this.logoutTimer$.unsubscribe();
		}

		this.templateService.clear();
		this.intervalService.clear();
		this.filterService.clearFilters();
		localStorage.removeItem('currentUser');

		this.cookieService.set('accessToken', null, null, '/');
		this.cookieService.set('refreshToken', null, null, '/');
		this.cookieService.set('isTimeSession', null, null, '/');
		this.cookieService.set('login', null, null, '/');
		this.cookieService.delete('accessToken', '/');
		this.cookieService.delete('refreshToken', '/');
		this.cookieService.delete('isTimeSession', '/');
		this.cookieService.delete('login', '/');

		if (this.router.url !== '/login') {
			if (this.router.url.includes('error')) {
				localStorage.setItem('storedUrl', URL_START_PAGE);
			} else {
				localStorage.setItem('storedUrl', this.router.url);
			}
		}
		this.isLoggedIn.next(false);
		this.router.navigateByUrl('login');
	}

	public clearData(): void {
		const cookies = document.cookie.split(';');
		for (let i = 0; i < cookies.length; i++) {
			const cookie = cookies[i];
			const eqPos = cookie.indexOf('=');
			const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
			document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00GTM';
		}
		sessionStorage.clear();
		localStorage.removeItem('AkitaStores');
		localStorage.removeItem('currentUser');
		localStorage.removeItem('clientId');
		localStorage.removeItem('storedUrl');
		localStorage.removeItem('nomenclatureId');
	}

	public refreshToken(): Observable<{
		accessToken: string,
		refreshToken: string,
	}> {
		let headers = new HttpHeaders();

		headers = headers.append(
			'authorization',
			'Bearer ' + this.cookieService.get('accessToken'),
		);

		return this.http.post<{
			accessToken: string,
			refreshToken: string,
		}>(
			this.config.javaApiUrl + '/auth/refreshToken',
			{
				refreshToken: this.cookieService.get('refreshToken'),
				accessToken: this.cookieService.get('accessToken'),
			},
			{headers},
		);
	}

	public updateTokens(access: string, refresh: string): void {
		this.cookieService.set('accessToken', access, null, '/');
		this.cookieService.set('refreshToken', refresh, null, '/');
	}

	public getTokens() {
		return {
			access: this.cookieService.get('accessToken'),
			refresh: this.cookieService.get('refreshToken'),
		};
	}

	public check(): void {
		const user = localStorage.getItem('currentUser');

		if (user) {
			this.isLoggedIn.next(true);
		} else {
			this.isLoggedIn.next(false);
		}
	}

	isAccessToVideocam() {
		const functional = [EFunctionalConstantEnums.videomonitoring];

		return this.accessSelector.isAnyFunctionalAvailable(functional);
	}
}
