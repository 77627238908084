import {Observable, Subject} from 'rxjs';
import {IUnitFull} from '../../../../modules/monitoring/modules/unit/models/unit';

export abstract class ParameterFilterService {
	protected _selectedUnit: Map<number, IUnitFull>;
	protected _selectedUnitChange: Subject<void>;

	abstract set(parameter: any): void;

	protected constructor() {
		this._selectedUnit = new Map();
		this._selectedUnitChange = new Subject<void>();
	}

	changeUnitsListeners(): Observable<void> {
		return this._selectedUnitChange.asObservable();
	}

	isHave(unitId: number): boolean {
		return this._selectedUnit.has(unitId);
	}

	getSelectedUnitMap(): Map<number, IUnitFull> {
		return this._selectedUnit;
	}

	clear(): void {
		this._selectedUnit = new Map();
	}
}
