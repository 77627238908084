import { Component, Input, OnInit } from '@angular/core';

import { untwistingAnimation } from '../../../../animations/common.animations';
import { TreeNode } from '@shared/ui-components/tree/services/tree-node';

@Component({
	selector: 'app-tree-nodes',
	templateUrl: './tree-nodes.component.html',
	styleUrls: ['./tree-nodes.component.scss'],
	animations: [untwistingAnimation],
})
export class TreeNodesComponent implements OnInit {
	@Input() node: TreeNode;
	@Input() useCheckbox = true;
	@Input() displayField = 'name';
	@Input() searchString?: string;

	ngOnInit(): void {
		// @ts-ignore
		if (this.node.isDefault === true) {
			this.node.checked = true;
		}
	}
}
