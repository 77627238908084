import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, skip } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { ITree } from '@shared/models/tree';
import { TreeNode } from '@shared/ui-components/tree/services/tree-node';
import { Tree } from '@shared/ui-components/tree/services/tree';

@UntilDestroy()
@Component({
	selector: 'app-tree',
	templateUrl: './tree.component.html',
	styleUrls: ['./tree.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreeComponent implements OnChanges, OnInit, OnDestroy {
	@Input() tree:
		(ITree & { [key: string]: any })[] |
		(ITree & { [key: string]: any });
	@Input() checkAll = false;
	@Input() displayField = 'name';
	@Input() useCheckbox = true;
	@Input() isSort = true;
	@Input() selectedIds = [];
	@Input() defaultIds: number[] = [];
	@Input() isListView = false;
	@Input() callEventAfterInit = true;

	@Output() selectedIdsChange = new EventEmitter<number[]>();
	@Output() onSelectedNodes = new EventEmitter<ITree[]>();
	@Output() treeChange = new EventEmitter<ITree[] | ITree>();
	@Output() onclick = new EventEmitter<ITree>();

	public filteredTree: Tree;
	public searchString$: BehaviorSubject<string>;

	constructor(
		private _cdr: ChangeDetectorRef,
	) {
		this.searchString$ = new BehaviorSubject<string>('');
	}

	public ngOnInit(): void {
		this.initTree();
		this.initFiltering();
		this.initComponentUpdating();
	}

	public ngOnDestroy(): void {
		this.filteredTree?.clear();
	}

	private initFiltering(): void {
		this.searchString$
			.pipe(
				untilDestroyed(this),
				debounceTime(300),
			).subscribe(value => this.filteredTree?.applyFilter({ line: value }));
	}

	private initComponentUpdating(): void {
		combineLatest([
			this.filteredTree?.isSelectedAll$,
			this.searchString$.pipe(debounceTime(400)),
		])
			.pipe(untilDestroyed(this))
			.subscribe(() => this._cdr.detectChanges());
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (!!this.filteredTree) {
			this.updateTree(changes);
		}
	}

	private updateTree(changes: SimpleChanges): void {
		if (changes?.checkAll?.currentValue) {
			this.filteredTree.applyToAll(true);
		}

		if (changes?.defaultIds?.currentValue) {
			this.filteredTree.setIds(this.defaultIds);
		}

		if (changes?.isSort?.currentValue) {
			this.filteredTree.sort();
		}

		if (changes?.tree?.currentValue) {
			this.filteredTree.update(this.tree);
		}
	}

	private initTree() {
		this.filteredTree = new Tree(this.tree);

		const {
			tree$,
			selectedIds$,
			selectedNodes$,
			clickNode$,
		} = this.filteredTree;

		this._checkCallEventAfterInit(tree$)
			.pipe(untilDestroyed(this))
			.subscribe(tree => this.treeChange.emit(tree));

		this._checkCallEventAfterInit(selectedIds$)
			.pipe(untilDestroyed(this))
			.subscribe(ids => this.selectedIdsChange.emit(ids));

		this._checkCallEventAfterInit(selectedNodes$)
			.pipe(untilDestroyed(this))
			.subscribe(nodes => this.onSelectedNodes.emit(nodes));

		clickNode$
			.pipe(untilDestroyed(this))
			.subscribe(this.onclick);

		if (this.defaultIds?.length) {
			this.filteredTree.setIds(this.defaultIds);
		}

		if (this.isSort) {
			this.filteredTree.sort();
		}

		if (this.callEventAfterInit) {
			this.filteredTree?.emit();
		}

		this.filteredTree.checkCorrect();
	}

	public getRootNodesAsArray(tree: TreeNode | TreeNode[]): TreeNode[] {
		return (tree as TreeNode[]);
	}

	private _checkCallEventAfterInit<T>(obs$: Observable<T>): Observable<T> {
		return this.callEventAfterInit ? obs$ : obs$.pipe(skip(1))
	}
}
