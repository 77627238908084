<div *ngIf="!availableDevices || (availableDevices && availableDevices.length === 0)">
	Отсутствуют подключенные камеры.
</div>

<div *ngIf="!hasPermission" style="padding-top: 130px">
	<h4>Отсутствует разрешение браузера на использование камеры</h4>
</div>

<div
	*ngIf="availableDevices && hasPermission"
	class="size-sm camera"
>
	<ngx-select
		[items]="availableDevices"
		optionValueField="deviceId"
		optionTextField="label"
		[(ngModel)]="deviceSelected"
		size="small"
		noResultsFound="Камера не найдена"
		(select)='onDeviceSelectChange($event)'
	></ngx-select>
</div>

<div class="g-width-100 g-height-100" style="text-align: center; align-content: center;">
	<div
		class="camera g-height-100"
	>
		<video
			id="js-video"
			class="reader-video g-height-100 g-width-100"
			autoplay
			playsinline
		></video>
	</div>
</div>

<canvas
	style="display: none"
	id="js-canvas"
></canvas>
