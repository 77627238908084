import {Component, Inject} from '@angular/core';
import {IListOfNewChangesModalData} from '@shared/modals/list-of-new-changes/models/list-of-new-changes.types';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {NgForOf, NgIf} from '@angular/common';
import {Router} from '@angular/router';
import {DIALOG_DATA, ModalRef} from '@shared/services/modals.service';
import {ButtonComponent} from '@shared/ui-components/button/button.component';
import {EnlargedImageModalModule} from '@shared/modals/enlarged-image-modal/enlarged-image-modal.module';

@Component({
	selector: 'app-list-of-new-changes-modal',
	templateUrl: './list-of-new-changes-modal.component.html',
	styleUrl: './list-of-new-changes-modal.component.scss',
	standalone: true,
	imports: [
		MatIconModule,
		MatTooltipModule,
		NgForOf,
		NgIf,
		EnlargedImageModalModule,
		ButtonComponent
	]
})
export class ListOfNewChangesModalComponent {
	public imgIncrease: string = null;

	constructor(
		@Inject(DIALOG_DATA) public data: IListOfNewChangesModalData,
		private _dialogRef: ModalRef<ListOfNewChangesModalComponent>,
		private _router: Router,
	) {
	}

	public getDate(date: Date): string {
		return new Date(date).toLocaleDateString();
	}

	public closeModal(): void {
		this._dialogRef.close();
	}

	public goToPage(path: string): void {
		this._dialogRef.close();

		this._router.navigate([path]);
	}
}
