<app-expand
	[isOpen]="isOpen"
	[isAbsolute]="isAbsolute"
	[isAutoClose]="isAutoClose"
	[template]="unitsTemplates"
>
	<span class="nowrap" label>{{ unitsGroupService.titleOfUnitsTemplate$.getValue() }}</span>
</app-expand>

<ng-template #unitsTemplates>
	<div class="g-padding-h">
		<app-units-templates [useButton]="useButton"></app-units-templates>
	</div>
</ng-template>
