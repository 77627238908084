import { Injectable } from '@angular/core';
import { ParametersFilterService } from '@shared/components/filter/services/parameters-filter.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { TemplateFilterService } from '@shared/components/template-unit-filter/services/template-filter.service';
import { ArrayUtils } from '../../../utils';
import { DocumentFormatEnums } from '@shared/constants/document-format.enums';
import { IUnitFull } from '../../../../modules/monitoring/modules/unit/models/unit';
import { EnumReports } from '@shared/components/filter/models/filter.enums';

@Injectable({
	providedIn: 'root'
})
export class FilterService {
	public fileType = DocumentFormatEnums.EXCEL;
	public reportsSelected: EnumReports = EnumReports.UNIT_DETAILS;


	private _selectedUnits$: BehaviorSubject<IUnitFull[]>;
	private _defaultUnits$: BehaviorSubject<IUnitFull[]>;

	constructor(
		private _parametersFilter: ParametersFilterService,
		private _templateFilter: TemplateFilterService,
	) {
		this._selectedUnits$ = new BehaviorSubject<IUnitFull[]>([]);
		this._defaultUnits$ = new BehaviorSubject<IUnitFull[]>([]);

		this._initListeners();
	}

	private _initListeners(): void {
		this._parametersFilter.getFilteredUnitsListener()
			.pipe(debounceTime(1))
			.subscribe((units: IUnitFull[]) => {
				this._defaultUnits$.next([...units]);

				if (this._templateFilter.getSelectedTemplate() !== null) {
					return;
				}

				this._setCheck(units);

				this._selectedUnits$.next(units);

				this._templateFilter.setSelectedTemplate(null);
			});

		this._templateFilter.getUnitsBySelectedTemplateListener()
			.pipe(debounceTime(1))
			.subscribe((units: IUnitFull[] | null) => {
				const data = units === null
					? this._parametersFilter.getFilteredUnits()
					: units;

				this._setCheck(data);
				this._selectedUnits$.next(data);
			});
	}

	public clearFilters(): void {
		this._selectedUnits$.next([]);
		this._defaultUnits$.next([]);
		this._parametersFilter.clear();
	}

	public getSelectedUnitsListener(): Observable<IUnitFull[]> {
		return this._selectedUnits$.asObservable();
	}

	public getSelectedUnits(): IUnitFull[] | null {
		return this._selectedUnits$.getValue();
	}

	public getSelectedUnitsIds(): number[] {
		return this._selectedUnits$.getValue().map((unit: IUnitFull) => unit.id);
	}

	public setSelectedUnits(units: IUnitFull[]): void {
		this._setCheck(units);
		this._selectedUnits$.next([...units]);
	}

	public setSelectedUnitsWithSort(units: IUnitFull[]): void {
		const selectedUnits = ArrayUtils.unitSortByLocation(units);

		this._setCheck(selectedUnits);

		this._selectedUnits$.next([...selectedUnits]);
	}

	public setDefaultUnits(units: IUnitFull[]): void {
		this._defaultUnits$.next(units);
	}

	public getDefaultUnitListener(): Observable<IUnitFull[]> {
		return this._defaultUnits$.asObservable();
	}

	public changeFilter(): void {
		if (this._templateFilter.getSelectedTemplate() === null) {
			this._parametersFilter.changeFilterParameters();
		} else {
			const template = this._templateFilter.getSelectedTemplate();
			this._templateFilter.setSelectedTemplate(template);
		}
	}

	private _setCheck(selectedUnits: IUnitFull[]): void {
		const set: Set<number> = new Set();

		selectedUnits.forEach((unit: IUnitFull) => {
			unit.checked = true;
			set.add(unit.id);
		});

		this._defaultUnits$.getValue()
			.forEach((unit: IUnitFull) => {
				if (!set.has(unit.id)) {
					unit.checked = false;
				}
			});
	}
}
