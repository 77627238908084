import {Injectable} from '@angular/core';
import {Title, Meta} from '@angular/platform-browser';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import {filter, map, mergeMap} from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@Injectable({
	providedIn: 'root'
})
export class MetaTagsService {
	constructor(
		private _titleService: Title,
		private _router: Router,
		private _activatedRoute: ActivatedRoute
	) {
	}

	public updateTitle(title?: string): void {
		if (!title) {
			this._router.events
				.pipe(
					filter(event => event instanceof NavigationEnd),
					map(() => this._activatedRoute),
					map(route => {
						while (route.firstChild) {
							route = route.firstChild;
						}

						return route;
					}),
					filter(route => route.outlet === 'primary'),
					mergeMap(route => route.data),
				)
				.subscribe(event => {
					this._titleService.setTitle(event['title'] + ' | Owl SCADA');
				});
		} else {
			this._titleService.setTitle(title + ' | Owl SCADA');
		}
	}
}
