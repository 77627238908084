<div class="modal g-overflow-visible">
	<div class="modal__header">
		<div class="title">Подписки</div>

		<app-button
			svgIcon="close"
			class="g-button-secondary-empty btn-close"
			(click)="closeModal()"
		></app-button>
	</div>

	<div
		class="modal__content g-overflow-visible"
		style="align-items: center"
	>
		<app-unit-subscription
			[unitId]="data?.unitId"
			class="g-width-100"
		></app-unit-subscription>
	</div>
</div>
