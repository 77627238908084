import {Directive, TemplateRef} from '@angular/core';

// tslint:disable-next-line:directives-selector
@Directive({selector: '[item]'})
export class ItemDirective {
	constructor(
		public templateRef: TemplateRef<any>,
	) {
	}
}

// tslint:disable-next-line:directives-selector
@Directive({selector: '[selectedItem]'})
export class SelectedItemDirective {
	constructor(
		public templateRef: TemplateRef<any>,
	) {
	}
}
