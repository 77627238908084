import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {MatButtonModule} from '@angular/material/button';

import {SortTableModule} from '@shared/directives/sort-table-directive/sort-table.module';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ScrollToTopModule} from '@shared/ui-components/scroll-to-top/scroll-to-top.module';
import {SelectorLettersModule} from '@shared/pipes/selector-letters/selector-letters.module';
import {LinkDirective} from '@shared/directives/link/link.directive';
import {StatisticUnitsContainerComponent} from '@shared/components/statistic-units-container/statistic-units-container.component';
import {StatisticUnitsTableComponent} from '@shared/components/statistic-units-container/components/statistic-units-table/statistic-units-table.component';
import {StatisticUnitsListComponent} from '@shared/components/statistic-units-container/components/statistic-units-list/statistic-units-list.component';
import {DynamicBarModule} from '@shared/components/charts/modules/dynamic-bar/dynamic-bar.module';
import {StaticBarModule} from '@shared/components/charts/modules/static-bar/static-bar.module';
import {IdleEditorModule} from '@shared/components/idle-editor/idle-editor.module';
import {ScrollModule} from '@shared/ui-components/scroll/scroll.module';
import {AdaptiveModule} from '@shared/directives/adaptive/adaptive.module';
import {TableModule} from '@shared/ui-components/table/table.module';
import {SelectorLettersComponent} from '@shared/ui-components/selector-letters/selector-letters.component';

@NgModule({
	declarations: [
		StatisticUnitsContainerComponent,
		StatisticUnitsTableComponent,
		StatisticUnitsListComponent,
	],
	imports: [
		CommonModule,
		SortTableModule,
		DynamicBarModule,
		StaticBarModule,
		RouterModule,
		IdleEditorModule,
		ScrollingModule,
		MatButtonModule,
		ScrollModule,
		AdaptiveModule,
		ScrollToTopModule,
		TableModule,
		MatTooltipModule,
		SelectorLettersModule,
		LinkDirective,
		SelectorLettersComponent,
	],
	exports: [StatisticUnitsContainerComponent],
})
export class StatisticUnitsContainerModule {
}
