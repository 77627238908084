<div
	class="print-container text-left"
	style="display: inline-block; margin-left: 1%"
>
	<app-checkbox
		[checked]="useUrl"
		(click)="rebuildQrInfo()"
	>
		Использовать URL в кодировке
	</app-checkbox>
</div>

<div
	class="print modal-body g-scroll"
	id="print"
>
	<div class="code">
		{{ qrInfo }}
		<hr>
		<div class="row justify-content-center g-gap">
			<div
				id="qr-code"
				[style.width]="sizePX"
				class="g-col-lg-4"
				style="text-align: center;"
			>
				<img id="data-matrix-img">
			</div>
			<app-button
				class="g-button-secondary-square"
				title="Печать QR"
				svgIcon="print-outline"
				(click)="printCode('qr-code')"
			>
				Печать
			</app-button>
		</div>
		<hr>
		<div class="row" style="align-items: center">
			<div class="g-col-md-2">Ширина:</div>
			<div class="g-col-md-2">
				<mat-form-field appearance="outline">
					<input
						type="number"
						matInput
						[(ngModel)]="barWidth"
						placeholder="Значение ширины"
						(change)="buildBarCode()"
					/>
				</mat-form-field>
			</div>
		</div>

		<div class="row" style="align-items: center">
			<div class="g-col-md-2">Высота:</div>
			<div class="g-col-md-2">
				<mat-form-field appearance="outline">
					<input
						type="number"
						matInput
						[(ngModel)]="barHeight"
						placeholder="Значение высоты"
						(change)="buildBarCode()"
					/>
				</mat-form-field>
			</div>
		</div>
		<br>
		<div [id]="uniqNameBarCode" class="row g-justify-content-center g-gap">
			<div
				id="barcode"
				class="g-col-lg-3"
				style="text-align: center;"
			>
				<img class="code128" id="code128">
			</div>
			<app-button
				class="g-button-secondary-square"
				title="Печать штрих-кода"
				svgIcon="print-outline"
				(click)="printCode('barcode')"
			>
				Печать
			</app-button>
		</div>
	</div>
</div>
