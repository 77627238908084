<div class="mt-4 ml-4">График {{ index + 1 }}</div>

<app-button
	appStopPropagation
	class="g-button-light-empty font-size-14 button-icon g-absolute g-right-025 g-top-025"
	svgIcon="close"
	(click)="deleteGroup.emit()"
></app-button>

<div cdkDropList class="list mt-8"
	 #list="cdkDropList"
	 [cdkDropListData]="charts"
	 [cdkDropListConnectedTo]="cdkDropGroups"
	 (cdkDropListDropped)="drop($event)"
>

	<app-trends-select-item *ngFor="let chart of charts; trackBy: trackByFn"
							class="mt-4 cursor-move selected"
							mode="delete"
							cdkDrag
							appStopPropagation
							(delete)="deleteChart.emit(chart)"
	>
		{{ chart.name }}
	</app-trends-select-item>

	<mat-icon *ngIf="active"
			  svgIcon="add"
			  class="text_dark mx-auto mt-4 mb-4 btn-add"
	></mat-icon>
</div>
