import {Component} from '@angular/core';

// Использует внутри unitService, сначала необходимо назначить нужный юнит в unitService

@Component({
	selector: 'app-unit-information',
	templateUrl: 'unit-information.component.html',
	styleUrls: ['unit-information.component.scss'],
})
export class UnitInformationComponent {
}
