import {Directive, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {isInteger, maskForPhone} from '@shared/utils/masks';

@Directive({
	selector: '[appPhoneMask]',
	standalone: true,
})
export class PhoneMaskDirective implements OnInit {
	@Input() firstValue: string = '';
	@Input() withoutCode: boolean = false;
	@Output() maskChange: EventEmitter<string> = new EventEmitter<string>();

	constructor(
		private _elementRef: ElementRef
	) {
		(this._elementRef.nativeElement as HTMLInputElement).placeholder = '+7 (000) 000-00-00';
	}

	public ngOnInit(): void {
		if (!!this.firstValue?.length) {
			this.useMask(this.firstValue, false, 0, 0);
		}

		(this._elementRef.nativeElement as HTMLElement).oninput = (event: InputEvent) => {
			const isDeleting = event.inputType !== 'insertFromPaste' && event.data === null;

			const selectionStart = this._elementRef.nativeElement.selectionStart;
			const selectionEnd = this._elementRef.nativeElement.selectionEnd;

			this.useMask(this._elementRef.nativeElement.value, isDeleting, selectionStart, selectionEnd);
		};
	}

	private useMask(value: string, isDeleting: boolean, selectionStart: number, selectionEnd: number): void {
		const phoneNumber = maskForPhone(value, selectionStart, isDeleting, this.withoutCode);

		(this._elementRef.nativeElement as HTMLInputElement).value = phoneNumber.value;
		this._elementRef.nativeElement.selectionStart = selectionStart + phoneNumber.shift;
		this._elementRef.nativeElement.selectionEnd = selectionEnd + phoneNumber.shift;

		let res = '';

		for (let i = 0; i < phoneNumber.value.length; i++) {
			if (isInteger(phoneNumber.value[i])) {
				res += phoneNumber.value[i];
			}
		}

		if (this.withoutCode) {
			this.maskChange.emit('7' + res);
		} else {
			this.maskChange.emit(res);
		}
	}
}
