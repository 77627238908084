import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {IPartialInterval, TKeyOfIntervalPeriod} from '@shared/ui-components/n-inverval/models/interval.types';

@Injectable({
	providedIn: 'root',
})
export class IntervalService {
	private _intervals: {
		[key: string]: {
			subject: BehaviorSubject<IPartialInterval>;
			interval: IPartialInterval;
		};
	};

	constructor() {
		this._intervals = {};
	}

	private _customPeriodOfInterval: Map<
		string,
		BehaviorSubject<TKeyOfIntervalPeriod>
	> = new Map();

	public clear(): void {
		this._intervals = {};
		this._customPeriodOfInterval.clear();
	}

	public getIntervalValue(nameInterval = 'common'): IPartialInterval {
		this.initInterval(nameInterval);
		return this._intervals[nameInterval].interval;
	}

	public getIntervalSubject(nameInterval = 'common'): BehaviorSubject<IPartialInterval> {
		this.initInterval(nameInterval);
		return this._intervals[nameInterval].subject;
	}

	public setInterval(interval: IPartialInterval, nameInterval = 'common'): void {
		this.initInterval(nameInterval);
		if (this.checkSameInterval(interval, nameInterval)) {
			return;
		}
		this._intervals[nameInterval].interval = Object.assign({}, interval);

		this._intervals[nameInterval].subject.next(interval);
	}

	public setCustomPeriod(
		customPeriod: TKeyOfIntervalPeriod,
		nameInterval = 'common',
	): void {
		if (this._customPeriodOfInterval.has(nameInterval)) {
			this._customPeriodOfInterval.get(nameInterval).next(customPeriod);
		} else {
			this._customPeriodOfInterval.set(
				nameInterval,
				new BehaviorSubject<TKeyOfIntervalPeriod>(customPeriod),
			);
		}
	}

	public getCustomPeriod(nameInterval = 'common'): TKeyOfIntervalPeriod | null {
		if (!this._customPeriodOfInterval.has(nameInterval)) {
			this._customPeriodOfInterval.set(
				nameInterval,
				new BehaviorSubject<TKeyOfIntervalPeriod>(null),
			);
		}

		return this._customPeriodOfInterval.get(nameInterval).getValue();
	}

	public getCustomPeriodListener(
		nameInterval = 'common',
	): Observable<TKeyOfIntervalPeriod | null> {
		if (!this._customPeriodOfInterval.has(nameInterval)) {
			this._customPeriodOfInterval.set(
				nameInterval,
				new BehaviorSubject<TKeyOfIntervalPeriod>(null),
			);
		}

		return this._customPeriodOfInterval.get(nameInterval);
	}

	private checkSameInterval(
		interval: IPartialInterval,
		nameInterval = 'common'
	): boolean {
		const savedInterval = this._intervals[nameInterval].interval;

		return (
			savedInterval &&
			interval.start.getTime() === savedInterval.start?.getTime() &&
			interval.end.getTime() === savedInterval.end?.getTime()
		);
	}

	private initInterval(name: string): void {
		if (!this._intervals[name]) {
			this._intervals[name] = {
				subject: new BehaviorSubject<IPartialInterval>({}),
				interval: {},
			};

			return;
		}

		if (!this._intervals[name].subject) {
			this._intervals[name].subject =
				new BehaviorSubject<IPartialInterval>(null);
		}

		if (!this._intervals[name].interval) {
			this._intervals[name].interval = {};
		}
	}
}
