<div
	*ngIf="chartName"
	id="{{chartName}}"
	style="width: 100%; margin-left:auto;margin-right:auto;"
	[style.height]="sizeHeight +'px'"
></div>
<div
	*ngIf="isBuilding"
	class="g-loader"
>
	<span></span>
</div>
