import {Component, Input, OnChanges, OnDestroy} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import {UnitApiRepository} from '@shared/repositories/unit-api.repository';
import {IProgramText} from '../../../../models/interfaces';
import {first} from 'rxjs/operators';
import {NgxTextDiffModule} from '@miccou/ngx-text-diff';
import {SharedModule} from '@shared/shared.module';
import { CommonModule, NgIf } from '@angular/common';
import { PaginationComponent } from '@shared/ui-components/paginator/pagination.component';

@Component({
	selector: 'app-text-compare',
	templateUrl: './text-compare.component.html',
	styleUrls: ['./text-compare.component.scss'],
	standalone: true,
	imports: [
		NgxTextDiffModule,
		SharedModule,
		CommonModule,
		PaginationComponent,
	],
})
export class TextCompareComponent implements OnChanges, OnDestroy {
	@Input() programFirst: number;
	@Input() programSecond: number | IProgramText;

	firstProgramArray: Array<string>;
	firstTextSlice: string;
	secondProgramArray: Array<string>;
	secondTextSlice: string;
	changeContent$;
	pageSize = 250;
	currentPage = 0;
	totalPages = 1;

	constructor(private _apiService: UnitApiRepository) {
		this.changeContent$ = new BehaviorSubject({
			leftContent: '',
			rightContent: ''
		});
	}

	public ngOnChanges(changes): void {
		if (changes.programSecond) {
			this.checkSecondInputType(changes.programSecond.currentValue);
		}

		if (changes.programFirst) {
			this._apiService
				.getProgramText(changes.programFirst.currentValue)
				.pipe(first())
				.subscribe((response: any) => {
					this.setText(response.text, true);
				});
		}
	}

	public ngOnDestroy(): void {
		this.changeContent$.unsubscribe();
	}

	private checkSecondInputType(program): void {
		if (typeof program === 'number') {
			this._apiService
				.getProgramText(program)
				.pipe(first())
				.subscribe((response: any) => {
					this.setText(response.text, false);
				});

			return;
		}

		this.setText(program.text, false);
	}

	private setText(text: string, isFirst: boolean): void {
		const currentObserverValue = this.changeContent$.getValue();

		if (isFirst) {
			this.firstProgramArray = this.makeArrayFromText(text);

			this.setPager(this.firstProgramArray);

			this.firstTextSlice = this.makeTextSlice(this.firstProgramArray);

			this.changeContent$.next({
				...currentObserverValue,
				leftContent: this.firstTextSlice
			});

			return;
		}

		this.secondProgramArray = this.makeArrayFromText(text);

		this.setPager(this.secondProgramArray);

		this.secondTextSlice = this.makeTextSlice(this.secondProgramArray);

		this.changeContent$.next({
			...currentObserverValue,
			rightContent: this.secondTextSlice
		});
	}

	public pageChanged(page: number): void {
		this.currentPage = page - 1;

		this.firstTextSlice = this.makeTextSlice(this.firstProgramArray);
		this.secondTextSlice = this.makeTextSlice(this.secondProgramArray);

		this.changeContent$.next({
			leftContent: this.firstTextSlice ? this.firstTextSlice : ' ',
			rightContent: this.secondTextSlice ? this.secondTextSlice : ' '
		});
	}

	private setPager(textArray): void {
		const newSize = Math.ceil(textArray.length / this.pageSize);

		if (newSize > this.totalPages) {
			this.totalPages = newSize;
		}
	}

	private makeArrayFromText(text: string): string[] {
		return text.split('\n')
			.map(str => str.replace(/\s+/g, ' ').trim());
	}

	private makeTextSlice(array): string {
		const sliceSize = this.currentPage * this.pageSize;
		const slice = array.slice(sliceSize, (this.currentPage + 1) * this.pageSize);

		return slice.length > 0 ? slice.join('\n') : ' ';
	}
}
