import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {BehaviorSubject, merge, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {IUnitTemplate} from '@shared/components/template-unit-filter/components/units-templates/models/units-templates.type';
import {TemplateFilterService} from '@shared/components/template-unit-filter/services/template-filter.service';
import {UnitsTemplatesService} from '@shared/components/template-unit-filter/components/units-templates/services/units-templates.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-units-templates',
	templateUrl: './units-templates.component.html',
	styleUrls: ['units-templates.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnitsTemplatesComponent implements OnInit {
	@Input() useButton = true;

	public selectedTemplate: IUnitTemplate;

	public lineOfSearch$: BehaviorSubject<string>;
	public filteredTemplates$: BehaviorSubject<IUnitTemplate[]>;

	public closePopover$: Subject<void>;

	constructor(
		public unitsTemplatesService: UnitsTemplatesService,
		public templateFilter: TemplateFilterService,
		private _cdr: ChangeDetectorRef,
	) {
	}

	public ngOnInit(): void {
		this.initSubjects();
		this.initFilteringGroups();
		this.initUpdatingPage();
	}

	private initSubjects(): void {
		this.lineOfSearch$ = new BehaviorSubject('');
		this.filteredTemplates$ = new BehaviorSubject<IUnitTemplate[]>([]);
		this.closePopover$ = new Subject<void>();
	}

	private initFilteringGroups() {
		merge(
			this.lineOfSearch$,
			this.templateFilter.getAllTemplatesListener(),
		).pipe(
			untilDestroyed(this),
			map(() =>
				!!this.lineOfSearch$.getValue()
					? this.templateFilter.getAllTemplates().filter(
						({name}) => name.toLowerCase().includes(this.lineOfSearch$.getValue())
					)
					: this.templateFilter.getAllTemplates()
			),
			map((templates: IUnitTemplate[]) => this.sortTemplates(templates)),
		).subscribe(this.filteredTemplates$);
	}

	public sortTemplates(templates: IUnitTemplate[]): IUnitTemplate[] {
		const defaultTemplateId = this.templateFilter.getDefaultTemplate()?.id;

		const sortedTemplates = [...templates]
			.filter(({id}) => id !== defaultTemplateId)
			.sort((a, b) => a.name > b.name ? 1 : -1);

		if (defaultTemplateId) {
			sortedTemplates.unshift(
				templates.find(
					({id}) => id === defaultTemplateId
				)
			);
		}

		return sortedTemplates;
	}

	private initUpdatingPage(): void {
		merge(
			this.filteredTemplates$,
			this.templateFilter.getSelectedTemplateListener()
		).pipe(
			untilDestroyed(this),
		).subscribe(() => this._cdr.markForCheck());
	}

	public async deleteTemplateById(id: number) {
		await this.unitsTemplatesService.deleteTemplateById(id);
		this.closePopover$.next();
	}

	public async editNameOfSelectedTemplate(name: string, group: IUnitTemplate) {
		await this.unitsTemplatesService.update({name}, group);
		this.closePopover$.next();
	}

	public isDefault({id}: IUnitTemplate): boolean {
		return this.templateFilter.getDefaultTemplate()?.id === id;
	}


	get amountOfGroups(): number {
		return this.templateFilter.getAllTemplates().length;
	}
}
