@if (linkForCopy) {
	<app-button
		class="g-button-primary-square"
		svgIcon="copy"
		text="Скопировать ссылку"
		(click)="copy()"
	></app-button>
}

<app-search (search)="filters.searchLine$.next($event)"></app-search>

<app-expand
	[isAbsolute]="true"
	[isAutoClose]="true"
	[template]="filterByTeams"
>
	<div label>Фильтр по бригадам (выбрано {{ getIdSelectedBrigades().length }})</div>
</app-expand>

<ng-template #filterByTeams>
	<app-tree
		[tree]="brigadesSelector.getBrigades()"
		(treeChange)="filters.brigades$.next($event)"
	></app-tree>
</ng-template>

<div class="header-list">
	<span
		[style.color]="'var(--text-middle)'"
		class="g-unselectable"
	>Выбрано {{ amountOfSelectUser }}</span>
	<div class="header-list__selectors">
		<app-button
			[class.g-button-secondary-empty]="!useSortBySelecting$.getValue()"
			[class.g-button-primary-empty]="useSortBySelecting$.getValue()"
			(click)="useSortBySelecting$.next(!useSortBySelecting$.getValue())"
			svgIcon="sort"
		></app-button>
		<app-checkbox
			[checked]="isSelectedAll$.getValue()"
			class="header-list__select"
			(click)="selectAll()"
			appTooltip="Получатели {{isSelectedAll$.getValue() ? '(Убрать все)' : '(Выбрать все)'}}"
		>
			<mat-icon
				class="share-icon"
				svgIcon="share-social"
			></mat-icon>
		</app-checkbox>

		@if (useEditing) {
			<app-checkbox
				[checked]="isAllUsersEditors$.getValue()"
				class="header-list__select"
				(click)="makeAllUsersAsEditors()"
				appTooltip="Возможность редактировать {{isAllUsersEditors$.getValue() ? '(Убрать все)' : '(Выбрать все)'}}"
			>
				<mat-icon
					class="edit-icon"
					svgIcon="edit"
				></mat-icon>
			</app-checkbox>
		}
	</div>
</div>

<app-scroll
	[data]="filteredUsers$.getValue()"
	[style.height]="'400px'"
>
	<div
		*scrollItem="let user"
		class="user-card g-unselectable"
		(click)="selectOne(user)"
	>
		<div class="user-card__image">
			<img
				*ngIf="user.image"
				alt="No"
				[src]="getUrlImage(user.image)"
			>
			<div
				*ngIf="!user?.image"
				class="user-card__image_empty"
			></div>
		</div>
		<div class="user-card__data">
			<app-selector-letters
				[value]="user.fullName"
				[searchString]="filters.searchLine$.getValue()"
			></app-selector-letters>
			<div class="user-card__buttons">
				<app-checkbox
					[style.width]="'15px'"
					[class.margin-right]="!useEditing"
					[checked]="selectUserIds$.getValue()[user.id]"
				></app-checkbox>

				@if (useEditing) {
					<app-button
						appStopPropagation
						class="g-button-danger-empty"
						[class.g-button-success-empty]="editorsIds$.getValue()[user.id]"
						(click)="makeUserAsEditor(user)"
						svgIcon="edit"
					></app-button>
				}
			</div>
		</div>

	</div>
</app-scroll>

<app-button
	class="g-button-primary-square g-width-100"
	[text]="'Отправить'"
	[loading]="isLoading"
	(click)="send()"
></app-button>
