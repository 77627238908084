import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IUnitTemplate } from '@shared/components/template-unit-filter/components/units-templates/models/units-templates.type';
import { IUnitFull } from '../../../../modules/monitoring/modules/unit/models/unit';
import { UnitsSelector } from '@store/units-store/units.selector';
import { UnitsMetadataSelector } from '@store/units-metadata-store/units-metadata.selector';
import { UnitsMetadataReducer } from '@store/units-metadata-store/units-metadata.reducer';

@Injectable({
	providedIn: 'root'
})
export class TemplateFilterService {
	private _selectedTemplate$: BehaviorSubject<IUnitTemplate | null>;
	private _defaultTemplate$: BehaviorSubject<IUnitTemplate>;

	constructor(
		private _unitsSelector: UnitsSelector,
		private _unitsMetadataSelector: UnitsMetadataSelector,
		private _unitsMetadataReducer: UnitsMetadataReducer,
	) {
		this.initSubjects();
	}

	public init(): void {
		this.initDefaultTemplate();
	}

	public clear(): void {
		this._selectedTemplate$.next(null);
		this._defaultTemplate$.next(null);
	}

	private initSubjects(): void {
		this._selectedTemplate$ = new BehaviorSubject<IUnitTemplate | null>(null);
		this._defaultTemplate$ = new BehaviorSubject<IUnitTemplate>(null);
	}

	private initDefaultTemplate(): void {
		const defaultTemplate = this.findDefaultTemplate();
		this._defaultTemplate$.next(defaultTemplate);
		this._selectedTemplate$.next(defaultTemplate);
	}

	private findDefaultTemplate(): IUnitTemplate | null {
		const currentUser: { userId }
			= JSON.parse(localStorage.getItem('currentUser'));

		if (!!currentUser?.userId) {
			const res = this.getAllTemplates().find(
					({unitTemplateUserList}) => unitTemplateUserList?.find(
						({isDefault, userId}) => isDefault && userId === currentUser.userId
					)
				);

			return !!res ? res : null;
		}

		return null;
	}

	public getDefaultTemplate(): IUnitTemplate | null {
		return this._defaultTemplate$.getValue();
	}

	public getAllTemplates(): IUnitTemplate[] {
		return this._unitsMetadataSelector.getUnitTemplates();
	}

	public getAllTemplatesListener(): Observable<IUnitTemplate[]> {
		return this._unitsMetadataSelector.select('unitsTemplates');
	}

	public setAllTemplates(templates: IUnitTemplate[]): void {
		this._unitsMetadataReducer.rewriteStore('unitsTemplates', templates);
	}

	public setDefaultTemplate(template: IUnitTemplate | null): void {
		this._defaultTemplate$.next(template);
	}

	public setSelectedTemplate(template: IUnitTemplate | null): void {
		this._selectedTemplate$.next(template);
	}

	public getSelectedTemplate(): IUnitTemplate | null {
		return this._selectedTemplate$.getValue();
	}

	public getSelectedTemplateListener(): Observable<IUnitTemplate | null> {
		return this._selectedTemplate$.asObservable();
	}

	public getUnitsBySelectedTemplateListener(): Observable<IUnitFull[] | null> {
		return this.getSelectedTemplateListener().pipe(
			map((event) => this.getUnitsBySelectedTemplate()),
		);
	}

	public getUnitsBySelectedTemplate(): IUnitFull[] | null {
		if (!this._selectedTemplate$.getValue()) {
			return null;
		}

		return this.getUnitsByTemplate(this._selectedTemplate$.getValue());
	}

	public getUnitsByTemplate(group: IUnitTemplate): IUnitFull[] {
		return group.unitTemplateOrderList
			.sort(({order}, {order: order2}) => order > order2 ? 1 : -1)
			.map(({unitId}) => this._unitsSelector.getUnitById(unitId));
	}
}
