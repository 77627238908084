import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BaseRepository} from './base-repository';
import {
	ITrendsTemplate,
	ITrendsTemplateCreate,
	ITrendsTemplateUpdate
} from '../../modules/monitoring/modules/unit/components/unit-tabs-area/components/trends/components/trends-template/models/trends-template.models';

@Injectable({providedIn: 'root'})
export class TrendsTemplateRepository extends BaseRepository {
	public getTemplate(userId: number, unitId: number): Observable<ITrendsTemplate[]> {
		const url = `${this._config.javaApiUrl}/core/template/user/${userId}/unit/${unitId}`;

		return this._http.get<ITrendsTemplate[]>(url);
	}

	public updateTemplate(data: ITrendsTemplateUpdate): Observable<ITrendsTemplate> {
		const url = `${this._config.javaApiUrl}/core/template`;

		return this._http.put<ITrendsTemplate>(url, data);
	}

	public createTemplate(data: ITrendsTemplateCreate): Observable<ITrendsTemplate> {
		const url = `${this._config.javaApiUrl}/core/template`;

		return this._http.post<ITrendsTemplate>(url, data);
	}

	public deleteTemplate(templateId: number): Observable<boolean> {
		const url = `${this._config.javaApiUrl}/core/template/` + templateId;

		return this._http.delete<boolean>(url);
	}
}
