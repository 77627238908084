import { NgModule } from '@angular/core';
import { UnitInformationComponent } from './unit-information.component';
import { CommonModule } from '@angular/common';
import { UnitInfoComponent } from './components/unit-info/unit-info.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { IconModule } from '@shared/ui-components/icon/icon.module';
import { ButtonComponent } from '@shared/ui-components/button/button.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
	declarations: [UnitInformationComponent, UnitInfoComponent],
	exports: [UnitInformationComponent],
	providers: [],
	imports: [
		CommonModule,
		TabsModule,
		FormsModule,
		MatIconModule,
		IconModule,
		ButtonComponent,
		MatTooltipModule,
	],
})
export class UnitInformationModule {
}
