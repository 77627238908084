import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IPostTechprocess } from './api.repository';
import { BaseRepository } from './base-repository';
import { IProgram } from '../../modules/monitoring/modules/unit/components/unit-tabs-area/components/details/models/interfaces';

export interface IUnitAlarmsRequest {
	isArchive: boolean;
	levels: number[];
	intervalEnd?: Date;
	intervalStart?: Date;
}

export interface IMnemoShemesItem {
	unitTypeId: number;
	jsonMnemo: string;
	image: string;
}

export interface ISaveMnemoRequest {
	unitTypeId: number;
	jsonMnemo: string;
	image: string;
}

export interface IMnemoScheme {
	id: number;
	mnemoData: string;
}

export interface IArchiveMessage {
	id: number;
	appearanceTime: Date;
	resolutionTime: Date;
	text: string;
	startDate: string;
	diff: string;
	code: string;
	messageLevel: {
		id: number;
		name: string;
		level: number;
	};
	device: {
		id: number;
		poolintTime: number
	};
}

export interface ICreateVideoTask {
	isPaused: boolean;
	isRecording: boolean;
	start: Date;
	end: Date;
	device: {
		id: number;
		poolintTime: number
	};
	videoBitrate: {
		id: number;
		name: null | string;
		description: null | string;
		value: null | string;
	};
	videoFramerate: {
		id: number;
		name: null | string;
		description: null | string;
		value: null | string;
	};
	videoResolution: {
		id: number;
		name: null | string;
		description: null | string;
		frameHeight: null | number;
		frameWidth: null | number;
	};
}

export interface IVideoTask extends ICreateVideoTask {
	id: number;
}

export interface ITechProcessData {
	id: number;
	unitId: number;
	deviceId: number;
	name: string;
	guid: string;
	start: Date;
	end: Date;
	programCurrentBlockDPId: number;
	isActive: boolean;

	duration?: string;
	programs?: IProgram[];
}

export interface IUnitVideo {
	id: number;
	start: Date;
	end: Date;
	duration: number | string;
	path: string;
}

export interface IProgramText {
	id: number;
	text: string;
	hash: string;
}

export interface IProgramEtalon {
	id: number;
	dseProgramTpOperationId: number;
	fullText: string;
	firstLineText?: string;
}

@Injectable({
	providedIn: 'root',
})
export class UnitApiRepository extends BaseRepository {
	public getAlarms(
		requestParams: IUnitAlarmsRequest, unitId: number,
	): Observable<IArchiveMessage[]> {
		const url = `${this._config.javaApiUrl}/core/unit/${unitId}/alarms`;

		return this._http.post<IArchiveMessage[]>(url, requestParams).pipe(
			catchError(error => {
				console.log(`get error with getAlarms: ${error}`);
				return of(error);
			}),
		);
	}

	public getVideoFiles(
		id: number, param: { periodStart: Date, periodEnd: Date },
	): Observable<IUnitVideo[]> {
		const url = `${this._config.javaApiUrl}/video/unit/${id}`;

		return this._http.post<IUnitVideo[]>(url, param).pipe(
			catchError(error => {
				console.log(`UnitApiService. Error with getVideoFiles: ${error}`);
				return of(error);
			}),
		);
	}

	public deleteVideoFile(id: number): Observable<{ message: string }> {
		const url = `${this._config.javaApiUrl}/video/data/${id}`;

		return this._http.delete<{ message: string }>(url).pipe(
			catchError(error => {
				console.log(`get error with deleteVideoFiles: ${error}`);
				return of(error);
			}),
		);
	}

	public createVideoTask(data: ICreateVideoTask): Observable<IVideoTask> {
		const url = `${this._config.javaApiUrl}/video/data/videotask`;

		return this._http.put<IVideoTask>(url, data);
	}

	public getVideoTasks(id: number): Observable<IVideoTask[]> {
		const url = `${this._config.javaApiUrl}/video/unit/${id}/task`;

		return this._http.get<IVideoTask[]>(url);
	}

	public updateVideoTask(data: Partial<IVideoTask>): Observable<IVideoTask> {
		const url = `${this._config.javaApiUrl}/video/data/videotask`;

		return this._http.post<IVideoTask>(url, data);
	}

	public removeVideoTask(id: number): Observable<boolean> {
		const url = `${this._config.javaApiUrl}/video/data/videotask/${id}`;

		return this._http.delete<boolean>(url);
	}

	public getProgramText(id: number): Observable<IProgram> {
		const url = `${this._config.javaApiUrl}/details/programs/text/${id}`;

		return this._http.get<IProgram>(url);
	}

	public getProgramsByTechProcessGuid(guid: string): Observable<IProgram[]> {
		const url = `${this._config.javaApiUrl}/details/programs/guid/${guid}`;

		return this._http.get<IProgram[]>(url);
	}

	public getArchiveTechprocesses(
		param: IPostTechprocess,
	): Observable<ITechProcessData[]> {
		const url = `${this._config.javaApiUrl}/details/techprocess/archive`;

		return this._http.post<ITechProcessData[]>(url, param);
	}

	public getCurrentTechProcess(id: number): Observable<ITechProcessData[]> {
		const url = `${this._config.javaApiUrl}/details/techprocess/current/${id}`;

		return this._http.get<ITechProcessData[]>(url);
	}

	public getMnemo(mnemoId: number): Observable<IMnemoScheme> {
		const url = `${this._config.javaApiUrl}/core/unit/mnemo/${mnemoId}`;

		return this._http.get<IMnemoScheme>(url);
	}

	public saveMnemoSh(param: ISaveMnemoRequest): Observable<string | {
		[key: string]: string
	}> {
		const url = `${this._config.javaApiUrl}/admin/mnemoshemes/save`;

		return this._http.post<string | {
			[key: string]: string
		}>(url, param);
	}

	public getMnemoSh(mnemoId: number): Observable<IMnemoShemesItem> {
		const url = `${this._config.javaApiUrl}/core/unit/mnemosheme/${mnemoId} `;

		return this._http.get<IMnemoShemesItem>(url);
	}

	// Добавляем в избранное юнит
	public addOrDeleteUnitToUserFavorites(unitId: number): Observable<{
		[key: string]: number
	}> {
		const url = `${this._config.javaApiUrl}/core/favorites/${unitId} `;

		return this._http.post<{ [key: string]: number }>(url, null);
	}

	public getAlarmReport(unitId: number, data: IUnitAlarmsRequest): Observable<any> {
		const url = `${this._config.javaApiUrl}/report/unit/${unitId}/alarm`;

		return this._http.post<any>(url, data);
	}

	public getProgramTextComparision(id: number) {
		const url: string = `${this._config.javaApiUrl}/sdweb/plan/get/program_text`;
		return this._http.post<IProgramText>(url, { 'value': [id] });
	}

	public getProgramEtalon(id: number) {
		const url: string = `${this._config.javaApiUrl}/sdweb/plan/get/dse_program_tp_operation_etalon`;
		return this._http.post<IProgramEtalon[]>(url, { 'value': [id] });
	}
}
