import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { SDService } from '../../modules/sd/models/sd-service.interface';
import { ApiRepository, IDictionary } from '@shared/repositories/api.repository';
import { forkJoin, Observable, Subject } from 'rxjs';
import { apiState } from '@shared/components/sd/models/apiState';
import { tap } from 'rxjs/operators';

export interface ITaskMetadataState {
	services: SDService[];
	priorities: IDictionary[];
}

export const TaskMetadataState: ITaskMetadataState = {
	services: [],
	priorities: [],
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'taskMetadata' })
export class TaskMetadataStore extends Store<ITaskMetadataState> {
	constructor(
		private _apiRepository: ApiRepository,
	) {
		super(TaskMetadataState);
	}

	init(): Observable<ITaskMetadataState> {
		const services$ = this._apiRepository.getServices(`${apiState.SDGateway}/${apiState.ServiceApi}`);
		const priorities$ = this._apiRepository.getDictionaryByShortName('priority');

		return forkJoin({
			services: services$,
			priorities: priorities$,
		}).pipe(
			tap((response) => {
				this.update({
					...response
				})
			})
		)
	}
}
