import { Injectable } from '@angular/core';
import { getErrorMessage } from '@shared/utils';
import { InfoRepository } from '@shared/repositories/info.repository';
import { take } from 'rxjs/operators';
import { IUnitFull } from '../../modules/monitoring/modules/unit/models/unit';
import { NotifierService } from 'angular-notifier';
import { UnitsReducer } from '@store/units-store/units.reducer';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UnitsExecutor {
	constructor(
		private _infoService: InfoRepository,
		private _unitsReducer: UnitsReducer,
		private _notifierService: NotifierService,
	) {
	}

	saveOrderNumber(unit: IUnitFull, orderNumber: string): Observable<void> {
		const sub$: Subject<void> = new Subject();
		this._infoService
			.saveOrderNumber(unit.id, orderNumber)
			.pipe(take(1))
			.subscribe({
				next: () => {
					unit.indexNumber = orderNumber;
					this._unitsReducer.updateUnit(unit);
					this._notifierService.notify('success', 'Порядковый номер успешно обновлен');
					sub$.next();
				},
				error: err => {
					this._notifierService.notify('error', getErrorMessage(err));
					sub$.error(err);
				},
			});

		return sub$.asObservable();
	}
}
