import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgxSelectModule} from 'ngx-select-ex';
import {FormsModule} from '@angular/forms';

import {QrScannerComponent} from '@shared/components/qr-scanner/qr-scanner.component';

@NgModule({
	declarations: [QrScannerComponent],
	imports: [
		CommonModule,
		NgxSelectModule,
		FormsModule
	],
	exports: [QrScannerComponent]
})
export class QrScannerModule {}
