import {Injectable} from '@angular/core';
import {NotifierService} from 'angular-notifier';

import {IDataServiceInterface} from '@shared/components/dto-viewer/models/data-service-interface';
import {MesCounterparty} from '@store/data/plan/mes-counterparty';
import {DataConfig, DataConfigType} from '@shared/components/dto-viewer/models/data-config';
import {DtoService} from '@shared/components/dto-viewer/services/dto-service';
import {SdwebRepository} from '@shared/repositories/sdweb.repository';
import {EntityConstantEnums} from '@shared/constants/entity-constant.enums';
import {MesNomenclature} from '@store/data/plan/mes-nomenclature';
import {ApiRepository} from '@shared/repositories/api.repository';
import {NgxUiLoaderService} from 'ngx-ui-loader';

@Injectable({
	providedIn: 'root'
})
export class NomenclatureService implements IDataServiceInterface {
	activeItem: MesNomenclature;
	itemList: MesNomenclature[] = [];
	itemMap: Map<number, MesNomenclature> = new Map<number, MesNomenclature>();

	activeDataConf: DataConfig[] = [];
	tableDataConf: DataConfig[] = [];

	dictionaryMap: Map<string, Map<number, any>> =
		new Map<string, Map<number, any>>();
	dictionaryList: Map<string, any[]> = new Map<string, any[]>();

	dtoTransfer: DtoService;

	constructor(
		private _mesApi: SdwebRepository,
		private _notifierService: NotifierService,
		private _loaderService: NgxUiLoaderService,
		private _apiService: ApiRepository
	) {
		this.dtoTransfer = new DtoService(
			this, EntityConstantEnums.nomenclature, _notifierService, _loaderService, _mesApi
		);

		this.asyncUpdate();

		this.activeDataConf.push(
			new DataConfig('id', 'id', DataConfigType.string, true)
		);
		this.activeDataConf.push(
			new DataConfig('name', 'Название', DataConfigType.string)
		);
		this.activeDataConf.push(
			new DataConfig('partnumber', 'Номер', DataConfigType.string)
		);
		this.activeDataConf.push(
			new DataConfig('comment', 'Описание', DataConfigType.textarea)
		);
		this.activeDataConf.push(
			new DataConfig(
				'taskTypeId', 'Привязка к типу задачи', DataConfigType.selector
			)
		);

		this.tableDataConf.push(
			new DataConfig('partnumber', 'Номер', DataConfigType.string)
		);
		this.tableDataConf.push(
			new DataConfig('name', 'Название', DataConfigType.string)
		);
		this.tableDataConf.push(
			new DataConfig('comment', 'Описание', DataConfigType.string)
		);

	}

	public asyncCheck(): void {
		this.dtoTransfer.asyncCheck();
	}

	public asyncUpdate(): void {
		this.dtoTransfer.asyncUpdate();
	}

	public saveActiveItem(data: MesCounterparty): void {
		this.dtoTransfer.save([data]);
	}

	public deleteActiveItem(ids: number[]): void {
		this.dtoTransfer.delete(ids);
	}

}
