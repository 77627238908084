<div class="modal g-overflow-visible">
	<div class="modal__header">
		<div class="title">Сравнение программ</div>

		<app-button
			svgIcon="close"
			class="g-button-secondary-empty btn-close"
			(click)="closeModal()"
		></app-button>
	</div>

	<div
		class="modal__content full-height"
	>
		<app-program-compare
			[unitId]="data.unitId"
			[program]="data.program"
		></app-program-compare>
	</div>
</div>
