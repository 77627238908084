import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {SidebarType} from '../models/sidebar.types';


@Injectable({providedIn: 'root'})
export class SidebarService {
	public isSidebarShown: BehaviorSubject<boolean>;
	public typeSidebar: BehaviorSubject<SidebarType>;

	constructor() {
		this.isSidebarShown = new BehaviorSubject(false);
		this.typeSidebar = new BehaviorSubject('default');
	}
}
