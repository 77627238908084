import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { DateTimePickerModule } from '@shared/ui-components/date-time-picker/date-time-picker.module';
import { ButtonComponent } from '@shared/ui-components/button/button.component';
import { IdleCreatorComponent } from '@shared/components/idle-creator/idle-creator.component';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';

@NgModule({
	declarations: [IdleCreatorComponent],
	imports: [
		CommonModule,
		FormsModule,
		BsDatepickerModule,
		DateTimePickerModule,
		ButtonComponent,
		MatSelectModule,
		MatInputModule,
	],
	exports: [IdleCreatorComponent],
})
export class IdleCreatorModule {
}
