<div style="max-width: 100%">
	<app-table
		[data]="subscriptionBodies"
		style="height: 45vh;"
	>
		<ng-container *viewItem>
			<app-table-header>
				Наименование подписки
			</app-table-header>
			<app-table-item *lineValue="let item">
				{{ item.subscriptionTypeTitle }}
			</app-table-item>
		</ng-container>
		<ng-container *viewItem>
			<app-table-header>
				PUSH
			</app-table-header>
			<app-table-item *lineValue="let item">
				<div class="g-center-h">
					<app-checkbox
						[(checked)]="item.push"
						(click)="clickCheckBoxPush(item)"
					></app-checkbox>
				</div>
			</app-table-item>
		</ng-container>
		<ng-container *viewItem>
			<app-table-header>
				EMAIL
			</app-table-header>
			<app-table-item *lineValue="let item">
				<div class="g-center-h">
					<app-checkbox
						[(checked)]="item.email"
						(click)="clickCheckBoxEmail(item)"
					></app-checkbox>
				</div>
			</app-table-item>
		</ng-container>
	</app-table>
	<div class="flex g-justify-content-center g-al-items-center">
		<app-button
			class="g-button-success-square mx-auto mt-16"
			style="width: 200px"
			[disabled]="!saveBtnAvailable"
			(click)="save()"
		>Сохранить
		</app-button>
	</div>
</div>

