import {
	trigger,
	state,
	style,
	animate,
	transition
} from '@angular/animations';

export const animatePanel = trigger('animatePanel', [
	state('close', style({
			top: '-350px'
	})),
	state('open',	style({
			top: '1px'
	})),
	transition('open => close', [
		animate('0.2s')
	]),
	transition('close => open', [
		animate('0.5s ease-out')
	]),
]);
