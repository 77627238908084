@if (!showNetwork) {
	<div
		class="card"
		#content
	>
		<div class="flex">
			<div>
				<div>
					<label>Наименование:</label>
					{{ unitService.unit.unitType.name }}
				</div>
				<div [matTooltip]="unitService.unit.unitType.description">
					<label>Описание: </label>
					{{ (unitService.unit.unitType.description.length > 51) ? (unitService.unit.unitType.description | slice:0:51) + '...' : (unitService.unit.unitType.description) }}
				</div>
				<div>
					<label>Инвентарный номер: </label>
					{{ unitService.unit.inventoryNumber }}
				</div>
				<div>
					<label>Серийный номер: </label>
					{{ unitService.unit.factoryNumber }}
				</div>
				<div>
					<label>Расположение: </label>
					{{ unitService.unit.location.name }}
				</div>
				<div>
					<label>Порядковый номер: </label>

					@if (isEdit) {
						<input
							style="height: 20px"
							class="form-control"
							maxlength="4"
							[(ngModel)]="orderNumberTMP"
							(ngModelChange)="changeDetected()"
						/>
						<app-button
							*ngIf="isOrderNumberChanged"
							class="g-button-success-square button-icon font-size-12 ml-4 inline-flex"
							svgIcon="save"
							(click)="saveOrderNumber()"
						>
						</app-button>

						<app-button
							*ngIf="isOrderNumberChanged"
							class="g-button-danger-square button-icon font-size-12 ml-4 inline-flex"
							svgIcon="close"
							(click)="canselChangeOrderNumber()"
						>
						</app-button>
					} @else {
						<span>{{ orderNumber }}</span>
					}
				</div>
			</div>
			<div class="flex mb-auto ml-auto">
				<app-button
					class="g-button-secondary-square font-size-12"
					svgIcon="edit"
					matTooltip="Редактировать порядковый номер"
					(click)="isEdit = !isEdit"
				></app-button>
				<app-button
					class="g-button-secondary-square font-size-12 ml-8"
					svgIcon="copy"
					matTooltip="Скопировать данные"
					(click)="copyText()"
				></app-button>
			</div>
		</div>
	</div>
} @else {
	<div
		class="flex-col"
		#internetSettings
	>
		@for (item of unitService.unit.devices; track item; let i = $index) {
			<div
				class="card"
				[id]="i"
			>
				<div class="flex">
					<div>
						@if (item.name) {
							<div>
								<label>Наименование:</label>
								{{ item.name }}
							</div>
						} @else {
							<div>
								<label>Наименование:</label> -
							</div>
						}

						@if (item.ethernetConnection) {
							<div>
								<label>IP адрес: </label>
								<span>{{ item.ethernetConnection.ipAddress }}</span>
							</div>
						} @else {
							<div>
								<label>IP адрес: </label>
								<span> - </span>
							</div>
						}

						@if (item.ethernetConnection) {
							<div>
								<label>Порт: </label>
								<span>{{ item.ethernetConnection.port }}</span>
							</div>
						} @else {
							<label>Порт:</label>
							<span> - </span>
						}

						@if (item.macAddress) {
							<div>
								<label>Mac адрес:</label>
								{{ item.macAddress }}
							</div>
						} @else {
							<div>
								<label>Mac адрес:</label>
								<span>-</span>
							</div>
						}

						<!--						@if (item.deviceAddress) {-->
						<!--							<div>-->
						<!--								<label>Адрес устройства:</label>-->
						<!--								{{ item.deviceAddress }}-->
						<!--							</div>-->
						<!--						} @else {-->
						<!--							<div>-->
						<!--								<label>Адрес устройства:</label>-->
						<!--								<span>-</span>-->
						<!--							</div>-->
						<!--						}-->
					</div>
					<app-button
						class="g-button-secondary-square font-size-12 ml-auto"
						svgIcon="copy"
						matTooltip="Скопировать данные"
						(click)="copyInternetSettings()"
					></app-button>
				</div>
			</div>
		}
	</div>
}





