import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DynamicBarComponent} from '@shared/components/charts/modules/dynamic-bar/dynamic-bar.component';

@NgModule({
	declarations: [DynamicBarComponent],
	imports: [
		CommonModule
	],
	exports: [DynamicBarComponent],
})
export class DynamicBarModule {
}
