import {ElementRef, EmbeddedViewRef, Inject, Injectable,} from '@angular/core';
import {TooltipService} from '@shared/directives/tooltip/tooltip.service';
import {DOCUMENT} from '@angular/common';
import {fromEvent} from 'rxjs';

@Injectable({providedIn: 'root'})
export class TemplateTooltipService
	extends TooltipService<EmbeddedViewRef<Object>> {

	private _tooltipView: EmbeddedViewRef<Object>;

	constructor(
		@Inject(DOCUMENT) private _document: Document
	) {
		super();
	}

	public create(
		{data: embeddedTooltip, container}: {
			data: EmbeddedViewRef<Object>,
			container: ElementRef<Object>
		}
	): void {
		if (this._tooltipView) {
			return;
		}

		const tooltipNode = embeddedTooltip.rootNodes[0];
		tooltipNode.style.position = 'fixed';

		// @ts-ignore
		this._mouseleave$ = fromEvent<MouseEvent>(container, 'mouseleave');
		// @ts-ignore
		this._containerLocation = container.getBoundingClientRect();
		this._tooltipView = embeddedTooltip;

		this.setLocation(tooltipNode, tooltipNode.clientWidth);
		this.getCloseEvent(tooltipNode).subscribe(() => this.close());
	}

	public isHave(): boolean {
		return !!this._tooltipView;
	}

	public close(): void {
		this._tooltipView?.destroy();

		this._tooltipView = undefined;
	}
}
