import {
	AfterViewChecked,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ContentChild,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	Output,
	ViewChild,
} from '@angular/core';
import {ItemDirective, SelectedItemDirective} from './directives/carousel.directive';
import {getRandomId, nextTick, sleep} from '../../utils';
import {scaleAnimation} from '../../animations/common.animations';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {fromEvent} from 'rxjs';

@UntilDestroy()
@Component({
	selector: 'app-carousel',
	templateUrl: './carousel.component.html',
	styleUrls: ['./carousel.component.scss'],
	animations: [scaleAnimation],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent implements AfterViewChecked {
	@Input() data: Array<any> = [];
	@Input() isClickable = false;
	@Input() isWidthMax = false;
	@Output() select = new EventEmitter<{ index: number; item: any }>();

	@ContentChild(ItemDirective) item: ItemDirective;
	@ContentChild(SelectedItemDirective) selectedItem?: SelectedItemDirective;

	@ViewChild('leftButtonTemplate', {static: true}) leftButton: ElementRef;
	@ViewChild('rightButtonTemplate', {static: true}) rightButton: ElementRef;

	public idCarousel: string;
	public carousel: {
		list: HTMLElement;
		content: HTMLElement;
		elements: NodeListOf<HTMLElement>;
		firstElement: HTMLElement;
		offsetElements: number[];
		rightButton: HTMLElement;
		leftButton: HTMLElement;
	};
	public indexCurrentElement: number;
	public isLongClick = false;

	private _oldData: Array<any>;
	private _currentMarginLeft: number;

	constructor(private changeDetector: ChangeDetectorRef) {
		this.idCarousel = getRandomId('carousel');
	}

	get visibilityLeftArrow(): boolean {
		return this.indexCurrentElement > 0;
	}

	get visibilityRightArrow(): boolean {
		return this.indexCurrentElement < this.data?.length - 1;
	}

	public ngAfterViewChecked(): void {
		if (this._oldData !== this.data) {
			this._oldData = this.data;
			this.initialize().then(() => {
			});
		}
	}

	@HostListener('window:resize')
	public changeSizeWindow(): void {
		this.carousel = this.getCarouselElements();

		this.moveTo(this.indexCurrentElement);
	}

	@HostListener('wheel', ['$event'])
	public onMousewheel({deltaY}: WheelEvent): void {
		if (deltaY > 0) {
			this.elemNext();
		} else {
			this.elemPrev();
		}
	}

	private getCarouselElements(): {
		list: HTMLElement;
		content: HTMLElement;
		elements: NodeListOf<HTMLElement>;
		firstElement: HTMLElement;
		offsetElements: number[];
		rightButton: HTMLElement;
		leftButton: HTMLElement;
	} {
		const crslRoot = document.getElementById(this.idCarousel);

		if (!crslRoot) {
			return null;
		}

		const list = <HTMLElement>crslRoot.querySelector('.carousel__list');
		const content = <HTMLElement>(
			crslRoot.querySelector('.carousel__content')
		);
		const elements = <NodeListOf<HTMLElement>>(
			list.querySelectorAll('.carousel__element')
		);
		const firstElement = <HTMLElement>(
			list.querySelector('.carousel__element')
		);
		const rightButton = <HTMLElement>(
			crslRoot.querySelector('.carousel__button_side_right')
		);
		const leftButton = <HTMLElement>(
			crslRoot.querySelector('.carousel__button_side_left')
		);

		const offsetElements = Array.from(elements).map(i => i.offsetLeft);

		return {
			list,
			elements,
			content,
			firstElement,
			offsetElements,
			rightButton,
			leftButton,
		};
	}

	private async initialize(): Promise<void> {
		this.carousel = this.getCarouselElements();

		if (!this.carousel) {
			return;
		}

		this.initLongClick(
			this.carousel.leftButton,
			() => this.visibilityLeftArrow,
			s => this.elemPrev(s)
		);

		this.initLongClick(
			this.carousel.rightButton,
			() => this.visibilityRightArrow,
			s => this.elemNext(s)
		);

		this.indexCurrentElement = 0;

		this.setListAnimation(0, 'linear');
		this.moveTo(this.indexCurrentElement);
		await nextTick();
		this.setListAnimation(300, 'ease-out');

		this.changeDetector.markForCheck();
	}

	private setListAnimation(duration: number, animation?: string): void {
		this.carousel.list.style.setProperty(
			'transition',
			`transform ${duration}ms ${animation}`,
			'important'
		);
	}

	private initLongClick(
		element: HTMLElement,
		conditionRun: () => boolean,
		next: (step: number) => void
	): void {
		let durationAnimationMove;
		let step;

		const stopLongClick = async () => {
			this.isLongClick = false;

			this.changeDetector.detectChanges();

			await sleep(durationAnimationMove);

			this.setListAnimation(300, 'ease-in-out');
		};

		fromEvent(element, 'mouseup')
			.pipe(untilDestroyed(this))
			.subscribe(stopLongClick);

		fromEvent(element, 'mousedown')
			.pipe(untilDestroyed(this))
			.subscribe(async () => {
				this.isLongClick = true;

				durationAnimationMove = 300;
				step = 1.5;

				await sleep(200);

				if (!this.isLongClick) {
					return;
				}

				this.setListAnimation(durationAnimationMove, 'linear');

				while (this.isLongClick) {
					conditionRun() ? next(Math.floor(step)) : stopLongClick();

					await sleep(durationAnimationMove);

					durationAnimationMove -= 30;
					step = step > 3 ? 3 : step + 0.1;
				}
			});
	}

	public moveByClick(index: number): void {
		if (!this.isClickable) {
			return;
		}

		this.indexCurrentElement = index;

		this.moveTo(index);
	}

	public clickArrow(side: 'left' | 'right'): void {
		side === 'left' ? this.elemPrev() : this.elemNext();
	}

	private elemPrev(step = 1): void {
		this.indexCurrentElement -= step;

		if (this.indexCurrentElement < 0) {
			this.indexCurrentElement = 0;
		}

		this.moveTo(this.indexCurrentElement);
	}

	private elemNext(step = 1): void {
		this.indexCurrentElement += step;

		if (this.indexCurrentElement >= this.carousel.elements.length) {
			this.indexCurrentElement = this.carousel.elements.length - 1;
		}

		this.moveTo(this.indexCurrentElement);
	}

	private moveTo(index: number): void {
		let offsetLeft = this.carousel.offsetElements[index];

		offsetLeft -= this.carousel.content.offsetWidth / 2;
		offsetLeft += this.carousel.elements[index].offsetWidth / 2;

		this.carousel.list.style.setProperty(
			'transform',
			`translateX(${-1 * offsetLeft}px)`,
			'important'
		);

		this._currentMarginLeft = -1 * offsetLeft;

		this.changeDetector.markForCheck();

		this.select.emit({index, item: this.data[index]});
	}
}
