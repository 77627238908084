import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {StopPropagationModule} from '@shared/directives/stop-propagation/stop-propagation.module';
import {MatIconModule} from '@angular/material/icon';
import {TooltipModule} from '@shared/directives/tooltip/tooltip.module';
import {CheckboxComponent} from '@shared/ui-components/checkbox/checkbox.component';
import {SelectorLettersModule} from '@shared/pipes/selector-letters/selector-letters.module';
import {SearchModule} from '@shared/ui-components/search/search.module';
import {TreeComponent} from '@shared/ui-components/tree/tree.component';
import {CommonModule} from '@angular/common';
import {TreeNodesComponent} from '@shared/ui-components/tree/components/tree-nodes/tree-nodes.component';
import {TreeNodeComponent} from '@shared/ui-components/tree/components/tree-node/tree-node.components';
import {SelectorLettersComponent} from '@shared/ui-components/selector-letters/selector-letters.component';

@NgModule({
	declarations: [
		TreeComponent,
		TreeNodesComponent,
		TreeNodeComponent,
	],
	exports: [TreeComponent],
	imports: [
		CommonModule,
		SearchModule,
		FormsModule,
		StopPropagationModule,
		MatIconModule,
		TooltipModule,
		CheckboxComponent,
		SelectorLettersModule,
		SelectorLettersComponent
	]
})
export class TreeModule {
}
