import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {
	MultiFormatReader,
	BarcodeFormat,
	DecodeHintType,
	RGBLuminanceSource,
	BinaryBitmap,
	HybridBinarizer
} from '@zxing/library';


@Component({
	selector: 'app-qr-scanner',
	templateUrl: './qr-scanner.component.html',
	styleUrls: ['./qr-scanner.component.scss']
})
export class QrScannerComponent implements OnInit {
	@Output() onResult = new EventEmitter<any>();

	availableDevices: MediaDeviceInfo[];
	deviceCurrent: MediaDeviceInfo;
	deviceSelected: string;

	hasPermission = false;
	video: any;

	restart = false;

	hints = new Map();
	formats = [
		BarcodeFormat.QR_CODE,
		BarcodeFormat.DATA_MATRIX,
		BarcodeFormat.CODE_128
	];
	reader = new MultiFormatReader();

	ngOnInit() {
		this.video = document.querySelector('#js-video');

		this.hints.set(DecodeHintType.POSSIBLE_FORMATS, this.formats);
		this.hints.set(DecodeHintType.TRY_HARDER, true);

		this.reader.setHints(this.hints);

		this.jsQRCameraInit();
	}

	private jsQRCameraInit() {
		if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
			console.log('enumerateDevices() не поддерживается.');
			this.hasPermission = false;

			return;
		}

		navigator.mediaDevices.enumerateDevices()
			.then((devices) => {
				this.availableDevices = devices;

				devices.forEach(device => {
					if (device.kind === 'videoinput') {
						this.deviceCurrent = device;
						this.deviceSelected = this.deviceCurrent.deviceId;
					}
				});
			})
			.catch(err => {
				console.log(err.name + ': ' + err.message);
				this.hasPermission = false;
			});

		this.jsQRStartCamera();
	}

	private jsQRStartCamera() {
		this.restart = false;

		if (this.deviceCurrent) {
			navigator.mediaDevices
				.getUserMedia({
					audio: false,
					video: {
						deviceId: this.deviceCurrent.deviceId,
					},
				})
				.then((stream) => {
					this.video.srcObject = stream;
					this.video.onloadedmetadata = () => {
						this.hasPermission = true;
						this.video.play();
						this.jsQRCutImageFromVideo();
					};
				})
				.catch((err) => {
					setTimeout(() => this.jsQRStartCamera(), 1000);
				});
		} else {
			setTimeout(() => this.jsQRStartCamera(), 1000);
		}
	}

	private jsQRCutImageFromVideo() {
		try {
			let canvas;
			canvas = document.querySelector('#js-canvas');

			if (this.restart) {
				this.ngOnDestroy();
				this.jsQRStartCamera();
				return;
			}

			if (canvas) {
				const ctx = canvas.getContext('2d');

				const width = canvas.width;
				const height = canvas.height;

				ctx.drawImage(this.video, 0, 0, width, height);
				const imageData = ctx.getImageData(0, 0, width, height);
				const len = imageData.width * imageData.height;
				const luminancesUint8Array = new Uint8ClampedArray(len);

				for (let i = 0; i < len; i++) {
					luminancesUint8Array[i] = (
						(
							imageData.data[i * 4] + imageData.data[i * 4 + 1] * 2 +
							imageData.data[i * 4 + 2]
						) / 4
					) & 0xFF;
				}

				const luminanceSource = new RGBLuminanceSource(
					luminancesUint8Array, width, height
				);
				const binaryBitmap = new BinaryBitmap(new HybridBinarizer(luminanceSource));

				const code = this.reader.decode(binaryBitmap, this.hints);

				if (code) {
					this.onResult.emit(code.getText());

					setTimeout(() => this.jsQRCutImageFromVideo(), 3000);
				} else if (this.restart) {
					this.jsQRStartCamera();
				} else {
					setTimeout(() => this.jsQRCutImageFromVideo(), 50);
				}
			} else {
				this.ngOnDestroy();
			}
		} catch (err) {
			setTimeout(() => this.jsQRCutImageFromVideo(), 50);
		}
	}

	// tslint:disable-next-line:use-life-cycle-interface
	ngOnDestroy() {
		if (!!this.video?.srcObject) {
			this.video.srcObject.getTracks().forEach(function (track) {
				track.stop();
			});
		}
	}

	onDeviceSelectChange(selected: string) {
		const device = this.availableDevices.find(
			x => x.deviceId === this.deviceSelected
		);

		this.deviceCurrent = device || undefined;
		this.restart = true;
	}
}
