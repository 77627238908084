@if (rangePosition === 'left') {
	<ng-container [ngTemplateOutlet]="pagRange"></ng-container>
}

<div class="flex-row pag-btns">
	<button
		class="pag-btn mr-8"
		(click)="back()"
	>
		<mat-icon svgIcon="arrow-left"></mat-icon>
	</button>

	@for (num of view; track num) {
		@if (num.isView) {
			<button
				class="pag-btn"
				[class.active]="this.currentPageTmp === num.value"
				[class.disabled]="null === num.value"
				(click)="setPage(num.value)"
			>
				{{ num.value ? num.value : '...' }}
			</button>
		}
	}

	<button
		class="pag-btn ml-8"
		(click)="next()"
	>
		<mat-icon svgIcon="arrow-right"></mat-icon>
	</button>
</div>

@if (rangePosition === 'right') {
	<ng-container [ngTemplateOutlet]="pagRange"></ng-container>
}

<ng-template #pagRange>
	<div class="flex-row text-light pag-range">
		<div class="mr-8">{{ currentPageTmp }}</div>
		<div>страница из</div>
		<div class="ml-8">{{ size }}</div>
	</div>
</ng-template>
