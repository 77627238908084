import {Component, Inject} from '@angular/core';
import {DIALOG_DATA, ModalRef} from '@shared/services/modals.service';
import {IDialogConfirmationData} from '@shared/modals/dialog-confirmation-modal/models/dialog-confirmation.types';

@Component({
	selector: 'app-dialog-confirmation',
	templateUrl: 'dialog-confirmation-modal.component.html',
	styleUrls: ['dialog-confirmation-modal.component.scss']
})
export class DialogConfirmationModalComponent {
	constructor(
		public dialogRef: ModalRef<DialogConfirmationModalComponent>,
		@Inject(DIALOG_DATA) public data: IDialogConfirmationData,
	) {
	}

	public confirm(): void {
		this.dialogRef.close(true);
	}

	public cancel(): void {
		this.dialogRef.close(false);
	}
}
