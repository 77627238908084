<div
	appClickOnOtherElements
	[class.expand_absolute]="isAbsolute"
	[useClickOnOtherElements]="isAutoClose"
	[useLeaveOnOtherElements]="isAutoClose"
	(clickOnOtherElements)="closeExpand()"
>
	<div
		class="row select_setting g-overflow-hidden box-shadow-1"
	>
		<div
			class="g-margin-h g-pointer title-filter"
			appExpandRow
			[(isOpen)]="isOpen"
			(isOpenChange)="isOpenChange.emit($event)"
		>
			<label class="g-unselectable">
				<ng-content select="[label]"></ng-content>
			</label>
		</div>

		<div class="expand g-unselectable">
			@if (template) {
				@if (isOpen) {
					<div
						class="g-overflow-hidden"
						@untwistingAnimation
					>
						<ng-container
							*ngTemplateOutlet="template"
						></ng-container>
					</div>
				}
			} @else {
				<ng-content select="[content]"></ng-content>
			}
		</div>
	</div>
</div>
