import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function MailValidator(requiredAddress?: string[]): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const addresses =
			!!requiredAddress?.length
				? requiredAddress : ['@kalashnikovconcern.ru', '@mail.ru'];

		let valid = false;

		if (typeof control.value === 'string' && control.value.length > 0) {
			for (let i = 0; i < addresses.length && !valid; i++) {
				valid = (control.value as string).includes(addresses[i]);
			}
		} else {
			valid = true;
		}

		return !valid ? { notValid: { value: !valid } } : null;
	};
}

export function NumberValidator(maxValue: number, minValue: number, isRewrite: boolean = false, maxlength: number | null = null): ValidatorFn {
	const checkValid = (control: AbstractControl, value: string) => {
		if (isRewrite) {
			control.setValue(value);
			return null;
		} else {
			return { notNumValid: { value: true } };
		}
	};

	return (control: AbstractControl): ValidationErrors | null => {
		let valid = true;

		if (typeof control.value === 'string' && control.value.length > 0) {
			const num = Number(control.value);

			if (Number.isNaN(num)) {
				const value = control.value.replace(/\D/g,'');

				return checkValid(control, value);
			}

			const moreMaxValue = control.value.length > maxValue.toString().length;

			if (maxlength !== null && moreMaxValue) {
				return checkValid(control, control.value.substring(0, maxlength));
			} else if (moreMaxValue) {
				return checkValid(control, maxValue.toString());
			}

			valid = num <= maxValue;

			if (!valid && isRewrite) {
				control.setValue(maxValue);
				return null;
			}

			valid = valid && num >= minValue;

			if (!valid && isRewrite) {
				control.setValue(minValue);
				return null;
			}
		}

		return !valid ? { notNumValid: { value: !valid } } : null;
	};
}
