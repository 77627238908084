<div class="modal">
	<div class="modal__header">
		<div class="title">Выбор станков</div>

		<app-button
			svgIcon="close"
			class="g-button-secondary-empty btn-close"
			(click)="dialogRef.close()"
		></app-button>
	</div>

	<div class="modal__content g-column-container">
		<app-units-selector [isFavoriteMode]="data.isFavoriteMode"></app-units-selector>
	</div>
</div>
