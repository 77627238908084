import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, firstValueFrom, Observable} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import {UnitsStore} from '@store/units-store/units.store';
import {UnitsMetadataStore} from '@store/units-metadata-store/units-metadata.store';
import {ShiftStore} from '@store/shift-store/shift.store';
import {TaskMetadataStore} from '@store/task-metadata-store/task-metadata.store';
import {AccessStore} from '@store/accesses-store/access.store';
import {LocationStore} from '@store/location-store/location.store';
import {UsersStore} from '@store/user-store/users.store';
import {BrigadesStore} from '@store/brigades-store/brigades.store';
import {SettingsStore} from '@store/settings-store/settings.store';

@UntilDestroy()
@Injectable({providedIn: 'root'})
export class StoreService {
	public isStoreLoaded: BehaviorSubject<boolean>;
	private _loading: BehaviorSubject<boolean>;

	constructor(
		private _unitsStore: UnitsStore,
		private _unitsMetadataStore: UnitsMetadataStore,
		private _taskMetadataStore: TaskMetadataStore,
		private _shiftStore: ShiftStore,
		private _accessStore: AccessStore,
		private _locationStore: LocationStore,
		private _usersStore: UsersStore,
		private _brigadesStore: BrigadesStore,
		private _settingsStore: SettingsStore,
	) {
		this.initFromConfig();
		this.initObservables();
	}

	public getLoading(): Observable<boolean> {
		return this._loading.asObservable();
	}

	private initFromConfig(): void {
	}

	private initObservables(): void {
		this.isStoreLoaded = new BehaviorSubject(false);
		this._loading = new BehaviorSubject(false);
	}

	private async initAccessStore() {
		const accessState$ = this._accessStore.init();

		await firstValueFrom(accessState$);
	}

	private async initStores(): Promise<any> {
		const unitsMetadataState$ = this._unitsMetadataStore.init();
		const taskMetadataState$ = this._taskMetadataStore.init();
		const shiftState$ = this._shiftStore.init();
		const unitsState$ = this._unitsStore.init(this._accessStore.getValue().permissions);
		const locationState$ = this._locationStore.init(this._accessStore.getValue().permissions);
		const usersState$ = this._usersStore.init();
		const brigadesState$ = this._brigadesStore.init();
		const settingsState$ = this._settingsStore.init();

		return firstValueFrom(combineLatest(
			unitsMetadataState$,
			taskMetadataState$,
			shiftState$,
			unitsState$,
			locationState$,
			usersState$,
			brigadesState$,
			settingsState$
		));
	}

	public async initStore(): Promise<void> {
		this._loading.next(true);

		this.initFromConfig();
		await this.initAccessStore();
		await this.initStores();

		this.isStoreLoaded.next(true);
		this._loading.next(false);
	}
}
