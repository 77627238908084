import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

import {SdwebRepository} from '@shared/repositories/sdweb.repository';
import {ApiRepository} from '@shared/repositories/api.repository';
import {NotifierService} from 'angular-notifier';
import {ConfigService} from '@shared/services/config.service';
import {NomenclatureService} from '../../../modules/plan/services/nomenclature.service';
import {MesNomenclature} from '@store/data/plan/mes-nomenclature';
import {IUnitFull} from '../../../modules/monitoring/modules/unit/models/unit';
import {Tool} from '@shared/components/tool-item/models/tool-item.classes';
import {ICurrentUser} from '../../../modules/auth/models/auth.types';
import {UnitsSelector} from '@store/units-store/units.selector';


@UntilDestroy()
@Component({
	selector: 'app-tool-item',
	templateUrl: './tool-item.component.html',
	styleUrls: ['./tool-item.component.scss']
})
export class ToolItemComponent implements OnChanges {

	@Input() toolId: number;

	@Input() toolTypeList: MesNomenclature[];
	@Input() newTool: Tool;
	@Input() currentUser: ICurrentUser;

	@Input() toolControll = true;

	@Output() refreshTool = new EventEmitter<Tool>();
	@Output() deleteTool = new EventEmitter<number>();

	public selectedIndexTab: number;

	sourceToolTypeId = 0;
	host: string;
	qrInfo;
	titleInfo = [];
	readyToDelete = false;
	readyToWriteOff = false;

	favoritesUnits = [];
	lastUnit: IUnitFull;

	itemUsing = new Map<number, {
		currentUsing: number,
		maxUsing: number,
		percent: number,
		type: string
	}>();

	constructor(
		private _mesApi: SdwebRepository,
		private _notifierService: NotifierService,
		private _config: ConfigService,
		private _cookieService: CookieService,
		private _unitsSelector: UnitsSelector,
		private _router: Router,
		private _apiService: ApiRepository,
		private _nomenclatureService: NomenclatureService
	) {
	}

	ngOnChanges() {
		this.host = this._config.getConfig(['javaApiUrl']).javaApiUrl;
		this.toolTypeList = this._nomenclatureService.itemList;

		if (this.toolId) {
			this._mesApi.getToolsByIds([this.toolId])
				.pipe(untilDestroyed(this))
				.subscribe(res => {
					if (res) {
						this.newTool = res[0];
						this.initTool();
					}
				});

			this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
		}

		this.initTool();
		this.getUnits();
	}

	// Получаем последний открытый юнит и список избранных юнитов
	private getUnits() {
		let checkedToolId = null;

		if (this._cookieService.get('unitId')) {
			// tslint:disable-next-line:radix
			checkedToolId = parseInt(this._cookieService.get('unitId'));
		}

		this.lastUnit = this._unitsSelector.getUnitById(checkedToolId);
	}

	private setToolUsing(toolId: number, maxUsing: number) {
		this._mesApi.getToolsUsage([toolId])
			.pipe(untilDestroyed(this))
			// Todo прооблема с подпиской
			.subscribe(res => {
				const currentUsing = !res[toolId]
					? 0
					: Math.round((res[toolId] / (60 * 1000 * 60)) * 100) / 100;
				const percent = currentUsing * 100 / maxUsing;

				let type = 'danger';

				if (percent < 70) {
					type = 'success';
				} else if (percent < 90) {
					type = 'warning';
				}

				this.itemUsing.set(toolId, {
					currentUsing: currentUsing,
					maxUsing: maxUsing,
					percent: percent,
					type: type
				});
			});
	}

	initTool() {
		if (this.newTool) {
			// Запоминаем тул ид в куках
			this._cookieService.set('toolId', this.newTool.id.toString(), null, '/');

			this.setToolUsing(this.newTool.id, this.newTool.toolTypeLifetime);

			this.sourceToolTypeId = this.newTool.toolTypeId;

			// let tempObject = {
			// 	'id': this.newTool.id,
			// 	'name': 'tool'
			// };
			this.qrInfo = this.newTool.id;
			this.titleInfo = [];

			this.addTitleInfo('ID', '' + this.newTool.id);
			this.addTitleInfo('Название', '' + this.newTool.toolTypeName);
		}
	}

	// сохраннение изменений инструмента
	// ToDo проблема с подпиской
	onSubmit() {
		this._mesApi.saveTool(this.newTool)
			.pipe(untilDestroyed(this))
			.subscribe(res => {
				if (res) {
					this.newTool = res;
					this.refreshTool.emit(this.newTool);
					this._notifierService.notify('success', 'Сохранено');
				} else {
					this._notifierService.notify('error', 'Ошибка. Проверьте данные');
				}
			});

	}

	// реакция на выбор типа инструмента
	doSelectToolType() {
		const toolType = {
			...this.toolTypeList.find(
				item => item.id === this.sourceToolTypeId
			)
		};

		if (!this.newTool) {
			this.newTool = new Tool();
		}

		this.newTool.toolTypeId = toolType.id;
		this.newTool.toolTypeName = toolType.name;
		// @ts-ignore
		this.newTool.toolTypeCharacteristics = toolType.characteristics;
		// @ts-ignore
		this.newTool.toolTypeLifetime = toolType.lifetime;
		this.newTool.toolTypeImage = toolType.image;

		this.newTool.creatorUserId = this.currentUser.userId;
		this.newTool.creatorUserName = this.currentUser.userName;
	}

	// Изменение размера картинки инструмена по щелчку на ней
	changeSizeImage(im) {
		const target = im.target;
		if (target.height === target.naturalHeight) {
			target.height = 150;
		} else {
			target.height = target.naturalHeight;
		}
	}

	addTitleInfo(fild: string, title: string) {
		const tempObject = {
			'fild': fild,
			'title': title
		};

		this.titleInfo.push(tempObject);
	}

	clickDelete() {
		if (this.readyToDelete === true) {
			this._mesApi.deleteTool(this.newTool.id)
				.pipe(untilDestroyed(this))
				// Todo прооблема с подпиской
				.subscribe(res => {
					if (res) {
						this._notifierService.notify('success', 'Успешно удалено');
						this.deleteTool.emit(this.newTool.id);
						this.newTool = undefined;
						this.sourceToolTypeId = 0;
						this.toolId = 0;
					} else {
						this._notifierService.notify('error', 'Ошибка удаления');
					}
				});
		}

		this.readyToDelete = !this.readyToDelete;
	}

	clickWriteOff() {
		if (this.readyToWriteOff === true) {
			this.newTool.writeOffUserId = this.currentUser.userId;
			this.newTool.writeOffUserName = this.currentUser.userName;
			this.onSubmit();
		}

		this.readyToWriteOff = !this.readyToWriteOff;
	}

	createDate(date: Date) {
		return new Date(date).toLocaleString('ru').toString();
	}

	goToUnit(unit: IUnitFull) {
		this.refreshTool.emit(null);

		this._router.navigate(
			['/monitoring/unit/' + unit.id], {fragment: 'viewType=8'}
		);
	}

	checkFavorites() {
		this._apiService.getUnitsFavorites()
			.pipe(untilDestroyed(this))
			.subscribe(res => {
				this.favoritesUnits = [];
				const units = this._unitsSelector.getAllowedUnits();

				units.forEach(unit => {
					if (res.includes(unit.id)) {
						this.favoritesUnits.push(unit);
					}
				});
			});
	}
}
