import {Component, Inject} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {
	ProgramCompareComponent
} from '../../../modules/monitoring/modules/unit/components/unit-tabs-area/components/details/components/program-compare/program-compare.component';
import {IConfirmModalData} from '@shared/modals/confirm-modal/models/confirm-modal.type';
import {ButtonComponent} from '@shared/ui-components/button/button.component';
import {DIALOG_DATA, ModalRef} from '@shared/services/modals.service';

@Component({
	selector: 'app-confirm-modal',
	templateUrl: './confirm-modal.component.html',
	styleUrl: './confirm-modal.component.scss',
	standalone: true,
	imports: [
		MatIconModule,
		ProgramCompareComponent,
		ButtonComponent
	]
})
export class ConfirmModalComponent {
	constructor(
		@Inject(DIALOG_DATA) public data: IConfirmModalData,
		private _dialogRef: ModalRef<ConfirmModalComponent>,
	) {
	}

	public closeModal(value?: boolean): void {
		if (typeof value === 'boolean') {
			this._dialogRef.close(value);
		} else {
			this._dialogRef.close();
		}
	}
}
