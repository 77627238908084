import {Component, Inject, OnInit} from '@angular/core';
import {IdlesRepository} from '@shared/repositories/idles.repository';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {NotifierService} from 'angular-notifier';
import {filter, first, map, startWith} from 'rxjs/operators';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {MatOptionSelectionChange} from '@angular/material/core';
import {Idle} from '@shared/modals/idle-editing/models/idle-editing.classes';
import {DIALOG_DATA, ModalRef} from '@shared/services/modals.service';

@Component({
	selector: 'app-idles-editing',
	templateUrl: './idle-editing.component.html',
	styleUrls: ['./idle-editing.component.scss'],
})
export class IdleEditingComponent implements OnInit {
	// todo : убрать все данные в эти два класса
	private readonly _baseIdle: Idle;
	private readonly _secondIdle: Idle;
	private _unitClassId: number;
	private _noIdleType = {
		id: 0,
		color: '#555',
		name: 'Не указан тип',
		disabled: true,
	};
	private _isSaved: boolean;

	public myControl: FormControl<any>;
	public myControl2: FormControl<any>;
	public filteredOptions: Observable<any>;
	public secondFilteredOptions: Observable<any>;

	public idleTypes: any;
	public bsConfig: Partial<BsDatepickerConfig>;

	public baseIdleType: any;
	public secondIdleType;
	public secondIdleTypeId;
	public start;
	public end;
	public newStart;
	public newEnd;
	public sliderInterval;

	public saveBtnAvailable: boolean;
	public editIdle: boolean;
	public idleTypeColor: string;

	constructor(
		@Inject(DIALOG_DATA) public idleData: any,
		private _dialogRef: ModalRef<IdleEditingComponent>,
		private idlesService: IdlesRepository,
		private notifier: NotifierService,
	) {
		this.myControl = new FormControl();
		this.myControl2 = new FormControl();

		this._baseIdle = new Idle();
		this._secondIdle = new Idle();
		this._isSaved = false;

		this.saveBtnAvailable = false;
		this.editIdle = false;

		this.bsConfig = Object.assign(
			{},
			{
				dateInputFormat: 'DD.MM.YYYY HH:mm',
				containerClass: 'theme-dark',
				selectFromOtherMonth: true,
			},
		);
	}

	public ngOnInit(): void {
		this.initIdlesType();
		this.filteredOptions = this.myControl.valueChanges.pipe(
			startWith(''),
			map(value => this._filter(value)),
		);
		this.secondFilteredOptions = this.myControl2.valueChanges.pipe(
			startWith(''),
			map(value => this._filter(value)),
		);
	}

	private _filter(value: string): any {
		const filterValue = value.toLowerCase();
		return this.idleTypes
			.filter(type => type.name.toLowerCase().includes(filterValue));
	}

	private initIdlesType(): void {
		this.idlesService.getIdlesTypes()
			.pipe(
				first(),
				filter(() => this.idleData?.id !== undefined),
			)
			.subscribe(idleTypes => {
				this.idlesService.getIdleById(this.idleData.id)
					.pipe(first())
					.subscribe(idleResponse => {
						this._unitClassId = idleResponse.unitClassId;

						this.idleTypes = this.filterUnitClass(idleTypes);
						this.idleTypes[Object.keys(this.idleTypes).length] = this._noIdleType;

						const endTime = idleResponse.idles[0].end_idle
							? new Date(idleResponse.idles[0].end_idle)
							: new Date();

						this.start = new Date(idleResponse.idles[0].start_idle);

						this.end = endTime;
						this.newStart = endTime;
						this.newEnd = endTime;

						this.sliderInterval = this.newEnd.getTime();

						this.baseIdleType = this.idleData.idleType;
						this.secondIdleType = {...this._noIdleType};
					});
			});
	}

	// Отфильтрует типы простоев по юнит классам
	private filterUnitClass(idleTypes: any[]): any[] {
		const res = [];

		idleTypes.forEach(value => {
			if (
				!value.unitClassIdList ||
				(
					value.unitClassIdList && value.unitClassIdList.find(
						classId => classId === this._unitClassId,
					)
				)
			) {
				res.push(value);
			}
		});

		return res;
	}

	public changeDate(date: Date): void {
		if (!date) {
			return;
		}

		if (date.getTime() < this.start.getTime()) {
			date = this.start;
		} else if (date.getTime() > this.newEnd.getTime()) {
			date = this.newEnd;
		}

		this.end = date;
		this.newStart = date;

		this.sliderInterval = this.newStart.getTime();

		this.checkIntervalPeriud();
	}

	public changeIdleType(event: MatOptionSelectionChange): void {
		if (!!event.source.value) {
			this.saveBtnAvailable = true;
		}
		const idle = this.idleTypes.find(val => val.name === event.source.value);
		this.idleData.idleType.id = idle.id;
	}

	public changeSecondIdleType(idle, event): void {
		// if (!!event.source.value) {
		// 	this.saveBtnAvailable = true;
		// }
		// const idle = this.idleTypes.find(val => val.name === event.source.value);
		// this.secondIdleType.id = idle.id;
		idle.id = event.target.value;

		const idleType = this.idleTypes.find(type => type.id === +event.target.value);

		idle.color = idleType.color;

		this.saveBtnAvailable = true;
	}

	public newSliderInterval(): void {
		this.end = new Date(this.sliderInterval);
		this.newStart = new Date(this.sliderInterval);

		this.checkIntervalPeriud();
	}

	private checkIntervalPeriud(): void {
		if (this.editIdle) {
			this.saveBtnAvailable = this.end.getTime() !== this.newEnd.getTime();
		}
	}

	public editIdleClick(): void {
		this.editIdle = !this.editIdle;
		this.saveBtnAvailable = false;
	}

	public save(): void {
		this._baseIdle.idleId = this.idleData.id;
		this._baseIdle.start = this.start;
		this._baseIdle.end = this.end;
		this._baseIdle.idleTypeId = this.idleData.idleType.id;

		this._secondIdle.start = this.newStart;
		this._secondIdle.end = this.newEnd;
		this._secondIdle.idleTypeId = Number(this.secondIdleType.id);

		const payLoad = {
			'baseIdle': this._baseIdle,
			'secondIdle': this._secondIdle,
		};

		this.idlesService.redactIdle(payLoad)
			.pipe(first(),
			).subscribe({
			next: idleResponse => {
				if (!!idleResponse) {
					this.notifier.notify('success', 'Успешно сохранено');
					// this.onIdleChenge.emit(idleResponse);
					this._isSaved = true;

					if (idleResponse.secondIdle.idleId && idleResponse.secondIdle.idleId > 0) {
						this.idleData.id = idleResponse.secondIdle.idleId;
						this.saveBtnAvailable = false;
						this.editIdle = false;
						this._noIdleType = {
							id: 0,
							color: '#555',
							name: 'Не указан тип',
							disabled: true,
						};
						this.initIdlesType();
					}
				} else {
					this.notifier.notify('warning', 'Ошибка сохранения - проверьте вводимые данные');
				}
			},
			error: err => {
				this.notifier.notify('warning', 'Ошибка сохранения - проверьте вводимые данные');
			},
		});
	}

	public closeModal(): void {
		this._dialogRef.close(this._isSaved);
	}
}
