import {Component, Inject} from '@angular/core';
import {DtoItemModule} from '@shared/components/dto-viewer/modules/dto-item/dto-item.module';
import {MatIconModule} from '@angular/material/icon';
import {IWarehouseModalData} from '@shared/modals/warehouse-modal/models/warehouse-modal.types';
import {DIALOG_DATA, ModalRef} from '@shared/services/modals.service';
import {ButtonComponent} from '@shared/ui-components/button/button.component';

@Component({
	selector: 'app-warehouse-modal',
	templateUrl: './warehouse-modal.component.html',
	styleUrls: ['./warehouse-modal.component.scss'],
	standalone: true,
	imports: [
		DtoItemModule,
		MatIconModule,
		ButtonComponent
	]
})
export class WarehouseModalComponent {
	constructor(
		@Inject(DIALOG_DATA) public data: IWarehouseModalData,
		private _dialogRef: ModalRef<WarehouseModalComponent>,
	) {
	}

	public closeModal(): void {
		this._dialogRef.close();
	}
}
