<div class="row g-width-100 g-gap g-padding">
	<div class="flex-column flex flex-1 g-height-100 g-flex-column">
		<div
			[style.padding-top]="'9px'"
			class="header g-justify-content-center"
		>
			<div class="font-size-18 g-unselectable">Станки для выбора:</div>

			<app-checkbox
				labelPosition="before"
				class="g-right-side"
				[checked]="withoutSort"
				(click)="this.withoutSort = !this.withoutSort"
			>
				Cортировать при добавлении
			</app-checkbox>
		</div>
		<app-table
			[data]="units"
			itemSize="65"
		>
			<ng-container *viewItem>
				<app-table-header
					[sizeColumn]="{maxSize: '60px', minSize: '5px'}"
					class="g-center-h"
				>
					<app-button
						class="g-button-success-empty"
						appTooltip="Выбрать все"
						[style.height]="'35px'"
						(click)="selectAll()"
						[svgIcon]="'done'"
					></app-button>
				</app-table-header>
				<app-table-item
					*lineValue="let element"
					class="g-center-h"
				>
					<app-checkbox
						[checked]="element.checked"
						(change)="selectUnit(element)"
						[id]="'unit' + element.id"
					></app-checkbox>
				</app-table-item>
			</ng-container>

			<ng-container *viewItem>
				<app-table-header
					#header
					[searchByField]="[['unitType', 'name'], ['inventoryNumber']]"
				>Модель
				</app-table-header>
				<app-table-item *lineValue="let element">
					<label
						[for]="'unit' + element.id"
						class="pointer g-unselectable"
						(click)="selectUnit(element)"
					>
						<app-selector-letters
							class="cell-unit__text"
							[value]="element.unitType.name + ' (' + element.inventoryNumber + ')'"
							[searchString]="header.searchInput$.getValue()"
						></app-selector-letters>
					</label>
				</app-table-item>
			</ng-container>

			<ng-container *viewItem>
				<app-table-header
					[sizeColumn]="{minSize: '85px', maxSize: '0.75fr'}"
					#header
				>Индекс
				</app-table-header>
				<app-table-item
					*lineValue="let element"
					class="g-center-h"
				>
					<div>{{ element.indexNumber }}</div>
				</app-table-item>
			</ng-container>

			<ng-container *viewItem>
				<app-table-header
					#header
					[searchByField]="['location', 'name']"
				>Расположение
				</app-table-header>
				<app-table-item
					*lineValue="let element"
					class="g-center-h"
				>
					<app-selector-letters
						class="cell-unit__text"
						[value]="element.location.name"
						[searchString]="header.searchInput$.getValue()"
					></app-selector-letters>
				</app-table-item>
			</ng-container>

			<ng-container *viewItem>
				<app-table-header
					[sizeColumn]="{maxSize: '15px'}"
				>Избранное
				</app-table-header>
				<app-table-item *lineValue="let element">
					<div class="g-center-h">
						@if (unitsMetaDataSelector.isUnitFavorite(element.id)) {
							<app-icon
								class="g-star-favourites"
								name="star"
								(click)="addOrRemoveFromFavourites(element)"
								matTooltip="Удалить оборудование из избранного"
							></app-icon>
						} @else {
							<app-icon
								class="g-star-favourites"
								name="star-outline"
								(click)="addOrRemoveFromFavourites(element)"
								matTooltip="Добавить оборудование в избранное"
							></app-icon>
						}
					</div>
				</app-table-item>
			</ng-container>
		</app-table>
	</div>

	<div class="flex-column flex flex-1 g-height-100 g-flex-column">
		<div class="header">
			@if (!isFavoriteMode) {
				<app-template-unit-filter
					class="template-unit"
					[isOpen]="false"
					[isAbsolute]="true"
					[isAutoClose]="true"
					[useButton]="false"
				></app-template-unit-filter>

				@if (unitGroupsService.isVisibleButtonAdd$.getValue()) {
					<app-button
						@untwistingWidthAnimation
						class="g-button-primary-empty g-overflow-hidden"
						appTooltip="Добавить шаблон"
						appPopover
						[close$]="closePopover$"
						[popoverTemplateRef]="newTemplate"
						svgIcon="add"
					></app-button>
				}
				@if (unitGroupsService.isVisibleButtonSave$.getValue() && isCanEdit$.getValue()) {
					<app-button
						@untwistingWidthAnimation
						(click)="updateSelectedTemplate()"
						[loading]="unitGroupsService.isLoadingUpdate$.getValue()"
						class="g-button-secondary-empty g-overflow-hidden"
						appTooltip="Сохранить изменения"
						svgIcon="save-classic"
					></app-button>
				}
				@if (unitGroupsService.isVisibleButtonSave$.getValue() &&
				isCanEdit$.getValue() &&
				!unitGroupsService.isLoadingUpdate$.getValue()) {
					<app-button
						@untwistingWidthAnimation
						(click)="cancelChanges()"
						[loading]="unitGroupsService.isLoadingUpdate$.getValue()"
						class="g-button-secondary-empty g-overflow-hidden"
						appTooltip="Отменить изменения"
						svgIcon="undo"
					></app-button>
				}
				@if (!!templateFilterService.getSelectedTemplate() && isCanEdit$.getValue()) {
					<app-button
						@untwistingWidthAnimation
						appPopover
						[popoverTemplateRef]="editTemplate"
						[close$]="closePopover$"
						class="g-button-secondary-empty g-overflow-hidden"
						appTooltip="Поменять имя шаблона"
						svgIcon="edit"
					></app-button>
				}
				@if (!!unitGroupsService.isVisibleButtonShare$.getValue() &&
				!unitGroupsService.isVisibleButtonSave$.getValue()) {
					<app-button
						@untwistingWidthAnimation
						appPopover
						[popoverTemplateRef]="shareGroup"
						[close$]="closePopover$"
						class="g-button-secondary-empty g-overflow-hidden"
						appTooltip="Поделиться группой"
						svgIcon="share-social"
					></app-button>
				}
				@if (unitGroupsService.isVisibleButtonDelete$.getValue()) {
					<app-button
						@untwistingWidthAnimation
						appPopover
						[popoverTemplateRef]="deleteGroup"
						class="g-button-danger-empty g-overflow-hidden"
						appTooltip="Удалить шаблон"
						svgIcon="trash-outline"
					></app-button>
				}
			}
		</div>
		<app-table
			[data]="filterService.getSelectedUnits()"
			[useDragAndDrop]="isCanEdit$.getValue()"
			(dropListDropped)="handleListDropped($event)"
		>
			<ng-container *viewItem>
				<app-table-header
					#header
					[searchByField]="[['unitType', 'name'], ['inventoryNumber']]"
				>Модель
				</app-table-header>
				<app-table-item
					*lineValue="let element"
					[class.g-cursor-move]="isCanEdit$.getValue()"
				>
					<app-selector-letters
						class="cell-unit__text"
						[value]="element.unitType.name + ' (' + element.inventoryNumber + ')'"
						[searchString]="header.searchInput$.getValue()"
					></app-selector-letters>
				</app-table-item>
			</ng-container>
			<ng-container *viewItem>
				<app-table-header
					[sizeColumn]="{minSize: '85px', maxSize: '0.75fr'}"
					#header
				>Индекс
				</app-table-header>
				<app-table-item
					*lineValue="let element"
					class="g-center-h"
					[class.g-cursor-move]="isCanEdit$.getValue()"
				>
					<div>{{ element.indexNumber }}</div>
				</app-table-item>
			</ng-container>
			<ng-container *viewItem>
				<app-table-header
					#header
					[searchByField]="['location', 'name']"
				>Расположение
				</app-table-header>
				<app-table-item
					*lineValue="let element"
					class="g-center-h"
					[class.g-cursor-move]="isCanEdit$.getValue()"
				>
					<app-selector-letters
						class="cell-unit__text"
						[value]="element.location.name"
						[searchString]="header.searchInput$.getValue()"
					></app-selector-letters>
				</app-table-item>
			</ng-container>
			<ng-container *viewItem>
				<app-table-header
					[sizeColumn]="{maxSize: '60px', minSize: '5px'}"
					class="g-center-h"
				>
					<app-button
						class="g-button-danger-empty"
						[style.height]="'35px'"
						svgIcon="trash-outline"
						appTooltip="Очистить все"
						(click)="dropAll()"
					></app-button>
				</app-table-header>
				<app-table-item
					*lineValue="let element"
					class="g-center-h"
				>
					<app-button
						class="g-button-danger-empty"
						svgIcon="trash-outline"
						(click)="selectUnit(element)"
					></app-button>
				</app-table-item>
			</ng-container>
		</app-table>
	</div>
</div>

<ng-template #newTemplate>
	<div class="template-new-group">
		<mat-form-field
			appearance="outline"
			class="g-full-width"
		>
			<mat-label>Название шаблона</mat-label>
			<input
				#input
				[autofocus]="true"
				(keyup)="$event.key === 'Enter' ? createNewGroup(input.value) : null"
				matInput
				type="text"
			>
		</mat-form-field>
		{{ input.focus() }}
		<app-button
			class="g-button-secondary-square"
			svgIcon="save-classic"
			[loading]="unitGroupsService.isLoadingCreate$.getValue()"
			(click)="createNewGroup(input.value)"
		></app-button>
	</div>
</ng-template>

<ng-template #editTemplate>
	<div class="template-new-group">
		<mat-form-field
			appearance="outline"
			class="g-full-width"
		>
			<mat-label>Новое название шаблона</mat-label>
			<input
				#input
				[autofocus]="true"
				(keyup)="$event.key === 'Enter' ? editNameSelectedGroup(input.value) : null"
				matInput
				type="text"
			>
		</mat-form-field>
		{{ input.focus() }}
		<app-button
			class="g-button-secondary-square"
			svgIcon="save-classic"
			[loading]="unitGroupsService.isLoadingUpdate$.getValue()"
			(click)="editNameSelectedGroup(input.value)"
		></app-button>
	</div>
</ng-template>

<ng-template #deleteGroup>
	<div class="template-delete-group">
		<span class="text-middle">Вы точно уверены, что хотите удалить шаблон?</span>

		<app-button
			class="g-button-danger-square"
			[text]="'Удалить'"
			[loading]="unitGroupsService.isLoadingDelete$.getValue()"
			(click)="unitGroupsService.deleteSelectedTemplate()"
		></app-button>
	</div>
</ng-template>

<ng-template #shareGroup>
	<div class="template-share-group">
		<app-share-selector
			[defaultUsersIds]="unitGroupsService.getUsersIdsOfSelectedTemplate()"
			[defaultEditorsIds]="unitGroupsService.getEditorsIdsOfSelectedTemplate()"
			[useEditing]="isCanEdit$.getValue()"
			[isLoading]="unitGroupsService.isLoadingUpdate$.getValue()"
			(share)="shareTemplateToUsers($event)"
		></app-share-selector>
	</div>
</ng-template>

