import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-date-time-picker',
	templateUrl: 'date-time-picker.component.html',
	styleUrls: ['date-time-picker.component.scss'],
})
export class DateTimePickerComponent implements OnInit {
	@Input()
	labelDate = 'Дата';

	@Input()
	labelTime = 'Время';

	@Input()
	date: Date;

	@Output()
	dateChange: EventEmitter<Date>;

	public timeFormControl: FormControl;
	public dateFormControl: FormControl;

	constructor() {
		this.dateChange = new EventEmitter<Date>();

		this.timeFormControl = new FormControl();
		this.dateFormControl = new FormControl();
	}

	public ngOnInit(): void {
		this.initSubscribes();

		this.date = new Date();

		this.timeFormControl.patchValue(this.date);
		this.dateFormControl.patchValue(this.date);
	}

	private initSubscribes(): void {
		this.timeFormControl.valueChanges
			.pipe(untilDestroyed(this))
			.subscribe((res: Date) => {
				const date = new Date(this.date);

				date.setTime(res.getTime());

				this.dateChange.emit(date);
			});

		this.dateFormControl.valueChanges
			.pipe(untilDestroyed(this))
			.subscribe((res: Date) => {
				const date = new Date(this.date);

				date.setDate(res.getDate());

				this.dateChange.emit(date);
			});
	}
}
