<div class="modal">
	<div class="modal__header">
		<div class="title">
			Потеряна связь с сервером!
		</div>

		<app-button
			class="g-button-secondary-empty btn-close"
			svgIcon="close"
			(click)="closeModal()"
		>
		</app-button>
	</div>

	<div class="modal__content g-scroll">
		<div>Есть несколько причин потери связи:</div>

		<ul>
			<li>
				Ваша сесия устарела - в целях безопастности просто заново авторизуйтесь.
			</li>

			<li>
				Ведутся работы на сервере - через нескослько минут обновите страницу.
				Если вы используете ПК то нажмите сочетание клавиш Ctrl + F5.
				Если связь не востановится - то обратитесь в поддержку.
			</li>
		</ul>
	</div>
</div>
