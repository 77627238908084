import {FocusMonitor} from '@angular/cdk/a11y';
import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {
	Component,
	ElementRef,
	EventEmitter,
	Inject,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Optional,
	Output,
	Self,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, NgControl,} from '@angular/forms';
import {MAT_FORM_FIELD, MatFormField, MatFormFieldControl} from '@angular/material/form-field';
import {Subject} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Time} from '@shared/ui-components/timepicker/components/time-input/models/time-input.classes';


@UntilDestroy()
@Component({
	selector: 'app-time-input',
	templateUrl: 'time-input.component.html',
	styleUrls: ['time-input.component.scss'],
	providers: [{provide: MatFormFieldControl, useExisting: TimeInputComponent}],
	host: {
		'[class.example-floating]': 'shouldLabelFloat',
		'[id]': 'id',
	},
})
export class TimeInputComponent implements MatFormFieldControl<Time>, OnInit, OnDestroy, OnChanges {
	static nextId = 0;
	@ViewChild('area') areaInput: HTMLInputElement;
	@ViewChild('exchange') exchangeInput: HTMLInputElement;
	@ViewChild('subscriber') subscriberInput: HTMLInputElement;

	@Input()
	hour = '0';

	@Input()
	minute = '0';

	@Output()
	hourChange = new EventEmitter<string>();

	@Output()
	minuteChange = new EventEmitter<string>();

	parts: FormGroup;
	stateChanges = new Subject<void>();
	focused = false;
	touched = false;
	id = `example-tel-input-${TimeInputComponent.nextId++}`;
	_placeholder: string;
	_required = false;
	_disabled = false;

	get empty() {
		const {
			value: {hour, minute},
		} = this.parts;

		return !hour || !minute;
	}

	get shouldLabelFloat() {
		return this.focused || !this.empty;
	}

	@Input()
	get placeholder(): string {
		return this._placeholder;
	}

	set placeholder(value: string) {
		this._placeholder = value;

		this.stateChanges.next();
	}

	@Input()
	get required(): boolean {
		return this._required;
	}

	set required(value: BooleanInput) {
		this._required = coerceBooleanProperty(value);

		this.stateChanges.next();
	}

	@Input()
	get disabled(): boolean {
		return this._disabled;
	}

	set disabled(value: BooleanInput) {
		this._disabled = coerceBooleanProperty(value);

		if (!!this.parts) {
			this._disabled ? this.parts.disable() : this.parts.enable();
			this.stateChanges.next();
		}
	}

	@Input()
	get value(): Time | null {
		if (this.parts.valid) {
			const {
				value: {hour, minute},
			} = this.parts;

			return new Time(hour!, minute!);
		}

		return null;
	}

	set value(tel: Time | null) {
		const {hour, minute} = tel || new Time(null, null);

		this.parts.setValue({hour, minute});
		this.stateChanges.next();
	}

	get errorState(): boolean {
		return this.parts.invalid && this.touched;
	}

	constructor(
		private _formBuilder: FormBuilder,
		private _focusMonitor: FocusMonitor,
		private _elementRef: ElementRef<HTMLElement>,
		@Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
		@Optional() @Self() public ngControl: NgControl,
	) {
	}

	ngOnInit(): void {
		this.parts = this._formBuilder.group({
			hour: [this.hour],
			minute: [this.minute],
		});

		if (this.disabled) {
			this.parts.disable();
		}

		this.parts
			.get('hour').valueChanges
			.pipe(untilDestroyed(this))
			.subscribe((value: string) => this.hourChange.emit(value));

		this.parts
			.get('minute').valueChanges
			.pipe(untilDestroyed(this))
			.subscribe((value: string) => this.minuteChange.emit(value));
	}

	ngOnChanges(changes: SimpleChanges) {
		if (!this.parts) {
			return;
		}

		if (!!changes?.hour?.currentValue) {
			this.parts.get('hour').patchValue(changes.hour.currentValue);
		}

		if (!!changes?.minute?.currentValue) {
			this.parts.get('minute').patchValue(changes.minute.currentValue);
		}
	}

	ngOnDestroy() {
		this.stateChanges.complete();
		this._focusMonitor.stopMonitoring(this._elementRef);
	}

	onFocusIn(event: FocusEvent) {
		if (!this.focused) {
			this.focused = true;

			this.stateChanges.next();
		}
	}

	onFocusOut(event: FocusEvent) {
		if (
			!this._elementRef.nativeElement.contains(event.relatedTarget as Element)
		) {
			this.touched = true;
			this.focused = false;

			this.stateChanges.next();
		}
	}

	autoFocusNext(control: AbstractControl, nextElement?: HTMLInputElement): void {
		if (!control.errors && nextElement) {
			this._focusMonitor.focusVia(nextElement, 'program');
		}
	}

	autoFocusPrev(control: AbstractControl, prevElement: HTMLInputElement): void {
		if (control.value.length < 1) {
			this._focusMonitor.focusVia(prevElement, 'program');
		}
	}

	setDescribedByIds(ids: string[]) {
		this._elementRef.nativeElement.querySelector(
			'.example-tel-input-container',
		)!;
	}

	onContainerClick() {
	}

	_handleInput(control: AbstractControl, nextElement?: HTMLInputElement): void {
		this.autoFocusNext(control, nextElement);
	}
}
