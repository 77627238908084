<div class="modal g-overflow-visible">
	<div class="modal__header">
		<div class="title">Добавление простоя</div>

		<app-button
			svgIcon="close"
			class="btn-close g-button-secondary-empty"
			(click)="closeModal()"
		></app-button>
	</div>

	<div
		class="modal__content g-overflow-visible"
		style="align-items: center"
	>
		<app-idle-creator
			class="g-width-100"
			(onIdleCreate)="data.eventOfIdleCreate.emit($event)"
		></app-idle-creator>
	</div>
</div>
