import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { IBrigade, SdwebRepository } from '@shared/repositories/sdweb.repository';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IBrigadesState {
	brigades: IBrigade[];
}

const DefaultBrigadesState = {
	brigades: [],
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'brigadesStore' })
export class BrigadesStore extends Store<IBrigadesState> {
	constructor(
		private _sdWebRepository: SdwebRepository
	) {
		super(DefaultBrigadesState);
	}

	init(): Observable<IBrigadesState> {
		const brigades$ = this._sdWebRepository.getBrigades();

		return brigades$.pipe(
			map((res) => {
				const data: IBrigadesState = {
					brigades: res
				}

				this.update(data);

				return data;
			})
		)
	}
}
