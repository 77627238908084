import {ApplicationRef, ComponentFactoryResolver, ComponentRef, ElementRef, Inject, Injectable, Injector, Renderer2, RendererFactory2} from '@angular/core';
import {TooltipComponent} from '@shared/directives/tooltip/component/tooltip.component';
import {fromEvent} from 'rxjs';
import {DOCUMENT} from '@angular/common';
import {TooltipService} from '@shared/directives/tooltip/tooltip.service';


@Injectable({providedIn: 'root'})
export class ComponentTooltipService extends TooltipService<{ text: string }> {
	private _tooltipComponent?: ComponentRef<TooltipComponent>;
	private _renderer: Renderer2;

	constructor(
		private _factoryResolver: ComponentFactoryResolver,
		private _injector: Injector,
		private _appRef: ApplicationRef,
		private _rendererFactory: RendererFactory2,
		@Inject(DOCUMENT) private _document: Document
	) {
		super();
		this._renderer = this._rendererFactory.createRenderer(null, null);
	}

	public create(
		{data, container}: {
			data: { text: string },
			container: ElementRef<any>
		}
	): void {
		if (this._tooltipComponent) {
			return;
		}

		// @ts-ignore
		this._mouseleave$ = fromEvent<MouseEvent>(container, 'mouseleave');
		// @ts-ignore
		this._containerLocation = container.getBoundingClientRect();

		this._tooltipComponent = this._factoryResolver.resolveComponentFactory(
			TooltipComponent
		).create(this._injector);

		const {nativeElement} = this._tooltipComponent.location;

		this._renderer.appendChild(container, nativeElement);

		this._tooltipComponent.instance.text = data.text;
		this._tooltipComponent.hostView.detectChanges();

		this.setLocation(nativeElement, nativeElement.clientWidth);
		this.getCloseEvent(nativeElement).subscribe(() => this.close());


	}

	public isHave(): boolean {
		return !!this._tooltipComponent;
	}

	public close() {
		if (!this._tooltipComponent) {
			return;
		}

		this._appRef.detachView(this._tooltipComponent.hostView);

		this._tooltipComponent.destroy();
		this._tooltipComponent = undefined;
	}
}

