<ng-container *ngIf="dynamicData">
	<app-dynamic-bar
		[lineId]="'line1'"
		[startTime]="interval.start"
		[endTime]="interval.end"
		(onChart)='onChart.emit([$event,  "line1"])'
		(onChartSync)='onChartSync.emit([$event,  "unit.id"])'
	></app-dynamic-bar>
</ng-container>

<app-scroll
	class="scroll"
	[data]="selectedUnits"
>
	<div
		*scrollItem="let unit"
		class="card g-card"
	>
		<ng-container *ngIf="showUnitsNames">
			<div class="g-container-between">
				<span class="g-unselectable">Название</span>

				<span
					class="link"
					[routerLink]="['/monitoring/unit', unit.id]"
					(click)="unitClick.emit(unit)"
				>
						  {{unit.unitType.name}}
					<span *ngIf="unit.inventoryNumber">({{unit.inventoryNumber}})</span>
		    </span>
			</div>
			<div
				*ngIf="unit?.indexNumber"
				class="g-container-between"
			>
				<span class="g-unselectable">Порядковый номер оборудования</span>
				<span>{{unit.indexNumber}}</span>
			</div>
			<div
				*ngIf="isEmpty(unit?.location?.name)"
				class="g-container-between"
			>
				<span class="g-unselectable">Расположение оборудования</span>
				<span>{{unit.location.name}}</span>
			</div>
		</ng-container>
		<app-dynamic-bar
			*ngIf="dynamicData"
			[id]="unit.id"
			[dynamicStat]="dynamicData? dynamicData[unit.id] : []"
			[dynamicRepairData]="dynamicRepairData? dynamicRepairData[unit.id]: []"
			[idlesData]="idlesData? idlesData[unit.id] : []"
			[startTime]="interval.start"
			[endTime]="interval.end"
			(onChart)='onChart.emit([$event,  unit.id])'
			(onChartSync)='onChartSync.emit([$event,  "unit.id"])'
			(redirectEmitter)="redirect.emit($event)"
		></app-dynamic-bar>
		<app-static-bar
			*ngIf="staticData"
			[id]="unit.id"
			[staticStat]="staticData? staticData.value[unit.id] : []"
			[idlesData]="idlesData? idlesData[unit.id] : null"
			[isIdleInChart]="isIdleInChart"
			[startTime]="interval.start"
			[endTime]="interval.end"
		></app-static-bar>
		<div
			*ngIf="coefficientsDataStatistic && coefficientsDataStatistic[selectedUnits[0].id].LOAD != null"
			class="g-container-between"
		>
		<span>
			{{coefficientsDataStatistic[selectedUnits[0].id].LOAD.status.title}}, {{coefficientsDataStatistic[selectedUnits[0].id].LOAD.status.measure}}
		</span>

			<span
				class="tab_date"
				[ngClass]="{'coef-red': unit.LOAD >= 0 && unit.LOAD < 40, 'coef-yellow': unit.LOAD >= 40 && unit.LOAD < 60, 'coef-green': unit.LOAD >= 60}"
			>{{unit.LOAD}}</span>
		</div>
		<div
			*ngIf="coefficientsDataStatistic && coefficientsDataStatistic[selectedUnits[0].id].OEE != null"
			class="g-container-between"
		>
		<span
			class="tab_date"
			[ngClass]="{'coef-red': unit.OEE >= 0 && unit.OEE < 40, 'coef-yellow': unit.OEE >= 40 && unit.OEE < 60, 'coef-green': unit.OEE >= 60}"
		>
			{{coefficientsDataStatistic[selectedUnits[0].id].OEE.status.title}}, {{coefficientsDataStatistic[selectedUnits[0].id].OEE.status.measure}}
		</span>

			<span>
			{{unit.OEE}}
		</span>
		</div>
		<div
			*ngIf="coefficientsDataStatistic && coefficientsDataStatistic[selectedUnits[0].id].AQ != null"
			class="g-container-between"
		>
		<span>
			{{coefficientsDataStatistic[selectedUnits[0].id].AQ.status.title}} , {{coefficientsDataStatistic[selectedUnits[0].id].AQ.status.measure}}
		</span>

			<span
				class="tab_date"
				[ngClass]="{'coef-red': unit.AQ >= 0.0 && unit.AQ < 0.6, 'coef-yellow': unit.AQ >= 0.6 && unit.af < 0.89, 'coef-green': unit.AQ >= 0.89}"
			>
			{{unit.AQ}}
		</span>
		</div>
		<div
			*ngIf="coefficientsDataStatistic && coefficientsDataStatistic[selectedUnits[0].id].MTTR != null"
			class="g-container-between"
		>
		<span>
			{{coefficientsDataStatistic[selectedUnits[0].id].MTTR.status.title}}, {{coefficientsDataStatistic[selectedUnits[0].id].MTTR.status.measure}}
		</span>
			<span class="tab_date">{{unit.MTTR}}</span>
		</div>
		<div
			*ngIf="coefficientsDataStatistic && coefficientsDataStatistic[selectedUnits[0].id].MTBF != null"
			class="g-container-between"
		>
		<span>
			{{coefficientsDataStatistic[selectedUnits[0].id].MTBF.status.title}}, {{coefficientsDataStatistic[selectedUnits[0].id].MTBF.status.measure}}
		</span>
			<span class="tab_date">{{unit.MTBF}}</span>
		</div>
		<div
			*ngIf="coefficientsDataStatistic && coefficientsDataStatistic[selectedUnits[0].id].MTTA != null"
			class="g-container-between"
		>
		<span>
			{{coefficientsDataStatistic[selectedUnits[0].id].MTTA.status.title}}, {{coefficientsDataStatistic[selectedUnits[0].id].MTTA.status.measure}}
		</span>
			<span class="tab_date">{{unit.MTTA}}</span>
		</div>
		<div
			*ngIf="coefficientsDataStatistic && coefficientsDataStatistic[selectedUnits[0].id].MDT != null"
			class="g-container-between"
		>
		<span>
			{{coefficientsDataStatistic[selectedUnits[0].id].MDT.status.title}}, {{coefficientsDataStatistic[selectedUnits[0].id].MDT.status.measure}}
		</span>
			<span class="tab_date">{{unit.MDT}}</span>
		</div>
		<div
			*ngIf="coefficientsDataStatistic && coefficientsDataStatistic[selectedUnits[0].id].MUT != null"
			class="g-container-between"
		>
		<span>
			{{coefficientsDataStatistic[selectedUnits[0].id].MUT.status.title}}, {{coefficientsDataStatistic[selectedUnits[0].id].MUT.status.measure}}
		</span>
			<span class="tab_date">{{unit.MUT}}</span>
		</div>
		<div
			*ngIf="coefficientsDataStatistic && coefficientsDataStatistic[selectedUnits[0].id].TASKSCOUNT != null"
			class="g-container-between"
		>
		<span>
			{{coefficientsDataStatistic[selectedUnits[0].id].TASKSCOUNT.status.title}}, {{coefficientsDataStatistic[selectedUnits[0].id].TASKSCOUNT.status.measure}}
		</span>
			<span>{{unit.TASKSCOUNT}}</span>
		</div>
	</div>
</app-scroll>

