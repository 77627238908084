export class DataConfig {
	name: string;
	title: string;
	type: DataConfigType;
	startWith?: string;
	fildName: string;
	isHidden: boolean;
	isNotClickable: boolean; // Проверка на валидность на форме
	bindDataDisplay: any;

	constructor(
		name: string,
		title: string,
		type: DataConfigType,
		isHidden?: boolean,
		fildName?: string,
		isNotClickable?: boolean,
		bindDataDisplay?: any
	) {
		this.name = name;
		this.title = title;
		this.type = type;
		this.fildName = fildName;
		this.isHidden = isHidden;
		this.isNotClickable = isNotClickable;
		this.bindDataDisplay = bindDataDisplay;
	}
}

export class ServiceTab {
	name: string;
	service: any;

	constructor(name: string, service: any) {
		this.name = name;
		this.service = service;
	}
}

export enum DataConfigType {
	string,
	stringIn,
	textarea,
	numeric,
	boolean,
	selector, // отборажение селектора для предоставления
	// пользователю возможности выбора (отображается из bindDataDisplay)
	id, // отображает данные из bindDataDisplay
	dataOut, // отображает дату без ввода
	dataIn, // ввод даты
	ftpLink,
	single_nomenclature,
	phone,
}
