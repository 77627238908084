import {Component, Inject} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {UnitSubscriptionComponent} from '@shared/components/unit-subscription/unit-subscription.component';
import {QrGeneratorModule} from '@shared/components/qr-generator/qr-generator.module';
import {IQRGeneratorModalData} from '@shared/modals/qr-generator-modal/models/qr-generator-modal.types';
import {DIALOG_DATA, ModalRef} from '@shared/services/modals.service';
import {ButtonComponent} from '@shared/ui-components/button/button.component';

@Component({
	selector: 'app-qr-generator-modal',
	templateUrl: './qr-generator-modal.component.html',
	styleUrls: ['./qr-generator-modal.component.scss'],
	standalone: true,
	imports: [
		MatIconModule,
		UnitSubscriptionComponent,
		QrGeneratorModule,
		ButtonComponent
	]
})
export class QrGeneratorModalComponent {
	constructor(
		@Inject(DIALOG_DATA) public data: IQRGeneratorModalData,
		private _dialogRef: ModalRef<QrGeneratorModalComponent>,
	) {
	}

	public closeModal(): void {
		this._dialogRef.close();
	}
}
