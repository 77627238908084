import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {sortByFullName} from '@shared/utils/sorting';
import {animatePanel} from './animations/animate';
import {ProgramCompareService} from './store/program-compare.service';
import {IProgram} from '../../models/interfaces';
import {ProgramCompareQuery} from './store/program-compare.query';
import {IUnitFull} from '../../../../../../models/unit';
import {UnitsSelector} from '@store/units-store/units.selector';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ButtonComponent} from '@shared/ui-components/button/button.component';
import { CommonModule, NgClass, NgIf } from '@angular/common';
import {TextCompareComponent} from './components/text-compare/text-compare.component';
import {TechprocessSelectorComponent} from './components/techprocess-selector/techprocess-selector.component';
import { UploadModule } from '@shared/ui-components/upload/upload.module';

@UntilDestroy()
@Component({
	selector: 'app-program-compare',
	templateUrl: './program-compare.component.html',
	styleUrls: ['./program-compare.component.scss'],
	standalone: true,
	imports: [
		CommonModule,
		ButtonComponent,
		NgClass,
		TextCompareComponent,
		TechprocessSelectorComponent,
		UploadModule,
	],
	animations: [animatePanel]
})
export class ProgramCompareComponent implements OnInit, OnDestroy {
	@Input() unitId?: number;
	@Input() program?: IProgram;
	units: Array<any>;
	currentProgram;
	programFirst$;
	programSecond$;
	currentFirstProgram;
	currentSecondProgram;
	showPanel$;
	isShowPanel = true;
	isFileLoaded = false;

	constructor(
		private _unitsSelector: UnitsSelector,
		private _programQuery: ProgramCompareQuery,
		private _programService: ProgramCompareService
	) {
	}

	ngOnInit() {
		this.units = this._unitsSelector.getAllowedUnits()
			.map(this.setFullName)
			.sort(sortByFullName);
		this.currentProgram = this.program ? Object.assign({}, this.program) : null;

		this.createStateSubscriptions();
	}

	public ngOnDestroy(): void {
		this.programFirst$.unsubscribe();
		this.programSecond$.unsubscribe();
		this.showPanel$.unsubscribe();
		this._programService.resetState('unitFirst');
		this._programService.resetState('unitSecond');
	}

	private createStateSubscriptions(): void {
		this.showPanel$ = this._programQuery.showProgramPanel$.subscribe(
			response => this.isShowPanel = response
		);

		this.programFirst$ = this._programQuery
			.getProgramTextSubscriber('unitFirst')
			.pipe(untilDestroyed(this))
			.subscribe(response => {
				this.currentFirstProgram = response;
				this._programService.setPanelVisibility(this.isNeededShowPanel());
			});

		this.programSecond$ = this._programQuery
			.getProgramTextSubscriber('unitSecond')
			.pipe(untilDestroyed(this))
			.subscribe(response => {
				this.currentSecondProgram = response;
				this._programService.setPanelVisibility(this.isNeededShowPanel());
			});
	}

	public setProgramTextFromFile(inputFile: any): void {
		const fileReader = new FileReader();

		fileReader.onload = (e) => {
			const textObject = {
				id: 0,
				text: fileReader.result
			};
			this.isFileLoaded = true;
			this.currentSecondProgram = textObject;
			this._programService.setPanelVisibility(this.isNeededShowPanel());
		};

		fileReader.readAsText(inputFile.target.files[0]);
	}

	public deleteFile(): void {
		this.isFileLoaded = false;

		this.currentSecondProgram = this._programQuery.getSelectedProgram(
			'unitSecond'
		);
	}

	public togglePanel(): void {
		return this._programService.setPanelVisibility(
			!this._programQuery.getPanelState()
		);
	}

	private setFullName = (item: IUnitFull) => {
		const inventory = item.inventoryNumber ? item.inventoryNumber : '';
		const fullName = item.unitType.name + ' | id ' + item.id + ' | ' + inventory;

		return {...item, fullName};
	};

	private isNeededShowPanel(): boolean {
		return !(
			this.currentFirstProgram &&
			(this.currentSecondProgram || this.isFileLoaded)
		);
	}
}
