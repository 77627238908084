import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild,} from '@angular/core';
import {FormControl} from '@angular/forms';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import { catchError, debounceTime, filter, map, mergeMap, startWith, switchMap, take, tap } from 'rxjs/operators';

import {Store} from '@store/data/plan/store/store';
import {UntilDestroy} from '@ngneat/until-destroy';
import {MatAutocomplete} from '@angular/material/autocomplete';
import {MesNomenclature} from '@store/data/plan/mes-nomenclature';
import {ItemsFilterResponse} from '@store/data/items-filter-response';
import {SdwebRepository} from '@shared/repositories/sdweb.repository';

@UntilDestroy()
@Component({
	selector: 'app-dto-item-select',
	templateUrl: 'dto-item-select.component.html',
	styleUrls: ['dto-item-select.component.scss'],
})
export class DtoItemSelectComponent implements OnInit, OnChanges {
	@ViewChild('auto') autocompleteModal: MatAutocomplete;

	@Input()
	label = '';

	@Input()
	classForm = '';

	@Input()
	displayWith = (option) => option?.name;

	@Input()
	startWith: string = '';

	@Input()
	value: number = null;

	@Input()
	items: any[];

	@Input()
	isActive!: boolean;

	@Input()
	nomenclatureGroupList: ItemsFilterResponse;

	@Input()
	isServerSide: boolean = false;

	@Output()
	selectedValue = new EventEmitter();

	@Output()
	selectedValueField = new EventEmitter();

	@Output()
	printValue = new EventEmitter();


	formControl: FormControl;
	filteredOptions: Observable<any[]>;
	isLoading$: Subject<boolean> = new Subject<boolean>();
	hasOptions$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

	constructor(
		private mesApi: SdwebRepository
	) {
	}

	ngOnInit(): void {
		this.initValues();
	}

	ngOnChanges(changes: SimpleChanges) {
		// todo это пока удалили , думали что это очищает, но походу нет
		if (!!this.formControl) {
			this.formControl.setValue(this.items.find(item => item.id === this.value));
		}

		if (this.isActive !== undefined && this.formControl !== undefined) {
			this.isActive ? this.formControl.enable() : this.formControl.disable();
		}
	}

	initValues(): void {
		this.formControl = new FormControl(
			this.items?.find(item => item.id === this.value)
		);

		if (!this.isActive) {
			this.formControl.disable();
		}

		this.initFilteredOptions(this.startWith);
	}

	initFilteredOptions(startWidth: string): void {
		if (!startWidth) {
			startWidth = '';
		}

		const pipesMeth = (obs: any) => {
			return obs.pipe(
				startWith(startWidth),
				filter((event) => typeof event === 'string'),
				map((event: any) => event.length === 0 ? '0' : event),
				debounceTime(1000),
				tap(() => this.isLoading$.next(true)),
				switchMap((s: string) => this.mesApi.findNomenclatureByGroup(s, this.nomenclatureGroupList)),
				catchError((err) => {
					this.initFilteredOptions('');
					this.isLoading$.next(false);
					return [];
				}),
				map((res: MesNomenclature[]) => this.definitionServerSide(res)),
				tap(() => this.isLoading$.next(false)),
				tap((res: Array<any>) => this.hasOptions$.next(res?.length > 0)),

				tap((res) => {
					if (!!startWidth?.length) {
						this.formControl.setValue(res[0]);
						this.selectValue(res[0]);
						this.initFilteredOptions('');
					}
				}),
			);
		};

		if (this.isServerSide) {
			this.filteredOptions = pipesMeth(this.formControl.valueChanges);
		} else {
			this.filteredOptions = this.formControl.valueChanges
				.pipe(
					startWith(startWidth),
					map((value) => this._filter(value)),
					tap((res: Array<any>) => this.hasOptions$.next(!!res?.length)),

					tap((res) => {
						if (!!startWidth?.length) {
							this.formControl.setValue(res[0]);
							this.selectValue(res[0]);
							this.initFilteredOptions('');
						}
					}),
				);
		}
	}

	selectValue(value: any): void {
		if (this.isServerSide) {
			this.items = [value];
		}
		this.selectedValue.emit(value.id);
		this.selectedValueField.emit(value);
	}

	clear(): void {
		this.formControl.setValue('');
		this.selectedValue.emit(null);
		this.selectedValueField.emit(null);
	}

	private _filter(value: any): any[] {
		if (!value && typeof value !== 'string') {
			return;
		}

		const filterValue = (!!value?.id)
			? value.name.toLowerCase()
			: value.toLowerCase();

		return this.items?.filter(
			option => option.name.toLowerCase().includes(filterValue)
		);
	}

	private definitionServerSide(res: MesNomenclature[]): MesNomenclature[] {
		this.items = res;

		if (res.length > 0 && !!this.value && !this.formControl.value) {
			this.formControl.setValue(res.find((nom) => nom.id === this.value))
		}

		return res;
	}
}
