import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatFormFieldAppearance} from '@angular/material/form-field/form-field';
import {MatMenuTrigger} from '@angular/material/menu';
import {cloneDeep} from 'lodash';

import {appearanceAnimation} from '../../animations/common.animations';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-timepicker',
	templateUrl: 'timepicker.component.html',
	styleUrls: ['timepicker.component.scss'],
	animations: [appearanceAnimation],
})
export class TimepickerComponent implements OnInit {
	@ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;

	@Input()
	timeForm: FormControl;

	@Input()
	labelForm: string = null;

	@Input()
	appearance: MatFormFieldAppearance = 'fill';

	public hour: string[];
	public minute: string[];
	public selectedHour: string;
	public selectedMinutes: string;
	public isOpenMenu: boolean;

	constructor() {
		this.hour = [];
		this.minute = [];
		this.selectedHour = '0';
		this.selectedMinutes = '0';
		this.isOpenMenu = false;
	}

	public ngOnInit(): void {
		const hour = this.timeForm.value.getHours();
		const minute = this.timeForm.value.getMinutes();

		this.selectedHour = `${hour}`;
		this.selectedMinutes = minute > 9 ? `${minute}` : `0${minute}`;

		for (let i = 0; i < 24; i++) {
			this.hour.push(`${i}`);
		}

		for (let i = 0; i < 60; i++) {
			const value = i > 9 ? `${i}` : `0${i}`;
			this.minute.push(value);
		}

		this.timeForm.valueChanges
			.pipe(untilDestroyed(this))
			.subscribe((time: Date) => {
				this.selectedHour = time.getHours().toString();
				this.selectedMinutes = time.getMinutes().toString();
			});
	}

	public closeMenu(): void {
		this.isOpenMenu = false;
	}

	public changeHour(event): void {
		this.selectedHour = event;

		this.timeForm.value.setHours(event);
		this.timeForm.patchValue(this.timeForm.value);
	}

	public changeMinute(event): void {
		this.selectedMinutes = event;

		this.timeForm.value.setMinutes(event);
		this.timeForm.patchValue(this.timeForm.value);
	}

	public cloneDeep(array: string[]): string[] {
		return cloneDeep(array);
	}
}
