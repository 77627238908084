<mat-tab-group
	mat-align-tabs="start"
	class="full-height"
	[(selectedIndex)]="selectedIndexTab"
>
	<mat-tab label="Информация">
		<div class="mx-auto">
			<div *ngIf='toolControll'>
				<label class="font-size-14">Выберите тип инструмента</label>
				<ngx-select
					[items]="toolTypeList"
					optionValueField="id"
					optionTextField="name"
					[(ngModel)]="sourceToolTypeId"
					size="small"
					noResultsFound="Ничего не найдено"
					(select)="doSelectToolType()"
				>
					<ng-template
						ngx-select-option-selected
						ngx-select-option
						let-option
						let-text="text"
					>
						<img
							*ngIf="option.data.image !='' && option.data.image != null"
							class="tool_type_img_menu"
							[src]="host + '/' + option.data.image"
						>
						{{ option.data.name }}
					</ng-template>
					<ng-template
						ngx-select-option-not-found
						let-input
					>
						Нет совпадений
					</ng-template>
				</ngx-select>
				<hr>
			</div>

			<div *ngIf="newTool && newTool.toolTypeId != 0">
				<div
					*ngIf="newTool.toolTypeImage"
					class="form-group upload"
				>
					<label>Изображение</label>
					<div class="model_img">
						<img
							height="150"
							(click)="changeSizeImage($event)"
							class="imgCnc"
							src="{{host + '/' + newTool.toolTypeImage}}"
						>
					</div>
				</div>

				<div
					*ngIf="newTool.toolTypeId"
					class="row"
				>
					<label
						class="g-col-sm-1"
					>Название </label>
					<div class="g-col-lg-2">
						<input
							readonly
							type="text"
							class="form-control form-control-sm"
							[(ngModel)]="newTool.toolTypeName"
						>
					</div>
				</div>

				<div class="row" *ngIf="newTool.toolTypeId">
					<label
						class="g-col-sm-1"
					>Время
						жизни инструмента (часы)</label>
					<div class="g-col-lg-2">
						<input
							*ngIf="newTool.id === 0"
							readonly
							type="number"
							min="0"
							class="form-control form-control-sm"
							[(ngModel)]="newTool.toolTypeLifetime"
						>

						<div *ngIf="newTool.id >0 && itemUsing && itemUsing.size > 0 && itemUsing.get(newTool.id)">
							{{ itemUsing.get(newTool.id).currentUsing }} / {{ itemUsing.get(newTool.id).maxUsing }} (час)
							<progressbar
								[value]='itemUsing.get(newTool.id).percent'
								type="danger"
								[type]="itemUsing.get(newTool.id).type"
							>
								<i>{{ itemUsing.get(newTool.id).percent }} %</i></progressbar>
						</div>

					</div>
				</div>

				<div class="row" *ngIf="newTool.toolTypeId">
					<label
						class="g-col-sm-1"
					>Характеристики</label>
					<div
						class="g-col-lg-2 mb-8"
					>
						<ng-container *ngFor="let charItem of newTool.toolTypeCharacteristics">
							<div class="char_row">
								<div
									class="size-colum"><input
									readonly
									class="form-control form-control-sm "
									[(ngModel)]="charItem.name"
									placeholder="Значение характеристики"
								/></div>

								<div
									class="size-colum"><input
									readonly
									class="form-control form-control-sm "
									[(ngModel)]="charItem.value"
									placeholder="Значение характеристики"
								/></div>
							</div>
						</ng-container>
					</div>
				</div>

				<div
					*ngIf="newTool.creatorUserName"
					class="row"
				>
					<label
						class="g-col-sm-1"
					>Зарегистрировал </label>
					<div class="g-col-lg-2">
						<input
							readonly
							type="text"
							class="form-control form-control-sm"
							[(ngModel)]="newTool.creatorUserName"
						>
					</div>
				</div>

				<div
					*ngIf="newTool.creationDate"
					class="row"
				>
					<label
						class="g-col-sm-1"
					>Дата регистрации </label>
					<div class="g-col-lg-2">
						<!-- <input readonly type="text" class="form-control form-control-sm" [(ngModel)]="createDate(newTool.creationDate)> -->
						{{ createDate(newTool.creationDate) }}
					</div>
				</div>

				<div
					*ngIf="newTool.toolTypeId"
					class="row"
				>
					<label
						class="g-col-sm-1">Комментарий </label>
					<div class="g-col-lg-2">
						<textarea
							class="form-control form-control-sm"
							placeholder="Введите комментарий"
							[(ngModel)]="newTool.comment"
						></textarea>
					</div>
				</div>

				<div
					*ngIf="newTool.writeOffUserName"
					class="row"
				>
					<label
						class="g-col-sm-1">Списал </label>
					<div class="g-col-lg-2">
						<input
							readonly
							type="text"
							class="form-control form-control-sm"
							[(ngModel)]="newTool.writeOffUserName"
						>
					</div>
				</div>

				<div
					*ngIf="newTool.writeOffDate"
					class="row"
				>
					<label
						class="g-col-sm-1">Дата списания
					</label>
					<div class="g-col-lg-2">
						<!-- <input readonly type="text" class="form-control form-control-sm" [(ngModel)]="createDate(newTool.writeOffDate)"> -->
						{{ createDate(newTool.writeOffDate) }}
					</div>
				</div>

				<div
					*ngIf="readyToWriteOff || newTool.writeOffComment"
					class="row"
				>
					<label
						class="g-col-sm-1">Комментарий
						при списании </label>
					<div class="g-col-lg-2">
						<textarea
							class="form-control form-control-sm"
							placeholder="Введите комментарий по причине списания инструмента"
							[(ngModel)]="newTool.writeOffComment"
						></textarea>
					</div>
				</div>

				<div *ngIf='toolControll'>
					<app-button
						*ngIf="!readyToDelete && !readyToWriteOff"
						class="g-button-danger-square inline-flex mr-8"
						(click)="clickDelete()"
					>
						Удалить
					</app-button>

					<app-button
						*ngIf="!readyToDelete && !readyToWriteOff && !newTool.writeOffDate"
						class="g-button-warning-square inline-flex"
						(click)="clickWriteOff()"
					>
						Списать
					</app-button>

					<div class="row">
						<div class="g-col-lg-4">
							<hr>
							<app-button
								*ngIf="!readyToDelete && !readyToWriteOff && !newTool.writeOffDate"
								class="g-button-success-square"
								(click)="onSubmit()"
							>
								Сохранить
							</app-button>

							<div *ngIf="readyToDelete">
								<div>Удалить инструмент? Удаление возможно, только если ранее инструмент не был зарегистрирован на станке.
									ВИНМАНИЕ! Выданный ранне QR код на данный инструмент станет недействительным!
								</div>
								<app-button
									class="g-button-danger-square save-btn"
									(click)="clickDelete()"
								>
									Удалить инструмент
								</app-button>
							</div>

							<div *ngIf="readyToWriteOff">
								<div>Списать инструмент? Дальнейшая регистрация данного инструмента на станках станет невозможной!</div>
								<app-button
									class="g-button-warning-square save-btn"
									(click)="clickWriteOff()"
								>
									Списать инструмент
								</app-button>
							</div>
						</div>
					</div>
				</div>

			</div>

			<div *ngIf="!newTool || ( newTool.id === 0 && newTool.toolTypeId === 0)">Инструмент отсутствует в базе</div>

		</div>
	</mat-tab>
	<mat-tab label="Действия">
		<div
			*ngIf="lastUnit"
			class="modal-content"
		>
			<app-button
				class="g-button-primary-square w50pc ml-auto mr-auto"
				(click)="goToUnit(lastUnit)"
			>
				Прейти на ранее просмотренное оборудование <br>
				{{ lastUnit.unitType.name }} инв. {{ lastUnit.inventoryNumber }}
			</app-button>
			<hr>
			<app-button
				*ngIf="!favoritesUnits || favoritesUnits.length === 0"
				class="g-button-primary-square w50pc ml-auto mr-auto"
				(click)='checkFavorites()'
			>
				Открыть список избранных станков
			</app-button>
			<app-statistic-units-container
				*ngIf="favoritesUnits && favoritesUnits.length > 0"
				[selectedUnits]='favoritesUnits'
				(onClickUnit)="goToUnit($event)"
			></app-statistic-units-container>
		</div>
	</mat-tab>
	<mat-tab label="QR Код">
		<div *ngIf="newTool && newTool.id != 0 && selectedIndexTab === 2">
			<app-qr-generator
				class="g-overflow-auto"
				[qrInfo]="qrInfo"
				[titleInfo]="titleInfo"
			></app-qr-generator>
		</div>
		<div *ngIf="!newTool || newTool.id === 0">
			Сохраните изменения по инструменту
		</div>
	</mat-tab>
</mat-tab-group>
