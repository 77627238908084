import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatTooltipModule} from '@angular/material/tooltip';
import {UnitsSelectorComponent} from '@shared/components/units-selector/units-selector.component';
import {IconModule} from '@shared/ui-components/icon/icon.module';
import {TableModule} from '@shared/ui-components/table/table.module';
import {SelectorLettersModule} from '@shared/pipes/selector-letters/selector-letters.module';
import {ButtonComponent} from '@shared/ui-components/button/button.component';
import {CheckboxComponent} from '@shared/ui-components/checkbox/checkbox.component';
import {ExpandModule} from '@shared/ui-components/expand/expand.module';
import {TooltipModule} from '@shared/directives/tooltip/tooltip.module';
import {MatInputModule} from '@angular/material/input';
import {PopoverModule} from '@shared/directives/popover/popover.module';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {TemplateUnitFilterModule} from '@shared/components/template-unit-filter/template-unit-filter.module';
import {ShareSelectorComponent} from '@shared/components/share-selector/share-selector.component';
import {SelectorLettersComponent} from '@shared/ui-components/selector-letters/selector-letters.component';

@NgModule({
	declarations: [UnitsSelectorComponent],
	imports: [
		CommonModule,
		IconModule,
		MatTooltipModule,
		TableModule,
		SelectorLettersModule,
		ButtonComponent,
		CheckboxComponent,
		ExpandModule,
		TooltipModule,
		MatInputModule,
		PopoverModule,
		MatCheckboxModule,
		TemplateUnitFilterModule,
		ShareSelectorComponent,
		SelectorLettersComponent,
	],
	exports: [UnitsSelectorComponent],
})
export class UnitsSelectorModule {
}
