import {Component, Inject} from '@angular/core';
import {ButtonComponent} from '@shared/ui-components/button/button.component';
import {MatIconModule} from '@angular/material/icon';
import {NgxUiLoaderModule} from 'ngx-ui-loader';
import {UnitSubscriptionComponent} from '@shared/components/unit-subscription/unit-subscription.component';
import {IUnitSubscriptionModalData} from '@shared/modals/unit-subscription/models/unit-subscription-modal.types';
import {UnitInformationModule} from '../../../modules/monitoring/modules/unit/components/unit-tabs-area/modules/unit-information/unit-information.module';
import {DIALOG_DATA, ModalRef} from '@shared/services/modals.service';

@Component({
	selector: 'app-unit-subscription-modal',
	templateUrl: './unit-subscription-modal.component.html',
	styleUrls: ['./unit-subscription-modal.component.scss'],
	standalone: true,
	imports: [
		ButtonComponent,
		MatIconModule,
		NgxUiLoaderModule,
		UnitInformationModule,
		UnitSubscriptionComponent,
	]
})
export class UnitSubscriptionModalComponent {
	constructor(
		@Inject(DIALOG_DATA) public data: IUnitSubscriptionModalData,
		private _dialogRef: ModalRef<UnitSubscriptionModalComponent>,
	) {
	}

	public closeModal(): void {
		this._dialogRef.close();
	}
}
