<div
	class="items"
	*ngFor="let dtype of dataService.activeDataConf"
>
	<div *ngIf="!dtype.isHidden">
		<div [ngSwitch]="dtype.type">
			<div *ngSwitchCase="dataConfigType.id">
				{{ dataService.dictionaryMap.get(dtype.name).get(dataService.activeItem[dtype.name]).name }}
			</div>
			<div *ngSwitchCase="dataConfigType.string">
				<mat-form-field appearance="outline">
					<mat-label>{{ dtype.title }}</mat-label>
					<input
						#input
						matInput
						[disabled]="dtype.isNotClickable"
						[ngModel]="dataService.activeItem[dtype.name]"
						(ngModelChange)="changeString(dtype.name, $event, input)"
					/>
				</mat-form-field>
			</div>
			<div *ngSwitchCase="dataConfigType.textarea">
				<mat-form-field appearance="outline">
					<mat-label>{{ dtype.title }}</mat-label>
					<textarea
						#textArea
						matInput
						[disabled]="dtype.isNotClickable"
						placeholder="Введите описание..."
						[ngModel]="dataService.activeItem[dtype.name]"
						(ngModelChange)="changeString(dtype.name, $event, textArea)"
					></textarea>
				</mat-form-field>
			</div>
			<div *ngSwitchCase="dataConfigType.numeric">
				<mat-form-field appearance="outline">
					<mat-label>{{ dtype.title }}</mat-label>
					<input
						matInput
						[disabled]="dtype.isNotClickable"
						type="number"
						[(ngModel)]="dataService.activeItem[dtype.name]"
					/>
				</mat-form-field>
			</div>
			<div *ngSwitchCase="dataConfigType.phone">
				<mat-form-field appearance="outline"
				>
					<mat-label>{{ dtype.title }}</mat-label>
					<span matPrefix class="text-middle">+7&nbsp;</span>
					<input
						matInput
						type="tel"
						appPhoneMask
						[withoutCode]="true"
						[firstValue]="dataService.activeItem[dtype.name]"
						(maskChange)="changeMask($event, dtype.name)"
						[disabled]="dtype.isNotClickable"
					/>
				</mat-form-field>
			</div>
			<div *ngSwitchCase="dataConfigType.dataIn">
				<mat-form-field appearance="outline">
					<mat-label>{{ dtype.title }}</mat-label>
					<input
						matInput
						[matDatepicker]="picker"
						[disabled]="dtype.isNotClickable"
						[(ngModel)]="dataService.activeItem[dtype.name]"
					>
					<mat-datepicker-toggle
						matSuffix
						[for]="picker"
					></mat-datepicker-toggle>
					<mat-datepicker #picker></mat-datepicker>
				</mat-form-field>
			</div>
			<div *ngSwitchCase="dataConfigType.ftpLink">
				<div class="row">
					<div class="col-sm-8">
						<mat-form-field appearance="outline">
							<mat-label>{{ dtype.title }}</mat-label>
							<input
								#docInput
								[(ngModel)]="dataService.activeItem[dtype.name]"
							/>
						</mat-form-field>
					</div>
					<div class="col-sm-1">
						<span
							class="pointer"
							matTooltip="Скопировать ссылку на документацию"
							(click)="copyInputInBuffer(docInput)"
						>
							<app-icon
								class="icon-padding"
								name="copy"
							></app-icon>
						</span>
					</div>
					<div class="col-sm-1">
						<span
							class="pointer"
							matTooltip="Перейти к скачиванию документации"
							(click)="goToFtp(dataService.activeItem[dtype.name])"
						>
							<app-icon
								class="icon-padding"
								name="cloud-download"
							></app-icon>
						</span>
					</div>
				</div>
			</div>
			<div *ngSwitchCase="dataConfigType.selector">
				<app-dto-item-select
					[isActive]="!dtype.isNotClickable"
					[label]="dtype.title"
					[name]="dtype.name"
					[startWith]="dtype.startWith"
					[value]="dataService.activeItem[dtype.name]"
					[items]="dataService.dictionaryList.get(dtype.name)"
					(selectedValueField)="changeSelectField(dtype.name, $event)"
				></app-dto-item-select>
			</div>
			<div *ngSwitchCase="dataConfigType.single_nomenclature">
				<app-dto-item-select
					[isActive]="!dtype.isNotClickable"
					[label]="dtype.title"
					[name]="dtype.name"
					[startWith]="dtype.startWith"
					[value]="dataService.activeItem[dtype.name]"
					[items]="dataService.dictionaryList.get(dtype.name)"
					[nomenclatureGroupList]="dtype.bindDataDisplay"
					[isServerSide]="true"
					[displayWith]="displayWithSingleNomenclature"
					(selectedValueField)="changeSelectField(dtype.name, $event)"
				></app-dto-item-select>
			</div>
			<div *ngSwitchCase="dataConfigType.boolean">
				<app-checkbox [(checked)]="dataService.activeItem[dtype.name]"></app-checkbox>
			</div>
		</div>
	</div>
</div>

<div *ngIf="showSaveButton" class="buttons">
	<app-button
		class="g-button-success-square mr-8"
		[loading]="loading"
		[disabled]="loading"
		(click)="onSubmit()"
	>
		Сохранить
	</app-button>
	<app-button
		class="g-button-danger-square"
		[disabled]="!dataService.activeItem || !dataService.activeItem.id || dataService.activeItem.id === 0"
		(click)="onDelete()"
	>
		Удалить
	</app-button>
</div>
