import {ITool} from '@shared/models/tool';

export class Tool implements ITool {
	id: number;

	toolTypeId: number;
	toolTypeName: string;
	toolTypeCharacteristics: any[];
	toolTypeLifetime: number;
	toolTypeImage: string;

	creatorUserId: number;
	creatorUserName: string;
	creationDate: Date;
	comment: string;

	writeOffUserId: number;
	writeOffUserName: string;
	writeOffDate: Date;
	writeOffComment: string;

	constructor() {
		this.id = 0;

		this.toolTypeId = 0;
		this.toolTypeName = null;
		this.toolTypeCharacteristics = null;
		this.toolTypeLifetime = null;
		this.toolTypeImage = null;

		this.creatorUserId = 0;
		this.creatorUserName = null;
		this.creationDate = null;
		this.comment = null;

		this.writeOffUserId = null;
		this.writeOffUserName = null;
		this.writeOffDate = null;
		this.writeOffComment = null;
	}
}
