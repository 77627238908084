import {Component, Inject} from '@angular/core';
import {INeedResetPasswordModalData} from '@shared/modals/need-reset-password-modal/models/need-reset-password-modal.types';
import {MatIconModule} from '@angular/material/icon';
import {DIALOG_DATA, ModalRef} from '@shared/services/modals.service';
import {ButtonComponent} from '@shared/ui-components/button/button.component';

@Component({
	selector: 'app-need-reset-password-modal',
	templateUrl: './need-reset-password-modal.component.html',
	styleUrl: './need-reset-password-modal.component.scss',
	standalone: true,
	imports: [
		MatIconModule,
		ButtonComponent,
	]
})
export class NeedResetPasswordModalComponent {
	constructor(
		@Inject(DIALOG_DATA) public data: INeedResetPasswordModalData,
		private _dialogRef: ModalRef<NeedResetPasswordModalComponent>,
	) {
	}

	public goToPageToResetPassword(): void {
		this.closeModal();
		window.open(this.data.path);
	}

	public closeModal(): void {
		this._dialogRef.close();
	}
}



