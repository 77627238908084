import {debounceTime, takeUntil} from 'rxjs/operators';
import {BehaviorSubject, combineLatest, Subject} from 'rxjs';
import {ITree} from '@shared/models/tree';
import {TreeUtils} from '../../../utils';
import {TreeNode} from '@shared/ui-components/tree/services/tree-node';

export class Tree {
	public rootNodes: TreeNode[] | TreeNode;

	public tree$: Subject<ITree | ITree[]>;
	public selectedIds$: Subject<number[]>;
	public selectedNodes$: Subject<ITree[]>;
	public clickNode$: Subject<ITree>;

	public isSelectedAll$: BehaviorSubject<boolean>;
	public isArray$: BehaviorSubject<boolean>;
	public isEmpty$: BehaviorSubject<boolean>;

	public unsubscribe$: Subject<void>;

	private _countNodes: number;
	private _tree: ITree[] | ITree;

	constructor(tree: ITree[] | ITree) {
		this.initSubjects();
		this.initTree(tree);
	}

	private initSubjects(): void {
		this.tree$ = new Subject<ITree>();
		this.selectedIds$ = new Subject();
		this.selectedNodes$ = new Subject();
		this.clickNode$ = new Subject();

		this.isSelectedAll$ = new BehaviorSubject<boolean>(false);
		this.isArray$ = new BehaviorSubject<boolean>(false);
		this.isEmpty$ = new BehaviorSubject<boolean>(true);
		this.unsubscribe$ = new Subject<void>();
	}

	public update(updatedTree: ITree[] | ITree): void {
		if (!TreeUtils.isEqualById(this._tree, updatedTree)) {
			TreeUtils.eachFromRoot(this.rootNodes, (tree) => {
				tree.click$.complete();
				tree.checked$.complete();
			});

			this.initTree(updatedTree);

			return;
		}

		TreeUtils.eachDownForPair<TreeNode, ITree>(
			this.rootNodes,
			updatedTree,
			(t1, t2) => {
				t1.checked = t2.checked || t2.isChecked;
			}
		);

		this.checkCorrect();
	}

	private initTree(tree: ITree | ITree[]): void {
		this.initDataOfTree(tree);
		this.initDetectTreeChanges(this.buildTree());
	}

	private buildTree(): BehaviorSubject<boolean>[] {
		const nodeUpdates: BehaviorSubject<boolean>[] = [];
		this._countNodes = 0;

		this.rootNodes = TreeUtils.cloneTree<ITree, TreeNode>(
			this._tree,
			(original) => {
				const newNode = new TreeNode(original);

				nodeUpdates.push(newNode.checked$);
				newNode.click$
					.pipe(takeUntil(this.unsubscribe$))
					.subscribe(this.clickNode$);

				this._countNodes++;

				return newNode;
			}
		);

		return nodeUpdates;
	}

	private initDataOfTree(tree: ITree | ITree[]): void {
		this._tree = tree;

		const isArray = Array.isArray(tree);
		const isEmpty = isArray
			? !Boolean((tree as TreeNode[])?.length)
			: !Boolean(tree);

		this.isArray$.next(isArray);
		this.isEmpty$.next(isEmpty);
	}

	private initDetectTreeChanges(nodeChanges: BehaviorSubject<boolean>[]): void {
		combineLatest(nodeChanges)
			.pipe(
				debounceTime(50),
				takeUntil(this.unsubscribe$)
			).subscribe(() => this.emit());
	}

	public clear(): void {
		this.rootNodes = null;

		this.tree$ = null;
		this.selectedIds$ = null;
		this.selectedNodes$ = null;
		this.clickNode$ = null;

		this.isSelectedAll$ = null;
		this.isArray$ = null;
		this.isEmpty$ = null;

		this.unsubscribe$.next();
		this.unsubscribe$.complete();
		this.unsubscribe$ = null;
	}

	public emit(): void {
		const {selectedIds, selectedNodes} = this.getSelected();

		this.tree$.next(this._tree);

		this.selectedNodes$.next(selectedNodes);
		this.selectedIds$.next(selectedIds);
		this.isSelectedAll$.next(selectedIds.length >= this._countNodes);
	}

	public setIds(ids: number[]): void {
		this.callMethod(TreeNode.prototype.applyIds, ids);
	}

	public sort(): void {
		this.callMethod(TreeNode.prototype.sort);
	}

	public applyFilter(data: { line: string }): void {
		this.callMethod(TreeNode.prototype.applyFilter, data);
	}

	public checkCorrect(): void {
		this.callMethod(TreeNode.prototype.checkCorrect);
	}

	public applyToAll(value: boolean): void {
		this.callMethod(TreeNode.prototype.applyToAll, value);
	}

	public getSelected(): { selectedIds: number[], selectedNodes: ITree[] } {
		const value = this.callMethod(TreeNode.prototype.getSelected);

		if (!this.isArray$.getValue()) {
			return value;
		}

		return value.reduce((acc, cur) => {
			acc.selectedIds = acc.selectedIds.concat(cur.selectedIds);
			acc.selectedNodes = acc.selectedNodes.concat(cur.selectedNodes);

			return acc;
		}, {selectedIds: [], selectedNodes: []});
	}

	private callMethod(func: Function, args?): any {
		if (!this.isArray$) {
			return;
		}

		if (this.isArray$.getValue()) {
			return (this.rootNodes as TreeNode[]).map(i => func.call(i, args));
		} else {
			return func.call((this.rootNodes as TreeNode), args);
		}
	}
}
