import {Component, EventEmitter, Inject, Input, OnInit, Optional, Output,} from '@angular/core';
import {NotifierService} from 'angular-notifier';
import {DIALOG_DATA, ModalRef} from '@shared/services/modals.service';
import {IEnlargedImage} from '@shared/modals/enlarged-image-modal/models/enlarged-image.types';

@Component({
	selector: 'app-enlarged-image',
	templateUrl: 'enlarged-image-modal.component.html',
	styleUrls: ['enlarged-image-modal.component.scss'],
})
export class EnlargedImageModalComponent implements OnInit {
	@Input()
	urlImg: string = null;

	@Output()
	close = new EventEmitter<void>();

	public isNotImg: boolean;

	constructor(
		@Optional() public dialogRef: ModalRef<EnlargedImageModalComponent>,
		@Optional() @Inject(DIALOG_DATA) public data: IEnlargedImage,
		private _notifierService: NotifierService
	) {
		this.isNotImg = false;
	}

	public ngOnInit(): void {
		if (this?.data?.urlImg) {
			this.urlImg = this.data.urlImg;
		}
	}

	public setDefaultImg(): void {
		this._notifierService.notify('error', 'Не удалось открыть изображение!');

		this.onClose();
	}

	public onClose(): void {
		if (this.data.urlImg || this.data.urlsImgs) {
			this.dialogRef.close();
		} else {
			this.close.emit();
		}
	}
}
