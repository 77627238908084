import {Component, Inject} from '@angular/core';
import {ButtonComponent} from '@shared/ui-components/button/button.component';
import {MatIconModule} from '@angular/material/icon';
import {
	ProgramCompareComponent
} from '../../../modules/monitoring/modules/unit/components/unit-tabs-area/components/details/components/program-compare/program-compare.component';
import {ICompareProgramsModalData} from '@shared/modals/compare-programs-modal/models/compare-programs-modal.types';
import {DIALOG_DATA, ModalRef} from '@shared/services/modals.service';

@Component({
	selector: 'app-compare-programs-modal',
	templateUrl: './compare-programs-modal.component.html',
	styleUrls: ['./compare-programs-modal.component.scss'],
	standalone: true,
	imports: [
		ButtonComponent,
		MatIconModule,
		ProgramCompareComponent,
	]
})
export class CompareProgramsModalComponent {
	constructor(
		@Inject(DIALOG_DATA) public data: ICompareProgramsModalData,
		private _dialogRef: ModalRef<CompareProgramsModalComponent>,
	) {
	}

	public closeModal(): void {
		this._dialogRef.close();
	}
}
