import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'program-compare' })
export class ProgramCompareStore extends Store<any> {
	constructor() {
		super({
			showProgramPanel: true,
		});
	}
}
