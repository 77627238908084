import {ChangeDetectionStrategy, Component, Input, OnChanges} from '@angular/core';
import {ConfigService} from '@shared/services/config.service';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {CarouselModule} from '@shared/ui-components/carousel/carousel.module';
import {NgForOf, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault} from '@angular/common';

@Component({
	selector: 'app-report-reviewer',
	templateUrl: './report-reviewer.component.html',
	styleUrls: ['./report-reviewer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		CarouselModule,
		NgSwitch,
		NgSwitchDefault,
		NgForOf,
		NgSwitchCase,
		NgIf
	]
})
export class ReportReviewerComponent implements OnChanges {
	@Input() jasperReport: string[];

	public htmlFileList: SafeResourceUrl[];
	public downloadFileList: string[];

	constructor(
		private globalConfig: ConfigService,
		private sanitaiser: DomSanitizer,
	) {
	}

	ngOnChanges(): void {
		this.htmlFileList = [];
		this.downloadFileList = [];

		this.jasperReport.forEach(path => {
			const pathDownload = `${this.globalConfig.getConfig(['javaApiUrl']).javaApiUrl}/${path}`;

			if (path.includes('.html')) {
				this.htmlFileList.push(
					this.sanitaiser.bypassSecurityTrustResourceUrl(pathDownload)
				);
			} else {
				this.downloadFileList.push(pathDownload);
			}
		});
	}

	openLink(path: string) {
		window.open(path);
	}

	getExtension(url: string) {
		const extension = url.split('.').at(-1);

		return extension.length < 5 ? extension : 'file';
	}
}
