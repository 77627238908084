<mat-form-field
	class="mat-form-field"
	appearance="outline"
	color="primary"
>
	<mat-label>{{ labelForm }}</mat-label>

	<input
		[value]="dateForm.value"
		(dateChange)="changeInput($event)"
		matInput
		[matDatepicker]="picker1"
		formControlName="start"
	>

	<mat-datepicker-toggle
		matSuffix
		[for]="picker1"
	>
		<mat-icon
			matDatepickerToggleIcon
			svgIcon="date-range"
		></mat-icon>
	</mat-datepicker-toggle>

	<mat-datepicker #picker1></mat-datepicker>
</mat-form-field>
