import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {IMnemoMapModalData} from '@shared/modals/mnemo-map/models/mnemo-map-modal.types';
import {UnitHelpersService} from '../../../modules/monitoring/modules/unit/services/unit-helpers.service';
import {DIALOG_DATA, ModalRef} from '@shared/services/modals.service';


@Component({
	selector: '',
	templateUrl: 'mnemo-map-modal.component.html',
	styleUrls: ['mnemo-map-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MnemoMapModalComponent {
	constructor(
		@Inject(DIALOG_DATA) public data: IMnemoMapModalData,
		public dialogRef: ModalRef<MnemoMapModalComponent>,
		public unitHelpers: UnitHelpersService,
	) {
	}
}
