@if (chartName) {
	<div
		id="{{chartName}}"
		style="width: 100%; margin-left:auto; margin-right:auto;"
		[style.height]="sizeHeight +'px'"
	></div>
}

@if (isBuilding) {
	<div class="g-loader">
		<span></span>
	</div>
}
