import {Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, Output,} from '@angular/core';
import {SwitchThemeService} from '@shared/services/switch-theme.service';
import {IStaticStat} from '@shared/components/charts/modules/static-bar/models/static-bar.types';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {of, Subject} from 'rxjs';
import {delay, skip, takeUntil} from 'rxjs/operators';

declare var CanvasJS: any;

@UntilDestroy()
@Component({
	selector: 'app-static-bar',
	templateUrl: './static-bar.component.html',
	styleUrls: ['./static-bar.component.scss'],
})
export class StaticBarComponent implements OnChanges, OnDestroy {
	@Input() id;
	@Input() staticStat;
	@Input() idlesData;
	@Input() isStaticWithIdels;
	@Input() startTime;
	@Input() endTime;
	@Input() isIdleInChart;
	@Input() prepareData;

	@Output() completedBuild = new EventEmitter<void>();

	private _chart;
	private _isLightTheme: boolean;

	public chartName: string;
	public isBuilding: boolean;
	public sizeHeight: number;

	private _cancelBuilding$: Subject<void>;

	constructor(
		private switchThemeService: SwitchThemeService
	) {
		this.isBuilding = true;
		this.sizeHeight = this.getSizeHeight();
		this._cancelBuilding$ = new Subject<void>();
	}

	@HostListener('window:resize', ['$event'])
	private checkAccess() {
		this.render();
	}

	public ngOnDestroy(): void {
		this._chart?.destroy();
		this._chart = undefined;
	}

	public ngOnChanges(changes): void {
		this.isBuilding = true;
		this._cancelBuilding$.next();

		if (this.idlesData) {
			this.idlesData = Object.values(this.idlesData);
		}

		if (this.idlesData) {
			const idlesMap = new Map();

			this.idlesData.forEach(element => {
				if (element.interval) {
					const start =
						new Date(element.interval.start).getTime() <
						this.startTime.getTime()
							? this.startTime.getTime()
							: new Date(element.interval.start).getTime();

					const end =
						new Date(element.interval.end).getTime() >
						this.endTime.getTime()
							? this.endTime.getTime()
							: new Date(element.interval.end).getTime();

					if (!idlesMap.has(element.title)) {
						idlesMap.set(element.title, {
							intervalDauration: end - start,
							title: element.title,
							color: element.color,
						});
					} else {
						idlesMap.get(element.title).intervalDauration +=
							end - start;
					}
				}
			});

			this.idlesData = Array.from(idlesMap.values());
		}

		if (this.id) {
			this.chartName = 'staticGraphContainer' + this.id;
		}

		of(null)
			.pipe(
				delay(500),
				untilDestroyed(this),
				takeUntil(this._cancelBuilding$),
			).subscribe(() => this.buildStatic());

		this.switchThemeService
			.isLightThemeListener()
			.pipe(
				untilDestroyed(this),
				skip(1),
			)
			.subscribe(i => {
				this._isLightTheme = i;
				this.buildStatic();
			});
	}

	private render(): void {
		if (!document.querySelector(`#${this.chartName}`) || !this._chart) {
			return;
		}

		this._chart.render();
	}

	private buildStatic(): void {
		if (!document.querySelector(`#${this.chartName}`)) {
			return;
		}

		if (this._chart) {
			this._chart?.destroy();
		}

		const statData = this.dataPrepare();

		this._chart = new CanvasJS.Chart(this.chartName, {
			backgroundColor: 'rgba(37,43,46,0)',
			toolTip: {
				cornerRadius: 5,
				backgroundColor: 'rgba(76,76,76,0.9)',
				fontColor: '#FFFFFF',
				borderThickness: 3,
				contentFormatter: function (e) {
					return (
						'<div style="color:#d7d6d6"><span style="color:#fed42a; text-shadow: 1px 1px 2px black, 0 0 1em #ffffff"><b style="color:' +
						e.entries[0].dataPoint.color +
						'">' +
						e.entries[0].dataPoint.name +
						'</b> ' +
						e.entries[0].dataPoint.y +
						'%' +
						'<div>' +
						msToTime(e.entries[0].dataPoint.absolute * 1000) +
						'</div>' +
						'</span>' +
						'</div>'
					);
				},
			},
			axisX: {
				lineThickness: 0,
				tickLength: 0,
				tickThickness: 0,
				labelFontSize: 0,
			},
			axisY: {
				lineThickness: 0,
				tickLength: 0,
				tickThickness: 0,
				interval: 10,
				labelFontSize: 0,
				labelFontColor: this._isLightTheme ? '#181c20' : '#fdfdfe',
			},
			dataPointWidth: setDataPointWidth(this.isIdleInChart),
			data: statData,
		});

		function setDataPointWidth(isIdleInChart) {
			if (isIdleInChart) {
				if (window.screen.width > 3000) {
					return 36;
				} else {
					return 16;
				}
			} else {
				if (window.screen.width > 3000) {
					return 110;
				} else {
					return 50;
				}
			}
		}

		this.render();

		function msToTime(duration) {
			const seconds = parseInt('' + ((duration / 1000) % 60));
			const minutes = parseInt('' + ((duration / (1000 * 60)) % 60));
			const hours = parseInt('' + ((duration / (1000 * 60 * 60)) % 24));
			const day = parseInt('' + duration / (1000 * 60 * 60 * 24));

			return (
				day +
				'д. ' +
				hours +
				' ч. ' +
				minutes +
				'мин. ' +
				seconds +
				'сек.'
			);
		}

		setTimeout(() => {
			this.isBuilding = false;
			this.completedBuild.emit();
		}, 200);
	}

	private staticDataPrepare(): IStaticStat[] {
		const data = [];
		let totalSum = 0;

		this.staticStat.forEach(element => {
			totalSum += element.absolute;
		});

		this.staticStat.forEach(element => {
			data.push({
				indexLabelFontSize: this.getFontSize(),
				type: 'stackedBar100',
				indexLabelPlacement: 'inside',
				indexLabel:
					this.getPercent(element.absolute, totalSum) > 10
						? '{y}%'
						: '',
				dataPoints: [
					{
						y: this.getPercent(element.absolute, totalSum),
						name: element.status.title,
						label: 'Static',
						title: element.status.title,
						color: element.status.color,
						absolute: element.absolute,
					},
				],
			});
		});

		return data;
	}

	private idlesDataPrepare(data: IStaticStat[]): any {
		let run;
		let otherStaticSum = 0;
		let totalSum = 0;

		this.staticStat.forEach(element => {
			if (element.status.orderNumber === 0) {
				run = element;
			}

			totalSum += element.absolute;
		});

		otherStaticSum = totalSum - (run ? run.absolute : 0);

		if (run) {
			data.push({
				indexLabelFontSize: this.getFontSize(),
				type: 'stackedBar100',
				indexLabelPlacement: 'inside',
				indexLabel:
					this.getPercent(run.absolute, totalSum) > 10 ? '{y}%' : '',
				dataPoints: [
					{},
					{
						y: this.getPercent(run.absolute, totalSum),
						label: 'StaticIdle',
						name: run.status.title,
						color: run.status.color,
						absolute: run.absolute,
					},
				],
			});
		}

		if (this.idlesData) {
			this.idlesData.forEach(element => {
				let absolute = element.intervalDauration / 1000;
				if (otherStaticSum - absolute < 0) {
					absolute = otherStaticSum;
					otherStaticSum = 0;
				} else {
					otherStaticSum -= absolute;
				}
				data.push({
					indexLabelFontSize: this.getFontSize(),
					type: 'stackedBar100',
					indexLabelPlacement: 'inside',
					indexLabel:
						this.getPercent(absolute, totalSum) > 10 ? '{y}%' : '',
					dataPoints: [
						{},
						{
							y: this.getPercent(absolute, totalSum),
							label: 'StaticIdle',
							name: element.title,
							color: element.color,
							absolute: absolute,
						},
					],
				});
			});
		}

		data.push({
			indexLabelFontSize: this.getFontSize(),
			type: 'stackedBar100',
			indexLabelPlacement: 'inside',
			indexLabel:
				this.getPercent(otherStaticSum, totalSum) > 10 ? '{y}%' : '',
			dataPoints: [
				{},
				{
					y: this.getPercent(otherStaticSum, totalSum),
					label: 'StaticIdle',
					name: 'Технологический простой',
					color: '#f2f2f2',
					absolute: otherStaticSum,
				},
			],
		});

		return data;
	}

	private dataPrepare(): IStaticStat[] {
		let data = this.staticDataPrepare();

		if (this.isIdleInChart || this.idlesData) {
			data = this.idlesDataPrepare(data);
		}

		return data;
	}

	private getPercent(value: number, total: number): number {
		return Math.round(((value * 100) / total) * 100) / 100;
	}

	private getSizeHeight(): number {
		return window.screen.width > 3000 ? 110 : 60;
	}

	private getFontSize(): 32 | 12 {
		return window.screen.width > 3000 ? 32 : 12;
	}
}
