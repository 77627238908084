import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {IConnectionLossModalData} from '@shared/modals/connection-loss/models/connection-loss-modal.types';
import {DIALOG_DATA, ModalRef} from '@shared/services/modals.service';

@Component({
	selector: '',
	templateUrl: 'connection-loss-modal.component.html',
	styleUrls: ['connection-loss-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConnectionLossModalComponent {
	constructor(
		@Inject(DIALOG_DATA) public data: IConnectionLossModalData,
		private _dialogRef: ModalRef<ConnectionLossModalComponent>,
	) {
	}

	public closeModal(): void {
		this._dialogRef.close();
	}

	protected readonly close = close;
}
