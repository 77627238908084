import {Observable} from 'rxjs';
import {IUnitDeviceParameter} from '@shared/services/unit.service';
import {FormControl} from '@angular/forms';
import {
	ITrendsTemplate,
	ITrendsTemplateCreate,
	ITrendsTemplateCreateParameter
} from '../../../../modules/monitoring/modules/unit/components/unit-tabs-area/components/trends/components/trends-template/models/trends-template.models';

export abstract class TrendsTemplateModalService {
	abstract title: string;
	abstract btnSaveTitle: string;
	abstract btnSaveAndApply: string;
	abstract formControl: FormControl;
	abstract isDefault: boolean;

	abstract charts: IUnitDeviceParameter[];
	abstract selectedCharts: IUnitDeviceParameter[][];
	abstract selectedChartsMap: Map<number, IUnitDeviceParameter>;

	abstract init(charts: IUnitDeviceParameter[], template: ITrendsTemplate): void;

	abstract save(unitId: number, userId: number): Observable<ITrendsTemplate>;

	preparationData(unitId: number, userId: number): ITrendsTemplateCreate {
		const selectedCharts: ITrendsTemplateCreateParameter[] = [];
		let order = 0;
		this.selectedCharts.forEach((charts: IUnitDeviceParameter[], trendNumber: number) => {
			charts.forEach((chart: IUnitDeviceParameter) => {
				selectedCharts.push({
					id: chart.deviceParameterId,
					order: order,
					trendNumber: trendNumber,
				});
				order++;
			});
		});

		return {
			name: this.formControl.value,
			unitId: unitId,
			userId: userId,
			isDefault: this.isDefault,
			parameterIds: selectedCharts
		};
	}
}
