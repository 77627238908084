<div class="modal g-overflow-visible">
	<div class="modal__header">
		<div class="title">QR код станка</div>

		<app-button
			svgIcon="close"
			class="btn-close g-button-secondary-empty"
			(click)="closeModal()"
		></app-button>
	</div>

	<div
		class="modal__content g-overflow-visible"
		style="align-items: center"
	>
		<app-qr-generator
			[qrInfo]="data.qrInfo"
			[titleInfo]="data.titleInfo"
		></app-qr-generator>
	</div>
</div>
