import {EventEmitter, Injectable} from '@angular/core';

import {environment} from '../../../environments/environment';
import {ConfigService} from '@shared/services/config.service';
import {MODALS} from '@shared/modals/modals';
import {AuthService} from '../../modules/auth/services/auth.service';
import {ModalsService} from '@shared/services/modals.service';

interface IMessage {
	method: string;
	requestId: number;
	data: any;
}

export enum EWebsocketEvents {
	IDENTIFY = 'Authentication/identify',
	ON_UNIT_DATA = 'EVENT/onUnitData',
	ON_LOCATION_DATA = 'EVENT/onLocationData',
	ON_USER_NOTIFICATION_DATA = 'EVENT/onUserNotificationData',
	IS_SOCKET_CLOSE = 'EVENT/isSocketClose',
	ON_EXECUTOR_IS_ACTIVE_DATA = 'EVENT/onExecutorIsActiveData',
	ON_EXECUTOR_IS_NOT_ACTIVE_DATA = 'EVENT/onExecutorIsNotActiveData',
	ON_OBSERVER_IS_ACTIVE_DATA = 'EVENT/onObserverIsActiveData',
	ON_OBSERVER_IS_NOT_ACTIVE_DATA = 'EVENT/onObserverIsNotActiveData',
}

@Injectable({
	providedIn: 'root'
})
export class WebsocketService {
	public wss: WebSocket;
	public req: {
		[id: number]: EventEmitter<number>
	} = {};


	requestId = 0;
	private _message: IMessage = <IMessage>{};
	private _messages = {
		[EWebsocketEvents.IDENTIFY]: new EventEmitter(),
		[EWebsocketEvents.ON_UNIT_DATA]: new EventEmitter(),
		[EWebsocketEvents.ON_LOCATION_DATA]: new EventEmitter(),
		[EWebsocketEvents.ON_USER_NOTIFICATION_DATA]: new EventEmitter(),
		[EWebsocketEvents.IS_SOCKET_CLOSE]: new EventEmitter(),
		[EWebsocketEvents.ON_EXECUTOR_IS_ACTIVE_DATA]: new EventEmitter(),
		[EWebsocketEvents.ON_EXECUTOR_IS_NOT_ACTIVE_DATA]: new EventEmitter(),
		[EWebsocketEvents.ON_OBSERVER_IS_ACTIVE_DATA]: new EventEmitter(),
		[EWebsocketEvents.ON_OBSERVER_IS_NOT_ACTIVE_DATA]: new EventEmitter(),
	};
	private _isSocketOpen = false;

	public get messages() {
		return new Proxy(this._messages, {
			get: (target, prop) => {
				if (!target[prop]) {
					target[prop] = new EventEmitter();
				}

				return target[prop];
			},
		});
	}

	constructor(
		private _authService: AuthService,
		private _config: ConfigService,
		private _dialog: ModalsService,
	) {
		this._authService.isLoggedIn.subscribe((res) => {
			if (res) {
				this.wssConnect();
			}
			// else if (!!this.wss) {
			// 	this.wss.close(1000);
			// }
		});

		this.messages[EWebsocketEvents.IS_SOCKET_CLOSE]
			.pipe()
			.subscribe(data => {
				const isWssCheckerEnable = this._config.getConfig(['isWssCheckerEnable']).isWssCheckerEnable;
				if (isWssCheckerEnable && isWssCheckerEnable === 'true') {
					this._dialog.open(MODALS.ConnectionLoss);
				}
			});
	}

	private wssConnect(): void {
		if (!this._authService.isLoggedIn.value) {
			return;
		}

		console.log('CONNECT TO WSS');

		this.wss = new WebSocket(`${environment.wssUrl}/wss`);

		this.wss.onopen = event => {
			console.log('CONNECTED TO WSS');
		};

		this.wss.onmessage = event => {
			this._isSocketOpen = true;
			const data = JSON.parse(event.data);

			if (data.error) {
				if (!data.data) {
					return;
				}
			}


			if (this.req[data.requestId]) {
				this.req[data.requestId].emit(data.data);

				delete this.req[data.requestId];
			} else {
				const m = data.method.split('/');

				this.messages[m[0] + '/' + m[1]].emit(data.data);
			}
		};

		this.wss.onclose = event => {
			console.log('onclose', event);
			this.checkWss();
		};
		this.wss.onerror = event => {
			console.log('onerror', event);
			this.checkWss();
		};
	}

	private checkWss(): void {
		if (this._isSocketOpen) {
			this._isSocketOpen = false;

			this.messages[EWebsocketEvents.IS_SOCKET_CLOSE].emit('Потеряна связь!');

			if (this._authService.isLoggedIn.value) {
				this.wssConnect();
			}
		}
	}

	public send<I, O>(method: string, data: I, type: string = null): Promise<O> {
		this.requestId++;

		switch (type) {
			case 'POST':

				break;

			case 'GET':

				break;

			default:
				this._message = <IMessage>{
					method: method,
					data: data,
					requestId: this.requestId
				};

				this.req[this.requestId] = new EventEmitter();

				this.wsSend(JSON.stringify(this._message));

				console.log('SEND TO SERVER ', this._message);

				return new Promise<O>((resolve, reject) => {
					this.req[this.requestId].subscribe((data) => {
						// @ts-ignore
						return resolve(data);
					});
				});

				break;
		}
	}

	private wsSend(data: string): void {
		if (!this.wss.readyState) {
			setTimeout(() => {
				console.log('Error! ' + data);
				this.wsSend(data);
			}, 500);
		} else {
			console.log('readyState ' + this.wss.readyState + ' CLOSED ' + this.wss.CLOSED + ' CLOSING ' + this.wss.CLOSING + ' CONNECTING ' + this.wss.CONNECTING);
			if (this.wss.readyState !== 1) {
				this.wssConnect();
			}
			if (this.wss.readyState === 1) {
				this.wss.send(data);
			}
		}
	}
}
