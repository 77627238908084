import {Directive, HostListener, Input} from '@angular/core';
import {Route, Router} from '@angular/router';

@Directive({
	selector: '[appLink]',
	standalone: true
})
export class LinkDirective {
	@Input()
	appLink: string;

	constructor(private _router: Router) {
	}

	@HostListener('mouseup', ['$event'])
	public onClick(event: MouseEvent): void {
		if (event.button === 1 || this.appLink.includes('http')) {
			window.open(this.appLink);
		} else if (event.button === 0) {
			this._router.navigateByUrl(this.appLink);
		}
	}
}
