<div
	class="container"
	*ngIf="start && end && newStart && newEnd "
>
	<div class="center g-col-md-3">
		<div class="select_container">
			<select
				#selectBaseType [value]="baseIdleType.id"
				[ngStyle]="{'background-color': baseIdleType.color}"
				(change)="changeIdleType(baseIdleType, $event)"
				[(ngModel)]="baseIdleType.id" class="form-control"
			>
				<option
					*ngFor="let type of idleTypes"
					[disabled]='type.disabled'
					[value]="type.id"
				>
					<span>{{ type.name }}</span>
				</option>
			</select>
		</div>

		Начало простоя
		<input
			bsDatepicker
			[(ngModel)]="start"
			type="text"
			class="form-control"
			[bsConfig]="{ dateInputFormat: 'DD.MM.YYYY HH:mm', containerClass: 'theme-dark', selectFromOtherMonth: false }"
			disabled
			readonly
		/>

		Конец простоя
		<input
			bsDatepicker
			[(ngModel)]="end"
			[(bsValue)]="end"
			[bsConfig]="{ dateInputFormat: 'DD.MM.YYYY HH:mm', containerClass: 'theme-dark', selectFromOtherMonth: false }"
			type="text"
			class="form-control"
			(change)="changeDate(end)"
			(bsValueChange)='changeDate(end)'
			[minDate]="start"
			[maxDate]="newEnd"
		/>

		<hr>

		<div *ngIf="editIdle">
			<div class="select_container">
				<select
					#selectSecondType
					[value]="secondIdleType.id"
					[ngStyle]="{'background-color': secondIdleType.color}"
					(change)="changeIdleType(secondIdleType, $event)"
					[(ngModel)]="secondIdleType.id" class="form-control"
				>
					<option
						*ngFor="let type of idleTypes"
						[disabled]='type.disabled'
						[value]="type.id"
						style="background-color: #353c42;"
					>
						<span>{{ type.name }}</span>
					</option>
				</select>
			</div>

			Начало нового простоя
			<input
				bsDatepicker
				[(ngModel)]="newStart"
				[(bsValue)]="newStart"
				[bsConfig]="{ dateInputFormat: 'DD.MM.YYYY HH:mm', containerClass: 'theme-dark', selectFromOtherMonth: false }"
				type="text"
				class="form-control "
				[bsConfig]="bsConfig"
				(change)="changeDate(newStart)"
				(bsValueChange)='changeDate(newStart)'
				[minDate]="start"
				[maxDate]="newEnd"
			/>
			Конец нового простоя
			<input
				bsDatepicker
				[(ngModel)]="newEnd"
				type="text"
				class="form-control "
				[bsConfig]="bsConfig"
				disabled
				readonly
			/>

			<hr>
			<div class="slidecontainer">
				<input
					type="range"
					min={{start.getTime()}}
					max={{newEnd.getTime()}}
					step="30000"
					[(ngModel)]="sliderInterval"
					(change)="newSliderInterval()"
					class="slider"
				>
			</div>
			<hr>

		</div>

		<div *ngIf="!editIdle">
			<app-button
				class="g-button-danger-square full-btn"
				(click)="editIdleClick()"
			>Разделить простой
			</app-button>
		</div>

		<app-button
			class="g-button-success-square full-btn"
			[disabled]="!saveBtnAvailable"
			svgIcon="save"
			(click)="save()"
		>Сохранить
		</app-button>
	</div>
</div>

