import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { DEFAULT_STATE } from './mes-store.models';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'mesStore' })
export class MesStore extends Store<any> {
	constructor() {
		super({
			selectedUnit: null,
			selectedUser: null,
			taskStatuses: [],
			selectedTask: null,
			repairTaskPriority: [],
			repairTaskType: [],
			tasks: DEFAULT_STATE,
		});
	}
}
