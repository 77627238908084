import {Component, Inject} from '@angular/core';
import {DatePipe} from '@angular/common';
import {IVideoModalData} from '@shared/modals/video-modal/models/video-modal.types';
import {ButtonComponent} from '@shared/ui-components/button/button.component';
import {MatIconModule} from '@angular/material/icon';
import {DIALOG_DATA, ModalRef} from '@shared/services/modals.service';

@Component({
	selector: 'app-video-modal',
	templateUrl: './video-modal.component.html',
	styleUrl: 'video-modal.component.scss',
	standalone: true,
	imports: [
		DatePipe,
		ButtonComponent,
		MatIconModule
	]
})
export class VideoModalComponent {
	constructor(
		@Inject(DIALOG_DATA) public data: IVideoModalData,
		private _dialogRef: ModalRef<IVideoModalData>,
	) {
	}

	public closeModal(): void {
		this._dialogRef.close();
	}
}
