import {ChangeDetectionStrategy, Component, ElementRef, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ConfigService} from '@shared/services/config.service';
import {Printer} from '../../utils';
import {sleep} from '@shared/utils';

declare var DATAMatrix: any;

@Component({
	selector: 'app-qr-generator',
	templateUrl: './qr-generator.component.html',
	styleUrls: ['./qr-generator.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class QrGeneratorComponent implements OnInit {

	@Input() qrInfo = '';
	@Input() titleInfo: any;
	uniqNameBarCode = '';
	size = 400;
	sizePX = '400px';
	url: string;
	useUrl = false;
	baseQrInfo = '';
	JsBarcode: any;
	barHeight = 80;
	barWidth = 1;

	constructor(
		private _configService: ConfigService,
		private _router: Router,
		private _el: ElementRef
	) {
	}

	ngOnInit() {
		this.JsBarcode = require('jsbarcode');
		this.baseQrInfo = this.qrInfo;

		if (this._configService.getConfig(['qrApi']).qrApi) {
			this.url = this._configService.getConfig(['qrApi']).qrApi;

			this.rebuildQrInfo();
		} else {
			this.url = document.URL.replace(this._router.url, '');
		}

		if (window.innerWidth > 768) {
			this.size = window.innerHeight / 3.5;
		} else {
			this.size = window.innerWidth * 0.8;
		}

		this.sizePX = this.size + 'px';

		sleep(100).then(() => this.buildBarCode());
		this.buildDataMatrix();
	}

	rebuildQrInfo() {
		this.qrInfo = this.baseQrInfo;
		this.useUrl = !this.useUrl;

		if (this.useUrl) {
			try {
				if (this.qrInfo.toString().includes('unit')) {
					this.qrInfo = this.url + '/monitoring/unit/' + this.qrInfo.replace('unit', '');
				} else if (this.qrInfo.toString().includes('repair')) {
					this.qrInfo = this.url + '/mes/task/' + this.qrInfo.replace('repair', '');
				} else if (this.qrInfo.toString().includes('mes')) {
					this.qrInfo = this.url + 'mes';
					// tslint:disable-next-line:radix
				} else if (parseInt(this.qrInfo.toString())) {
					this.qrInfo = this.url + '/tool/' + this.qrInfo;
				}
			} catch (ex) {
				console.log(ex);
			}

			this.qrInfo = this.qrInfo.toString().replace('http://', '');
			this.qrInfo = this.qrInfo.toString().replace('https://', '');
		}

		this.buildBarCode();
		this.buildDataMatrix();
	}

	buildBarCode() {
		if (this.barWidth >= 15 || this.barWidth <= 0) {
			this.barWidth = 1;
		}

		const width = (this.barWidth - 1) * 112 + 168;

		if (width >= this._el.nativeElement.offsetWidth) {
			--this.barWidth;
			return;
		}

		this.JsBarcode('#code128', this.qrInfo, {
			width: this.barWidth,
			height: this.barHeight,
			fontSize: 0,
			margin: 25,
		});

		this.uniqNameBarCode = 'UniqBarCode';
	}

	async buildDataMatrix() {
		const svgNode = DATAMatrix({
			msg: this.qrInfo,
			pal: ['#000000', '#ffffff']
		});

		const clonedSvgElement = svgNode.cloneNode(true);
		const outerHTML = clonedSvgElement.outerHTML;
		const blob = new Blob([outerHTML], {type: 'image/svg+xml;charset=utf-8'});
		const blobURL = URL.createObjectURL(blob);

		await sleep(100);

		document
			.getElementById('data-matrix-img')
			.setAttribute('src', blobURL);
	}

	public printCode(name: string) {
		Printer.printElement(name).then();
	}
}
