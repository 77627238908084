<div class="modal">
	<div class="modal__header">
		<div class="title">{{ service.title }}</div>

		<app-button
			svgIcon="close"
			class="g-button-secondary-empty btn-close"
			(click)="closeModal()"
		></app-button>
	</div>

	<div
		class="modal__content g-column-container"
	>
		<mat-form-field
			appearance="outline"
		>
			<mat-label>Название</mat-label>

			<input type="text"
				   placeholder="Назвнание"
				   matInput
				   [formControl]="service.formControl"
			>
		</mat-form-field>

		<div class="flex-row full-height full-width g-overflow-auto">
			<section class="width50">
				<div class="section_header">

				</div>

				<div class="list">
					<app-trends-select-item @scaleAppearanceAnimation
											*ngFor="let chart of service.charts"
											class="mt-4"
											mode="add"
											[isChecked]="service.selectedChartsMap.has(chart.deviceParameterId)"
											(click)="choiceChart(chart)"
					>
						{{ chart.name }}
					</app-trends-select-item>
				</div>
			</section>

			<div class="vertical-line"></div>

			<section class="width50">
				<div class="section_header">
				</div>

				<div cdkDropList class="list"
					 cdkDropListGroup
					 (cdkDropListSorted)="dropSorted($event)"
					 (cdkDropListDropped)="drop($event)"
				>
					<app-group-trends
						*ngFor="let charts of service.selectedCharts; let i = index"
						cdkDrag
						cdkDragLockAxis="y"
						class="mt-8"
						[charts]="charts"
						[index]="i"
						[active]="selectedGroup === i"
						[class.active]="selectedGroup === i"
						[class.withoutTransition]="isDropped"
						[cdkDropGroups]="cdkDropLists"
						(activeChange)="selectActiveGroup(i)"
						(deleteChart)="deleteChart($event, i)"
						(deleteGroup)="deleteGroup(charts, i)"
					></app-group-trends>

					<app-button
						class="g-button-primary-square mx-auto mt-8"
						svgIcon="add"
						(click)="addNewGroup()"
					>
						Добавить
					</app-button>
				</div>
			</section>
		</div>
	</div>

	<div class="modal__basement">
		<app-checkbox
			class="text-middle"
			[(checked)]="service.isDefault"
		>
			Сделать активным по умолчанию
		</app-checkbox>

		<app-button
			class="g-button-primary-square g-right-side"
			(click)="save(false)"
		>
			{{ service.btnSaveTitle }}
		</app-button>

		<app-button
			class="g-button-primary-square ml-8"
			(click)="save(true)"
		>
			{{ service.btnSaveAndApply }}
		</app-button>
	</div>
</div>
