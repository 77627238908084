<div class="row">
	<div
		class="ml-auto"
	>
		<!--		<div class="float-right">-->
		<!--			<div-->
		<!--				class="open_panel"-->
		<!--				(click)="togglePanel()"-->
		<!--			>Настройка параметров сравнения <i class="icon ion-md-arrow-dropdown"></i></div>-->
		<!--		</div>-->
		<app-button
			class="g-button-primary-square"
			(click)="togglePanel()"
		>
			Настройка параметров сравнения
		</app-button>
	</div>
</div>

<ng-container>
	<div
		class="parameters_wrapper"
		[@animatePanel]=" isShowPanel ? 'open' : 'close'"
	>
		<div class="row">
			<div class="g-col-md-2">
				<div class="title">
					Выбранная программа с оборудования
				</div>
				<app-techprocess-selector
					[units]="units"
					[unitStoreName]="'unitFirst'"
					[unitId]="unitId"
					[currentProgram]="currentProgram"
				></app-techprocess-selector>
			</div>
			<div class="g-col-md-2">
				<div class="title">
					Для сравнения подберите программу
				</div>

				<div [ngClass]="{'hidden': isFileLoaded}">
					<app-techprocess-selector
						[units]="units"
						[unitId]="unitId"
						[currentStartTime]="currentProgram.start"
						[unitStoreName]="'unitSecond'"
					></app-techprocess-selector>
					<hr>
					<div class="title">...или загрузите из файла</div>

					<app-upload
						(change)="setProgramTextFromFile($event)"
					></app-upload>
				</div>
				<ng-container *ngIf="isFileLoaded">
					<hr>
					<div class="title">Файл программы загружен</div>
					<app-button
						class="g-button-danger-square inline-flex float-right"
						(click)="deleteFile()"
					>
						Удалить
					</app-button>
				</ng-container>
			</div>
		</div>
		<!--		<div-->
		<!--			*ngIf="isShowPanel"-->
		<!--			class="close_panel"-->
		<!--			(click)="togglePanel()"-->
		<!--		>-->
		<!--			<i class="icon ion-md-arrow-dropup"></i>-->
		<!--		</div>-->
	</div>
</ng-container>

@if (currentFirstProgram && currentSecondProgram) {
	<div class="full-width diff_wrapper">
		<app-text-compare
			class="flex-col g-full-max-height"
			[programFirst]="currentFirstProgram"
			[programSecond]="currentSecondProgram"
		></app-text-compare>
	</div>
}
