import { Injectable } from '@angular/core';
import { Reducer } from '@store/reducer';
import { IUnitsState, UnitsStore } from '@store/units-store/units.store';
import { IUnitFull } from '../../modules/monitoring/modules/unit/models/unit';

@Injectable({ providedIn: 'root' })
export class UnitsReducer extends Reducer<IUnitsState> {
	constructor(private s: UnitsStore) {
		super(s);
	}

	updateUnit(unit: IUnitFull): void {
		const stateUnit = this.s.getValue().mapUnitsById.get(unit.id);

		for (let key in stateUnit) {
			stateUnit[key] = unit[key];
		}
		this.s.update(this.s.getValue());
	}
}
