<ng-container>
	<app-sidebar
		*ngIf="token && !hideSidebar && isStoreLoaded && !loadingError"
		[ngClass]="{ open: isSidebarShown }"
	></app-sidebar>

	<div
		class="main"
		[ngClass]="{
			starter: !hideSidebar,
			open: isSidebarShown,
			'full-screen': url === 'dashboard' && !token
		}"
	>
		<app-header
			*ngIf="!isHeaderHidden && token && isStoreLoaded && !loadingError"
			[currentEnterprise]="currentEnterprise"
		></app-header>

		<!--		<main>-->
		<!--			<router-outlet></router-outlet>-->
		<!--		</main>-->
		<main *ngIf="(url === 'other' || url === 'dashboard') && isStoreLoaded && !loadingError; else loginPage">
			<router-outlet></router-outlet>
		</main>


	</div>
</ng-container>

<ng-template #loginPage>
	<main *ngIf="url === 'login' && (!token || !isStoreLoaded || loadingError); else dashboardPage">
		<app-login></app-login>
	</main>
	<ng-template #dashboardPage>
		<main *ngIf="url === 'dashboard' && !(isStoreLoaded && !loadingError)">
			<router-outlet></router-outlet>
		</main>
	</ng-template>
</ng-template>

<!--Данное скрытое поле заполняется с терминла ip адресами системы которая запустила фронт-->
<input
	hidden
	id="TerminalIpList"
	class="form-control"
	(change)="setSystemIpList()"
>

<notifier-container></notifier-container>

<ngx-ui-loader></ngx-ui-loader>
