import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {IPartialInterval} from '@shared/ui-components/n-inverval/models/interval.types';
import {ICreateIdle} from '@shared/models/idle';
import {IInterval} from '@shared/models/interval';
import {BaseRepository} from './base-repository';
import {IIdleChart, IIdleStat} from './api.repository';

export interface IIdleType {
	id: number;
	color: string;
	name: string;
	unitClassIdList: number[];
	durationInSeconds?: number;
}

export interface IPostIdleComment {
	id: number;
	idle_id: number;
	idle_type_id: number;
	user_id: number;
	comment: string;
}

export interface IIdleComment {
	id: number;
	comment: string;
	comment_date: string;
	user: {
		id: number;
		name: string;
	};
	idle_type: IIdleType;
}

export interface IIdle {
	id: number;
	start_idle: string;
	end_idle: string;
	downtime: number;
	unitId: number;
	comments: IIdleComment[];
}

export interface IIdleSchedule {
	id: number;
	idleType: IIdleType;
	duration: number;
	start: string;
	userId: number;
	comment: string;
	isCut: boolean;
	minIdleDuration: number;
}

export interface IUnitIdleSchedule {
	id: number;
	unitId: number;
	idleSchedule: IIdleSchedule;
}

export interface IIdleUnit {
	id: number;
	indexNumber: number | null;
	inventory_number: string;
	name: string;
	unitClassId: number;
	idles: IIdle[];
	summaryIdlesDuration: number;
	summaryIdlesDurationText: string;
	idlesByTypes: IIdleType[];
	diagramRects: IIdleType[];
}

export interface IPostIdleRedact {
	baseIdle: {
		idleId: number;
		idleTypeId: number;
		start: Date;
		end: Date;
		comment: String;
	};
	secondIdle: {
		idleId: number;
		idleTypeId: number;
		start: Date;
		end: Date;
		comment: String;
	};
}

@Injectable({
	providedIn: 'root',
})
export class IdlesRepository extends BaseRepository {
	public getIdlesTypes(): Observable<IIdleType[]> {
		const url = `${this._config.javaApiUrl}/idles/types`;

		return this._http.get(url).pipe(
			catchError(error => {
				console.log(`get error with getIdlesTypes: ${error}`);

				return of(error);
			}),
		);
	}

	public getIdleTypesByUnitClassId(id: number): Observable<IIdleType[]> {
		const url = `${this._config.javaApiUrl}/idles/unit-class/${id}`;

		return this._http.get(url).pipe(
			catchError(error => {
				console.log(`get error with getIdlesTypes: ${error}`);

				return of(error);
			}),
		);
	}

	public postIdlesTypes(payLoad: IIdleType): Observable<IIdleType[]> {
		const url = `${this._config.javaApiUrl}/idles/types/create`;

		return this._http.post(url, payLoad).pipe(
			catchError(error => {
				console.log(`get error with getIdlesTypes: ${error}`);

				return of(error);
			}),
		);
	}

	/**
	 *
	 * @param comment : {
	 * 	"idle_id": 51,
		"idle_type_id": 6,
		"user_id": 144,
		"comment": "тест3"
		}
	 */

	public addComment(comment: IPostIdleComment): Observable<IIdleComment> {
		const url = `${this._config.javaApiUrl}/idles/comment/add`;

		return this._http.post(url, comment).pipe(
			catchError(error => {
				console.log(`get error with addComment: ${error}`);

				return of(error);
			}),
		);
	}

	public createIdle(idle: ICreateIdle): Observable<boolean> {
		const url = `${this._config.javaApiUrl}/idles/create-new-idle`;

		return this._http.post(url, idle).pipe(
			catchError(error => {
				console.log(`get error with createIdle: ${error}`);

				return of(error);
			}),
		);
	}

	/**
	 *
	 * @param interval : {
		"start": "2020-04-15T00:20:01.000Z",
		"end": "2020-04-16T19:59:59.000Z"
		}
	 */
	public getUnitIdles(
		unitId: number,
		interval: IPartialInterval,
	): Observable<IIdleUnit[]> {
		const url = `${this._config.javaApiUrl}/idles/unit/${unitId}`;

		return this._http.post(url, interval).pipe(
			catchError(error => {
				console.log(`get error with getUnitIdIdles: ${error}`);

				return of(error);
			}),
		);
	}

	public getUnitIdlesActive(
		unitId: number,
		interval: IPartialInterval,
	): Observable<IIdleUnit[]> {
		const url = `${this._config.javaApiUrl}/idles/active/unit/${unitId}`;

		return this._http.post(url, interval).pipe(
			catchError(error => {
				console.log(`get error with getUnitIdIdles: ${error}`);

				return of(error);
			}),
		);
	}

	public getLocationActiveIdles(params: {
		intervalDate: IInterval;
		value: number[];
	}): Observable<IIdleUnit[]> {
		const url = `${this._config.javaApiUrl}/idles/active`;

		return this._http.post(url, params).pipe(
			catchError(error => {
				console.log(`get error with getLocationArchiveIdles: ${error}`);

				return of(error);
			}),
		);
	}

	public getLocationArchiveIdles(params: {
		intervalDate: IInterval;
		value: number[];
	}): Observable<IIdleUnit[]> {
		const url = `${this._config.javaApiUrl}/idles/archive`;

		return this._http.post(url, params).pipe(
			catchError(error => {
				console.log(`get error with getLocationArchiveIdles: ${error}`);

				return of(error);
			}),
		);
	}

	public getIdleById(id: number): Observable<IIdleUnit> {
		const url = `${this._config.javaApiUrl}/idles/idle/${id}`;

		return this._http.get(url).pipe(
			catchError(error => {
				console.log(`get error with getIdlesTypes: ${error}`);

				return of(error);
			}),
		);
	}

	public rebuildUnitIdels(params: {
		intervalDate: IPartialInterval;
		value: number[];
	}): Observable<boolean> {
		const url = `${this._config.javaApiUrl}/idles/units/rebuild`;

		return this._http.post<boolean>(url, params);
	}

	public redactIdle(payLoad: IPostIdleRedact): Observable<IPostIdleRedact> {
		const url = `${this._config.javaApiUrl}/idles/redact/`;

		return this._http.post(url, payLoad).pipe(
			catchError(error => {
				console.log(`get error with redactIdle: ${error}`);

				return of(error);
			}),
		);
	}

	public getUnitsIdlelsChart(
		ids: number[],
		interval: IPartialInterval
	): Observable<IIdleChart> {
		const payload = {
			intervalDate: {
				start: interval.start,
				end: interval.end,
			},
			value: ids,
		};

		return this._http.post<IIdleChart>(
			this._config.javaApiUrl + '/idles/timeline/units/',
			payload
		);
	}

	public getFullIdlelsStat(
		ids: number[],
		interval: IPartialInterval
	): Observable<IIdleStat[]> {
		const payload = {
			intervalDate: {
				start: interval.start,
				end: interval.end,
			},
			value: ids,
		};

		return this._http.post<IIdleStat[]>(
			this._config.javaApiUrl + '/idles/statistic/units/',
			payload
		);
	}

	public getBindingIdleTypesToUnitClass(id: number): Observable<IIdleType[]> {
		const url = `${this._config.javaApiUrl}/idles/unit-class/${id}`;
		return this._http.get<IIdleType[]>(url);
	}


}
