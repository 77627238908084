<div class="modal">
	<div class="modal__header">
		<div class="title">Добавление комментария</div>

		<app-button
			class="g-button-secondary-empty btn-close"
			svgIcon="close"
			(click)="closeModal()"
		>
		</app-button>
	</div>

	<div class="modal__content">
		<mat-form-field
			appearance="outline"
			class="mx-auto input__comment"
		>
			<mat-label>Комментарий</mat-label>
			<textarea
				#textArea
				matInput
				placeholder="Введите комментарий ..."
				[(ngModel)]="dataComment.newComment.comment"
			></textarea>
		</mat-form-field>

		<hr>

		<span>Оставленные комментарии:</span>

		<app-table
			[data]="dataComment.comments"
		>
			<ng-container *viewItem>
				<app-table-header [sizeColumn]="{minSize: '85px', maxSize: '.5fr'}">
					Дата
				</app-table-header>
				<app-table-item *lineValue="let element">
					{{ element.comment_date | date: 'dd.MM.yyyy H:mm' }}
				</app-table-item>
			</ng-container>

			<ng-container *viewItem>
				<app-table-header [sizeColumn]="{minSize: '85px', maxSize: '.5fr'}">
					Пользователь
				</app-table-header>
				<app-table-item *lineValue="let element">
					{{ element.user.name }}
				</app-table-item>
			</ng-container>

			<ng-container *viewItem>
				<app-table-header [sizeColumn]="{minSize: '85px', maxSize: '.5fr'}">
					Установленный тип
				</app-table-header>
				<app-table-item *lineValue="let element">
					{{ element.idle_type.name }}
				</app-table-item>
			</ng-container>

			<ng-container *viewItem>
				<app-table-header [sizeColumn]="{minSize: '85px', maxSize: '.5fr'}">
					Комментарий
				</app-table-header>
				<app-table-item *lineValue="let element">
					{{ element.comment }}
				</app-table-item>
			</ng-container>
		</app-table>
	</div>

	<div class="modal__basement">
		<app-button
			class="g-button-success-square font-size-16"
			(click)="setNewCommentText()"
		>
			Сохранить
		</app-button>
	</div>
</div>
