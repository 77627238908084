<div class="graph-container">
	<ngx-graph
		*ngIf="edges.length !== 0 && nodes.length !== 0"
		[view]=""
		[nodes]="nodes"
		[links]="edges"
		[update$]="update"
		[layoutSettings]="layoutSettings"
		[enableZoom]="zoomEnabled"
		[panningEnabled]="panningEnabled"
		[panOnZoom]="panOnZoom"
		[curve]="curve"
		[layout]="layout"
		[autoCenter]="true"
		[draggingEnabled]="true"
		[center$]="update"
	>
		<ng-template #defsTemplate>
			<svg:marker
				id="arrow"
				viewBox="0 -5 10 10"
				refX="8"
				refY="0"
				markerWidth="4"
				markerHeight="4"
				orient="auto"
			>
				<svg:path
					d="M0,-5L10,0L0,5"
					class="arrow-head"
				/>
			</svg:marker>
		</ng-template>

		<ng-template
			#nodeTemplate
			let-node
		>
			<svg:g class="node">
				<ng-container
					[ngTemplateOutlet]="node.id === currentNode.id ? currentNodeTemplate : defaultNode"
					[ngTemplateOutletContext]="{node:node}"
				>
				</ng-container>
				<svg:text
					alignment-baseline="central"
					[attr.x]="10"
					[attr.y]="node.dimension.height / 2"
				>{{node.label}}</svg:text>
			</svg:g>
		</ng-template>

		<ng-template
			#linkTemplate
			let-link
		>
			<svg:g class="edge">
				<svg:path
					class="line"
					stroke-width="2"
					marker-end="url(#arrow)"
				>
				</svg:path>
				<svg:text
					class="edge-label"
					text-anchor="middle"
				>
					<textPath
						class="text-path"
						[attr.href]="'#' + link.id"
						[style.dominant-baseline]="link.dominantBaseline"
						startOffset="50%"
					></textPath>
				</svg:text>
			</svg:g>
			<svg:g
				class="linkMidpoint"
				*ngIf="link.midPoint"
				[attr.transform]="'translate(' + (link.midPoint.x + 5) + ',' + link.midPoint.y + ')'"
			>
				<svg:text
					alignment-baseline="central"
					[ngStyle]="{'fill': '#ADADADFF'}"
				>{{ link.label }}</svg:text>
			</svg:g>
		</ng-template>

		<ng-template
			#currentNodeTemplate
			let-node='node'
		>
			<svg:rect
				[attr.width]="node.dimension.width"
				[attr.height]="node.dimension.height"
				[attr.fill]="node.data.customColor"
				[attr.rx]=5 [attr.ry]=5
				[attr.stroke]=stroke
				[attr.stroke-width]=6
			/>
		</ng-template>

		<ng-template
			#defaultNode
			let-node='node'
		>
			<svg:rect
				[attr.width]="node.dimension.width"
				[attr.height]="node.dimension.height"
				[attr.fill]="node.data.customColor"
				[attr.rx]=5 [attr.ry]=5
			/>
		</ng-template>
	</ngx-graph>
</div>


