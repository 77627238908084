import {IDto} from '@store/data/dto-interface';

export class MesCounterparty implements IDto {
	public id: number;
	public name: string;
	public description: string;
	public address: string;

	constructor() {
		this.id = null;
		this.name = null;
		this.description = null;
		this.address = null;
	}
}
