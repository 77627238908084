<div class="modal g-overflow-visible">
	<div class="modal__header">
		<div class="title">{{ data.title }}</div>

		<app-button
			svgIcon="close"
			class="btn-close g-button-secondary-empty"
			(click)="closeModal()"
		></app-button>
	</div>

	<div
		class="modal__content g-overflow-visible"
	>
		<app-dto-item
			[dataService]="data.service"
			(deleted)="closeModal()"
			(saved)="closeModal()"
		></app-dto-item>
	</div>
</div>
