import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { getCustomScaleAppearanceAnimation } from '@shared/animations/common.animations';
import { NgTemplateOutlet } from '@angular/common';

interface IPaginatorView {
	isView: boolean;
	value: number | null;
}

@Component({
	selector: 'app-pagination',
	templateUrl: 'pagination.component.html',
	styleUrls: ['pagination.component.scss'],
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [getCustomScaleAppearanceAnimation()],
	imports: [
		MatIconModule,
		NgTemplateOutlet,
	],
})
export class PaginationComponent implements OnInit, OnChanges {
	@Input() totalItem: number = null;
	@Input() pageSize: number = null;
	@Input() size: number;
	@Input() currentPage: number = 1;
	@Input() rangePosition: 'left' | 'right' | 'none' = 'left';

	@Output() change: EventEmitter<number> = new EventEmitter<number>();

	public view: IPaginatorView[] = null
	public currentPageTmp: number = 1;

	private initViewPaginator: (page: number) => void;
	constructor(
		private _cdr: ChangeDetectorRef,
	) {
	}

	public ngOnInit(): void {

	}

	init(): void {
		if (this.totalItem !== null && this.pageSize !== null) {
			this.size = this.totalItem === 0 ? 1 : Math.ceil(this.totalItem / this.pageSize);
		}
		this.view = [];

		if (this.size >= 6) {
			this.initViewPaginator = this.initView;
			for (let i = 0; i < 7; i++) {
				this.view.push({
					isView: true,
					value: null,
				});
			}

			this.view[0].value = 1;
			this.view[6].value = this.size;
		} else {
			this.initViewWithout();
			this.initViewPaginator = () => {
				this._cdr.markForCheck();
			};
		}

		this.initViewPaginator(this.currentPageTmp);
	}

	public ngOnChanges(): void {
		if (this.view !== null && this.currentPage !== this.currentPageTmp) {
			this.setPage(this.currentPage);
		} else {
			this.init();
		}
	}

	public next(): void {
		if (this.currentPageTmp === this.size) {
			return ;
		}

		this.currentPageTmp++;
		this.initViewPaginator(this.currentPageTmp);
		this.changePage();
	}

	public back(): void {
		if (this.currentPageTmp <= 1) {
			return ;
		}

		this.currentPageTmp--;
		this.initViewPaginator(this.currentPageTmp);
		this.changePage();
	}

	public setPage(page: number | null): void {
		if (page === null) {
			return;
		}
		if (page < 1 || page > this.size) {
			return ;
		}

		this.currentPageTmp = page;
		this.initViewPaginator(this.currentPageTmp);
		this.changePage();
	}

	private initViewWithout(): void {
		this.view = [];
		for (let i = 1; i <= this.size; i++) {
			this.view.push({
				isView: true,
				value: i,
			});
		}

		this._cdr.markForCheck();
	}

	private initView(page: number) {
		if (this.size - 2 <= page) {
			this.initEndPaginator();
		} else if (page < 4) {
			this.initStartPaginator();
		} else {
			this.view[1].value = null;

			this.view[2].value = page - 1;
			this.view[2].isView = true;
			this.view[3].value = page;
			this.view[4].value = page + 1;
			this.view[4].isView = true;

			this.view[5].value = null;
		}

		this._cdr.markForCheck();
	}

	initStartPaginator(): void {
		this.view[1].value = 2;

		this.view[2].value = 3;
		this.view[2].isView = true;
		this.view[3].value = 4;
		this.view[4].value = 5;
		this.view[4].isView = true;

		this.view[5].value = null;
	}

	initEndPaginator(): void {
		this.view[1].value = null;

		this.view[2].value = this.size - 4;
		this.view[2].isView = true;
		this.view[3].value = this.size - 3;
		this.view[4].value = this.size - 2;
		this.view[4].isView = true;

		this.view[5].value = this.size - 1;
	}

	private changePage(): void {
		this.change.emit(this.currentPageTmp);
	}

	protected readonly length = length;
}
