import {Component, Inject} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {IdleCreatorModule} from '@shared/components/idle-creator/idle-creator.module';
import {IIdleCreatorModalData} from '@shared/modals/idle-creator-modal/models/idle-creator-modal.types';
import {DIALOG_DATA, ModalRef} from '@shared/services/modals.service';
import {ButtonComponent} from '@shared/ui-components/button/button.component';

@Component({
	selector: 'app-idle-creator-modal',
	templateUrl: './idle-creator-modal.component.html',
	styleUrl: './idle-creator-modal.component.scss',
	standalone: true,
	imports: [
		MatIconModule,
		IdleCreatorModule,
		ButtonComponent
	]
})
export class IdleCreatorModalComponent {
	constructor(
		@Inject(DIALOG_DATA) public data: IIdleCreatorModalData,
		private _dialogRef: ModalRef<IdleCreatorModalComponent>,
	) {
	}

	public closeModal(): void {
		this._dialogRef.close();
	}
}
