import {firstValueFrom, fromEvent, Observable} from 'rxjs';
import {ElementRef} from '@angular/core';
import {delay, first, mergeMap} from 'rxjs/operators';

export abstract class TooltipService<T> {
	private _containerLocation: DOMRect;
	private _mouseleave$: Observable<MouseEvent>;

	abstract create(value: {
		data?: T,
		container: ElementRef<any>,
	}): void;

	abstract close(): void;
	abstract isHave(): boolean;

	protected setLocation(tooltip: HTMLElement, tooltipWidth: number) {
		const { right, left, bottom } = this._containerLocation;

		tooltip.style.left =
			(left + ((right - left) / 2) -
			(tooltipWidth / 2)) + 'px';

		tooltip.style.top = bottom + 10 + 'px';
	}

	protected getCloseEvent(tooltip: HTMLElement) {
		const tooltipLeave = firstValueFrom(fromEvent(tooltip, 'mouseleave'));

		let waitingTooltipLeaving = Promise.resolve(null);

		fromEvent(tooltip, 'mouseenter')
			.pipe(first())
			.subscribe(() => waitingTooltipLeaving = tooltipLeave);

		return this._mouseleave$.pipe(
			first(),
			delay(300),
			mergeMap(() => waitingTooltipLeaving)
		);
	}
}
