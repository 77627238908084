import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { IUnit } from '@shared/models/unit';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { IPartialInterval } from '@shared/ui-components/n-inverval/models/interval.types';
import { TableComponent } from '@shared/ui-components/table/table.component';

@Component({
	selector: 'app-statistic-units-table',
	templateUrl: './statistic-units-table.component.html',
	styleUrls: ['./statistic-units-table.component.scss'],
})
export class StatisticUnitsTableComponent implements OnChanges {
	@Input() showUnitsNames;
	@Input() selectedUnits;
	@Input() dynamicData;
	@Input() staticData;
	@Input() coefficientsDataStatistic;
	@Input() interval: IPartialInterval;
	@Input() dynamicRepairData;
	@Input() idlesData;
	@Input() isIdleInChart;
	@Input() isPrintMode = false;

	@Output() changeUnits = new EventEmitter<IUnit>();
	@Output() onChart = new EventEmitter<[any, number]>();
	@Output() onChartSync = new EventEmitter<any>();
	@Output() unitClick = new EventEmitter<void>();
	@Output() redirect = new EventEmitter<any>();
	@Output() built = new EventEmitter<void>();

	@ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;

	@ViewChild(TableComponent) tableComponent: TableComponent;

	completedBuildCanvas = 0;

	ngOnChanges(changes: SimpleChanges): void {
		this.completedBuildCanvas = 0;

		if (changes.isPrintMode) {
			this.completedBuildCanvas = 0;
		}
	}

	completeBuildCanvas(): void {
		if (!this.isPrintMode) {
			return;
		}

		this.completedBuildCanvas++;

		if (this.selectedUnits.length === this.completedBuildCanvas) {
			this.built.emit();
		}
	}

	get LOAD() {
		return this.coefficientsDataStatistic[this.selectedUnits[0].id].LOAD;
	}

	get OEE() {
		return this.coefficientsDataStatistic[this.selectedUnits[0].id].OEE;
	}

	get AQ() {
		return this.coefficientsDataStatistic[this.selectedUnits[0].id].AQ;
	}

	get MTTR() {
		return this.coefficientsDataStatistic[this.selectedUnits[0].id].MTTR;
	}

	get MTBF() {
		return this.coefficientsDataStatistic[this.selectedUnits[0].id].MTBF;
	}

	get MTTA() {
		return this.coefficientsDataStatistic[this.selectedUnits[0].id].MTTA;
	}

	get MDT() {
		return this.coefficientsDataStatistic[this.selectedUnits[0].id].MDT;
	}

	get MUT() {
		return this.coefficientsDataStatistic[this.selectedUnits[0].id].MUT;
	}

	get TASKSCOUNT() {
		return this.coefficientsDataStatistic[this.selectedUnits[0].id]
			.TASKSCOUNT;
	}
}
