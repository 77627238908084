import {ICreateIdle} from '@shared/models/idle';

export class Idle implements ICreateIdle {
	comments = [
		{
			idle_type: {
				id: 0
			},
			comment: ''
		}
	];
	end_idle: Date;
	start_idle: Date;
	unitId: number;
}
