import {Observable, BehaviorSubject} from 'rxjs';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot} from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class RouterStateService {
	private readonly _currentRouteData: BehaviorSubject<{
		id: number | null,
		componentType: string,
	}>;

	constructor() {
		this._currentRouteData = new BehaviorSubject({
			componentType: '',
			id: null
		});
	}

	public getCurrentRoute(): Observable<any> {
		return this._currentRouteData;
	}

	private isMonitoringUnit(url: string): boolean {
		return url.includes('monitoring/unit');
	}

	private isMonitoringLocationMap(url: string): boolean {
		return url.includes('monitoring/map');
	}

	private isMonitoringLocation(url: string): boolean {
		return url.includes('monitoring/');
	}

	private getMonitoringType(url: string): 'monitoring-unit' | 'monitoring-map' | 'monitoring-location' | 'default-component' {
		if (this.isMonitoringUnit(url)) {
			return 'monitoring-unit';
		}
		if (this.isMonitoringLocationMap(url)) {
			return 'monitoring-map';
		}
		if (this.isMonitoringLocation(url)) {
			return 'monitoring-location';
		}

		return 'default-component';
	}

	public setCurrentRoute(route: ActivatedRouteSnapshot): void {
		this._currentRouteData.next({
			id: route.params.id ? route.params.id : null,
			// @ts-ignore
			componentType: route._routerState.url
				// @ts-ignore
				? this.getMonitoringType(route._routerState.url)
				: ''
		});
	}
}
