<div class="flex g-width-100" style="overflow-y: hidden">
	<div style="width: 85vh;" class="g-scroll">
		<span class="g-unselectable">Общие сведения</span>
		<app-unit-info></app-unit-info>
	</div>
	<div class="gr" style="margin-left: 1.5rem;margin-right: 1.5rem;"></div>
	<div class="g-scroll" style="width: 100vh;">
		<span class="g-unselectable">Сетевые настройки</span>
		<app-unit-info [showNetwork]="true"></app-unit-info>
	</div>
</div>
