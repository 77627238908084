import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { first, map } from 'rxjs/operators';
import { getCustomUntwistingWidthAnimation } from '@shared/animations/common.animations';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { TemplateFilterService } from '@shared/components/template-unit-filter/services/template-filter.service';
import { BehaviorSubject, merge, Subject } from 'rxjs';
import { FilterService } from '@shared/components/filter/services/filter.service';
import { UnitsTemplatesService } from '@shared/components/template-unit-filter/components/units-templates/services/units-templates.service';
import { IUser } from '@store/types';
import { IUnitFull } from '../../../modules/monitoring/modules/unit/models/unit';
import { UnitFavoriteExecutor } from '@store/units-metadata-store/unit-favorite/unit-favorite.executor';
import { UnitsMetadataSelector } from '@store/units-metadata-store/units-metadata.selector';
import { UsersSelector } from '@store/user-store/users.selector';


@UntilDestroy()
@Component({
	selector: 'app-units-selector',
	templateUrl: './units-selector.component.html',
	styleUrls: ['./units-selector.component.scss'],
	animations: [getCustomUntwistingWidthAnimation(0.1)],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnitsSelectorComponent implements OnChanges, OnInit {
	@Input() isFavoriteMode = false;

	public units: IUnitFull[];
	public withoutSort = true;

	public closePopover$: Subject<void>;
	public isCanEdit$: BehaviorSubject<boolean>;

	constructor(
		private _cdr: ChangeDetectorRef,
		public filterService: FilterService,
		public unitsMetaDataSelector: UnitsMetadataSelector,
		public templateFilterService: TemplateFilterService,
		public unitGroupsService: UnitsTemplatesService,
		private _unitFavoriteExecutor: UnitFavoriteExecutor,
		private _usersSelector: UsersSelector,
	) {
		this.closePopover$ = new Subject();
		this.isCanEdit$ = new BehaviorSubject(false);
	}

	public ngOnInit(): void {
		this.filterService.getDefaultUnitListener()
			.pipe(untilDestroyed(this))
			.subscribe((units: IUnitFull[]) => {
				this.units = units;
			});

		this.initUpdatingIsCanEdit();
		this.initUpdatingPage();
	}

	private initUpdatingPage(): void {
		merge(
			this.filterService.getSelectedUnitsListener(),
			this.filterService.getDefaultUnitListener()
		).pipe(
			untilDestroyed(this),
		).subscribe(() => this._cdr.markForCheck());
	}

	private initUpdatingIsCanEdit(): void {
		this.templateFilterService.getSelectedTemplateListener()
			.pipe(
				untilDestroyed(this),
				map(
					(selectedTemplate) => this.unitGroupsService.isCanEdit(selectedTemplate)
				)
			).subscribe((isCanEdit) => this.isCanEdit$.next(isCanEdit));
	}

	public ngOnChanges(changes: SimpleChanges): void {
	}

	public async shareTemplateToUsers(
		{users, editorsIds}: { users: IUser[], editorsIds?: Record<string, boolean> }
	): Promise<void> {
		const selectedTemplate = this.templateFilterService.getSelectedTemplate();

		const unitTemplateUserList = users.map((user) => ({
			userId: user.id,
			isEdit: editorsIds ? editorsIds[user.id] : false,
			isDefault: this.unitGroupsService.isDefaultForUser(selectedTemplate, user),
		}));

		const currentUserId = this._usersSelector.getCurrentUser()?.id;
		const currentUserInTemplate = users.find(({id}) => id === currentUserId);

		if (!currentUserInTemplate) {
			unitTemplateUserList.push({
				userId: currentUserId,
				isEdit: true,
				isDefault: this.unitGroupsService.isDefaultForUser(
					selectedTemplate,
					currentUserInTemplate
				),
			});
		}

		await this.unitGroupsService.updateSelectedTemplate({unitTemplateUserList});

		this.closePopover$.next();
	}


	public async updateSelectedTemplate(): Promise<void> {
		const selectedUnits = this.filterService.getSelectedUnits();
		const unitTemplateOrderList = selectedUnits.map(({id}, index) => ({
			unitId: id,
			order: index,
		}));

		await this.unitGroupsService.updateSelectedTemplate({
			unitTemplateOrderList
		});
	}

	public cancelChanges(): void {
		const selectedTemplate = this.templateFilterService.getSelectedTemplate();

		this.templateFilterService.setSelectedTemplate(selectedTemplate);
	}

	public handleListDropped(event: CdkDragDrop<string[]>): void {
		const selectedUnits = this.filterService.getSelectedUnits();
		moveItemInArray(selectedUnits, event.previousIndex, event.currentIndex);

		this.filterService.setSelectedUnits(selectedUnits);
	}

	public async createNewGroup(name: string): Promise<void> {
		await this.unitGroupsService.createTemplate(name, []);
		this.closePopover$.next();
	}

	public async editNameSelectedGroup(name: string): Promise<void> {
		await this.unitGroupsService.updateSelectedTemplate({name});
		this.closePopover$.next();
	}

	public selectAll(): void {
		const data: IUnitFull[] = this.units;

		this.saveInFilterService(data);
	}

	public dropAll(): void {
		this.saveInFilterService([]);
	}

	public selectUnit(unit: IUnitFull): void {
		const data = this.toggleUnitInReportList(unit);
		this.saveInFilterService(data);
	}

	private toggleUnitInReportList(unit: IUnitFull): IUnitFull[] {
		const indexUnit = this.filterService.getSelectedUnits()
			?.findIndex((item) => item.id === unit.id);

		const data = this.filterService.getSelectedUnits();

		if (indexUnit !== -1) {
			data.splice(indexUnit, 1);
		} else {
			data.push(unit);
		}

		return data;
	}

	public addOrRemoveFromFavourites(unit): void {
		this._unitFavoriteExecutor.addOrRemoveFromFavourites(unit.id)
			.pipe(first())
			.subscribe(() => {
				this._cdr.markForCheck();
			});
	}

	private saveInFilterService(units: IUnitFull[]): void {
		if (this.withoutSort) {
			this.filterService.setSelectedUnitsWithSort(units);
		} else {
			this.filterService.setSelectedUnits(units);
		}
	}

	protected readonly ChannelMergerNode = ChannelMergerNode;
	protected readonly first = first;
}
