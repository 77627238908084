<div class="checkbox">
	<app-checkbox *ngIf="mode === 'add'"
				  [checked]="isChecked"
				  (checkedChange)="click.emit()"
	></app-checkbox>
</div>

<span class="ml-8"> <ng-content></ng-content> </span>

<div *ngIf="mode === 'delete'"
	 class="g-right-side delete"
	 (click)="delete.emit()"
>
	<mat-icon svgIcon="delete"
	></mat-icon>
</div>
