import {Component, Input, OnInit} from '@angular/core';

import {WebsocketService} from '@shared/services/data.service';
import {NotifierService} from 'angular-notifier';
import {TableModule} from '@shared/ui-components/table/table.module';
import {CheckboxComponent} from '@shared/ui-components/checkbox/checkbox.component';
import {ButtonComponent} from '@shared/ui-components/button/button.component';

@Component({
	selector: 'app-unit-subscription',
	templateUrl: './unit-subscription.component.html',
	styleUrls: ['./unit-subscription.component.scss'],
	standalone: true,
	imports: [
		TableModule,
		CheckboxComponent,
		ButtonComponent
	]
})
export class UnitSubscriptionComponent implements OnInit {
	@Input() unitId: number;

	currentUser: string;
	userId: number;
	subscription: any;
	saveBtnAvailable = false;
	isSelectedAllPush = false;
	isSelectedAllEmail = false;
	subscriptionBodies: any = [];

	constructor(
		private dataService: WebsocketService,
		private notifierService: NotifierService,
	) {
	}

	public async ngOnInit(): Promise<void> {
		this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
		this.userId = this.currentUser['userId'];

		const body = {
			userId: this.userId,
			unitsId: typeof this.unitId === 'number' ? [this.unitId] : null,
		};

		console.log('body: ', body);

		this.subscriptionBodies = await this.dataService
			.send('subscription/getUserSubscription', body);

		console.log('subscriptionBobies: ', this.subscriptionBodies);
	}

	public save(): void {
		this.dataService
			.send('subscription/saveUserSubscription', this.subscriptionBodies)
			.then(data => {
				this.subscriptionBodies = data;
			});

		this.notifierService.notify(
			'success',
			'Успешно сохранено.',
		);

		this.saveBtnAvailable = false;
	}

	public clickCheckBoxPush(subscribe: any): void {
		this.saveBtnAvailable = true;

		if (subscribe.email) {
			subscribe.email = false;
		}
	}

	public clickCheckBoxEmail(subscribe: any): void {
		this.saveBtnAvailable = true;

		if (!subscribe.push) {
			subscribe.push = true;
		}
	}

	selectAllPush(): void {
		this.isSelectedAllPush = !this.isSelectedAllPush;

		this.changeAllPush();
	}

	private changeAllPush(): void {
		if (this.isSelectedAllPush === true) {
			this.subscriptionBodies.forEach(b => (b.push = true));
		} else {
			this.subscriptionBodies.forEach(b => (b.push = false));
		}
	}

	selectAllEmail(): void {
		this.isSelectedAllEmail = !this.isSelectedAllEmail;

		this.changeAllEmail();
	}

	private changeAllEmail(): void {
		if (this.isSelectedAllEmail === true) {
			this.subscriptionBodies.forEach(b => (b.email = true));
		} else {
			this.subscriptionBodies.forEach(b => (b.email = false));
		}
	}
}
