@if (node) {
	<div
		class="node-item"
		(click)="click()"
		(mousedown)="mouseDown()"
		(mouseup)="mouseUp()"
	>
		<div
			[class.node-item__arrow]="node?.children?.length"
			[class.node-item__arrow_open]="node?.isOpen"
		></div>

		@if (useCheckbox) {
			<app-checkbox
				appStopPropagation
				[isStopPropagation]="!node.children?.length"
				[class.node-item_found]="node.isFound"
				[checked]="node.checked"
				[fill]="node?.isSomeChildrenSelected && !node.checked"
				[isOffChangesByClickTitle]="!!node.children?.length"
				[isStopPropagationByClickOnCheck]="!!node.children?.length"
				(change)="node.select()"
			>
				<ng-container *ngTemplateOutlet="text"></ng-container>
			</app-checkbox>
		} @else {
			<ng-container *ngTemplateOutlet="text"></ng-container>
		}

		@if ((node?.isSomeChildrenSelected && !node.checked) || (node.isOpen && node.children?.length)) {
			<button
				class="node-item__button"
				(click)="node.openAllVisibleChildren()"
				appStopPropagation
				appTooltip
				[appTooltip]="node?.isOpen ? 'Закрыть все' : 'Раскрыть все выделенные элементы'"
			>
				<mat-icon [svgIcon]="node?.isOpen ? 'close' : 'visibility'"></mat-icon>
			</button>
		}
	</div>
}

<ng-template #text>
	<ng-content></ng-content>
</ng-template>
