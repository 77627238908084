<app-search
	[isShowIcon]="false"
	[value]="lineOfSearch$.getValue()"
	[placeholder]="'Фильтр'"
	(valueChange)="lineOfSearch$.next($event)"
></app-search>


<mat-radio-group
	[ngModel]="templateFilter.getSelectedTemplate()?.id"
>
	<div class="item">
		<mat-radio-button
			class="item__radio"
			[value]="null"
			(click)="templateFilter.setSelectedTemplate(null)"
		>
			<span class="g-unselectable">Ничего не выбрано</span>
		</mat-radio-button>
	</div>

	<app-scroll
		class="flex"
		[style.height]="amountOfGroups > 5 ? '150px' : amountOfGroups * 32 + 'px'"
		[usePaddingBottom]="amountOfGroups > 5"
		[data]="filteredTemplates$.getValue()"
	>
		<div
			*scrollItem="let template"
			class="item"
			appStopPropagation
			(click)="templateFilter.setSelectedTemplate(template)"
		>
			<mat-radio-button
				class="item__radio"
				[checked]="templateFilter.getSelectedTemplate()?.id === template.id"
				[value]="template.id"
			>
				<app-selector-letters
					class="g-unselectable"
					[value]="template.name"
					[searchString]="lineOfSearch$.getValue()"
				></app-selector-letters>
				@if (isDefault(template)) {
					<span class="g-unselectable"> (По умолчанию)</span>
				}
			</mat-radio-button>

			@if (useButton) {
				<div class="item__buttons">
					<app-checkbox
						appStopPropagation
						(click)="unitsTemplatesService.selectAsDefault(template)"
						[checked]="isDefault(template)"
						appTooltip="Сохранить шаблон по умолчанию"
					>
					</app-checkbox>

					@if (unitsTemplatesService.isCanEdit(template)) {
						<app-button
							class="g-button-primary-empty item__button"
							svgIcon="edit"
							appStopPropagation
							appTooltip="Поменять название"
							appPopover
							[popoverTemplateRef]="editGroup"
							[close$]="closePopover$"
							(click)="selectedTemplate = template"
						></app-button>
					}

					<app-button
						class="g-button-danger-empty item__button"
						svgIcon="trash-outline"
						appStopPropagation
						appTooltip="Удалить"
						appPopover
						[popoverTemplateRef]="deleteGroup"
						[close$]="closePopover$"
						(click)="selectedTemplate = template"
					></app-button>
				</div>
			}
		</div>
	</app-scroll>

</mat-radio-group>

<ng-template #editGroup>
	<div class="template-edit-group">
		<mat-form-field
			appearance="outline"
			class="g-full-width"
		>
			<mat-label>Новое название шаблона</mat-label>
			<input
				#input
				[autofocus]="true"
				(keyup)="$event.key === 'Enter' ? editNameOfSelectedTemplate(input.value, selectedTemplate) : null"
				matInput
				type="text"
			>
		</mat-form-field>
		{{ input.focus() }}
		<app-button
			class="g-button-secondary-square"
			svgIcon="save-classic"
			[loading]="unitsTemplatesService.isLoadingCreate$.getValue()"
			(click)="editNameOfSelectedTemplate(input.value, selectedTemplate)"
		></app-button>
	</div>
</ng-template>

<ng-template #deleteGroup>
	<div class="template-delete-group">
		<span class="text-middle">
			Вы точно уверены, что хотите удалить шаблон?
		</span>
		<app-button
			class="g-button-danger-square"
			[text]="'Удалить'"
			[loading]="unitsTemplatesService.isLoadingDelete$.getValue()"
			(click)="deleteTemplateById(selectedTemplate.id)"
		></app-button>
	</div>
</ng-template>
