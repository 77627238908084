export type IStateStatus = 'default' | 'loading' | 'success' | 'failure'

export interface IState<T = any> {
	status: IStateStatus;
	value: T;
	items: T[];
}

export const DEFAULT_STATE: IState = {
	status: 'default',
	value: null,
	items: []
};
