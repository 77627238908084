import {Component, Input} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {sleep} from '../../../../utils';
import {TreeNode} from '@shared/ui-components/tree/services/tree-node';

@UntilDestroy()
@Component({
	selector: 'app-tree-node',
	templateUrl: './tree-node.component.html',
	styleUrls: ['./tree-node.component.scss'],
})
export class TreeNodeComponent {
	@Input() node?: TreeNode;

	@Input() disabled = false;
	@Input() useCheckbox = true;

	private _flagClick = false;
	private _longClicked = false;

	public click(): void {
		if (this._longClicked) {
			this._longClicked = false;
			return;
		}

		if (this.node?.children?.length) {
			this.node.open();
		} else {
			this.node.select();
		}
	}

	public async mouseDown(): Promise<void> {
		this._flagClick = true;

		await sleep(200);

		if (this._flagClick) {
			if (!this.disabled) {
				this.node.select();
			}
			this._longClicked = true;
		}
	}

	public mouseUp(): void {
		this._flagClick = false;
	}
}
