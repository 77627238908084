import {Injectable} from '@angular/core';
import {TrendsTemplateModalService} from './trends-template-modal.service';
import {Observable} from 'rxjs';
import {IUnitDeviceParameter} from '@shared/services/unit.service';
import {FormControl, Validators} from '@angular/forms';
import {TrendsTemplateRepository} from '@shared/repositories/trends-template.repository';
import {
	ITrendsTemplate,
	ITrendsTemplateCreate,
	ITrendsTemplateParameter,
	ITrendsTemplateUpdate
} from '../../../../modules/monitoring/modules/unit/components/unit-tabs-area/components/trends/components/trends-template/models/trends-template.models';

@Injectable({providedIn: 'root'})
export class TrendsTemplateModalEditService extends TrendsTemplateModalService {
	title: string = 'Редактирование';
	btnSaveTitle: string = 'Сохранить';
	btnSaveAndApply: string = 'Сохранить и применить';
	formControl: FormControl;
	isDefault: boolean;

	charts: IUnitDeviceParameter[];
	selectedCharts: IUnitDeviceParameter[][];
	selectedChartsMap: Map<number, IUnitDeviceParameter>;

	templateId: number;

	constructor(private _trendsTemplateService: TrendsTemplateRepository) {
		super();
	}

	init(charts: IUnitDeviceParameter[], template: ITrendsTemplate): void {
		const chartsMap: Map<number, IUnitDeviceParameter> = new Map();
		this.selectedChartsMap = new Map();
		this.selectedCharts = [];
		this.formControl = new FormControl(template.name, [Validators.required]);
		this.templateId = template.id;
		this.isDefault = template.isDefault;

		charts.forEach((item: IUnitDeviceParameter) => {
			chartsMap.set(item.deviceParameterId, item);
		});

		let trendNumber = -1;

		template.userUnitParameterTemplates.forEach((item: ITrendsTemplateParameter) => {
			this.selectedChartsMap.set(item.deviceParameterId, chartsMap.get(item.deviceParameterId));

			if (item.trendNumber > trendNumber) {
				trendNumber++;
				this.selectedCharts.push([]);
			}

			this.selectedCharts[trendNumber].push(chartsMap.get(item.deviceParameterId));
		});


		this.charts = charts;
	}

	save(unitId: number, userId: number): Observable<ITrendsTemplate> {
		const tmp: ITrendsTemplateCreate = this.preparationData(unitId, userId);
		const data: ITrendsTemplateUpdate = {
			...tmp,
			id: this.templateId
		};

		return this._trendsTemplateService.updateTemplate(data);
	}
}
