import {Directive, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges} from '@angular/core';

@Directive({selector: '[appExpandRow]'})
export class ExpandRowDirective implements OnInit, OnChanges {
	@Input() isOpen = false;
	@Output() isOpenChange = new EventEmitter();

	public parentNode;

	constructor(
		private _el: ElementRef,
		private _renderer: Renderer2
	) {
		this.parentNode = this._el.nativeElement.parentNode;

		this._el.nativeElement.classList.add('pointer', 'expandControl');
		this._el.nativeElement.parentNode?.classList?.add('expandControl__wrapper');
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (!!changes.isOpen) {
			this.checkStateExpand();
		}
	}

	public ngOnInit(): void {
		if (this._el.nativeElement.classList.contains('treeRoot')) {
			this.expandRow();
		}

		const nextElement = this._el.nativeElement.nextElementSibling;
		if (Array.from(nextElement.classList).includes('expand')) {
			this._renderer.listen(
				this.parentNode,
				'click',
				() => this.expandRow(),
			);
			this._renderer.listen(
				nextElement,
				'click',
				(event) => event.stopPropagation(),
			);
		} else {
			this._renderer.listen(
				this._el.nativeElement,
				'click',
				() => this.expandRow(),
			);
		}
	}

	private expandRow(): void {
		this.isOpen = !this.isOpen;

		this.checkStateExpand();

		this.isOpenChange.emit(this.isOpen);
	}

	private checkStateExpand(): void {
		const parentNode = this._el.nativeElement.parentNode;
		const expand = parentNode.querySelector('.expand');

		if (this.isOpen) {
			expand.classList.add('open');
			parentNode?.classList.remove('expandControl__wrapper');

			this._el.nativeElement.classList.add('open');
		} else {
			expand.classList.remove('open');
			parentNode?.classList.add('expandControl__wrapper');

			this._el.nativeElement.classList.remove('open');
		}
	}
}
