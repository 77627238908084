import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { cloneDeep } from 'lodash';

import { ProgramCompareStore } from './program-compare.store';

@Injectable({ providedIn: 'root' })
export class ProgramCompareQuery extends Query<any> {
	showProgramPanel$ = this.select('showProgramPanel');

	constructor(
		protected programStore: ProgramCompareStore
	) {
		super(programStore);
	}

	getTechprocessesSubscriber(unitStoreName: string) {
		return this.select(unitStoreName + 'Techprocesses');
	}
	getProgramsSubscriber(unitStoreName: string) {
		return this.select(unitStoreName + 'Programs');
	}
	getProgramTextSubscriber(unitStoreName: string) {
		return this.select(unitStoreName + 'SelectedProgram');
	}

	getPrograms(unitStoreName: string) {
		return cloneDeep(this.getValue()[unitStoreName + 'Programs']);
	}
	getSelectedProgram(unitStoreName: string) {
		return this.getValue()[unitStoreName + 'SelectedProgram'];
	}
	getPanelState() {
		return this.getValue().showProgramPanel;
	}
}
