import {Component, Inject, OnInit} from '@angular/core';
import {filter} from 'rxjs/operators';
import {NavigationEnd, Router} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {IScheduleInfoModalData} from '@shared/modals/schedule-info/models/schedule-info-modal.types';
import {DIALOG_DATA, ModalRef} from '@shared/services/modals.service';

@UntilDestroy()
@Component({
	selector: '',
	templateUrl: './schedule-info-modal.component.html',
	styleUrls: [
		'./schedule-info-modal.component.scss',
	]
})
export class ScheduleInfoModalComponent implements OnInit {
	public message: string;
	public isNotNeedScheduleRuleDeleteInfo: boolean;

	constructor(
		@Inject(DIALOG_DATA) public data: IScheduleInfoModalData,
		private _dialogRef: ModalRef<ScheduleInfoModalComponent>,
		private _router: Router,
	) {
		this.isNotNeedScheduleRuleDeleteInfo = false;
	}

	public ngOnInit(): void {
		this.message = this.data.isAutocomplete
			? 'Работник будет удален из последующих ' +
			'дней для данной смены по выбранному станку,' +
			' вплоть до следующей записи'
			: 'Работник будет удален из выбранного дня ' +
			'для данной смены по выбранному станку';

		this._dialogRef.setDataForOutsideClosing({
			isDelete: false,
			isNotNeedScheduleRuleDeleteInfo: false,
		});

		this.initListeners();
	}

	public closeModal(isDelete = false): void {
		this._dialogRef.close({
			isDelete,
			isNotNeedScheduleRuleDeleteInfo: this.isNotNeedScheduleRuleDeleteInfo
		});
	}

	private initListeners(): void {
		this._router.events
			.pipe(
				filter(event => event instanceof NavigationEnd),
				untilDestroyed(this)
			).subscribe(() => this.closeModal());
	}
}
