<div class="modal">
	<div class="modal__header">
		<div class="title">Вы точно хотите удалить?</div>

		<app-button
			class="g-button-secondary-empty btn-close"
			svgIcon="close"
			(click)="closeModal()"
		></app-button>
	</div>

	<div
		class="modal__content g-column-container g-center-h"
		[style.max-width]="'350px'"
	>
		<span>{{ message }}</span>
		<app-checkbox
			class="checkbox"
			[(checked)]="isNotNeedScheduleRuleDeleteInfo"
		>
			Больше не показывать
		</app-checkbox>
		<div class="g-line-container g-center-h">
			<app-button
				class="g-button-secondary-square"
				(click)="closeModal()"
			>
				Отменить
			</app-button>

			<app-button
				class="g-button-danger-square"
				(click)="closeModal(true)"
			>Удалить
			</app-button>
		</div>
	</div>
</div>
