<div class="modal g-overflow-visible">
	<div class="modal__header">
		<div class="title">Необходимо сменить пароль!</div>

		<app-button
			svgIcon="close"
			class="btn-close g-button-secondary-empty"
			(click)="closeModal()"
		></app-button>
	</div>

	<div
		class="modal__content g-overflow-visible"
		style="align-items: center"
	>
		Для смены пароля перейдите по <a style="color: #4f8bee; cursor: pointer"
										 (click)="goToPageToResetPassword()">ссылке</a>.
	</div>
</div>
